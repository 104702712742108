<div class="help-text mt-1">
    <div
        *ngIf="control.invalid && (control.dirty || control.touched)"
        class="alert alert-danger"
        @fadeIn
        @fadeOut
    >
        <p class="mb-1" *ngFor="let errorKey of errorKeys">
            {{ getErrorMessage(errorKey) }}
        </p>
    </div>
</div>
