import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UserCacheService {
    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.backendUrl + '/rest/usercache/';
    }

    public saveData(
        path: string,
        data: any,
        projectId?: string
    ): Observable<any> {
        const callUrl = this.createCallUrl(path);
        if (projectId) {
            return this.http.post(callUrl, data, {
                params: { projectId: projectId },
            });
        } else {
            return this.http.post(callUrl, data);
        }
    }

    public loadData(path: string, projectId?: string): Observable<any> {
        const callUrl = this.createCallUrl(path);
        if (projectId) {
            return this.http.get(callUrl, { params: { projectId: projectId } });
        } else {
            return this.http.get(callUrl);
        }
    }

    private createCallUrl(path: string): string {
        let callUrl = this.baseUrl;
        if (path.startsWith('/')) {
            callUrl += path.substring(1);
        } else {
            callUrl += path;
        }
        return callUrl;
    }
}
