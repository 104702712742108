import { Injectable } from '@angular/core';
import { SceneElement } from 'src/app/scenes/models/scene-element';
import * as Skill from '../models/layer-namespace';
import { LayerModel, selectableCategories } from '../models/layer-namespace';
import { ParsedEntity } from './drawing/parsed-entity';
import { InputLayerSceneElement } from './drawing/input-layer-scene-element';

@Injectable({
    providedIn: 'root',
})
export class EntityParserService {
    constructor() {}

    public parseEntity(entity: Skill.SkillArchitectureEntity): ParsedEntity {
        let drawableLayers = this.findDrawableLayer(entity);
        let linksWithIndices = this.findLinksWithIndices(drawableLayers);
        return {
            layers: drawableLayers,
            links: linksWithIndices,
        };
    }

    private findDrawableLayer(entity: Skill.SkillArchitectureEntity) {
        let drawableLayers = [];

        for (let layer of entity.model.layers) {
            drawableLayers.push({
                id: layer.name,
                type: layer.class_name,
                name: layer.config.name,
                color: this.detectLayerColor(layer.class_name),
            });
        }

        return drawableLayers;
    }

    private findLinksWithIndices(drawableLayers: Array<any>) {
        let linksWithIndices = [];

        for (let i = 0; i < drawableLayers.length - 1; i++) {
            linksWithIndices.push({
                source: i,
                target: i + 1,
            });
        }
        return linksWithIndices;
    }

    private detectLayerColor(className: string): string {
        const layerCategory = selectableCategories.find(
            (category) => Object.values(category.type).indexOf(className) !== -1
        );

        return layerCategory ? layerCategory.color : '#000';
    }
}
