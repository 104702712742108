<section class="input-group">
    <div class="input-wrapper">
        <div *ngIf="hasCheckbox" class="tryb-checkbox">
            <input
                type="checkbox"
                (change)="toggleCheckboxAndInput()"
                [formControl]="statusControl"
            />
            <img
                class="unchecked-icon"
                src="/assets/svg/cost-function/checkbox.svg"
                alt="Unchecked checkbox"
            />
            <img
                class="checked-icon"
                src="/assets/svg/cost-function/checkbox-checked.svg"
                alt="Checked checkbox"
            />
        </div>
        <div
            class="custom-input"
            [ngClass]="{
                error: valueControl.invalid,
                filled: valueControl.value
            }"
        >
            <input
                #textInput
                type="text"
                (change)="formatAndUpdateValue(false)"
                (keyup.enter)="formatAndUpdateValue(true)"
                (input)="limitDecimalPlaces()"
                (focus)="inputFocused()"
                (focusout)="keepFocusOnInvalidInput($event)"
                [formControl]="valueControl"
                [ngClass]="{
                    'show-disabled-value': showDisabledValue,
                    focused: forceFocus
                }"
            />
            <div *ngIf="hasSteppers" class="steppers position-absolute">
                <div
                    class="stepper up"
                    (click)="changeValueWithStepper('+')"
                    (mouseleave)="forceInputBlur()"
                >
                    <img
                        class="info-icon d-block"
                        src="assets/svg/algorithm/stepper-arrow.png"
                        alt="upward arrow"
                    />
                </div>
                <div
                    class="stepper down"
                    (click)="changeValueWithStepper('-')"
                    (mouseleave)="forceInputBlur()"
                >
                    <img
                        class="info-icon d-block"
                        src="assets/svg/algorithm/stepper-arrow.png"
                        alt="downward arrow"
                    />
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="!disabled && valueControl && valueControl.errors"
        class="alert alert-danger"
    >
        {{ getErrorMessage(valueControl) }}
    </div>
</section>
