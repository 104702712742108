import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { SceneVisualService } from '../../../scenes/services/scene-visual.service';
import {
    ChartItem,
    ChartPoint,
} from 'src/app/shared/components/charts/models/chart-item';
import { interval, Subscription } from 'rxjs';

const source = interval(2000);

@Component({
    selector: 'app-player-dialog',
    templateUrl: './player-dialog.component.html',
    styleUrls: ['./player-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    // providers: [SceneVisualService], TODO: evaluate
})
export class PlayerDialogComponent implements OnInit {
    state: ChartItem;
    currentPoint: ChartPoint;
    subscription: Subscription;
    running = false;

    constructor(
        private dialog: MatDialog,
        public sceneVisualService: SceneVisualService,
        @Inject(MAT_DIALOG_DATA) public data: { state: ChartItem }
    ) {}

    ngOnInit(): void {
        this.state = this.data.state;
        this.currentPoint = this.state.points[0];
        this.startOrPause();
    }

    @ViewChild('container')
    set container(container: ElementRef) {
        this.sceneVisualService.initialize(container.nativeElement);
    }

    closeDialog() {
        this.dialog.closeAll();
    }

    onClickTimePoint(point: ChartPoint) {
        this.currentPoint = point;
    }

    startOrPause() {
        if (this.running) {
            this.subscription?.unsubscribe();
            this.running = false;
        } else {
            this.subscription = source.subscribe(() => {
                if (this.currentPoint.x < this.state.points.length - 1) {
                    this.currentPoint =
                        this.state.points[this.currentPoint.x + 1];
                    console.log(this.currentPoint.y);
                }
            });
            this.running = true;
        }
    }
}
