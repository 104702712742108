<div class="d-flex justify-content-start">
    <div class="user-picture-container">
        <img
            alt="User picture"
            class="user-picture"
            [src]="
                userInfo.profilePicture
                    ? userInfo.profilePicture
                    : 'assets/svg/profile.svg'
            "
        />
    </div>
    <div class="user-text-container d-flex flex-column justify-content-between">
        <div class="pt-2">
            <p class="name-info">
                {{ getDisplayName() }}
            </p>
            <p class="other-info">
                {{ getTranslationKeyForRole() | translate }}
            </p>
        </div>
        <div class="pb-2" *ngIf="userInfo.company">
            <p class="other-info">
                {{ userInfo.company }}
            </p>
        </div>
    </div>
</div>
