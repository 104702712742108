export enum AlgorithmType {
    PPO = 'PPO',
    ADAM = 'ADAM',
    SAC = 'SAC',
}

export enum Policy {
    MLP_POLICY = 'MLP_POLICY',
    MLP_LSTM_POLICY = 'MLP_LSTM_POLICY',
    MLP_LN_LSTM_POLICY = 'MLP_LN_LSTM_POLICY',
    CNN_POLICY = 'CNN_POLICY',
    CNN_LSTM_POLICY = 'CNN_LSTM_POLICY',
    CNN_LN_LSTM_POLICY = 'CNN_LN_LSTM_POLICY',
}

export enum FrequencyUnit {
    STEP = 'STEP',
    EPISODE = 'EPISODE',
}

export enum ActionNoiseType {
    NORMAL = 'NORMAL',
    ORNSTEIN_UHLENBECK = 'ORNSTEIN_UHLENBECK',
    NONE = 'NONE',
}

export enum ReplayBufferClass {
    REPLAY_BUFFER = 'REPLAY_BUFFER',
    NONE = 'NONE',
}

export interface AlgorithmDTO {
    type: AlgorithmType;
    parameter: Object;
    advancedSetting: boolean;
}
