import { Layer } from './layer-configs/layer';
import {
    AttentionLayerType,
    ConvolutionalLayerType,
    FullyConnectedLayerType,
    NormalizationLayerType,
    PoolingLayerType,
    // RandomizationLayerType,
    // PreprocessingLayerType,
    TransformationLayerType,
    WrapperLayerType,
} from './layer-type';

export type InboundNodesType = [string, number, number, object][][];
export type IOLayerType = [string, number, number][];

export class LayerModel {
    class_name: string;
    config: Layer;
    name: string;
    inbound_nodes: InboundNodesType = [];
}

export class SkillArchitecture {
    name = 'skill';
    layers: LayerModel[] = [];
    input_layers: IOLayerType = [];
    output_layers: IOLayerType = [];
}

export class SkillArchitectureEntity {
    id: string;
    name: string;
    creator: string;
    created: Date;
    model: SkillArchitecture;
}

export type SkillArchitectureCategory = {
    name: string;
    type: unknown;
    color: string;
    dotsColor: string;
};

export const selectableCategories: SkillArchitectureCategory[] = [
    // { // TODO: Special treatment
    //     name: 'Input',
    //     type: InputLayerType,
    // },
    // {
    //     name: 'Convolutional',
    //     type: ConvolutionalLayerType,
    //     color: '#0c2e78',
    //     dotsColor: '#314d8c'
    // },
    {
        name: 'Fully-connected',
        type: FullyConnectedLayerType,
        color: '#009de1',
        dotsColor: '#0a84b9',
    },
    // {
    //     name: 'Attention',
    //     type: AttentionLayerType,
    //     color: '#ff6201',
    //     dotsColor: '#cd5d26'
    // },
    {
        name: 'Normalization',
        type: NormalizationLayerType,
        color: '#e4007e',
        dotsColor: '#c20970',
    },
    // {
    //     name: 'Transformation',
    //     type: TransformationLayerType,
    //     color: '#951b81',
    //     dotsColor: '#7e1f70'
    // },
    // {
    //     name: 'Pooling',
    //     type: PoolingLayerType,
    //     color: '#26272b',
    //     dotsColor: '#3f424d'
    // },
    // {
    //     name: 'Wrapper',
    //     type: WrapperLayerType,
    //     color: '#757e95',
    //     dotsColor: '#6b7387'
    // },
    // TODO: Tensorflow 2.7 compat
    // {
    //     name: 'Randomization',
    //     type: RandomizationLayerType,
    // },
    // {
    //     name: 'Preprocessing',
    //     type: PreprocessingLayerType,
    // },
    // TODO: Complex architectures support
    // {
    //     name: 'Combine',
    //     type: CombineLayerType,
    // }
];
