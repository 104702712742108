import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfo } from 'src/app/shared/models/user-info';
import { ContainerService } from 'src/app/shared/services/container/container.service';
import { Container } from 'src/app/shared/services/container/container.types';

@Component({
    selector: 'app-project-summary',
    templateUrl: './project-summary.component.html',
    styleUrls: ['./project-summary.component.scss'],
})
export class ProjectSummaryComponent implements OnInit {
    project: Container;
    userInfo: UserInfo;
    imageUrl: string;
    notificationText: string;
    notificationParam: string;

    constructor(
        private containerService: ContainerService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit(): void {
        const cId = String(this.route.snapshot.paramMap.get('projectId'));
        if (cId === 'undefined') {
            this.router.navigate(['/project/**']);
            return;
        }
        this.containerService.getById(cId).subscribe((data) => {
            if (data === undefined) {
                this.router.navigate(['/project/**']);
                return;
            }
            this.project = data;
            this.determineProjectPicture();
            this.containerService.getOwner(cId).subscribe((memberInfo) => {
                this.userInfo = {
                    ...memberInfo,
                    profilePicture: memberInfo.profilePicture
                        ? 'data:image/jpeg;base64,' + memberInfo.profilePicture
                        : null,
                };
            });
        });
    }

    routeToProjectWorkspace(): void {
        this.router.navigate(['/project', this.project.id]);
    }

    closeNotification(): void {
        this.notificationText = null;
    }

    joinProject(): void {
        this.notificationText =
            'project.components.projectOverview.projectOverviewComponent.requestCreated';
        this.notificationParam = this.project.name;
        this.containerService.createJoinRequest(this.project.id).subscribe();
    }

    determineProjectPicture(): void {
        if (
            this.project.pictureLocation &&
            this.project.pictureLocation.length > 0
        ) {
            this.imageUrl = this.project.pictureLocation;
        } else {
            this.imageUrl = 'assets/img/card-icons/default-item.png';
        }
    }
}
