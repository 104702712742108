import { Injectable } from '@angular/core';
import * as Skill from '../models/layer-namespace';
import * as d3 from 'd3';
import { BaseType } from 'd3';
import { EntityParserService } from './entity-parser.service';
import { Drawable } from './drawing/drawable';
import { DrawableNetwork } from './drawing/drawable-network';
import { InputLayerSceneElement } from './drawing/input-layer-scene-element';

@Injectable({
    providedIn: 'root',
})
export class DrawingService {
    private drawableNetwork: DrawableNetwork;

    constructor(private entityParserService: EntityParserService) {}

    init(
        selection: d3.Selection<BaseType, unknown, HTMLElement, unknown>
    ): Drawable {
        this.drawableNetwork = new DrawableNetwork(selection);

        return this.drawableNetwork;
    }

    clear(): void {
        this.drawableNetwork.clear();
    }

    visualizeSkillArchitecture(
        entity: Skill.SkillArchitectureEntity,
        sceneElements: InputLayerSceneElement[]
    ): void {
        const parsedEntity = this.entityParserService.parseEntity(entity);
        this.drawableNetwork.draw(parsedEntity, sceneElements);
    }
}
