<div class="alert alert-info" *ngIf="notificationText">
    {{ notificationText | translate: { notificationParam: notificationParam } }}
    <button
        type="button"
        class="btn btn-secondary"
        (click)="closeNotification()"
    >
        OK
    </button>
</div>
<app-header-box
    [name]="
        'project.components.projectOverview.components.projectOverviewContent.gallery'
            | translate
    "
></app-header-box>
<app-project-overview-layout [headerText]="project?.name">
    <div leftSide class="side-class">
        <app-user-info *ngIf="userInfo" [userInfo]="userInfo"> </app-user-info>
        <div class="project-description-and-button-container">
            <h5 class="description-header">
                {{
                    'project.components.projectOverview.components.projectOverviewContent.projectDescription'
                        | translate
                }}
            </h5>
            <div
                class="project-description"
                [innerHTML]="project?.description | newLineToBr"
            ></div>
            <div class="button-row">
                <button
                    type="button"
                    class="btn project-summary-btn"
                    (click)="routeToProjectWorkspace()"
                    [disabled]="true"
                >
                    {{
                        'project.components.projectOverview.projectOverviewComponent.buttonGoToWorkspace'
                            | translate
                    }}
                </button>
                <button
                    type="button"
                    class="btn project-summary-btn"
                    (click)="joinProject()"
                >
                    {{
                        'project.components.projectOverview.projectOverviewComponent.buttonJoinProject'
                            | translate
                    }}
                </button>
            </div>
        </div>
    </div>
    <div rightSide class="side-class">
        <img
            class="project-image img-fluid"
            [src]="imageUrl"
            alt="Project Picture"
        />
    </div>
</app-project-overview-layout>
