import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent {
    @Input()
    placeholder: string;

    @Input()
    searchText: string;

    @Output()
    search: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    initSearch(): void {
        this.search.emit(this.searchText);
    }

    keyupEvent(event): void {
        if (event.code === 'Enter') {
            this.initSearch();
        }
    }
}
