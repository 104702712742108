import { TranslateService } from '@ngx-translate/core';

export class ActivityTimeFormatter {
    private static HOUR_IN_MINUTES: number = 60;
    private static DAY_IN_MINUTES: number =
        ActivityTimeFormatter.HOUR_IN_MINUTES * 24;
    private static WEEK_IN_MINUTES: number =
        ActivityTimeFormatter.DAY_IN_MINUTES * 7;

    public static formatTime(date: Date, translateService: TranslateService) {
        const now: Date = new Date();
        const difference = this.differenceInMinutesBetween(now, date);
        if (difference < this.WEEK_IN_MINUTES) {
            let [timeTranslateKey, timeTranslateNumber] =
                this.makeTranslationKey(difference);
            return translateService.instant(timeTranslateKey, {
                time: timeTranslateNumber,
            });
        } else return date.toLocaleDateString(translateService.currentLang);
    }

    public static makeTranslationKey(difference: number): [string, number] {
        const translateKeyBase = 'project.components.projectEvents.';
        if (difference < this.HOUR_IN_MINUTES) {
            const differenceInMinutes = this.toRoundedMinutes(difference);
            if (differenceInMinutes === 1)
                return [translateKeyBase + 'oneMinuteAgo', 0];
            else {
                return [translateKeyBase + 'minutesAgo', differenceInMinutes];
            }
        } else if (difference < this.DAY_IN_MINUTES) {
            const differenceInHours = this.toRoundedHours(difference);
            if (differenceInHours === 1)
                return [translateKeyBase + 'oneHourAgo', 0];
            else {
                return [translateKeyBase + 'hoursAgo', differenceInHours];
            }
        } else if (difference < this.WEEK_IN_MINUTES) {
            const differenceInDays = this.toRoundedDays(difference);
            if (differenceInDays === 1)
                return [translateKeyBase + 'oneDayAgo', 0];
            else {
                return [translateKeyBase + 'daysAgo', differenceInDays];
            }
        }
    }

    private static differenceInMinutesBetween(
        date1: Date,
        date2: Date
    ): number {
        return (date1.getTime() - date2.getTime()) / 1000 / 60;
    }

    private static toRoundedMinutes(minutes: number): number {
        return Math.floor(minutes);
    }

    private static toRoundedHours(hours: number): number {
        return Math.floor(hours / 60);
    }

    private static toRoundedDays(days: number): number {
        return Math.floor(days / 60 / 24);
    }
}
