import { BehaviorSubject, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ImgAnnotation } from '../model/img-annotation';
import { ImgDbService } from './img-db.service';
import { Action } from '../model/action';
import { ActionType } from '../model/action-type.enum';

@Injectable()
export class AnnotationStateService {
    public $annotations: BehaviorSubject<ImgAnnotation[]> = new BehaviorSubject(
        []
    );
    private actions: Action[] = [];
    private actionStateIndex: number = -1;
    private imageDbId: string;
    private maxActionsLimit = 50;
    private selectedImgId: string;
    private actionsImgId: string;

    constructor(private imgDbService: ImgDbService) {}

    fetchAnnotations(imageDbId: string, imgId: string) {
        this.selectedImgId = imgId;
        this.imageDbId = imageDbId;
        this.imgDbService
            .getAllAnnotationForImage(imageDbId, imgId)
            .subscribe((annotArray: ImgAnnotation[]) => {
                this.$annotations.next(annotArray);
            });
    }

    hasUndo() {
        return (
            this.selectedImgId === this.actionsImgId &&
            this.actions.length > 0 &&
            this.actionStateIndex !== this.actions.length - 1 &&
            this.actions[this.actionStateIndex + 1].annotation.id !== undefined
        );
    }

    hasRedo() {
        return (
            this.selectedImgId === this.actionsImgId &&
            this.actions.length > 0 &&
            this.actionStateIndex >= 0 &&
            this.actions[this.actionStateIndex].annotation.id !== undefined
        );
    }

    undo() {
        const action = this.actions[++this.actionStateIndex];
        switch (action.type) {
            case ActionType.Add:
                this.removeAnnotation(action.annotation.id, action);
                break;
            case ActionType.Remove:
                this.addAnnotation(action.annotation, action);
                break;
        }
    }

    redo() {
        const action = this.actions[this.actionStateIndex--];
        switch (action.type) {
            case ActionType.Remove:
                this.removeAnnotation(action.annotation.id, action);
                break;
            case ActionType.Add:
                this.addAnnotation(action.annotation, action);
                break;
        }
    }

    resetActions() {
        this.actionStateIndex = -1;
        this.actions = [];
    }

    addAnnotation(newAnnotation: ImgAnnotation, action?: Action) {
        newAnnotation.id = undefined;
        if (this.actionsImgId !== this.selectedImgId) {
            this.resetActions();
            this.actionsImgId = this.selectedImgId;
        }
        this.$annotations.next([...this.$annotations.value, newAnnotation]);

        if (action) {
            action.annotation = newAnnotation;
        } else {
            this.addAction({
                type: ActionType.Add,
                annotation: newAnnotation,
            } as Action);
        }
        forkJoin([
            this.$annotations.pipe(take(1)),
            this.imgDbService.createAnnotationForImage(
                this.imageDbId,
                newAnnotation.imageId,
                newAnnotation
            ),
        ]).subscribe(([annotations, annotation]) => {
            newAnnotation.id = annotation.id;
            if (
                this.$annotations.value.find(
                    (ann) => ann.imageId === annotation.imageId
                )
            ) {
                this.$annotations.next(annotations);
            }
        });
    }

    removeAnnotation(id: string, action?: Action) {
        if (this.actionsImgId !== this.selectedImgId) {
            this.resetActions();
            this.actionsImgId = this.selectedImgId;
        }
        const index = this.getAnnotationIndex(this.$annotations.getValue(), id);

        if (index === -1) return;

        const removed = this.$annotations.getValue().splice(index, 1);

        if (action) {
            action.annotation = removed[0];
        } else {
            this.addAction({
                type: ActionType.Remove,
                annotation: removed[0],
            } as Action);
        }

        this.$annotations.next(this.$annotations.getValue());
        forkJoin([
            this.$annotations.pipe(take(1)),
            this.imgDbService.deleteAnnotation(this.imageDbId, id),
        ]).subscribe(([annotations, result]) => {});
    }

    selectAnnotation(id) {
        this.$annotations.pipe(take(1)).subscribe((annotations) => {
            const index = this.getAnnotationIndex(annotations, id);
            annotations[index].active = !annotations[index].active;
            this.$annotations.next(annotations);
        });
    }

    private addAction(action: Action) {
        if (this.actionStateIndex !== -1) {
            this.resetActions();
        }

        this.actions.unshift(action);

        if (this.actions.length > this.maxActionsLimit) {
            this.actions.splice(this.maxActionsLimit);
        }
    }

    private getAnnotationIndex(annotations, id) {
        return annotations.findIndex((annotation) => annotation.id === id);
    }
}
