import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { ProjectAddCard } from 'src/app/card/model/add-card-implementations/project-add-card';
import { ProjectCard } from 'src/app/card/model/card-implementations/project-card';
import { CardPage } from 'src/app/card/model/card-page';
import {
    createPageableByRouterParameters,
    Pageable,
} from 'src/app/shared/models/pageable';
import { ProjectList } from 'src/app/project/models/project-list';
import { ProjectService } from 'src/app/project/services/project.service';
import { SortBy } from 'src/app/shared/enums/sortby.enum';
import {
    Container,
    ContainerPage,
} from 'src/app/shared/services/container/container.types';

@Component({
    selector: 'app-project-library',
    templateUrl: './project-library.component.html',
    styleUrls: ['./project-library.component.scss'],
})
export class ProjectLibraryComponent implements OnInit {
    userId: string;
    cardPage: CardPage = new CardPage();
    @Input() profilePicture: string;

    constructor(
        private projectService: ProjectService,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private modalService: NgbModal
    ) {}

    ngOnInit() {
        this.retrieveCurrentUserId();
        this.createCardsFromProjects();
    }

    private retrieveCurrentUserId() {
        this.userId = this.authenticationService.currentUserValue.id;
    }

    private createCardsFromProjects(): void {
        this.route.queryParams.subscribe((params: Params) => {
            this.cardPage.pageable = createPageableByRouterParameters(
                new Pageable(),
                params
            );
            this.cardPage.pageable.sortBy = SortBy.CREATED;
            this.cardPage.pageable.asc = false;
            this.cardPage.pageable.pageSize = 11;
            this.cardPage.addCard = new ProjectAddCard(this.modalService);

            if (this.cardPage.pageable.page > 0) {
                this.cardPage.pageable.page = this.cardPage.pageable.page - 1;
            }

            const projects = this.fetchProjects();
            projects.subscribe({
                next: (projectDetails) => {
                    this.fillCardPageWith(projectDetails);
                },
                error: (error) => {
                    console.error('error' + error);
                },
            });
        });
    }

    private fillCardPageWith(projectDetails: ContainerPage) {
        this.cardPage.itemCards = [];
        this.cardPage.numberOfPages = projectDetails.totalPages;

        projectDetails.values.forEach((project: Container) => {
            const projectCard = ProjectCard.mapContainerToProjectCard(
                project,
                this.userId,
                project.creator,
                this.profilePicture
            );
            this.cardPage.itemCards.push(projectCard);
        });
    }

    private fetchProjects(): Observable<ContainerPage> {
        return this.projectService.getMyProjects(
            this.userId,
            this.cardPage.pageable
        );
    }
}
