import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CategoryCmsResponse } from '../models/category-cms-response';
import { Category } from '../models/category';

@Injectable({
    providedIn: 'root',
})
export class ShopwareCmsService {
    private readonly restUrl: string;
    private readonly accessKey: string;
    public readonly rootCategoryId: string;
    public readonly rootCmsCategoryId: string;

    constructor(private http: HttpClient) {
        this.restUrl = environment.shopwareUrl;
        this.accessKey = environment.shopwareAccessKey;
        this.rootCategoryId = environment.rootCategoryId;
        this.rootCmsCategoryId = environment.rootCmsCategoryId;
    }

    getActiveCategoryId(categoryId?: string): string {
        return categoryId ?? this.rootCmsCategoryId;
    }

    getCategory(categoryId: string): Observable<CategoryCmsResponse> {
        const url = `${this.restUrl}/category/${categoryId}`;
        return this.http.get<CategoryCmsResponse>(url, {
            headers: new HttpHeaders().set('sw-access-key', this.accessKey),
        });
    }

    getMainNavigation(): Observable<Category[]> {
        const url = `${this.restUrl}/navigation/${this.rootCmsCategoryId}/${this.rootCategoryId}`;
        return this.http.get<Category[]>(url, {
            headers: new HttpHeaders().set('sw-access-key', this.accessKey),
        });
    }
}
