import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserProfile } from '../authentication/profile/models/user-profile';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { UserService } from '../authentication/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
    profilePicture: string;
    forumUserProfile: UserProfile;
    private profilePictureSub: Subscription;

    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this.authenticationService.isLoggedIn()) {
            this.userService
                .getUser(this.authenticationService.currentUserValue.id)
                .subscribe((user) => {
                    this.forumUserProfile = user;
                    this.profilePictureSub =
                        this.userService.profilePicture$.subscribe(
                            (pic) => (this.profilePicture = pic)
                        );
                });
        } else {
            console.log('There is an internal error.');
            this.authenticationService.signOut();
        }
    }

    ngOnDestroy(): void {
        this.profilePictureSub?.unsubscribe();
    }
}
