import { composeLayer, WithAxis } from './tf-properties';
import { TfDtype } from './tf-types';

// import "reflect-metadata";

// const optionalMetadataKey = Symbol("optional");

// function optional() {
//   return Reflect.metadata(optionalMetadataKey, true);
// }

// function mandatory() {
//   return Reflect.metadata(optionalMetadataKey, false);
// }

// function isOptional(target: any, propertyKey: string) {
//   return Reflect.getMetadata(optionalMetadataKey, target, propertyKey);
// }

export class Layer {
    name = '';
    trainable = true;
    dtype: TfDtype = TfDtype.FLOAT32;
}

export class Input extends Layer {
    batch_input_shape: number[] = [];
    sparse: boolean = false;
    ragged: boolean = false;
}

export class Add extends Layer {}

export class Concatenate extends composeLayer([WithAxis]) {}
