import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Configuration } from '../../../authentication/config/configuration';
import { ActivatedRoute } from '@angular/router';
import { RobotService } from '../../../robots/services/robot.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-urdf',
    templateUrl: './urdf.component.html',
    styleUrls: ['./urdf.component.scss'],
})
export class UrdfComponent implements OnInit {
    inputUrl = '';
    url: string = this.configuration.URDF_URL + '?urdf=' + this.inputUrl;
    urlSafe: SafeResourceUrl;
    id = '';
    urldecoded;
    objectMetaData;
    dataLoaded = false;

    constructor(
        public sanitizer: DomSanitizer,
        private configuration: Configuration,
        private route: ActivatedRoute,
        private robotService: RobotService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.inputUrl = params.url || 0;
            this.id = params.id || 0;
        });
        this.urldecoded = decodeURIComponent(this.inputUrl);
        this.url = this.configuration.URDF_URL + '?urdf=' + this.urldecoded;
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
    onDelete(id: string) {
        this.robotService
            .deleteRobotByID(id)
            .subscribe((message) => console.log(message));
    }
}
