import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { UserService } from 'src/app/authentication/services/user.service';
import { ImageUpdateComponent } from 'src/app/image-update/image-update.component';
import { UserProfile } from '../../models/user-profile';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { validationPatterns } from '../../../../shared/validation-patterns';

export enum ControlName {
    COMPANY = 'company',
    COUNTRY = 'country',
    CITY = 'city',
    LANGUAGE = 'language',
    PHONE = 'phone',
    DATE_TIME = 'dateTime',
    LICENSE = 'license',
    TWO_FACTOR = 'twoFactor',
}

type ControlValueType = string | boolean;

@Component({
    selector: 'app-profile-page',
    templateUrl: './profile-page.component.html',
    styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit, OnDestroy {
    user: UserProfile;
    userId: string;
    username: string;
    twoFactor: boolean;
    license: string;
    language: string;
    dateTime: string = '';
    company: string;
    country: string = '';
    city: string;
    phone: string;

    profileForm: FormGroup;

    protected readonly ControlName = ControlName;

    private baseNamePattern = validationPatterns.baseNamePattern;
    private phonePattern = validationPatterns.phonePattern;

    profilePicture: string;
    private profilePictureSubscription: Subscription;

    @ViewChild(ImageUpdateComponent)
    imageComponent: ImageUpdateComponent;

    userSub: Subscription;

    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.profileForm = new FormGroup({
            [ControlName.COMPANY]: new FormControl('', [
                Validators.minLength(3),
                Validators.pattern(this.baseNamePattern),
            ]),
            [ControlName.COUNTRY]: new FormControl(this.country),
            [ControlName.CITY]: new FormControl('', [
                Validators.minLength(3),
                Validators.pattern(this.baseNamePattern),
            ]),
            [ControlName.PHONE]: new FormControl('', [
                Validators.pattern(this.phonePattern),
            ]),
            [ControlName.LICENSE]: new FormControl(this.license),
            [ControlName.LANGUAGE]: new FormControl(
                this.language,
                Validators.required
            ),
            [ControlName.DATE_TIME]: new FormControl(this.dateTime),
            [ControlName.TWO_FACTOR]: new FormControl(
                this.twoFactor,
                Validators.required
            ),
        });

        if (this.authenticationService.isLoggedIn()) {
            this.userSub = this.userService
                .getUser(this.authenticationService.currentUserValue.id)

                .subscribe((user) => {
                    this.user = user;
                    this.userId = user.id;
                    this.username = user.displayName;
                    this.license = user.license;
                    this.language = user.language;
                    this.twoFactor = user.twoFactor;
                    this.company = user.company;
                    this.city = user.city;
                    this.phone = user.phone;

                    this.userService.profilePicture$.subscribe(
                        (pic: string) => (this.profilePicture = pic)
                    );

                    this.profileForm.patchValue({
                        [ControlName.COMPANY]: this.company,
                        [ControlName.CITY]: this.city,
                        [ControlName.PHONE]: this.phone,
                        [ControlName.LICENSE]: this.license,
                        [ControlName.LANGUAGE]: this.language,
                        [ControlName.TWO_FACTOR]: this.twoFactor,
                    });
                });
        } else {
            console.error('There is an internal error.');
            this.authenticationService.signOut();
        }
    }

    ngOnDestroy(): void {
        this.profilePictureSubscription?.unsubscribe();
        this.userSub?.unsubscribe();
    }

    savePicture(file: File): void {
        this.userService.updateProfilePicture(file, this.userId).subscribe({
            next: () => {
                this.userService.getProfilePicture(this.userId);
                this.imageComponent.addFileUploadName(file.name);
                this.imageComponent.showSuccess();
            },
            error: () => {
                this.imageComponent.showError(
                    'Profilbild konnte nicht gespeichert werden.'
                );
            },
        });
    }

    onEscape(controlName: ControlName, inputRef: HTMLInputElement) {
        this.getControl(controlName).setValue(this[controlName]);
        inputRef.blur();
    }

    onEnter(controlRef: HTMLInputElement) {
        controlRef.blur();
    }

    updateUser(type: ControlName, controlRef?: HTMLInputElement) {
        const control = this.getControl(type);
        const value: ControlValueType = control.value;

        if (control.invalid) {
            controlRef?.focus();
        }

        if (control.valid && control.value !== this.user[type]) {
            (this[type] as ControlValueType) = value;
            (this.user[type] as ControlValueType) = value;

            this.userService.updateUser(this.user, this.user.id).subscribe();
        }
    }

    getControl(controlName: string): FormControl {
        return this.profileForm.get(controlName) as FormControl;
    }
}
