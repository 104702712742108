import { Component, OnInit } from '@angular/core';
import { CmsAbstractBlockComponent } from '../cms-abstract-block.component';
import { CmsBlockComponent } from '../cms-block.component';
import { CmsElementService } from '../../../services/cms-element.service';
import { CmsSlot } from '../../../models/cms-slot';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-cms-text',
    templateUrl: './cms-text.component.html',
})
export class CmsTextComponent
    extends CmsAbstractBlockComponent
    implements OnInit, CmsBlockComponent
{
    contentSlot: CmsSlot;

    content: SafeHtml;

    constructor(private cmsElementService: CmsElementService) {
        super();
    }

    ngOnInit(): void {
        this.initBlock();
        this.loadComponent();
    }

    loadComponent(): void {
        this.contentSlot = this.slots.find((slot) => slot.slot === 'content');

        this.content = this.cmsElementService.renderContent(this.contentSlot);
    }
}
