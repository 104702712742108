import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserProfile } from 'src/app/authentication/profile/models/user-profile';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { UserService } from 'src/app/authentication/services/user.service';
import { ProjectMemberRole } from 'src/app/project/models/project-member-role';
import { ProjectService } from 'src/app/project/services/project.service';
import { UserInfoMember } from 'src/app/shared/models/user-info';
import { Container } from 'src/app/shared/services/container/container.types';

@Component({
    selector: 'app-project-settings',
    templateUrl: './project-settings.component.html',
    styleUrls: ['./project-settings.component.scss'],
})
export class ProjectSettingsComponent implements OnInit, OnDestroy {
    project: Container;
    currentUser: UserProfile;
    subscriptions: Subscription[] = [];
    projectForm: FormGroup;

    constructor(
        private projectService: ProjectService,
        private route: ActivatedRoute,
        private tokenService: AuthenticationService,
        private userService: UserService,
        private formBuilder: FormBuilder
    ) {}

    ngOnInit(): void {
        this.initializeForm();
        if (this.projectService.selectedProject$.value) {
            this.subscriptions.push(
                this.projectService.selectedProject$.subscribe((project) => {
                    this.project = project;
                    this.fetchFormValues(project);
                })
            );
        } else {
            this.subscriptions.push(
                this.projectService
                    .getProjectById(
                        this.route.snapshot.paramMap.get('projectId')
                    )
                    .subscribe((project) => {
                        this.project = project;
                        this.fetchFormValues(project);
                    })
            );
        }

        this.userService
            .getUser(this.tokenService.currentUserValue.id)
            .subscribe((user: UserProfile) => (this.currentUser = user));
    }

    getUserInfo(): UserInfoMember {
        const userImagePath: string = this.userService.profilePicture$.value;

        return {
            firstName: this.currentUser.firstName,
            lastName: this.currentUser.lastName,
            displayName: this.currentUser.displayName,
            company: this.currentUser.company,
            profilePicture: userImagePath,
            role: ProjectMemberRole.OWNER,
        };
    }

    initializeForm(): void {
        this.projectForm = this.formBuilder.group({
            openly: [true],
            description: [''],
            name: '',
        });
    }

    fetchFormValues(project: Container): void {
        this.projectForm.patchValue({
            openly: project.openly,
            description: project.description,
            name: project.name,
        });
    }

    onRadioButtonChange(): void {
        this.project.openly = this.projectForm.get('openly').value;
        this.projectService
            .updateProjectById(this.project.id, this.projectForm)
            .subscribe();
    }

    onTextAreaSaveChanges(): void {
        this.projectForm
            .get('description')
            .setValue(this.projectForm.get('description').value.trim());

        this.project.description = this.projectForm.get('description').value;
        this.projectService
            .updateProjectById(this.project.id, this.projectForm)
            .subscribe();
    }

    onTextAreaDiscardChanges(): void {
        this.projectForm.patchValue({
            description: this.project.description,
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }
}
