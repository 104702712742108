<mat-tab-group mat-stretch-tabs class="tryb-mat-group">
    <mat-tab
        label="{{ 'costFunction.costFunction.customCodeInput' | translate }}"
    >
        <div class="custom-input">
            <textarea
                [(ngModel)]="customCode"
                [disabled]="editingIsDisabled"
                (keydown)="handleKeydown($event)"
                spellcheck="false"
                autocapitalize="off"
                autocomplete="off"
            ></textarea>
        </div>

        <br />
        <div class="button-container">
            <button
                class="btn btn-tryb-blue"
                [disabled]="editingIsDisabled"
                (click)="onSaveCode()"
            >
                {{ 'costFunction.costFunction.save' | translate }}
            </button>
        </div>
    </mat-tab>
</mat-tab-group>
