<mat-horizontal-stepper>
    <mat-step label="Upload-Type">
        <div class="text-center">
            <button
                mat-raised-button
                color="primary"
                (click)="OpenDialog('zip')"
            >
                {{
                    'robots.components.uploadTypeDialog.uploadFilesZip'
                        | translate
                }}
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="OpenDialog('git')"
            >
                {{
                    'robots.components.uploadTypeDialog.useGitImporter'
                        | translate
                }}
            </button>
        </div>
    </mat-step>
</mat-horizontal-stepper>
