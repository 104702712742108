<ul *ngIf="showListRobots" class="robot2">
    <li *ngFor="let child of children">
        <ng-template [ngIf]="child.type">
            <app-joint-tree-node
                [child]="child"
                [searchTerm]="searchTerm"
                [searchResults]="searchResults"
                [searchIndex]="searchIndex"
            ></app-joint-tree-node>
        </ng-template>
    </li>
</ul>
