<div class="evaluation">
    <app-header-box [name]="training?.name"></app-header-box>
    <div class="evaluation-content">
        <div class="left">
            <div class="header">
                <div class="col">
                    {{
                        getSideContainerHeader(SideContainerPosition.LEFT)
                            | translate
                    }}
                </div>
            </div>
            <div class="data">
                <div class="side-content-wrapper">
                    <app-side-container
                        [side]="SideContainerPosition.LEFT"
                        *ngIf="!isDiagramMode || graphDataAvailable"
                    ></app-side-container>
                </div>
            </div>
        </div>
        <div class="central">
            <div class="header">
                <p class="toogle-label-eval">
                    {{
                        'evaluation.' +
                            training?.trainingType +
                            '.diagrams.toggle' | translate
                    }}
                </p>
                <mat-slide-toggle
                    class="slide-toggle"
                    (change)="onToggle()"
                    [checked]="!isDiagramMode"
                ></mat-slide-toggle>
                <p class="toogle-label-play">
                    {{
                        'evaluation.' + training?.trainingType + '.media.toggle'
                            | translate
                    }}
                </p>
            </div>
            <div class="data">
                <div class="central-content-wrapper">
                    <div
                        class="no-data-message-container"
                        *ngIf="isDiagramMode && !graphDataAvailable"
                    >
                        <p>
                            {{
                                'evaluation.SL.diagrams.noDataMessage'
                                    | translate
                            }}
                        </p>
                    </div>
                    <app-line-chart
                        class="plot"
                        *ngIf="isDiagramMode && graphDataAvailable"
                        [tooltipLabel]="mapTooltipLabel"
                        [idPrefix]="'costs-svg'"
                        [dataSource]="iterationDataService.costOut"
                        [xAxisLabelSource]="evalDataStorage.xAxisLabel"
                        [yAxisLabelSource]="evalDataStorage.yAxisLabel"
                        [maxNumberEpoch]="maxNumberEpochs"
                        (clickEvent)="clickOnIteration($event)"
                    ></app-line-chart>
                    <div
                        class="video-container"
                        *ngIf="
                            training?.trainingType === TrainingType.MOTORIC &&
                            !isDiagramMode
                        "
                    >
                        <app-video-player [id]="id"></app-video-player>
                    </div>
                    <app-image-viewer
                        *ngIf="
                            training?.trainingType === TrainingType.VISUAL &&
                            !isDiagramMode &&
                            initialized
                        "
                        [selectedImageIndexInAllImages]="selectedImageIndex"
                        [trainingId]="id"
                    ></app-image-viewer>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="header">
                <div class="col">
                    {{
                        getSideContainerHeader(SideContainerPosition.RIGHT)
                            | translate
                    }}
                </div>
            </div>
            <div class="data">
                <div class="side-content-wrapper">
                    <app-side-container
                        [side]="SideContainerPosition.RIGHT"
                        *ngIf="!isDiagramMode || graphDataAvailable"
                    ></app-side-container>
                </div>
            </div>
        </div>
    </div>
</div>
