import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

// returns all values/entries in given enumerator
export function getEnumStringsByEnum(enumMap): Map<string, any> {
    let enumValueList = [];
    const enumValueMap = new Map<string, any>();
    for (const [key, value] of enumMap) {
        const enumerator = value;
        for (const enumKey in enumerator) {
            const enumValue = enumerator[enumKey];
            enumValueList.push(enumValue);
        }
        enumValueMap.set(key, enumValueList);
        enumValueList = [];
    }
    return enumValueMap;
}

export function getEnumKeyByEnumString(myEnum, enumValue) {
    const key = Object.keys(myEnum).find((x) => myEnum[x] === enumValue);
    return key !== undefined ? key : null;
}

export function* getEnumValues(myEnum: any): any {
    for (const key in myEnum) {
        yield myEnum[key];
    }
}

export function snakeToKebab(snakeString: string): string {
    return snakeString.replace(/_/gi, '-');
}

export function kebabToSnake(kebabString: string): string {
    return kebabString.replace(/-/gi, '_');
}

export function isNil(val: any): boolean {
    return val === undefined || val === null;
}

export function nonNil(val: any): boolean {
    return val !== undefined && val !== null;
}

export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
}

export class FakeLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return of(lang + 'language');
    }
}

export function degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
}

export function radiansToDegrees(radians: number): number {
    return radians * (180 / Math.PI);
}

export function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export class Reference<T> {
    constructor(value: T) {
        this.value = value;
    }

    value: T;
}
