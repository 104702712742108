import { Injectable, ViewContainerRef } from '@angular/core';
import { CmsCategoryNavigationComponent } from '../components/block/cms-category-navigation/cms-category-navigation.component';
import { CmsCenterTextComponent } from '../components/block/cms-center-text/cms-center-text.component';
import { CmsTextTwoColumnComponent } from '../components/block/cms-text-two-column/cms-text-two-column.component';
import { CmsBlockComponent } from '../components/block/cms-block.component';
import { CmsBlock } from '../models/cms-block';
import { CmsTextComponent } from '../components/block/cms-text/cms-text.component';

@Injectable({
    providedIn: 'root',
})
export class CmsBlockService {
    blockResolver = {
        'category-navigation': CmsCategoryNavigationComponent,
        'center-text': CmsCenterTextComponent,
        'text-two-column': CmsTextTwoColumnComponent,
        text: CmsTextComponent,
    };

    loadComponent(
        viewContainerRef: ViewContainerRef,
        blocks: CmsBlock[]
    ): void {
        viewContainerRef.clear();
        blocks.forEach((block) => {
            const cmsBlockType = block.type;
            const blockComponent = this.blockResolver[cmsBlockType];
            if (blockComponent) {
                const componentRef =
                    viewContainerRef.createComponent<CmsBlockComponent>(
                        blockComponent
                    );
                componentRef.instance.position = block.position;
                componentRef.instance.type = cmsBlockType;
                componentRef.instance.slots = block.slots;
                componentRef.instance.marginBottom = block.marginBottom;
                componentRef.instance.marginLeft = block.marginLeft;
                componentRef.instance.marginRight = block.marginRight;
                componentRef.instance.marginTop = block.marginTop;
                componentRef.instance.backgroundMedia = block.backgroundMedia;
            }
        });
    }
}
