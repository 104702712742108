<div class="header">
    <h3 class="modal-title">
        {{
        'imageUpdate.components.imageCropper.cropProfilePicture' | translate
        }}
    </h3>
</div>
<image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [imageFile]="imageFile"
    [transform]="transform"
    [maintainAspectRatio]="maintainAspectRatio"
    [aspectRatio]="aspectRatio"
    [resizeToWidth]="resizeToWidth"
    [resizeToHeight]="resizeToHeight"
    [roundCropper]="roundCropper"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
    onlyScaleDown="true"
></image-cropper>
<div class="footer">
    <div class="row buttons">
        <div class="col text-center">
            <button
                type="button"
                class="btn btn-primary confirm"
                (click)="confirmImage()"
            >
                {{
                'imageUpdate.components.imageCropper.saveSelection'
                    | translate
                }}
            </button>
        </div>
        <div class="col text-center">
            <button
                type="button"
                class="btn btn-primary cancel"
                (click)="cancel()"
            >
                {{ 'imageUpdate.components.imageCropper.cancel' | translate }}
            </button>
        </div>
    </div>
</div>
