<div id="commuinty-wrapper">
    <div class="container-fluid gallery-card-container">
        <div class="row">
            <div class="col-12">
                <h2 class="title-text text-center">
                    {{
                        'project.components.communityProject.title' | translate
                    }}
                </h2>
            </div>
        </div>
        <div class="row">
            <!-- BEGIN: Hide the sorting drop down list -->
            <div class="col-12 change-sort-tryb-dropdown d-none">
                <div class="styled-select">
                    <select
                        (change)="changeSort($event.target.value)"
                        class="form-select change-sort"
                    >
                        <option
                            *ngFor="let sortValue of sortValues"
                            [value]="sortValue.id"
                            [selected]="sortValue.isSelected"
                        >
                            {{ sortValue.sortBy }}
                        </option>
                    </select>
                </div>
            </div>
            <!-- END: Hide the sorting drop down list -->
            <div class="col-12">
                <div class="d-flex justify-content-center">
                    <div
                        class="filter-chip motoric"
                        [class.active]="
                            currentFilter.includes(
                                ContainerType.PROJECT_MOTORIC
                            )
                        "
                        (click)="changeFilter(ContainerType.PROJECT_MOTORIC)"
                    >
                        {{
                            'project.components.communityProject.chips.morotic'
                                | translate
                        }}
                    </div>
                    <div class="chip-spacer"></div>
                    <div
                        class="filter-chip visual"
                        [class.active]="
                            currentFilter.includes(ContainerType.PROJECT_VISUAL)
                        "
                        (click)="changeFilter(ContainerType.PROJECT_VISUAL)"
                    >
                        {{
                            'project.components.communityProject.chips.visual'
                                | translate
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid gallery-card-container">
        <app-card-page [cardPage]="cardPage"></app-card-page>
    </div>
</div>
