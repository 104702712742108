import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CardPage } from '../../model/card-page';

@Component({
    selector: 'app-card-page',
    templateUrl: './card-page.component.html',
    styleUrls: ['./card-page.component.scss'],
})
export class CardPageComponent {
    @Output()
    initCreate: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    cardPage: CardPage;

    constructor(private router: Router) {}

    changePage(newPage: number): void {
        this.router.navigate([this.getUrlWithoutQueryParams()], {
            queryParams: {
                page: newPage,
                pageSize: this.cardPage.pageable.pageSize,
                sortBy: this.cardPage.pageable.sortBy,
                asc: this.cardPage.pageable.asc,
            },
            queryParamsHandling: 'merge',
        });
    }

    private getUrlWithoutQueryParams(): string {
        return this.router.url.split('?')[0];
    }

    public removeItem(itemId: string) {
        const index = this.cardPage.itemCards
            .map((i) => {
                return i.itemId;
            })
            .indexOf(itemId);

        this.cardPage.itemCards.splice(index, 1);
    }
}
