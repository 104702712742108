<div class="scene-diversity">
    <h4 class="title">{{ 'sceneDiversity.title' | translate }}</h4>
    <div class="h-100">
        <div
            class="scene-diversity__main-scene"
            [ngClass]="{
                active: !!sceneDiversitySelection.base
            }"
        >
            <span (click)="selectSceneDiversity(true)">
                {{ 'sceneDiversity.scene' | translate }}
            </span>
            <mat-icon
                class="input-icon"
                (click)="
                    createNewExampleMotion({
                        base: true
                    })
                "
                *ngIf="!editingIsDisabled"
                >content_copy</mat-icon
            >
        </div>
        <ul class="scene-diversity__list scrollable-container">
            <li
                class="d-flex flex-wrap"
                *ngFor="let item of diversity.exampleMotions; let i = index"
            >
                <div
                    class="scene-diversity__list-item"
                    [ngClass]="{
                        active:
                            !sceneDiversitySelection.base &&
                            sceneDiversitySelection.exampleMotion?.id ===
                                item.id
                    }"
                >
                    <span
                        class="scene-diversity__list-item-title"
                        (click)="selectSceneDiversity(false, item)"
                    >
                        {{ item.name }}
                    </span>
                    <div class="d-flex align-items-center">
                        <mat-icon
                            (click)="deleteExampleMotion(item)"
                            class="input-icon bi bi-trash-fill trash-icon"
                            aria-hidden="true"
                            *ngIf="!editingIsDisabled"
                        ></mat-icon>
                        <mat-icon
                            class="input-icon"
                            (click)="
                                createNewExampleMotion({ exampleMotion: item })
                            "
                            *ngIf="!editingIsDisabled"
                            >content_copy</mat-icon
                        >
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
