<mat-card *ngIf="visible">
    <mat-card-title>{{
        'authentication.resetPassword.resetPassword.forgotPassword' | translate
        }}</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="submit()" #f="ngForm">
            <p>
                <mat-form-field>
                    <input
                        type="password"
                        autocomplete="new-password"
                        matInput
                        placeholder="{{
                            'authentication.resetPassword.resetPassword.newPasswordPlaceholder'
                                | translate
                        }}"
                        formControlName="password"
                    />
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <input
                        type="password"
                        autocomplete="new-password"
                        matInput
                        placeholder="{{
                            'authentication.resetPassword.resetPassword.confirmPasswordPlaceholder'
                                | translate
                        }}"
                        formControlName="confirmPassword"
                    />
                </mat-form-field>
            </p>

            <p *ngIf="error" class="error">
                {{ error }}
            </p>
            <div
                *ngIf="
                    (form.get('confirmPassword').dirty || f.submitted) &&
                    form.get('confirmPassword').errors
                "
            >
                <div
                    class="alert alert-danger"
                    *ngIf="form.get('confirmPassword').errors.required"
                >
                    {{
                    'authentication.resetPassword.resetPassword.confirmPasswordError1'
                        | translate
                    }}
                </div>
                <div
                    class="alert alert-danger"
                    *ngIf="form.get('confirmPassword').errors.minlength"
                >
                    {{
                    'authentication.resetPassword.resetPassword.confirmPasswordError2'
                        | translate
                    }}
                </div>
                <div
                    class="alert alert-danger"
                    *ngIf="
                        form.get('confirmPassword').errors.mustMatch &&
                        f.submitted
                    "
                >
                    {{
                    'authentication.resetPassword.resetPassword.confirmPasswordError3'
                        | translate
                    }}
                </div>
            </div>
            <div class="button">
                <button type="submit" mat-button>
                    {{
                    'authentication.resetPassword.resetPassword.send'
                        | translate
                    }}
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<mat-card *ngIf="submitted">
    <mat-card-content>
        <mat-label>{{ message |translate}}</mat-label>
    </mat-card-content>
</mat-card>
