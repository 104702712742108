import {
    composeLayer,
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDepthwise,
    WithDilationRate1D,
    WithDilationRate2D,
    WithDilationRate3D,
    WithFilters,
    WithGroups,
    WithImplementation,
    WithKernel,
    WithKernelSize,
    WithOutputPadding,
    WithPadding,
    WithPointwise,
    WithStrides1D,
    WithStrides2D,
    WithStrides3D,
    WithUseBias,
} from './tf-properties';

export class Conv1D extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDilationRate1D,
    WithFilters,
    WithGroups,
    WithKernel,
    WithKernelSize,
    WithPadding,
    WithStrides1D,
    WithUseBias,
]) {}

export class Conv2D extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDilationRate2D,
    WithFilters,
    WithGroups,
    WithKernel,
    WithKernelSize,
    WithPadding,
    WithStrides2D,
    WithUseBias,
]) {}

export class Conv3D extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDilationRate3D,
    WithFilters,
    WithGroups,
    WithKernel,
    WithKernelSize,
    WithPadding,
    WithStrides3D,
    WithUseBias,
]) {}

export class Conv1DTranspose extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDilationRate1D,
    WithFilters,
    WithGroups,
    WithKernel,
    WithKernelSize,
    WithOutputPadding,
    WithPadding,
    WithStrides1D,
    WithUseBias,
]) {}

export class Conv2DTranspose extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDilationRate2D,
    WithFilters,
    WithGroups,
    WithKernel,
    WithKernelSize,
    WithOutputPadding,
    WithPadding,
    WithStrides2D,
    WithUseBias,
]) {}

export class Conv3DTranspose extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDilationRate3D,
    WithFilters,
    WithGroups,
    WithKernel,
    WithKernelSize,
    WithOutputPadding,
    WithPadding,
    WithStrides3D,
    WithUseBias,
]) {}

export class DepthwiseConv2D extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDepthwise,
    WithDilationRate2D,
    WithFilters,
    WithGroups,
    WithKernel,
    WithKernelSize,
    WithPadding,
    WithStrides2D,
    WithUseBias,
]) {}

export class LocallyConnected1D extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithFilters,
    WithGroups,
    WithImplementation,
    WithKernel,
    WithKernelSize,
    WithPadding,
    WithStrides1D,
    WithUseBias,
]) {}

export class LocallyConnected2D extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithFilters,
    WithGroups,
    WithImplementation,
    WithKernel,
    WithKernelSize,
    WithPadding,
    WithStrides2D,
    WithUseBias,
]) {}

export class SeparableConv1D extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDepthwise,
    WithDilationRate1D,
    WithFilters,
    WithGroups,
    WithKernel,
    WithKernelSize,
    WithPadding,
    WithPointwise,
    WithStrides1D,
    WithUseBias,
]) {}

export class SeparableConv2D extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithDepthwise,
    WithDilationRate2D,
    WithFilters,
    WithGroups,
    WithKernel,
    WithKernelSize,
    WithPadding,
    WithPointwise,
    WithStrides2D,
    WithUseBias,
]) {}
