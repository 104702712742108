import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ImgAnnotation } from '../../img-db/model/img-annotation';
import { ImgWithSelection } from '../../img-db/model/img-with-selection';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { PagingResult } from '../../shared/models/pageable';
import { PanZoomAPI, PanZoomConfig, PanZoomModel } from 'ngx-panzoom';
import { Subject, Subscription, tap } from 'rxjs';
import { ColorService } from '../../shared/services/color.service';
import { ImageViewerStorageService } from '../service/image-viewer-storage.service';
import { isVisualEvalAnnotation } from '../models/visual-eval-data-set';

@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss'],
    providers: [ImageViewerStorageService],
})
export class ImageViewerComponent implements OnInit, OnDestroy {
    readonly PAGE_SIZE: number = 8;

    images: ImgWithSelection[] = [];
    selectedImage: ImgWithSelection;
    isLeftArrowVisible: boolean;
    isRightArrowVisible: boolean;
    totalPages: number;
    totalElements: number;
    currentImageNumber: number = 1;
    currentPage: number = 1;
    timer: any;

    @ViewChild('labeling') labeling: ElementRef;
    @ViewChild('currentImage') currentImage: ElementRef;
    @ViewChild('panzoomParent') panzoomParent: ElementRef;

    @Input() trainingId: string;
    @Input() selectedImageIndexInAllImages: number;

    svgStatus: any;
    annotations: ImgAnnotation[];

    currentZoomLevel: number = 0;

    // panZoom configuration object
    panZoomConfig: PanZoomConfig = new PanZoomConfig({
        zoomLevels: 14, // default + 13 levels
        scalePerZoomLevel: 1.2, // 20% zoom per level
        initialZoomLevel: 0,
        zoomOnMouseWheel: true,
        zoomOnDoubleClick: false,
        invertMouseWheel: true,
        freeMouseWheel: false, // make mouse wheel zoom in 20% steps as defined above
        panOnClickDrag: true,
    });
    private panZoomAPI: PanZoomAPI;

    // check if panning was started and ended
    imagePanned: boolean = false;

    //check if zooming was started and ended
    imageZoomed: boolean = false;

    targetZoomLevel: number = 0;

    // used to set and clear delayed alignment after zoom
    zoomAlignmentTimeout: any;

    cursorView: string = 'grab';

    private subscriptions: Subscription[] = [];

    destroy$: Subject<void> = new Subject<void>();

    showData: boolean = false;

    constructor(
        private colorService: ColorService,
        private imageViewerStorageService: ImageViewerStorageService
    ) {
        this.isLeftArrowVisible = false;
        this.isRightArrowVisible = false;
    }

    ngOnInit() {
        if (!this.selectedImageIndexInAllImages) {
            this.selectedImageIndexInAllImages = 0;
        }
        this.currentPage = this.calculatePageNumberFromImageIndexInAllImages(
            this.selectedImageIndexInAllImages
        );
        this.imageViewerStorageService.currentPage = this.currentPage;
        this.imageViewerStorageService.init(this.trainingId);
        this.subscriptions.push(
            this.imageViewerStorageService
                .getImages()
                .pipe(
                    take(1),
                    switchMap((page: PagingResult<ImgWithSelection>) => {
                        this.initializeData(page);
                        return this.imageViewerStorageService.annotations$.pipe(
                            takeUntil(this.destroy$)
                        );
                    }),
                    switchMap((annotations: ImgAnnotation[]) => {
                        this.annotations = annotations;
                        return this.panZoomConfig.api.pipe(
                            takeUntil(this.destroy$)
                        );
                    }),
                    switchMap((api: PanZoomAPI) => {
                        this.panZoomAPI = api;
                        return this.panZoomConfig.modelChanged.pipe(
                            tap((model: PanZoomModel) => {
                                this.onModelChanged(model);
                            }),
                            takeUntil(this.destroy$)
                        );
                    })
                )
                .subscribe()
        );
    }

    private initializeData(page: PagingResult<ImgWithSelection>) {
        this.svgStatus = {
            w: '100%',
            h: '100%',
            viewBox: '0 0 0 0',
        };
        if (page?.values?.length) {
            this.showData = true;
            this.images = page.values;
            this.currentImageNumber = this.selectedImageIndexInAllImages + 1;
            const indexInPage =
                this.selectedImageIndexInAllImages -
                this.PAGE_SIZE * (this.currentPage - 1);
            this.selectImage(indexInPage);
            this.totalElements = page.totalElements;
            this.totalPages = page.totalPages;
            this.checkArrowsAvailability();
        }
    }

    ngOnDestroy(): void {
        // unsubscribe from the subscriptions
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
        this.destroy$.complete();
    }

    // this is called every time the image is dragged or zoomed (for each animation step)
    onModelChanged(model: PanZoomModel) {
        if (model.isPanning) {
            this.imagePanned = true;
            clearTimeout(this.zoomAlignmentTimeout); //abort alignment after zoom
        } else if (this.imagePanned) {
            this.imagePanned = false;
            this.keepImageInBounds();
        } else if (model.zoomLevel !== this.targetZoomLevel) {
            // go here if image is currently zooming
            this.actionWhileZoom(model);
        } else if (this.imageZoomed) {
            // go here if image zoom is finished
            this.actionAfterZoom();
        }
    }

    private actionWhileZoom(model: PanZoomModel) {
        this.imageZoomed = true;
        clearTimeout(this.zoomAlignmentTimeout); //abort alignment after zoom

        // set current zoom level to target zoom level of current animation
        if (model.zoomLevel > this.currentZoomLevel) {
            this.targetZoomLevel = Math.ceil(model.zoomLevel);
        } else {
            this.targetZoomLevel = Math.floor(model.zoomLevel);
        }
    }

    private actionAfterZoom() {
        this.imageZoomed = false;
        this.currentZoomLevel = this.targetZoomLevel;
        // align image after zoom animation is finished with delay
        this.zoomAlignmentTimeout = setTimeout(() => {
            if (!this.imageZoomed) {
                this.keepImageInBounds();
            }
        }, 400);
    }

    private keepImageInBounds() {
        const model = this.panZoomAPI.model;
        const parentWidth = this.panzoomParent.nativeElement.offsetWidth;
        const parentHeight = this.panzoomParent.nativeElement.offsetHeight;
        const imgWidth =
            this.currentImage.nativeElement.getBoundingClientRect().width;
        const imgHeight =
            this.currentImage.nativeElement.getBoundingClientRect().height;

        // current zoomLevel. This will return 0,1,2,etc, NOT the actual scale like 1, 1.2, 1.44 and so on
        const zoomLevel = this.currentZoomLevel;

        // actual scale value
        const scaleValue = Math.pow(1.2, zoomLevel);

        const newX = this.calculateValue(imgWidth, parentWidth, model.pan.x);
        const newY = this.calculateValue(imgHeight, parentHeight, model.pan.y);

        // set the delta position of the image, i.e. the distance of x and y that the image needs to be panned by
        const dx = ((newX - model.pan.x) * -1) / scaleValue;
        const dy = ((newY - model.pan.y) * -1) / scaleValue;
        this.panDeltaWithoutJitter(dx, dy);
    }

    private panDeltaWithoutJitter(dx: number, dy: number) {
        // We need to check if there are any differences and only call penDelta when necessary.
        // Calling penDelta all the time can disable zoom.
        // We're comparing the absolute values to 2 and not 0 in order to avoid jittering due to small margins like 0.1, 0.2, etc
        if (Math.abs(dx) > 2 || Math.abs(dy) > 2) {
            this.panZoomAPI.panDelta(
                {
                    x: dx,
                    y: dy,
                },
                0
            );
        }
    }

    private calculateValue(imgValue: any, parentValue: any, panValue: number) {
        let newValue: number = panValue;
        // if the width of the image is less than or equal to the frame width
        // we need to center the image horizontally
        if (imgValue <= parentValue) {
            newValue = parentValue / 2 - imgValue / 2;
        } else {
            // if the image width is larger than the frame, we need to make sure that edges
            // of the image don't go inside the frame, in other words, the image should always
            // cover the entire width of the frame.
            // x > 0 means the left side of the image inside the frame
            if (panValue > 1) {
                newValue = 0; // return the left side of the image on the left edge of the frame, , i.e. first valid value
            } else if (panValue + imgValue < parentValue) {
                // check the right side of them image, x + imgWidth < parentWidth means the right side of the image is inside the frame.
                // return it to the position where right side of the image is on the right edge of the frame, i.e. first valid value.
                // x + imgWidth = parentWidth, i.e. x = parentWidth - imgWidth
                newValue = parentValue - imgValue;
            }
        }
        return newValue;
    }

    private onZoomChanged(zoomLevel: number) {
        if (zoomLevel > 13 || zoomLevel < 0 || !this.selectedImage) {
            return;
        }
        const point = {
            x: this.panzoomParent.nativeElement.offsetWidth / 2,
            y: this.panzoomParent.nativeElement.offsetHeight / 2,
        };
        this.panZoomAPI.changeZoomLevel(zoomLevel, point);
    }

    resetZoom() {
        this.onZoomChanged(0);
        this.keepImageInBounds();
    }

    updateSvgStatus() {
        this.svgStatus = {
            w: this.currentImage.nativeElement.clientWidth,
            h: this.currentImage.nativeElement.clientHeight,
            viewBox: `0 0 ${this.selectedImage.width} ${this.selectedImage.height}`,
        };
    }

    selectImage(indexInPage: number) {
        const img = this.images[indexInPage];
        if (this.selectedImage?.id !== img?.id) {
            this.selectedImage = img;
            this.imageViewerStorageService.selectedImageIndex$.next(
                indexInPage + this.PAGE_SIZE * (this.currentPage - 1)
            );
            this.onImagesChanged();
        }
    }

    getColor(categoryName: string): string {
        return this.colorService.getColor(categoryName);
    }

    getColorByAnnotation(
        annotation: ImgAnnotation,
        isBorderColor: boolean = true
    ) {
        const categoryName: string = this.getImgCategoryName(
            annotation.categoryId
        );

        if (!isBorderColor) {
            return isVisualEvalAnnotation(annotation)
                ? this.colorService.getColorWithTransparency(categoryName)
                : 'transparent';
        }

        return this.getColor(categoryName);
    }

    getImgCategoryName(id: string): string {
        return this.imageViewerStorageService.categories.get(id);
    }

    onImagesChanged(): void {
        if (!this.labeling || !this.labeling.nativeElement) {
            return;
        }
        const rects = this.labeling.nativeElement.querySelectorAll('g');
        if (!rects || !rects.length) return;
        rects.forEach((element) => {
            element.remove();
        });
        this.resetZoom();
    }

    getNextPage() {
        if (this.currentPage + 1 <= this.totalPages) {
            this.currentPage++;
            this.imageViewerStorageService.currentPage = this.currentPage;
            this.getNewPage();
        }
    }

    getPrevPage() {
        if (this.currentPage - 1 >= 1) {
            this.currentPage--;
            this.imageViewerStorageService.currentPage = this.currentPage;
            this.getNewPage();
        }
    }

    getNewPage(indexOfImageToBeSelected?: number) {
        // Timeout to prevent unnecessary requests when user clicks repeatedly
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.imageViewerStorageService
                .getImages()
                .pipe(take(1))
                .subscribe((page: PagingResult<ImgWithSelection>) => {
                    this.images = page.values;
                    this.checkArrowsAvailability();
                    if (
                        typeof indexOfImageToBeSelected === 'number' &&
                        indexOfImageToBeSelected >= 0
                    ) {
                        this.selectImage(indexOfImageToBeSelected);
                    }
                });
        }, 300);
    }

    private checkArrowsAvailability() {
        this.isRightArrowVisible = this.currentPage < this.totalPages;
        this.isLeftArrowVisible = this.currentPage > 1;
    }

    onNextValue(numberProvidedFromPagingBar: number) {
        if (this.currentImageNumber !== numberProvidedFromPagingBar) {
            this.currentImageNumber = numberProvidedFromPagingBar;

            const indexOfImageToBeSelected: number =
                this.findIndexOfImageToBeSelected(this.currentImageNumber) - 1;

            const newPage: number = this.calculateNextPage(
                numberProvidedFromPagingBar
            );
            if (newPage !== this.currentPage) {
                this.currentPage = newPage;
                this.imageViewerStorageService.currentPage = this.currentPage;
                this.getNewPage(indexOfImageToBeSelected);
            } else {
                this.selectImage(indexOfImageToBeSelected);
            }
        }
    }

    onSelectImage(indexOfSelectedImage: number) {
        this.selectImage(indexOfSelectedImage);
        this.currentImageNumber = this.findImageIndexToProvide();
    }

    private findImageIndexToProvide(): number {
        const idx: number = this.images.findIndex(
            (img: ImgWithSelection) => img.id === this.selectedImage.id
        );

        return this.calculateImageIndexInAllImagesFromImageIndexInPage(idx) + 1;
    }

    private calculateNextPage(val: number): number {
        return Math.ceil(val / this.PAGE_SIZE);
    }

    private calculatePageNumberFromImageIndexInAllImages(
        imageIndexInAllImages: number
    ): number {
        return Math.floor(imageIndexInAllImages / this.PAGE_SIZE) + 1;
    }

    private calculateImageIndexInAllImagesFromImageIndexInPage(
        imageIndexInPage: number
    ): number {
        return imageIndexInPage + this.PAGE_SIZE * (this.currentPage - 1);
    }

    private findIndexOfImageToBeSelected(imageToGet: number): number {
        return imageToGet % this.PAGE_SIZE || this.PAGE_SIZE;
    }
}
