import {
    Component,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
    EventEmitter,
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Router } from '@angular/router';
import { MenuButton } from '../../models/menu-button';
import { MenuItem } from '../../models/menu-item';

@Component({
    selector: 'app-menu-button',
    templateUrl: './menu-button.component.html',
    styleUrls: ['./menu-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MenuButtonComponent implements OnInit {
    @Input()
    menuButton: MenuButton;

    @Input()
    activateButton: string;

    @Output()
    menuItemOpenStatusEvent = new EventEmitter<boolean>();

    opened = false;
    orderedItems: MenuItem[][] = [];
    emptyContainers: number[] = [];
    extraColumnCount = 0;

    @ViewChild('menuTrigger')
    menuTrigger: MatMenuTrigger;

    constructor(private router: Router) {}

    ngOnInit(): void {
        const rowSize = 3;
        let count = 0;
        if (this.menuButton.items !== undefined) {
            for (let i = 0; i < this.menuButton.items.length; i += rowSize) {
                const array: MenuItem[] = [];
                for (
                    let j = 0;
                    j < rowSize && this.menuButton.items.length > count;
                    j++, count++
                ) {
                    array.push(this.menuButton.items[i + j]);
                }
                this.extraColumnCount = rowSize - array.length;
                this.orderedItems.push(array);
            }
        }

        for (let i = 0; i < this.extraColumnCount; i++) {
            this.emptyContainers.push(i);
        }
    }

    triggerMenu(): void {
        this.menuTrigger.toggleMenu();

        if (this.opened) {
            this.menuTrigger.closeMenu();
        } else {
            this.menuTrigger.openMenu();
        }
    }

    closeMenu(): void {
        if (this.menuTrigger === undefined) return;
        this.menuTrigger.closeMenu();
    }

    open(opened: boolean): void {
        this.opened = opened;
        if (this.menuButton.routerLink !== undefined) {
            this.router.navigate([this.menuButton.routerLink]);
            this.menuTrigger.closeMenu();
        }
        this.menuItemStatus(opened);
    }

    menuItemStatus(value: boolean) {
        this.menuItemOpenStatusEvent.emit(value);
    }

    onMenuButtonClick(): void {
        if (this.menuButton.onClick) {
            this.menuButton.onClick();
        }
    }
}
