<div>
    <form [formGroup]="tokenNameForm" autocomplete="off">
        <div class="input-group" [class]="{'hidden': !isEditTokenName}">
            <div class="input-group-prepend">
                <span class="input-group-text"><i class="bi bi-pencil"></i></span>
            </div>
             <input #tokenNameField
               class="form-control input-sm"
               formControlName="name"
               type="text"
               (blur)="onChangeName()"
               (keydown.enter)="onChangeName()"
        />
        </div>

        <span class="edit-name" [class]="{'hidden': isEditTokenName}" (click)="toggleEditName()">
            <i class="bi bi-pencil m-3"></i>
            {{ token.name }}
        </span>
    </form>
</div>
