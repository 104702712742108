import { Router } from '@angular/router';
import { Scene } from 'src/app/scenes/models/scene';
import { SceneService } from 'src/app/scenes/services/scene.service';
import { AddCard } from './add-card';

export class SceneAddCard extends AddCard {
    private _sceneService: SceneService;
    private _router: Router;

    public constructor(sceneService: SceneService, router: Router) {
        super('card.model.addCardImplementations.sceneAddCard.createNewScene');
        this._sceneService = sceneService;
        this._router = router;
    }

    public createNewModel(): void {
        const scene: Scene = new Scene();
        scene.name = 'Noname';
        this._sceneService.createScene(scene).subscribe((retrievedScene) => {
            this._router.navigate(['/scenes/' + retrievedScene.id]);
        });
    }
}
