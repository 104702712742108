import { UserActivity } from './user-activity';
import { UserActivityResponse } from '../user-activity-response';
import { Params } from '@angular/router';

export class CompletedTrainingActivity extends UserActivity {
    public constructor(response: UserActivityResponse) {
        super(response);
    }

    get activityText(): string {
        return this.projectName
            ? 'user.completed_training_public'
            : 'user.completed_training_private';
    }

    public override get afterDescLink() {
        return ['/project/workspace'];
    }

    public override get afterDescParams(): Params {
        return {
            trainingUuid: this.trainingUUID,
            projectUuid: this.projectUUID,
            questUuid: this.questUUID,
        };
    }

    public override get afterDescText(): string {
        return this.trainingName;
    }
}
