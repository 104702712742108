import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PublicApiToken } from '../../models/public-api-token';

@Component({
    selector: 'app-admin-token-name-form',
    templateUrl: './admin-token-name-form.component.html',
    styleUrls: ['./admin-token-name-form.component.scss'],
})
export class AdminTokenNameFormComponent implements OnInit {
    @ViewChild('tokenNameField') tokenNameField: ElementRef;
    @Input() token: PublicApiToken;
    @Output() changedToken = new EventEmitter<PublicApiToken>();

    isEditTokenName: boolean = false;
    public tokenNameForm: FormGroup;

    ngOnInit(): void {
        this.tokenNameForm = new FormGroup({
            name: new FormControl(this.token.name),
        });
    }
    toggleEditName() {
        this.isEditTokenName = !this.isEditTokenName;
        if (this.isEditTokenName && this.tokenNameField) {
            setTimeout(() => {
                this.tokenNameField.nativeElement.focus();
                this.tokenNameField.nativeElement.select();
            }, 0);
        }
    }
    onChangeName() {
        if (this.isEditTokenName && this.tokenNameField) {
            setTimeout(() => {
                this.tokenNameField.nativeElement.blur();
            }, 0);
        }
        this.isEditTokenName = false;
        this.token.name = this.tokenNameForm.value.name;
        this.changedToken.emit(this.token);
    }
}
