import {
    WizardSlide,
    WizardSlideContentElement,
    WizardType,
} from '../../../project/models/wizard-slide';
import { Injectable } from '@angular/core';
import { ImgDbService } from '../../services/img-db.service';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { BehaviorSubject, Subject, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    Container,
    ContainerPage,
} from '../../../shared/services/container/container.types';

const cocoPath = 'assets/img/create-project-modal/image-database.jpg';
const dicePath = 'assets/img/create-project-modal/dice-database.jpg';
const emptyDbPath = 'assets/img/create-project-modal/empty-database.jpg';
const wizardPointPath: string = 'assets/img/create-project-modal/';

@Injectable()
export class ImgDbWizardSlideCreationService {
    constructor(
        private imgDbService: ImgDbService,
        private authenticationService: AuthenticationService
    ) {}

    createWizardSlides(): WizardSlide[] {
        return [this.createImgDbSelectorSlide(), this.createSecondSlide()];
    }

    getDefaultElements(redirectSlideID: number): WizardSlideContentElement[] {
        return [
            {
                image: emptyDbPath,
                cardTitle: 'imgDb.wizard.cardTitle.empty',
                cardDisc: 'imgDb.wizard.cardDescription.empty',
                redirectSlideId: redirectSlideID,
                cardValue: null,
            },
            {
                image: cocoPath,
                cardTitle: 'imgDb.wizard.cardTitle.coco',
                cardDisc: 'imgDb.wizard.cardDescription.coco',
                redirectSlideId: redirectSlideID,
                cardValue: '85e8c56e-6c23-48f1-92fa-2017c0c0da7a',
            },
            {
                image: dicePath,
                cardTitle: 'imgDb.wizard.cardTitle.dice',
                cardDisc: 'imgDb.wizard.cardDescription.dice',
                redirectSlideId: redirectSlideID,
                cardValue: '85e8c56e-6c23-48f1-92fa-112024c00bea',
            },
        ];
    }

    createImgDbSelectorSlide(): WizardSlide {
        return {
            id: 1,
            root: true,
            type: WizardType.CARD,
            slideTitle: 'imgDb.wizard.firstSlideTitle',
            slideContent: this.createCards(2, 'imgDbWizard'),
        };
    }

    createSecondSlide(): WizardSlide {
        return {
            id: 2,
            root: false,
            type: WizardType.FORM,
            slideContent: new Subject<WizardSlideContentElement[]>(),
        };
    }

    createCards(
        redirectSlideID: number,
        key: string
    ): BehaviorSubject<WizardSlideContentElement[]> {
        let elements: BehaviorSubject<WizardSlideContentElement[]> =
            new BehaviorSubject([]);
        if (this.authenticationService.isLoggedIn()) {
            this.imgDbService
                .getMyImageDbs()
                .pipe(
                    map((page: ContainerPage) => page.values),
                    tap((value: Container[]) => {
                        let res = this.createImgDbCards(
                            value,
                            redirectSlideID,
                            key
                        );
                        elements.next(
                            this.getDefaultElements(redirectSlideID).concat(res)
                        );
                    })
                )
                .subscribe();
        } else {
            console.error('There is an internal error.');
            this.authenticationService.signOut();
        }
        return elements;
    }

    createImgDbCards(
        val: Container[],
        redirectSlideID: number,
        key: string
    ): WizardSlideContentElement[] {
        let cardList = [];
        val.forEach((container: Container) => {
            let data: WizardSlideContentElement = {
                cardTitle: container.name,
                cardDisc: `imgDb.wizard.cardDescription.custom.from.${key}`,
                image: cocoPath,
                redirectSlideId: redirectSlideID,
                cardValue: container.imageDb.id,
            };
            cardList.push(data);
        });
        return cardList;
    }

    imgDbPointImages(): string[] {
        return [wizardPointPath + '1.jpg', wizardPointPath + '2.jpg'];
    }
}
