import { Layer } from './layer';
import {
    composeLayer,
    WithDataFormat,
    WithKeepDims,
    WithPadding,
    WithPoolSize1D,
    WithPoolSize2D,
    WithPoolSize3D,
    WithStrides1D,
    WithStrides2D,
    WithStrides3D,
} from './tf-properties';

export class GlobalMaxPooling1D extends composeLayer([
    WithDataFormat,
    WithKeepDims,
]) {}

export class GlobalMaxPooling2D extends composeLayer([
    WithDataFormat,
    WithPadding,
    WithPoolSize2D,
    WithStrides2D,
]) {}

export class GlobalMaxPooling3D extends composeLayer([
    WithDataFormat,
    WithKeepDims,
]) {}

export class GlobalAveragePooling1D extends composeLayer([WithDataFormat]) {}

export class GlobalAveragePooling2D extends composeLayer([
    WithDataFormat,
    WithKeepDims,
]) {}

export class GlobalAveragePooling3D extends composeLayer([
    WithDataFormat,
    WithKeepDims,
]) {}

export class MaxPool1D extends composeLayer([
    WithDataFormat,
    WithPadding,
    WithPoolSize1D,
    WithStrides1D,
]) {}

export class MaxPool2D extends composeLayer([
    WithDataFormat,
    WithPadding,
    WithPoolSize2D,
    WithStrides2D,
]) {}

export class MaxPool3D extends composeLayer([
    WithDataFormat,
    WithPadding,
    WithPoolSize3D,
    WithStrides3D,
]) {}

export class AveragePooling1D extends composeLayer([
    WithDataFormat,
    WithPadding,
    WithPoolSize1D,
    WithStrides1D,
]) {}

export class AveragePooling2D extends composeLayer([
    WithDataFormat,
    WithPadding,
    WithPoolSize2D,
    WithStrides2D,
]) {}

export class AveragePooling3D extends composeLayer([
    WithDataFormat,
    WithPadding,
    WithPoolSize3D,
    WithStrides3D,
]) {}

export class Maximum extends Layer {}
export class Minimum extends Layer {}
