import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class Configuration {
    public API_BASE_URL = environment.backendUrl;

    public URDF_URL = environment.frontendUrl + '/assets/urdf/index.html';
    public OAUTH2_REDIRECT_URI = environment.frontendUrl + '/oauth2/redirect';

    public GOOGLE_AUTH_URL =
        this.API_BASE_URL +
        '/oauth2/authorize/google?redirect_uri=' +
        this.OAUTH2_REDIRECT_URI;
    public FACEBOOK_AUTH_URL =
        this.API_BASE_URL +
        '/oauth2/authorize/facebook?redirect_uri=' +
        this.OAUTH2_REDIRECT_URI;
    public GITHUB_AUTH_URL =
        this.API_BASE_URL +
        '/oauth2/authorize/github?redirect_uri=' +
        this.OAUTH2_REDIRECT_URI;
    public MICROSOFT_AUTH_URL =
        this.API_BASE_URL +
        '/oauth2/authorize/microsoft?redirect_uri=' +
        this.OAUTH2_REDIRECT_URI;

    public TOKEN_HEADER_KEY = 'Authorization';
}
