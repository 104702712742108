import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChangeLangEventService } from './service/change-lang-event.service';
import { LanguageCode } from './data/laguage-code';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-language-nav-menu',
    templateUrl: './language-nav-menu.component.html',
    styleUrls: ['./language-nav-menu.component.scss'],
})
export class LanguageNavMenuComponent implements OnInit, OnDestroy {
    languages: LanguageCode[] = [LanguageCode.DE, LanguageCode.EN];
    setLanguage: LanguageCode = LanguageCode.DE;
    private changeLangSubscription: Subscription;
    langExpand = false;
    constructor(public changeLangEventService: ChangeLangEventService) {}

    ngOnInit(): void {
        this.changeLangSubscription = this.changeLangEventService
            .getChangeLang()
            .subscribe((data) => {
                this.setLanguage = LanguageCode[data.toUpperCase()];
            });
    }

    ngOnDestroy(): void {
        this.changeLangSubscription.unsubscribe();
    }

    toogleExpandMenu() {
        this.langExpand = !this.langExpand;
    }

    changeLanguage(event: LanguageCode) {
        this.setLanguage = event;
        this.changeLangEventService.setLangSubject(event);
        this.toogleExpandMenu();
    }
}
