import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CostFunctionService } from '../../services/cost-function.service';

@Component({
    selector: 'app-cost-function-editor',
    templateUrl: './cost-function-editor.component.html',
    styleUrls: ['./cost-function-editor.component.scss'],
})
export class CostFunctionEditorComponent {
    @Input() sceneId: string;
    @Input() editingIsDisabled: boolean;
    @Output() selectedTabIndex: EventEmitter<number> =
        new EventEmitter<number>();
    @Output() disableFixedElementDrag: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    isCostFunctionInvalid: boolean = false;

    constructor(private costFunctionService: CostFunctionService) {
        this.costFunctionService.isInvalid$.subscribe((nextValue: boolean) => {
            this.isCostFunctionInvalid = nextValue;
        });
    }

    onTabIndexChange($event) {
        this.selectedTabIndex.emit($event);
    }

    onDisableFixedElementDrag($event) {
        this.disableFixedElementDrag.emit($event);
    }
}
