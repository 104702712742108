<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input
        #inputName
        type="text"
        id="name-input"
        class="form-control name-input"
        [formControlName]="formControlName"
        (blur)="onBlur()"
        (keydown.enter)="onBlur()"
    />
    <div
        class="
            d-flex
            flex-row
            justify-content-center
            project_availability_container
        "
        *ngIf="formForProject"
    >
        <div
            class="d-flex flex-row justify-content-center"
            title="{{
                'project.components.wizardForm.publicTooltip' | translate
            }}"
        >
            <label class="custom-radio d-flex flex-row pa_radio">
                <input
                    type="radio"
                    id="cpw_public"
                    name="project_availability"
                    (change)="projectAvailability = true"
                    checked
                />
                <div class="outer-circle"></div>
                <div
                    class="inner-circle"
                    [ngClass]="{ checked: projectAvailability }"
                ></div>
            </label>
            <label for="cpw_public" class="radio_spacer">
                <div>
                    {{ 'project.components.wizardForm.public' | translate }}
                </div>
            </label>
        </div>
        <div
            class="d-flex flex-row justify-content-center"
            title="{{
                'project.components.wizardForm.privateTooltip' | translate
            }}"
        >
            <label class="custom-radio d-flex flex-row pa_radio">
                <input
                    type="radio"
                    id="cpw_private"
                    name="project_availability"
                    (change)="projectAvailability = false"
                />
                <div class="outer-circle"></div>
                <div
                    class="inner-circle"
                    [ngClass]="{ checked: !projectAvailability }"
                ></div>
            </label>
            <label for="cpw_private">
                <div>
                    {{ 'project.components.wizardForm.private' | translate }}
                </div>
            </label>
        </div>
    </div>
    <app-display-errors
        [control]="nameControl"
        translationKey="imgDb.wizard.wizardSlide"
    >
    </app-display-errors>
    <div class="d-flex justify-content-center">
        <button
            type="submit"
            class="btn btn-tryb-blue btn-create-project"
            [disabled]="form.invalid"
        >
            {{ submitButtonTxt }}
        </button>
    </div>
</form>
