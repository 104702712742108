import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ExpertDatasetService } from '../../services/expert-dataset.service';
import {
    ExpertDatasetSelectionDTO,
    ExpertDatasetUsageType,
} from '../../models/expert-data';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { MatLegacySlideToggle } from '@angular/material/legacy-slide-toggle';

@Component({
    selector: 'app-expert-dataset',
    templateUrl: './expert-dataset.component.html',
    styleUrls: ['./expert-dataset.component.scss'],
})
export class ExpertDatasetComponent implements OnInit, OnDestroy {
    expertDatasets: string[];
    dataLoaded: boolean = true;
    subscriptions: Subscription[] = [];
    id: string;
    usageType: ExpertDatasetUsageType;
    changedState: boolean;
    @ViewChild('toggle') toggle: MatLegacySlideToggle;
    dropdownControl: FormControl<string> = new FormControl(null);

    constructor(
        private expertDatasetService: ExpertDatasetService,
        private route: ActivatedRoute,
        private authservice: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('trainingId');
        this.expertDatasetService
            .getExpertDataById(this.id)
            .subscribe((expertDataset: ExpertDatasetSelectionDTO) => {
                this.expertDatasets = expertDataset.availableOptions;
                if (this.expertDatasets.length === 0) {
                    this.toggle?.setDisabledState(true);
                }
                this.setExpertDatasetValue(
                    expertDataset.expertDatasetDto?.datasetName
                );
                this.usageType = expertDataset.expertDatasetUsageType;
                if (this.usageType === ExpertDatasetUsageType.NO_EXPERT_DATA) {
                    this.dropdownControl.disable();
                }
                this.changedState = false;
            });
        this.subscriptions.push(
            this.authservice.logoutTriggered$.subscribe(() => {
                if (this.changedState) {
                    this.changedState = false;
                    this.expertDatasetService
                        .saveExpertDataSelection(
                            this.id,
                            this.dropdownControl.value,
                            this.usageType
                        )
                        .subscribe(() => {
                            this.authservice.logoutPreparationsFinished$.next(
                                true
                            );
                        });
                } else {
                    this.authservice.logoutPreparationsFinished$.next(true);
                }
            })
        );
    }

    setExpertDatasetValue(expertData: any) {
        this.dropdownControl.setValue(expertData);
        this.changedState = true;
    }

    onToggle() {
        if (this.usageType === ExpertDatasetUsageType.NO_EXPERT_DATA) {
            this.usageType = ExpertDatasetUsageType.ONLY_EXPERT_DATA;
            this.dropdownControl.enable();
        } else {
            this.usageType = ExpertDatasetUsageType.NO_EXPERT_DATA;
            this.dropdownControl.disable();
        }
        this.changedState = true;
    }

    ngOnDestroy(): void {
        if (this.changedState) {
            this.changedState = false;
            this.expertDatasetService
                .saveExpertDataSelection(
                    this.id,
                    this.dropdownControl.value,
                    this.usageType
                )
                .subscribe();
        }

        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event) {
        if (this.changedState) {
            this.changedState = false;
            this.expertDatasetService
                .saveExpertDataSelection(
                    this.id,
                    this.dropdownControl.value,
                    this.usageType
                )
                .subscribe();
            event.preventDefault();
            event.returnValue = false;
        }
    }
}
