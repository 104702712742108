import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CartesianCoordinateValidator {
    static validateRange(control: AbstractControl): ValidationErrors | null {
        if (isEmptyField(control) || isSign(control)) {
            return null;
        }

        let value: string = control.value;
        //TODO: replace with real internationalization
        value = value.replace(',', '.');
        const parsedValue: number = parseFloat(value);

        const min = -100000;
        const max = 100000;

        const isWithinRange = min <= parsedValue && parsedValue <= max;
        return isWithinRange
            ? null
            : {
                  invalidRange: {
                      inputValue: control.value,
                  },
              };
    }
}

function isEmptyField(control: AbstractControl): boolean {
    const isEmpty: boolean = true;
    return !control.value ? isEmpty : !isEmpty;
}

function isSign(control: AbstractControl): boolean {
    const value = control.value;
    return value === '-' || value === '+';
}
