<div class="sidebar-container">
    <div class="title">Mujoco Poses</div>
    <div class="content tryb-scroll">
        <ul *ngFor="let pose of this.poseMappings">
            <li>
                <p class="element-name">{{ pose.name }}</p>
                <ul>
                    <li *ngIf="pose.qpos_range_position">
                        <p class="pose-category">Position</p>
                        <ul>
                            <li class="pose-value">
                                {{ pose.qpos_range_position }}
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="pose.qpos_range_orientation">
                        <p class="pose-category">Orientation</p>
                        <ul>
                            <li class="pose-value">
                                {{ pose.qpos_range_orientation }}
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="pose.qpos_range_joint">
                        <p class="pose-category">Joint Range</p>
                        <ul>
                            <li class="pose-value">
                                {{ pose.qpos_range_joint }}
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
