import { Component, Inject } from '@angular/core';
import {
    MatDialogConfig,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-delete-category-dialog',
    templateUrl: './delete-category-dialog.component.html',
    styleUrls: ['./delete-category-dialog.component.scss'],
})
export class DeleteCategoryDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<DeleteCategoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MatDialogConfig<number>,
        public translate: TranslateService
    ) {}

    onCancel(): void {
        this.dialogRef.close();
    }
}
