<div class="row m-0">
    <div class="col-12 px-0">
        <app-header-box [name]="trainingName"></app-header-box>
    </div>
</div>
<div *ngIf="dataLoaded" class="cost-function-row-wrapper">
    <div class="cost-function-row" cdkDropListGroup>
        <div class="col-2 text-center tryb-scene-composition">
            <app-scene-composition
                [scene]="scene"
                [editingIsDisabled]="editingIsDisabled"
                [type]="isCustomCodeMode ? 'customCode' : 'costFunction'"
                [disabledCheckboxInfoText]="
                    'scenes.components.sceneComposition.disabledInfoTextCostFunction'
                "
                [disableFixedElementDrag]="
                    isFixedElementDragDisabled || editingIsDisabled
                "
                [disableFixedElementDragInfoText]="
                    selectedTabIndex === 1
                        ? 'scenes.components.sceneComposition.disableFixedElementDistanceDragInfoText'
                        : ''
                "
            ></app-scene-composition>
        </div>

        <div class="cost-function-container canv">
            <div class="slide-toggle expert-toggle">
                <p class="me-3 slide-toggle-title">
                    {{
                        'costFunction.costFunction.customCodeInput' | translate
                    }}
                </p>
                <mat-slide-toggle
                    [checked]="!!customCode"
                    (change)="onSlideToggleChange($event)"
                    [disabled]="isToggleDisabled"
                ></mat-slide-toggle>
            </div>
            <app-cost-function-editor
                *ngIf="!isCustomCodeMode"
                [sceneId]="scene.id"
                [trainingId]="trainingId"
                [customCode]="customCode"
                (selectedTabIndex)="onTabIndexChange($event)"
                (disableFixedElementDrag)="onDisableFixedElementDrag($event)"
                (disableToggle)="onDisableToggle($event)"
                [editingIsDisabled]="editingIsDisabled"
            ></app-cost-function-editor>
            <app-custom-code-editor
                *ngIf="isCustomCodeMode"
                [trainingId]="trainingId"
                [customCode]="customCode"
                (disableToggle)="onDisableToggle($event)"
                [editingIsDisabled]="editingIsDisabled"
            ></app-custom-code-editor>
        </div>
    </div>
</div>
