import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ResetPasswordService {
    private readonly databaseUrl: string;

    constructor(private http: HttpClient) {
        this.databaseUrl = environment.backendUrl;
    }
    forgetPassword(userEmail: string) {
        const url = `${this.databaseUrl}/auth/forget/password`;
        const formData = new FormData();
        formData.append('userEmail', userEmail);
        return this.http.post(url, formData).pipe(catchError(this.handleError));
    }

    resetPassword(token: string, newPassword: string): Observable<any> {
        const url = `${this.databaseUrl}/auth/reset/password`;
        const formData = new FormData();
        formData.append('token', token);
        formData.append('newPassword', newPassword);
        return this.http.post(url, formData);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        return throwError(() => error);
    }
}
