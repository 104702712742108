import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-input-steppers',
    templateUrl: './input-steppers.component.html',
    styleUrls: ['./input-steppers.component.scss'],
})
export class InputSteppersComponent {
    @Input() value: string = '0';
    @Output() changeEmitter: EventEmitter<string> = new EventEmitter();

    @Input() step: number = 0.01;

    constructor() {}

    changeValueWithStepper(operation: string) {
        let numValue = this.value.replace(',', '.');
        if (!numValue) {
            numValue = '0';
        }
        const numberValue = parseFloat(numValue);
        const newValue =
            operation === '+'
                ? numberValue + this.step
                : numberValue - this.step;
        const roundedNewValue = parseFloat(newValue.toFixed(4));
        this.changeEmitter.emit(roundedNewValue.toString());
    }
}
