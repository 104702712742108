import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageData, MessageType } from '../models/message';
import { MessageService } from '../services/message.service';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnDestroy {
    private messageSubscription: Subscription;

    messageData: MessageData;
    alertClass = '';

    waitingForAutoClose = false;

    constructor(private messageService: MessageService) {
        this.messageSubscription = messageService.get().subscribe((data) => {
            this.messageData = data;
            this.updateAlertClass();

            if (
                data &&
                (data.type === MessageType.SUCCESS ||
                    data.type === MessageType.ERROR)
            ) {
                this.waitingForAutoClose = true;
                setTimeout(() => {
                    if (this.waitingForAutoClose) {
                        this.closeAlert();
                    }
                }, 3000);
            }
        });
    }

    ngOnDestroy(): void {
        this.messageSubscription?.unsubscribe();
    }

    closeAlert(): void {
        this.waitingForAutoClose = false;
        this.messageService.deleteMessage();
    }

    private updateAlertClass(): void {
        const defaultClass = '';
        if (!this.messageData) {
            this.alertClass = defaultClass;
        } else if (this.messageData.type === MessageType.ERROR) {
            this.alertClass = 'alert-danger';
        } else if (this.messageData.type === MessageType.SUCCESS) {
            this.alertClass = 'alert-success';
        } else {
            this.alertClass = defaultClass;
        }
    }
}
