export class UserSettings {
    id: string;
    active: boolean;
    disable: boolean;
    isAdmin: boolean;
    canTrain: boolean;

    constructor(
        id: string,
        active: boolean,
        disable: boolean,
        isAdmin: boolean,
        canTrain: boolean
    ) {
        this.id = id;
        this.active = active;
        this.disable = disable;
        this.isAdmin = isAdmin;
        this.canTrain = canTrain;
    }
}
