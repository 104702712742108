import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
    MatLegacyDialogRef as MatDialogRef,
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ConfirmDialogData } from '../../models/confirm-dialog-data';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent {
    confirmButtonText: string;
    cancelButtonText: string;
    title: string;
    message: string;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
    ) {
        this.confirmButtonText = data.confirmButtonText;
        this.cancelButtonText = data.cancelButtonText;
        this.title = data.title;
        this.message = data.message;
    }
}
