<div class="pagination justify-content-center align-items-center">
    <mat-icon class="pagination-left" (click)="backward()"
        >keyboard_arrow_left</mat-icon
    >
    <ng-container
        *ngTemplateOutlet="pageBtns; context: { $implicit: pageArrayFirst }"
    ></ng-container>
    <ng-container
        *ngTemplateOutlet="
            extendedPageBtns;
            context: { $implicit: pageArraySecond }
        "
    ></ng-container>
    <ng-container
        *ngTemplateOutlet="
            extendedPageBtns;
            context: { $implicit: pageArrayThird }
        "
    ></ng-container>
    <mat-icon class="pagination-right" (click)="forward()"
        >keyboard_arrow_right</mat-icon
    >
</div>
<ng-template #extendedPageBtns let-array>
    <ng-container *ngIf="array.length > 0">
        <span>...</span>
        <ng-container
            *ngTemplateOutlet="pageBtns; context: { $implicit: array }"
        ></ng-container>
    </ng-container>
</ng-template>
<ng-template #pageBtns let-array>
    <ng-container *ngFor="let page of array">
        <span
            (click)="changePage(page)"
            [ngClass]="page === currentPage ? 'selected' : ''"
            >{{ page }}</span
        >
    </ng-container>
</ng-template>
