import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadFileType } from 'src/app/object3-d/models/upload-file-type';
import { AddZipObjectComponent } from 'src/app/robots/components/add-zip-object/add-zip-object.component';
import { AddCard } from './add-card';

export class ObjectAddCard extends AddCard {
    private modal: NgbModal;

    public constructor(modal: NgbModal) {
        super(
            'card.model.addCardImplementations.objectAddCard.uploadNewObject'
        );
        this.modal = modal;
    }

    public createNewModel(): void {
        const modalref = this.modal.open(AddZipObjectComponent, {
            windowClass: 'add-scene-dialog',
        });
        modalref.componentInstance.uploadFileType = UploadFileType.OBJECT;
    }
}
