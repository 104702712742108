import { Component } from '@angular/core';

@Component({
    selector: 'app-admin-trainings',
    templateUrl: './admin-trainings.component.html',
    styleUrls: ['./admin-trainings.component.scss'],
})
export class AdminTrainingsComponent {
    constructor() {}
}
