import { Options } from './options';

export const OPTIONS_DEFAULT: Options = {
    dropTargetSize: 25,
    height: 800,
    width: 800,
    layerHeight: 180,
    layerWidth: 50,
    inputLayerWidth: 180,
    marginBetweenLayers: 119,
    zoomEnabled: true,
};
