import { UserActivity } from './user-activity';
import { UserActivityResponse } from '../user-activity-response';

export class CreatedProjectActivity extends UserActivity {
    constructor(response: UserActivityResponse) {
        super(response);
    }

    get activityText(): string {
        return 'user.created_project';
    }
}
