<section class="tab-content">
    <table class="table">
        <caption hidden>
            List of scene elements with orientation info and weight
        </caption>
        <tr>
            <th scope="col">
                <p>
                    {{
                        'costFunction.distanceTabContent.elementName1'
                            | translate
                    }}
                </p>
            </th>
            <th scope="col">
                <p>{{ xAxisLabel }}</p>
            </th>
            <th scope="col">
                <p>{{ yAxisLabel }}</p>
            </th>
            <th scope="col">
                <p>{{ zAxisLabel }}</p>
            </th>
            <th scope="col">
                <p>
                    {{ 'costFunction.distanceTabContent.weight' | translate }}
                </p>
            </th>
            <th scope="col delete"></th>
        </tr>
        <tr *ngFor="let element of rows; let i = index">
            <td>
                <p>{{ element.get('name').value }}</p>
            </td>
            <td>
                <app-cost-function-input-group
                    [valueControl]="element.get('angleX')"
                    [statusControl]="element.get('angleXActive')"
                    [disabled]="false"
                    [hasCheckbox]="false"
                    [decimalPoint]="axisDecimalPoint"
                    [minValue]="axisMinValue"
                    [maxValue]="axisMaxValue"
                    [convertNegativeValue]="true"
                    (valueChanged)="updateRow(element, i)"
                    (statusChanged)="updateRow(element, i)"
                ></app-cost-function-input-group>
            </td>
            <td>
                <app-cost-function-input-group
                    [valueControl]="element.get('angleY')"
                    [statusControl]="element.get('angleYActive')"
                    [disabled]="false"
                    [hasCheckbox]="false"
                    [decimalPoint]="axisDecimalPoint"
                    [minValue]="axisMinValue"
                    [maxValue]="axisMaxValue"
                    [convertNegativeValue]="true"
                    (valueChanged)="updateRow(element, i)"
                    (statusChanged)="updateRow(element, i)"
                ></app-cost-function-input-group>
            </td>
            <td>
                <app-cost-function-input-group
                    [valueControl]="element.get('angleZ')"
                    [statusControl]="element.get('angleZActive')"
                    [disabled]="false"
                    [hasCheckbox]="false"
                    [decimalPoint]="axisDecimalPoint"
                    [minValue]="axisMinValue"
                    [maxValue]="axisMaxValue"
                    [convertNegativeValue]="true"
                    (valueChanged)="updateRow(element, i)"
                    (statusChanged)="updateRow(element, i)"
                ></app-cost-function-input-group>
            </td>
            <td>
                <app-cost-function-input-group
                    [valueControl]="element.get('weight')"
                    [disabled]="false"
                    [hasCheckbox]="false"
                    [decimalPoint]="weightDecimalPoint"
                    [minValue]="weightMinValue"
                    [maxValue]="weightMaxValue"
                    (valueChanged)="updateRow(element, i)"
                    [hasSteppers]="true"
                ></app-cost-function-input-group>
            </td>
            <td>
                <div class="delete-button-container" *ngIf="!editingIsDisabled">
                    <div class="delete-button" (click)="removeElement(i)">
                        <span class="material-icons">delete_forever</span>
                    </div>
                </div>
            </td>
        </tr>
    </table>
    <div
        *ngIf="!editingIsDisabled"
        cdkDropList
        [cdkDropListData]="rows"
        (cdkDropListDropped)="drop($event)"
        class="dropzone"
        [id]="type + '-dropzone'"
    >
        {{ 'costFunction.distanceTabContent.dragSceneElement1' | translate }}
    </div>
</section>
