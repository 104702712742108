import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/message/services/message.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DeleteService } from 'src/app/shared/services/delete.service';
import { Card } from '../../model/card-implementations/card';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['../shared/card.component.scss'],
})
export class CardComponent implements OnInit {
    @Input()
    card: Card;
    @Output()
    deleteCardEvent: EventEmitter<string> = new EventEmitter<string>();

    activeSlideIndex = 0;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private messageService: MessageService,
        private deleteCardService: DeleteService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.card.deleteCardService = this.deleteCardService;
        this.card.router = this.router;
    }

    onClickUserProfilePicture(): void {
        this.router.navigate(['/user/', this.card.userName]);
    }

    onClickCardPicture(): void {
        // disable click redirect for robots and objects temporary
        if (!this.card.options?.isOnClickDisabled) {
            this.card.onCardPictureClick();
        }
    }

    onSlide(slideData: any): void {
        if (slideData.direction === 'right') {
            this.decrementActiveSlideIndex();
        } else if (slideData.direction === 'left') {
            this.incrementActivSlideIndex();
        }
    }

    private incrementActivSlideIndex() {
        if (this.activeSlideIndex < this.card.pictures.length - 1) {
            this.activeSlideIndex++;
        } else {
            this.activeSlideIndex = 0;
        }
    }

    private decrementActiveSlideIndex() {
        if (this.activeSlideIndex > 0) {
            this.activeSlideIndex--;
        } else {
            this.activeSlideIndex = this.card.pictures.length - 1;
        }
    }

    openConfirmationDialog(): MatDialogConfig {
        const results = this.translate.instant(
            [
                'card.components.card.delete',
                'card.components.card.keep',
                'card.components.card.confirmation',
                'card.components.card.confirmationText',
            ],
            {
                typeName: this.translate.instant(
                    this.card.cardTypeTranslationKey
                ),
                title: this.card.footer.title,
            }
        );
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            confirmButtonText: results['card.components.card.delete'],
            cancelButtonText: results['card.components.card.keep'],
            title: results['card.components.card.confirmation'],
            message: results['card.components.card.confirmationText'],
        };
        return dialogConfig;
    }

    openDeletionDialog() {
        // Ask user if element is to be deleted
        const dialogConfig: MatDialogConfig = this.openConfirmationDialog();

        this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.deleteCard();
                }
            });
        // If yes, delete object
    }

    private deleteCard() {
        this.card.deleteCard().subscribe({
            next: () => {
                this.reloadPageCard();
                this.messageService.displaySuccessMessage(
                    this.translate.instant(
                        'card.components.card.deleteSuccess',
                        {
                            name: this.translate.instant(
                                this.card.cardTypeTranslationKey
                            ),
                        }
                    )
                );
            },
            error: () => {
                this.messageService.displayErrorMessage(
                    this.translate.instant(
                        'card.components.card.deleteFailure',
                        {
                            name: this.translate.instant(
                                this.card.cardTypeTranslationKey
                            ),
                        }
                    )
                );
            },
        });
    }

    private reloadPageCard() {
        this.deleteCardEvent.emit(this.card.itemId);
    }
}
