import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoginRedirectionService {
    private redirectionURL$: BehaviorSubject<string>;
    loginTriggered$: Subject<boolean> = new Subject();
    loginCompleted$: Subject<boolean> = new Subject();

    constructor() {
        this.redirectionURL$ = new BehaviorSubject<string>('/home');
    }

    public getRedirectionURL(): Observable<string> {
        return this.redirectionURL$.asObservable();
    }

    public setRedirectionURL(url: string): void {
        this.redirectionURL$.next(url);
    }

    public resetRedirectionURL(): void {
        this.redirectionURL$.next('/home');
    }
}
