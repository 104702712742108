import { Component } from '@angular/core';

@Component({
    selector: 'app-admin-performance',
    templateUrl: './admin-performance.component.html',
    styleUrls: ['./admin-performance.component.scss'],
})
export class AdminPerformanceComponent {
    constructor() {}
}
