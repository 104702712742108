import { Observable } from 'rxjs';
import { BrowserAndPhysicsLoadable } from 'src/app/object3-d/models/browser-and-physics-loadable';
import { CardUser } from '../card-user';
import { Card, CardFooter } from './card';

export class RobotCard extends Card {
    readonly typeName: string;
    readonly cardTypeTranslationKey = 'card.type.robot';
    readonly isOnClickDisabled: boolean = true;

    constructor(
        itemId: string,
        userId: string,
        userName: string,
        head: CardFooter
    ) {
        super(itemId, userId, userName, head);
        this.navigationUrl = '/robots/' + itemId;
    }

    static mapToRobotCard(
        robot: BrowserAndPhysicsLoadable,
        cardUser: CardUser
    ) {
        return new RobotCard(
            robot.uuid,
            cardUser.id,
            robot.creator,
            new CardFooter(robot.name, cardUser.picture)
        );
    }

    public deleteCard(): Observable<unknown> {
        return this.deleteCardService.deleteRobot(this.itemId);
    }

    public onCardPictureClick(): void {
        this.router.navigate([this.navigationUrl], {
            queryParams: this.navigationUrlParams,
        });
    }
}
