<div class="d-flex justify-content-end" *ngIf="messageData">
    <div class="alert" [ngClass]="alertClass">
        <span
            class="close"
            data-dismiss="alert"
            aria-label="close"
            (click)="closeAlert()"
            >&nbsp;&times;</span
        >
        {{ messageData.message.text }}
        <ul
            *ngIf="
                messageData?.message?.list &&
                messageData.message.list.length > 0
            "
        >
            <li *ngFor="let item of messageData.message.list">{{ item }}</li>
        </ul>
    </div>
</div>
