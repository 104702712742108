import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { validationPatterns } from '../validation-patterns';
import { NumberFormatValidator } from '../validators/number-format-validator';
import { CartesianCoordinateValidator } from '../validators/cartesian-coordinate-validator';
import { OrientationValidator } from '../validators/orientation-validator';

type Limit = {
    lower: number;
    upper: number;
};

@Injectable({ providedIn: 'root' })
export class FormValidatorService {
    getControlByType(controlType: string, limit?: Limit): FormControl<string> {
        if (controlType === 'orientation') {
            return this.getOrientationControl(limit);
        } else if (controlType === 'position') {
            return this.getPositionControl();
        } else if (controlType === 'name') {
            return this.getNameControl();
        } else {
            return null;
        }
    }

    private getNameControl() {
        return new FormControl('', [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
            Validators.pattern(validationPatterns.baseNamePattern),
            this.noWhitespaceValidator,
        ]);
    }

    noWhitespaceValidator(control: FormControl) {
        const isWhitespace = control.value && control.value.trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }

    private getOrientationControl(limit: Limit) {
        return new FormControl('', [
            Validators.required,
            NumberFormatValidator.validateFormat,
            OrientationValidator.validateRangeWithLimits(limit),
        ]);
    }

    private getPositionControl() {
        return new FormControl('', [
            Validators.required,
            NumberFormatValidator.validateFormat,
            CartesianCoordinateValidator.validateRange,
        ]);
    }
}
