import {
    TransformData,
    TransformViewInfo,
} from './architecture-visualization/drawing/transform';

export interface ZoomBehavior {
    isAllowed(transformData: TransformData);
    createAllowedTransformation(transformData: TransformData): TransformData;
}
/**
 * Special behavior which will keep the graph centered,
 * as long as the height or width do not exceed the viewable size.
 * Will keep the Scale/Zoom always between 0 and 100 percent.
 */
export class InsideMovementZoomBehavoir implements ZoomBehavior {
    infoProvider: TransformViewInfo;

    constructor(infoProvider: TransformViewInfo) {
        this.infoProvider = infoProvider;
    }

    isAllowed(transformData: TransformData): boolean {
        return (
            this.infoProvider &&
            (this.infoProvider.getGraphHeight() >
                this.infoProvider.getViewableHeight() ||
                this.infoProvider.getGraphWidth() >
                    this.infoProvider.getViewableWidth()) &&
            transformData.scale <= 1
        );
    }

    createAllowedTransformation(transformData: TransformData): TransformData {
        return {
            x: this.getAllowedX(transformData),
            y: this.getAllowedY(transformData),
            scale: this.getAllowedScale(transformData),
        } as TransformData;
    }

    getAllowedX(transformData: TransformData): number {
        const graphWidth = this.infoProvider.getGraphWidth();
        const viewWidth = this.infoProvider.getViewableWidth();

        const scale = this.infoProvider.getCurrentScale();
        if (graphWidth > viewWidth) {
            if (transformData.x > 0) {
                return 0;
            } else {
                const scaledWidth = graphWidth * scale;
                const delta = viewWidth - scaledWidth;
                return Math.max(delta, transformData.x);
            }
        } else {
            return transformData.x;
        }
    }

    getAllowedY(transformData: TransformData): number {
        const graphHeight = this.infoProvider.getGraphHeight();
        const viewHeight = this.infoProvider.getViewableHeight();
        const scale = this.infoProvider.getCurrentScale();
        if (graphHeight > viewHeight) {
            const scaledHeight = graphHeight * scale;
            const delta = viewHeight - scaledHeight;
            if (transformData.y > 0) {
                return 0;
            } else {
                if (delta < 0 && transformData.y < delta) {
                    return delta;
                } else {
                    return transformData.y;
                }
            }
        } else {
            return viewHeight / 2 - (graphHeight / 2) * scale;
        }
    }

    getAllowedScale(transformData: TransformData): number {
        const graphHeight = this.infoProvider.getGraphHeight();
        const graphWidth = this.infoProvider.getGraphWidth();
        const viewWidth = this.infoProvider.getViewableWidth();
        const viewHeight = this.infoProvider.getViewableHeight();

        let scaleHeight =
            graphHeight > viewHeight ? viewHeight / graphHeight : 1;
        let scaleWidth = graphWidth > viewWidth ? viewWidth / graphWidth : 1;
        if (transformData.scale > 1) {
            return 1;
        } else {
            let minScale = Math.min(scaleWidth, scaleHeight);
            if (transformData.scale < minScale) {
                return minScale;
            } else {
                return transformData.scale;
            }
        }
    }
}
