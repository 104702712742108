import { ActivityResponse } from '../activity-response';
import { TranslateService } from '@ngx-translate/core';
import { ActivityTimeFormatter } from '../../../../../../../shared/activity-time-formatter';

export abstract class Activity {
    private readonly _userId: number;
    private readonly _username: string;
    private readonly _profilePicture: Blob;
    protected readonly createTime: Date;

    get userId(): number {
        return this._userId;
    }

    get username(): string {
        return this._username;
    }

    get profilePicture(): Blob {
        return this._profilePicture;
    }

    protected constructor(activityResponse: ActivityResponse) {
        this._userId = activityResponse.userId;
        this._username = activityResponse.username;
        this._profilePicture = activityResponse.profilePicture;
        this.createTime = new Date(activityResponse.createTime);
    }

    public abstract selectActivityText(): string;

    public generateActivityTime(translate: TranslateService): string {
        return ActivityTimeFormatter.formatTime(this.createTime, translate);
    }

    public abstract getActivityParameter(): string;
}
