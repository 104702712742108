import { NgModule } from '@angular/core';
import { CostFunctionComponent } from './components/cost-function/cost-function.component';
import { CostFunctionEditorComponent } from './components/cost-function-editor/cost-function-editor.component';
import { OrientationTabContentComponent } from './components/orientation-tab-content/orientation-tab-content.component';
import { CostFunctionInputGroupComponent } from './components/cost-function-input-group/cost-function-input-group.component';
import { DistanceTabContentComponent } from './components/distance-tab-content/distance-tab-content.component';
import { PositionTabContentComponent } from './components/position-tab-content/position-tab-content.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { SceneCompositionComponent } from '../scenes/components/scene-composition/scene-composition.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NodeTreeViewComponent } from '../scenes/components/node-tree-view/node-tree-view.component';
import { JointTreeNodeComponent } from '../scenes/components/joint-tree-node/joint-tree-node.component';
import { SearchHighlightPipe } from '../scenes/pipes/search-highlight.pipe';
import { CustomCodeEditorComponent } from './components/custom-code-editor/custom-code-editor.component';

@NgModule({
    declarations: [
        CostFunctionComponent,
        CostFunctionEditorComponent,
        CustomCodeEditorComponent,
        OrientationTabContentComponent,
        CostFunctionInputGroupComponent,
        DistanceTabContentComponent,
        PositionTabContentComponent,
        //TODO: Move to scene.module.ts, when exists
        SceneCompositionComponent,
        NodeTreeViewComponent,
        JointTreeNodeComponent,
        SearchHighlightPipe,
        //End: TODO
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MaterialModule,
        DragDropModule,
    ],
    //TODO: Move to scene.module.ts, when exists
    exports: [SceneCompositionComponent],
    //End: TODO
})
export class CostFunctionModule {}
