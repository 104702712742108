<div class="side-container">
    <div>
        <div *ngIf="containerData?.contentType !== ContentType.NON_INPUT">
            <form>
                <div
                    *ngFor="let item of containerData?.contentItems"
                    [ngClass]="[containerData?.contentType + '-container']"
                >
                    <input
                        [type]="containerData?.contentType"
                        [id]="item.value"
                        [name]="
                            containerData?.contentType === ContentType.RADIO
                                ? 'radio-group'
                                : item.value
                        "
                        [value]="item.value"
                        [checked]="item.checked"
                        (change)="onChange($event.target.value)"
                    />

                    <div
                        *ngIf="item.tagForm"
                        [ngStyle]="{
                            backgroundColor: getTagColor(item),
                            borderColor: getTagColor(item)
                        }"
                        [ngClass]="['tag', item.tagForm.join(' ')]"
                    >
                        <div
                            class="line"
                            [ngStyle]="{
                                borderColor: getTagColor(item)
                            }"
                        ></div>
                    </div>

                    <div class="description-container">
                        <label [for]="item.value">
                            {{
                                item.translationKey
                                    ? (item.translationKey | translate)
                                    : item.value
                            }}
                        </label>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="containerData?.contentType === ContentType.NON_INPUT">
            <div
                *ngFor="let item of containerData.contentItems"
                [ngClass]="[containerData?.contentType + '-container']"
            >
                <div class="text-container">
                    <div
                        *ngIf="item.tagForm"
                        [ngClass]="['tag', item.tagForm.join(' ')]"
                        [ngStyle]="{ backgroundColor: getTagColor(item) }"
                    ></div>
                    <div class="d-flex align-items-start label-container">
                        <p>
                            {{
                                item.translationKey
                                    ? (item.translationKey | translate)
                                    : item.value
                            }}
                        </p>
                    </div>
                </div>
                <div
                    class="info-container"
                    title="{{ item.infoText | translate }}"
                    *ngIf="item.infoText"
                >
                    <img
                        src="assets/svg/evaluation/info-icon.svg"
                        alt="mouseover_info-icon"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
