import { ImgAnnotation } from '../../img-db/model/img-annotation';
import { ImgWithSelection } from '../../img-db/model/img-with-selection';

export interface VisualEvalDataSetHttpResponse {
    categories: VisualEvalCategory[];
    evalSet: VisualEvalSetHttpResponse[];
}

export class VisualEvalDataSet {
    constructor(
        public categories: VisualEvalCategory[],
        public evalSet: VisualEvalSet[]
    ) {}
}

export class VisualEvalCategory {
    constructor(public id: number, public name: string) {}
}

export class VisualEvalSet {
    constructor(
        public annotations: ImgAnnotation[],
        public modelAnnotations: VisualEvalAnnotation[],
        public image: ImgWithSelection
    ) {}
}

export interface VisualEvalSetHttpResponse {
    annotations: AnnotationHttpResponse[];
    modelAnnotations: AnnotationHttpResponse[];
    location: string;
    name: string;
    width: number;
    height: number;
}

export interface AnnotationHttpResponse {
    categoryId: string;
    x: number;
    y: number;
    width: number;
    height: number;
    confidence: number;
}

export class VisualEvalAnnotation extends ImgAnnotation {
    constructor(
        imageId: string,
        categoryId: string,
        x: number,
        y: number,
        width: number,
        height: number,
        public confidence: number
    ) {
        super(null, imageId, categoryId, x, y, width, height, null);
    }
}

export function isVisualEvalAnnotation(
    annotation: any
): annotation is VisualEvalAnnotation {
    return annotation && annotation.confidence;
}
