<div
    class="
        navbar
        justify-content-center
        navbar-dark
        sticky-top
        bg-dark
        flex-md-nowrap
        p-0
    "
>
    <a class="navbar-brand col-sm-3 col-md-2 me-0" routerLink="">
        <h1>BÄM</h1>
    </a>
    <ul class="navbar-nav ms-auto" *ngIf="!isLoggedIn">
        <li class="nav-item">
            <a href="/signup" class="nav-link" routerLink="signup">{{
                'navigation.navbar.signUp' | translate
            }}</a>
        </li>
        <li class="nav-item">
            <a href="/login" class="nav-link" routerLink="login">{{
                'navigation.navbar.login' | translate
            }}</a>
        </li>
    </ul>
    <ul class="navbar-nav ms-auto" *ngIf="isLoggedIn && profilePicture">
        <li>
            <img
                [src]="profilePicture"
                class="profile-picture image-fluid rounded-circle"
                alt="profilePicture"
            />
        </li>
    </ul>
    <ul class="navbar-nav ms-auto" *ngIf="isLoggedIn">
        <li class="nav-item">
            <a href class="nav-link" (click)="logout()">{{
                'navigation.navbar.logout' | translate
            }}</a>
        </li>
    </ul>
</div>
