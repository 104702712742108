import { Injectable } from '@angular/core';
import {
    insertLayerIntoModel,
    removeLayer,
} from 'src/app/skill-architecture-editor/skill-architecture/models/skill-architecture-utils';
import { BehaviorSubject } from 'rxjs';
import * as Skill from './models/layer-namespace';
import { SkillArchitectureService } from './skill-architecture.service';
import { LayerRemovalData } from './models/skill-architecture-types';
import { SkillArchitectureDropEventData } from './architecture-visualization/drop-event/drop-event';

export interface DroppedLayer {
    name: string;
    type: unknown;
    dropTargetIndex: number;
}

@Injectable({
    providedIn: 'root',
})
export class SkillArchitectureStateService {
    public skillArchitecture$: BehaviorSubject<Skill.SkillArchitectureEntity> =
        new BehaviorSubject(null);
    public selectedLayer$: BehaviorSubject<Skill.LayerModel> =
        new BehaviorSubject(null);
    public isSelectedLayerInputOrOutput: BehaviorSubject<boolean> =
        new BehaviorSubject(true);

    constructor(private skillArchitectureService: SkillArchitectureService) {
        this.checkIfSelectedLayerIsInputOrOutput();
    }

    private get entity() {
        return this.skillArchitecture$.value;
    }

    private get model() {
        return this.skillArchitecture$.value?.model;
    }

    private get entityCopy() {
        return { ...this.entity };
    }

    initializeSkillArchitecture(
        newSkillArchitecture: Skill.SkillArchitectureEntity
    ) {
        this.skillArchitecture$.next(newSkillArchitecture);
    }

    updateSkillArchitecture(
        newSkillArchitecture: Skill.SkillArchitectureEntity
    ) {
        if (newSkillArchitecture) {
            this.skillArchitecture$.next(newSkillArchitecture);
            this.skillArchitectureService
                .patch(newSkillArchitecture)
                .subscribe();
        }
    }

    insertSubmittedLayer(
        data: SkillArchitectureDropEventData,
        dropTargetIndex: number
    ): void {
        const configItem = new (<any>Skill)[data.name]() as Skill.Layer;
        const newLayer: Skill.LayerModel = {
            name: '',
            config: configItem,
            class_name: data.name,
            inbound_nodes: [],
        };
        const siblingLayerNames = this.getSiblingLayerNames(dropTargetIndex);
        let newEntity = this.performInsertion(newLayer, siblingLayerNames);
        this.publishInsertion(newEntity, newLayer);
    }

    deleteSelectedLayer() {
        const layerToDelete = this.selectedLayer$.value;
        const removalData: LayerRemovalData = removeLayer(
            this.model,
            layerToDelete
        );
        const newEntity = this.entityCopy;
        newEntity.model = removalData.model;
        this.updateSkillArchitecture(newEntity);
        this.selectedLayer$.next(removalData.layerFollowingDeletedLayer);
    }

    private getSiblingLayerNames(dropTargetIndex: number) {
        const preLayer = this.model.layers[dropTargetIndex];
        const postLayer = this.model.layers[dropTargetIndex + 1];
        return [preLayer.name, postLayer.name];
    }

    private performInsertion(
        newLayer: Skill.LayerModel,
        siblingLayerNames: string[]
    ): Skill.SkillArchitectureEntity {
        const newModel = insertLayerIntoModel(
            this.model,
            newLayer,
            siblingLayerNames
        );

        const newEntity = this.entityCopy;
        newEntity.model = newModel;

        return newEntity;
    }

    private publishInsertion(
        newEntity: Skill.SkillArchitectureEntity,
        newLayer: Skill.LayerModel
    ) {
        this.updateSkillArchitecture(newEntity);
        this.selectedLayer$.next(newLayer);
    }

    updateLayer(layerWithChanges: Skill.LayerModel): void {
        this.writeChanges(layerWithChanges);
        this.notifyEntitySubscribers();
    }

    private writeChanges(layerWithChanges: Skill.LayerModel) {
        const oldLayer = this.findLayerByName(layerWithChanges.name);
        oldLayer.config = layerWithChanges.config;
    }

    private notifyEntitySubscribers() {
        this.updateSkillArchitecture(this.entity);
    }

    selectLayer(selectedLayerName: string) {
        this.performSelection(selectedLayerName);
    }

    private performSelection(selectedLayerName: string) {
        this.selectedLayer$.next(this.findLayerByName(selectedLayerName));
    }

    private findLayerByName(name: string): Skill.LayerModel {
        return this.model.layers.find((layer) => layer.name === name);
    }

    private checkIfSelectedLayerIsInputOrOutput() {
        this.selectedLayer$.subscribe((_) => {
            this.isSelectedLayerInputOrOutput.next(
                this.checkIfSelectedLayerIsInput() ||
                    this.checkIfSelectedLayerIsOutput()
            );
        });
    }

    private checkIfSelectedLayerIsInput() {
        return this.getSelectedIndex() === 0;
    }

    private checkIfSelectedLayerIsOutput() {
        return this.getSelectedIndex() + 1 === this.model?.layers.length;
    }

    public getSelectedIndex(): number {
        const selectedLayerIndex = this.model?.layers.indexOf(
            this.selectedLayer$.value
        );
        return selectedLayerIndex;
    }
}
