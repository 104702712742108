import { ElementRef, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ScrollableService {
    public checkIfHasScrollbar(elem: ElementRef): boolean {
        if (!elem) {
            return false;
        }

        const listClientHeight = elem.nativeElement.clientHeight;
        const listScrollHeight = elem.nativeElement.scrollHeight;

        return listScrollHeight > listClientHeight;
    }
}
