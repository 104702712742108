import { Component, Input } from '@angular/core';
import { UserInfoMember } from '../../models/user-info';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent {
    @Input() userInfo: UserInfoMember;

    getDisplayName(): string {
        return this.userInfo.firstName && this.userInfo.lastName
            ? this.userInfo.firstName + ' ' + this.userInfo.lastName
            : this.userInfo.displayName;
    }

    getTranslationKeyForRole(): string {
        return `role.project.${this.userInfo.role}`;
    }
}
