import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { TrainingTreeNode } from '../../models/training-tree-node';
import { TreeStateService } from '../../services/tree-state.service';
import { TrainingStatus } from 'src/app/shared/models/training-status';
import { JobService } from '../../services/job.service';

@Component({
    selector: 'app-right-click-menu',
    templateUrl: './right-click-menu.component.html',
    styleUrls: ['./right-click-menu.component.scss'],
})
export class RightClickMenuComponent implements OnInit, OnDestroy {
    treeNode: TrainingTreeNode;
    projectUuid: string;
    treeStateSubscription: Subscription;
    TrainingStatus = TrainingStatus; //So the HTML template can see the enum.

    constructor(
        private treeStateService: TreeStateService,
        private jobService: JobService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translateService: TranslateService
    ) {}

    public ngOnInit() {
        this.treeStateSubscription =
            this.treeStateService.selectedTreeNode$.subscribe(
                (node: TrainingTreeNode) => {
                    this.treeNode = node;
                }
            );
        this.projectUuid = this.route.snapshot.params['projectId'];
    }

    public ngOnDestroy() {
        if (this.treeStateSubscription) {
            this.treeStateSubscription.unsubscribe();
        }
    }

    start(): void {
        this.jobService.start(this.treeNode).subscribe(() => {
            this.treeNode.status = TrainingStatus.QUEUED;
            this.treeStateService.setTreeHasRunningTraining(true);
        });
        this.closeContextMenu();
    }

    stop(): void {
        this.jobService.stop(this.treeNode).subscribe(() => {
            this.treeNode.status = TrainingStatus.CANCELLING;
        });
        this.closeContextMenu();
    }

    clone(): void {
        this.treeStateService.cloneTreeNode(
            this.treeNode.uuid,
            this.translateService.instant(
                'splitScreen.components.contextMenu.cloneSuffix'
            ),
            this.projectUuid
        );
        this.closeContextMenu();
    }

    openDeletionConfirmDialog(): void {
        const results = this.translateService.instant(
            [
                'splitScreen.components.contextMenu.delete1',
                'splitScreen.components.contextMenu.keep',
                'splitScreen.components.contextMenu.confirmation',
                'splitScreen.components.contextMenu.delete2',
            ],
            {
                name: this.treeNode.name,
            }
        );
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            confirmButtonText:
                results['splitScreen.components.contextMenu.delete1'],
            cancelButtonText:
                results['splitScreen.components.contextMenu.keep'],
            title: results['splitScreen.components.contextMenu.confirmation'],
            message: results['splitScreen.components.contextMenu.delete2'],
        };

        this.dialog
            .open(ConfirmDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.deleteNode();
                }
            });
        this.closeContextMenu();
    }

    deleteNode(): void {
        this.treeStateService.deleteTreeNode(
            this.treeNode.uuid,
            this.projectUuid
        );
    }

    closeContextMenu() {
        this.treeStateService.isRightClickMenuOpen = false;
    }

    isStatus(value: TrainingStatus): boolean {
        return this.treeNode.status === value;
    }

    isStatusOneOf(values: TrainingStatus[]): boolean {
        for (const val of values) {
            if (this.isStatus(val)) {
                return true;
            }
        }
        return false;
    }
}
