import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormValidatorService } from '../../services/form-validator.service';
import { TranslateService } from '@ngx-translate/core';
import { NumberTranslateService } from '../../services/number-translate.service';
import { Subscription } from 'rxjs';
import { nonNil } from '../../utility';

type ValueLimits = {
    lower: number;
    upper: number;
};

/**
 * @param errorTranslationKey: string -> is used for errors
 * @param leftLabel: string -> if there are no limits u may want to show a label on the left of input like "x,y,z"
 * @param step: number -> used for up/down rows inside the input field. (0.1, 0.01 ...)
 * @param controlType: string -> there are three types: 'orientation', 'position', 'name' that will be used by method in FormValidatorService
 * @param decimalPlace: number -> defines how many decimal places to be shown
 * @param isControlValid: EventEmitter<boolean> -> used for emitting that input field invalid and disable accordion in scene
 * @param valueChanged: EventEmitter<number> -> emits a new value from the input field
 * @param shouldBeFocused: boolean -> defines if the input field will be focused or not
 * @param lowerLimit ->
 *                       used for validation rules and for limiting of steppers
 * @param upperLimit ->
 */
@Component({
    selector: 'app-floating-number-input',
    templateUrl: './floating-number-input.component.html',
    styleUrls: ['./floating-number-input.component.scss'],
})
export class FloatingNumberInputComponent
    implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
    @Input() errorTranslationKey: string = '';
    @Input() leftLabel: string;
    @Input() step: number;
    @Input() controlType: string;
    @Input() defaultValue: number = 0;
    @Input() decimalPlace: number;
    @Input() shouldBeFocused: boolean = false;
    @Input() lowerLimit: number;
    @Input() upperLimit: number;
    @Input() showLimits: boolean = false;
    @Input() selectValueOnFocus: boolean = false;

    @ViewChild('inputField') inputElementRef: ElementRef;

    @Output() isControlValid: EventEmitter<boolean> =
        new EventEmitter<boolean>();
    @Output() valueChanged: EventEmitter<number> = new EventEmitter<number>();

    limits: ValueLimits;
    oldInputValue: number;
    control: FormControl<string>;
    translateServiceSubscription: Subscription;
    statusChangesSubscription: Subscription;

    constructor(
        private validatorService: FormValidatorService,
        private translateService: TranslateService,
        private numberTranslateService: NumberTranslateService
    ) {}

    ngOnInit(): void {
        this.step = Math.abs(this.step);

        this.prepareControl();

        this.translateServiceSubscription =
            this.translateService.onLangChange.subscribe(() => {
                this.setFixedControlValue(this.getControlValue());
            });
    }

    private prepareControl() {
        this.setLimits();

        this.control = this.validatorService.getControlByType(
            this.controlType,
            this.limits
        );
        this.setFixedControlValue(this.defaultValue);

        this.unsubscribeFromStatusChanges();
        this.subscribeOnStatusChanges();

        this.oldInputValue = this.getControlValue();
    }

    private subscribeOnStatusChanges() {
        this.statusChangesSubscription = this.control.statusChanges.subscribe(
            (status) => {
                if (status === 'INVALID') {
                    this.isControlValid.emit(false);
                    this.requestFocus();
                } else {
                    this.isControlValid.emit(true);
                }
            }
        );
    }

    private unsubscribeFromStatusChanges() {
        if (this.statusChangesSubscription) {
            this.statusChangesSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lowerLimit || changes.upperLimit) {
            this.prepareControl();
        } else if (changes.defaultValue) {
            this.setFixedControlValue(this.defaultValue);
        }
        this.focusElement();
    }

    ngAfterViewInit(): void {
        this.focusElement();
    }

    private focusElement() {
        if (this.shouldBeFocused) {
            this.requestFocus();
        }
    }

    private requestFocus() {
        if (this.inputElementRef) {
            this.inputElementRef.nativeElement.focus();
        }
    }

    onFocus() {
        if (this.selectValueOnFocus) {
            this.inputElementRef.nativeElement.select();
        }
    }

    onEscapeButtonPress() {
        this.setFixedControlValue(this.oldInputValue);
        this.inputElementRef.nativeElement.blur();
    }

    onBlur() {
        if (this.control.valid && this.hasValueChanged) {
            let currentValue = this.getControlValue();

            this.setFixedControlValue(currentValue);

            currentValue = this.getControlValue();

            this.emitNewValue(currentValue);

            this.oldInputValue = currentValue;
        } else if (this.control.invalid) {
            this.requestFocus();
        }
    }

    private emitNewValue(newValue: number) {
        this.valueChanged.emit(newValue);
    }

    private get hasValueChanged(): boolean {
        return this.oldInputValue !== this.getControlValue();
    }

    onEnterKey() {
        if (this.control.valid) {
            this.inputElementRef.nativeElement.blur();
        }
    }

    plusStep() {
        this.requestFocus();

        const valueAfterStep = this.getControlValue() + this.step;

        if (valueAfterStep <= this.limits.upper) {
            this.setFixedControlValue(valueAfterStep);
        }
    }

    minusStep() {
        this.requestFocus();

        const valueAfterStep = this.getControlValue() - this.step;

        if (valueAfterStep >= this.limits.lower) {
            this.setFixedControlValue(valueAfterStep);
        }
    }

    getControlValue(): number {
        if (this.control.valid) {
            const internationalizedValue = this.control.value;
            const floatString = internationalizedValue.replace(',', '.');
            return parseFloat(floatString);
        }
    }

    setFixedControlValue(newValue: number) {
        const translatedValue = this.numberTranslateService.translateNumber(
            newValue.toFixed(this.decimalPlace)
        );
        this.control.setValue(translatedValue);
    }

    private setLimits() {
        this.limits = { lower: null, upper: null };

        if (nonNil(this.lowerLimit)) {
            this.limits.lower = this.lowerLimit;
        } else {
            this.limits.lower = Number.MIN_SAFE_INTEGER / 10;
        }

        if (nonNil(this.upperLimit)) {
            this.limits.upper = this.upperLimit;
        } else {
            this.limits.upper = Number.MAX_SAFE_INTEGER / 10;
        }
    }

    ngOnDestroy(): void {
        if (this.translateServiceSubscription) {
            this.translateServiceSubscription.unsubscribe();
        }
        this.unsubscribeFromStatusChanges();
    }
}
