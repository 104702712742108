import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { VideoAndIterationFileService } from '../service/video-and-iteration-file.service';

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
    showVideo: boolean = false;
    @ViewChild('trainingVideo') trainingVideo: ElementRef;
    @Input() id: string;

    constructor(
        private videoAndIterationFileService: VideoAndIterationFileService
    ) {}

    ngOnInit(): void {
        this.fetchVideoSrc();
    }

    displayPlayer(): string {
        if (this.showVideo) {
            return 'visible';
        } else {
            return 'hidden';
        }
    }

    handleVideoResponse(response: HttpResponse<ArrayBuffer>) {
        if (response.body.byteLength) {
            this.showVideo = true;
            const blob = new Blob([response.body]);
            this.trainingVideo.nativeElement.src = URL.createObjectURL(blob);
        } else {
            this.showVideo = false;
        }
    }

    fetchVideoSrc() {
        this.videoAndIterationFileService
            .getEvaluationVideo(this.id)
            .subscribe((response) => {
                this.handleVideoResponse(response);
            });
    }
}
