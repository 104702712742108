import * as Skill from './layer-namespace';
import { SkillArchitecture } from './skill-architecture';
import { LayerRemovalData } from './skill-architecture-types';

export function insertLayerIntoModel(
    model: Skill.SkillArchitecture,
    newLayer: Skill.LayerModel,
    siblingLayerNames: string[]
): Skill.SkillArchitecture {
    var localModel = { ...model };
    const preLayer = findLayerByName(localModel, siblingLayerNames[0]);
    const postLayer = findLayerByName(localModel, siblingLayerNames[1]);

    newLayer.inbound_nodes = postLayer.inbound_nodes.filter(
        (link) => link[0][0] === preLayer.name
    );
    postLayer.inbound_nodes = postLayer.inbound_nodes.filter(
        (link) => link[0][0] !== preLayer.name
    );

    const newName = newLayer.class_name.toLowerCase();
    const newIndex = getNumberOfLayers(localModel) + 1;
    newLayer.name = `${newName}_${newIndex}`;
    postLayer.inbound_nodes.push([[newLayer.name, 0, 0, {}]]);

    localModel.layers.splice(
        localModel.layers.indexOf(preLayer) + 1,
        0,
        newLayer
    );
    return localModel;
}

export function removeLayer(
    model: Skill.SkillArchitecture,
    layerToDelete: Skill.LayerModel
) {
    const localModel: SkillArchitecture = { ...model };
    const postLayer = findLayerInInboudNodes(localModel, layerToDelete.name);
    const preLayerName = layerToDelete.inbound_nodes[0][0][0];
    const preLayer = findLayerByName(localModel, preLayerName);
    const layerToDeleteIndex = localModel.layers.indexOf(layerToDelete);
    localModel.layers.splice(layerToDeleteIndex, 1);
    postLayer.inbound_nodes = [[[preLayer.name, 0, 0, {}]]];
    return {
        model: localModel,
        layerFollowingDeletedLayer: postLayer,
    } as LayerRemovalData;
}

function findLayerInInboudNodes(model: SkillArchitecture, layerName: string) {
    return model.layers.find((layer: Skill.LayerModel) => {
        const inboundLayerName = layer.inbound_nodes[0]?.[0][0];
        return inboundLayerName === layerName;
    });
}

export function getNumberOfLayers(model: Skill.SkillArchitecture): number {
    return model.layers
        .map((layer) => layer.name.split('_')[1])
        .map((layerNumber: string) => +layerNumber)
        .reduce((highestLayerNumber, selectedLayerNumber) =>
            highestLayerNumber > selectedLayerNumber
                ? highestLayerNumber
                : selectedLayerNumber
        );
}

function findLayerByName(model: Skill.SkillArchitecture, layerName: string) {
    return model.layers.find(
        (layer: Skill.LayerModel) => layer.name === layerName
    );
}
