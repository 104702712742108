import { Injectable } from '@angular/core';
import { HierarchyPointNode } from 'd3';
import {
    DThreeNodeEnterElement,
    DThreeSvgElement,
} from 'src/app/shared/models/d-three-types';
import { TrainingTreeNode } from '../models/training-tree-node';
import { TreeStateService } from './tree-state.service';

@Injectable({
    providedIn: 'root',
})
export class TreeActionsService {
    refuseTransfer = false;
    replayClickData: HierarchyPointNode<TrainingTreeNode>;

    constructor(private treeStateService: TreeStateService) {
        this.treeStateService.onForceChangeSelected.subscribe(() => {
            this.replayClickData = undefined;
        });
    }

    addCloseMenuActionTo(element: DThreeSvgElement): void {
        element.on('click', () => {
            this.treeStateService.closeRightClickMenu();
        });
    }

    addOpenMenuAction(enterElement: DThreeNodeEnterElement) {
        enterElement
            .selectAll('foreignObject')
            .on(
                'contextmenu',
                (
                    event: MouseEvent,
                    node: HierarchyPointNode<TrainingTreeNode>
                ) => {
                    if (this.refuseTransfer) {
                        event.preventDefault();
                        return;
                    }
                    if (event.shiftKey) {
                        return;
                    }
                    event.preventDefault();
                    this.treeStateService.openRightClickMenu(event, node.data);
                    this.treeStateService.selectedTreeNode = node.data;
                }
            );
    }

    addNodeClickAction(enterElement: DThreeNodeEnterElement) {
        enterElement
            .selectAll('foreignObject')
            .on('mouseup', (_, node: HierarchyPointNode<TrainingTreeNode>) =>
                this.handleClick(node)
            );
    }

    handleClick(node: HierarchyPointNode<TrainingTreeNode>) {
        if (!this.refuseTransfer) {
            this.treeStateService.selectedTreeNode = node.data;
            this.replayClickData = undefined;
        }
    }
}
