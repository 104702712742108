import { Component, Input, OnInit } from '@angular/core';
import { Media } from '../../../models/media';

@Component({
    selector: 'app-cms-snippet-background-image',
    templateUrl: './cms-snippet-background-image.component.html',
})
export class CmsSnippetBackgroundImageComponent implements OnInit {
    @Input()
    backgroundMedia: Media;
    alt: string;

    ngOnInit(): void {
        if (this.backgroundMedia) {
            this.alt =
                this.backgroundMedia.alt ?? this.backgroundMedia.fileName;
        }
    }
}
