import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    UntypedFormControl,
    FormGroupDirective,
    NgForm,
    Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { RobotService } from '../../services/robot.service';
import {
    HttpErrorResponse,
    HttpEventType,
    HttpResponse,
} from '@angular/common/http';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatStepper } from '@angular/material/stepper';
import { validationPatterns } from 'src/app/shared/validation-patterns';
import { BrowserAndPhysicsLoadable } from 'src/app/object3-d/models/browser-and-physics-loadable';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { delay } from '../../../shared/utility';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: UntypedFormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

@Component({
    selector: 'app-add-git-object',
    templateUrl: './add-git-object.component.html',
    styleUrls: ['../shared/add-object.component.scss'],
})
export class AddGitObjectComponent implements OnInit, OnDestroy {
    @ViewChild('stepper')
    stepper: MatStepper;

    uploadType: string;
    isLinear = true;
    robot: BrowserAndPhysicsLoadable | null = null;
    progress = 0;
    message = '';
    success = false;
    hide = true;
    isPrivate = false;
    checked = false;
    ngDefaultControl;
    uri: string;
    username: string;
    password: string;
    validPattern = validationPatterns.baseNamePattern; // alphanumeric exact 10 letters
    step2completed = false;

    objectFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.validPattern),
        Validators.maxLength(20),
    ]);

    matcher = new MyErrorStateMatcher();

    private subscription: Subscription | undefined;

    constructor(
        private objectService: RobotService,
        private modalService: NgbModal,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.robot = new BrowserAndPhysicsLoadable();
    }

    onSubmit() {
        this.robot.name = this.objectFormControl.value;
        this.progress = 0;
        this.subscription = this.objectService
            .createRobotsByRepo(
                this.robot,
                this.uri,
                this.username,
                this.password
            )
            .subscribe({
                next: (event) => {
                    if (event.type === HttpEventType.UploadProgress) {
                        this.progress = Math.round(
                            (30 * event.loaded) / event.total
                        );
                    } else if (event instanceof HttpResponse) {
                        this.message = this.translate.instant(
                            'robots.components.addZipObject.uploadSuccess'
                        );
                        this.success = true;
                        this.progress = 100;
                        this.step2completed = true;
                        delay(100).then(() => this.stepper.next());
                        delay(300).then(() =>
                            this.router.navigate([], {
                                relativeTo: this.route,
                                queryParams: {
                                    selected: 'robots',
                                },
                            })
                        );
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.progress = 0;
                    if (error.status === 409) {
                        this.message = this.translate.instant(
                            'robots.components.addZipObject.nameAlreadyUsed'
                        );
                    } else {
                        this.message = 'Error: ' + error.error;
                    }
                    this.step2completed = true;
                    delay(100).then(() => this.stepper.next());
                },
            });
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    changeObjectName(event: any) {
        this.robot.name = event.target.value;
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    cleanUp() {
        this.uri = undefined;
    }
}
