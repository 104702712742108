import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { validationPatterns } from 'src/app/shared/validation-patterns';
import { TreeStateService } from '../../services/tree-state.service';
import { TrainingTreeNode } from '../../models/training-tree-node';
import { TreeActionsService } from '../../services/tree-actions.service';
import { Subscription } from 'rxjs';
import { TrainingMediaAction } from '../../models/media-action';
import { TrainingTile } from '../../models/training-tile';
import { TrainingStatus } from 'src/app/shared/models/training-status';
import { TrainingService } from 'src/app/shared/services/training.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MessageService } from 'src/app/message/services/message.service';

@Component({
    selector: 'app-training-control',
    templateUrl: './training-control.component.html',
    styleUrls: ['./training-control.component.scss'],
})
export class TrainingControlComponent implements OnDestroy, OnChanges, OnInit {
    @ViewChild('trainingNameInputField')
    trainingNameInputElement: ElementRef;

    @Input() tileNameSuffixes: TrainingTile[];

    managedTileSuffixes: TrainingTile[] = [];

    @Input() resizeElemSplite: boolean = false;

    @Input() treeNode: TrainingTreeNode;

    @Output() tileClicked = new EventEmitter<TrainingTile>();

    @Output() trainingMediaButtonClicked =
        new EventEmitter<TrainingMediaAction>();

    public trainingNameForm: UntypedFormGroup;
    private defaultTrainingName: string;
    centralButtonState: string = 'play';
    subChanges: Subscription;
    readonly maxAmountOfHexagons = 6;

    get trainingNameFormValue() {
        return this.trainingNameControl.value;
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private treeStateService: TreeStateService,
        private treeActionsService: TreeActionsService,
        private trainingService: TrainingService,
        private messageService: MessageService
    ) {}

    ngOnDestroy(): void {
        if (this.subChanges) {
            this.subChanges.unsubscribe();
        }
    }

    ngOnInit() {
        this.initializeTrainingNameForm();
        this.validateTileNamesInput();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tileNameSuffixes && !changes.tileNameSuffixes.firstChange) {
            this.validateTileNamesInput();
        }
        if (
            changes.firstChange ||
            document.activeElement !==
                this.trainingNameInputElement?.nativeElement
        ) {
            this.trainingNameForm?.patchValue({
                name: this.treeNode.name,
            });
        }
        this.defaultTrainingName = this.treeNode.name;
        if (this.treeNode.name) {
            this.treeStateService.getTrainingOfSelectedNode().subscribe();
        }

        // hexagon component
        this.centralButtonState = this.getCentralButtonState(
            this.treeNode.status
        );
    }

    /*
     * Following code used for training name input field
     */

    private initializeTrainingNameForm(): void {
        this.trainingNameForm = this.formBuilder.group({
            name: [
                '',
                [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(32),
                    Validators.pattern(validationPatterns.baseNamePattern),
                ],
            ],
        });
    }

    onBlur(submittedTrainingName: string): void {
        this.treeStateService.isTrainingNameValid =
            this.trainingNameControl.invalid;
        const trimmedTrainingName = this.trimTrainingNameInForm(
            submittedTrainingName
        );
        this.handleNewNameSubmission(trimmedTrainingName);
    }

    onKeyDown(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.trainingNameForm
                .get('name')
                .setValue(this.defaultTrainingName);
            this.trainingNameControl.disable();
            this.trainingNameControl.enable();
        } else if (event.key === 'Enter') {
            (event.target as HTMLElement).blur();
        }
    }

    private trimTrainingNameInForm(nameContainingWhitespace: string): string {
        const trimmedTrainingName = nameContainingWhitespace.trim();
        this.trainingNameForm.patchValue({ name: trimmedTrainingName });
        return trimmedTrainingName;
    }

    handleNewNameSubmission(newName: string): void {
        if (this.trainingNameForm.invalid) {
            this.putPromptAtEndOfInputField();
            this.treeActionsService.refuseTransfer = true;
        } else {
            this.treeStateService
                .updateSelectedTreeNodeName(newName)
                .subscribe(() => {
                    if (!this.treeActionsService.replayClickData) {
                        return;
                    }
                    if (
                        this.treeActionsService.replayClickData.data.uuid ===
                        this.treeNode.uuid
                    ) {
                        this.treeActionsService.replayClickData.data.name =
                            newName;
                    }
                    this.treeActionsService.handleClick(
                        this.treeActionsService.replayClickData
                    );
                });
        }
    }

    private putPromptAtEndOfInputField(): void {
        this.trainingNameInputElement.nativeElement.focus();
        const textLength = this.trainingNameControl.value.length;
        this.trainingNameInputElement.nativeElement.setSelectionRange(
            textLength,
            textLength
        );
    }

    get trainingNameControl() {
        return this.trainingNameForm.get('name') as FormControl;
    }

    /*
     * Following code used for hexagon component
     */

    private validateTileNamesInput() {
        this.managedTileSuffixes = this.tileNameSuffixes;
        if (this.managedTileSuffixes.length > this.maxAmountOfHexagons) {
            console.warn(
                `More than ${this.maxAmountOfHexagons} hexagon tile names specified: [${this.managedTileSuffixes}]`
            );
            this.managedTileSuffixes = this.managedTileSuffixes.slice(
                0,
                this.maxAmountOfHexagons
            );
        } else {
            this.fillMissingTileNames();
        }
    }

    private fillMissingTileNames() {
        let index = this.maxAmountOfHexagons - this.managedTileSuffixes.length;
        for (let i = 0; i < index; i++) {
            this.managedTileSuffixes.push(TrainingTile.EMPTY);
        }
    }

    public onTileClick(tileName: TrainingTile) {
        if (tileName !== TrainingTile.EMPTY) {
            this.tileClicked.emit(tileName);
        }
    }

    private getCentralButtonState(status: TrainingStatus) {
        switch (status) {
            case TrainingStatus.NEW:
                return 'play';
            case TrainingStatus.RUNNING:
            case TrainingStatus.QUEUED:
            case TrainingStatus.PREPARING:
            case TrainingStatus.REQUESTING:
                return 'stop';
            case TrainingStatus.SUCCEEDED:
                return 'success';
            case TrainingStatus.CANCELLED:
            case TrainingStatus.CANCELLING:
            case TrainingStatus.FAILED:
                return 'fail';
        }
    }

    start(): void {
        this.trainingMediaButtonClicked.emit(TrainingMediaAction.START);
    }

    stop(): void {
        this.trainingMediaButtonClicked.emit(TrainingMediaAction.STOP);
    }

    getTileNameTranslationKey(tileNameSuffix: TrainingTile) {
        if (tileNameSuffix !== TrainingTile.EMPTY) {
            return `splitScreen.components.hexagonTiles.${tileNameSuffix.toString()}`;
        }
        return '';
    }

    downloadTrainingSkill(): void {
        this.trainingService.downloadSkill(this.treeNode.uuid).subscribe({
            next: (response: HttpResponse<Blob>) => {
                const contentDisposition = response.headers.get(
                    'content-disposition'
                );
                const downloadURL = window.URL.createObjectURL(response.body);
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download =
                    this.getTrainingSkillFileName(contentDisposition);
                link.click();
                window.URL.revokeObjectURL(downloadURL);
            },
            error: (error: HttpErrorResponse) => {
                let messageKey: string =
                    'splitScreen.components.contextMenu.canNotDownloadSkill';
                if (error.status === 404) {
                    messageKey =
                        'splitScreen.components.contextMenu.canNotFindSkillFile';
                }
                this.messageService.displayTranslatedErrorMessage(
                    messageKey,
                    null
                );
            },
        });
    }

    private getTrainingSkillFileName(contentDisposition: string): string {
        return contentDisposition
            ? contentDisposition
                  .split(';')[1]
                  .trim()
                  .split('=')[1]
                  .replace(/\"/g, '')
            : 'file';
    }
}
