import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { AlgorithmInputComponent } from './components/algorithm-input/algorithm-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlgorithmComponent } from './components/algorithm/algorithm.component';
import { PpoParametersComponent } from './components/ppo-parameters/ppo-parameters.component';
import { AlgorithmFormService } from './services/algorithm-form.service';
import { AdamParametersComponent } from './components/adam-parameters/adam-parameters.component';
import { SacParametersComponent } from './components/sac-parameters/sac-parameters.component';
import { ExpertDatasetComponent } from './components/expert-dataset/expert-dataset.component';

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        SharedModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslateModule.forRoot(),
    ],
    declarations: [
        AlgorithmInputComponent,
        AlgorithmComponent,
        PpoParametersComponent,
        AdamParametersComponent,
        SacParametersComponent,
        ExpertDatasetComponent,
    ],
    providers: [AlgorithmFormService],
    exports: [AlgorithmComponent],
})
export class AlgorithmModule {}
