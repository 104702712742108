import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormControl,
    FormGroupDirective,
    NgForm,
    Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { RobotService } from '../../services/robot.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatStepper } from '@angular/material/stepper';
import { UploadFileType } from '../../../object3-d/models/upload-file-type';
import { Object3DService } from '../../../object3-d/service/object3-d.service';
import { BrowserAndPhysicsLoadable } from 'src/app/object3-d/models/browser-and-physics-loadable';
import { validationPatterns } from 'src/app/shared/validation-patterns';
import { TranslateService } from '@ngx-translate/core';
import { delay } from '../../../shared/utility';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(
        control: UntypedFormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        const isSubmitted = form && form.submitted;
        return !!(
            control &&
            control.invalid &&
            (control.dirty || control.touched || isSubmitted)
        );
    }
}

@Component({
    selector: 'app-add-zip-object',
    templateUrl: './add-zip-object.component.html',
    styleUrls: ['../shared/add-object.component.scss'],
})
export class AddZipObjectComponent implements OnInit, OnDestroy {
    @Input()
    uploadFileType: UploadFileType;
    @ViewChild('stepper') stepper: MatStepper;
    processing: boolean;
    maxFileSize = 20;
    isLinear = true;
    file: File | null = null;
    robot: BrowserAndPhysicsLoadable | null = null;
    progress = 0;
    message = '';
    success = false;
    validPattern = validationPatterns.baseNamePattern;
    fileSizeMessage = '';
    step1completed = false;
    step2completed = false;
    acceptedFileTypes: string;
    objectFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.validPattern),
        Validators.maxLength(20),
    ]);
    matcher = new MyErrorStateMatcher();
    routerSubscription: any;
    @Output()
    uploadObjectItem = new EventEmitter<BrowserAndPhysicsLoadable>();

    private subscription: Subscription | undefined;

    constructor(
        private robotService: RobotService,
        private router: Router,
        private modalService: NgbModal,
        private object3DService: Object3DService,
        private translate: TranslateService
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
            }
        });
    }

    ngOnInit() {
        if (this.uploadFileType === UploadFileType.OBJECT) {
            this.acceptedFileTypes = '.zip,.rar,.glb';
        } else if (this.uploadFileType === UploadFileType.ROBOT) {
            this.acceptedFileTypes = '.zip,.rar';
        }

        this.robot = new BrowserAndPhysicsLoadable();
    }

    uploadNewObjectItem(item: BrowserAndPhysicsLoadable) {
        this.uploadObjectItem.emit(item);
    }

    onFileInput(files: FileList | null): void {
        if (files) {
            this.file = files.item(0);
            this.fileSizeMessage = '';
            if (this.file) {
                this.step1completed = true;
                delay(100).then(() => this.stepper.next());
            }
        }
    }

    onSubmit() {
        this.robot.name = this.objectFormControl.value;
        this.progress = 0;
        if (this.file) {
            if (this.file.size / 1024 / 1024 < this.maxFileSize) {
                let uploadFile;
                if (
                    this.uploadFileType === UploadFileType.OBJECT ||
                    this.uploadFileType === UploadFileType.OBJECT_IN_SCENE
                ) {
                    uploadFile = this.object3DService.createFileObject3D(
                        this.file,
                        this.robot
                    );
                } else if (this.uploadFileType === UploadFileType.ROBOT) {
                    uploadFile = this.robotService.createRobot(
                        this.file,
                        this.robot
                    );
                }
                this.subscription = uploadFile.subscribe(
                    (event) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            this.progress = Math.round(
                                (30 * event.loaded) / event.total
                            );
                        } else if (event instanceof HttpResponse) {
                            this.message = this.translate.instant(
                                'robots.components.addZipObject.uploadSuccess'
                            );
                            this.success = true;
                            this.progress = 100;
                            this.step2completed = true;
                            delay(100).then(() => this.stepper.next());

                            if (
                                this.uploadFileType !==
                                UploadFileType.OBJECT_IN_SCENE
                            ) {
                                this.router.navigateByUrl(
                                    `/workspace?selected=${this.uploadFileType}`
                                );
                            } else {
                                this.uploadNewObjectItem(event.body);
                            }
                        }
                    },
                    (error) => {
                        this.progress = 0;
                        if (error.status === 409) {
                            this.message = this.translate.instant(
                                'robots.components.addZipObject.nameAlreadyUsed'
                            );
                        } else {
                            this.message = 'Error: ' + error.error;
                        }
                        this.step2completed = true;
                        delay(100).then(() => this.stepper.next());
                    }
                );
            } else {
                this.fileSizeMessage =
                    'robots.components.addZipObject.fileTooLarge';
                this.progress = 0;
            }
        }
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
        if (this.routerSubscription) {
            this.routerSubscription?.unsubscribe();
        }
    }

    changeObjectName(event: any) {
        this.robot.name = event.target.value;
    }

    dismissModal() {
        this.modalService.dismissAll();
    }

    buttonClicked(value: number) {
        this.processing = false;
        if (!this.processing) {
            this.stepper.selectedIndex = value;
        }
    }
}
