import { Pipe, PipeTransform } from '@angular/core';
import { Reference } from '../../shared/utility';

@Pipe({
    name: 'searchHighlight',
})
export class SearchHighlightPipe implements PipeTransform {
    transform(
        value: string,
        searchTerm: string,
        searchResults: Reference<number>,
        searchIndex: number
    ): string {
        if (searchTerm === '') {
            return value;
        }
        const searchExp = new RegExp(searchTerm, 'gi');
        if (searchExp.test(value)) {
            searchResults.value++;
        }
        return value.replace(searchExp, (match) => {
            // found is incremented before it is checked
            if (searchResults.value === searchIndex + 1) {
                return `<span class='searched-selected'>${match}</span>`;
            } else {
                return `<span class='searched'>${match}</span>`;
            }
        });
    }
}
