export enum TfDtype {
    BFLOAT16 = 'bfloat16',
    COMPLEX128 = 'complex128',
    COMPLEX64 = 'complex64',
    FLOAT16 = 'float16',
    FLOAT32 = 'float32',
    FLOAT64 = 'float64',
    INT16 = 'int16',
    INT32 = 'int32',
    INT64 = 'int64',
    INT8 = 'int8',
    UINT16 = 'uint16',
    UINT32 = 'uint32',
    UINT64 = 'uint64',
    UINT8 = 'uint8',

    QINT16 = 'qint16',
    QINT16_REF = 'qint16_ref',
    QINT32 = 'qint32',
    QINT32_REF = 'qint32_ref',
    QINT8 = 'qint8',
    QINT8_REF = 'qint8_ref',
    QUINT16 = 'quint16',
    QUINT16_REF = 'quint16_ref',
    QUINT8 = 'quint8',
    QUINT8_REF = 'quint8_ref',

    BOOL = 'bool',
    HALF = 'half',
    RESOURCE = 'resource',
    STRING = 'string',
    VARIANT = 'variant',
}

export enum ActivationType {
    ELU = 'elu',
    EXPONENTIAL = 'exponential',
    GELU = 'gelu',
    HARD_SIGMOID = 'hard_sigmoid',
    LEAKY_RELU = 'leaky_relu',
    LINEAR = 'linear',
    RELU = 'relu',
    SELU = 'selu',
    SIGMOID = 'sigmoid',
    SOFTMAX = 'softmax',
    SOFTPLUS = 'softplus',
    SOFTSIGN = 'softsign',
    SWISH = 'swish',
    TANH = 'tanh',
}

export type TfDataFormat = 'channels_first' | 'channels_last';
export type TfInterpolation = 'nearest' | 'bilinear';
export type TfPadding = 'valid' | 'same';
export type TfVarMode = 'fan_in' | 'fan_out' | 'fan_avg';
export type TfDistribution =
    | 'truncated_normal'
    | 'untruncated_normal'
    | 'uniform';
export type TfFlipMode = 'horizontal' | 'vertical' | 'horizontal_and_vertical';
export type TfFillMode = 'constant' | 'reflect' | 'wrap' | 'nearest';
export type TfMergeMode = 'sum' | 'mul' | 'concat' | 'ave';
export type TfOutputMode = 'int' | 'one_hot' | 'multi_hot' | 'count' | 'tf_idf';
export type RNNCell = number;
