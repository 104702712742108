import { Component, Input } from '@angular/core';
import { BaseAlgorithmClass } from '../base-algorithm-class';

@Component({
    selector: 'app-adam-parameters',
    templateUrl: './adam-parameters.component.html',
    styleUrls: [
        '../../shared/algorithm-parameters.scss',
        './adam-parameters.component.scss',
    ],
})
export class AdamParametersComponent extends BaseAlgorithmClass {
    @Input() advancedSettingEnabled: boolean = false;

    constructor() {
        super();
    }
}
