<mat-tab-group animationDuration="0ms">
    <mat-tab>
        <ng-template mat-tab-label>{{
            'admin.components.adminTrainings.overview' | translate
        }}</ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>Compute-Cloud</ng-template>
        <app-global-config></app-global-config>
    </mat-tab>
</mat-tab-group>
