<div class="card h-100">
    <ngb-carousel
        #carousel
        (slide)="onSlide($event)"
        [showNavigationIndicators]="false"
        [showNavigationArrows]="false"
        class="carousel"
    >
        <ng-template ngbSlide *ngFor="let picture of card.pictures">
            <img
                [src]="picture"
                alt="Random first slide"
                class="card-img-top img-fluid main-image"
                (click)="onClickCardPicture()"
            />
        </ng-template>
    </ngb-carousel>
    <div
        class="card-footer"
        [ngClass]="{
            visual: card.rootObj.containerType === 'PROJECT_VISUAL',
            motoric: card.rootObj.containerType === 'PROJECT_MOTORIC'
        }"
    >
        <div class="left-side">
            <div class="user-profile-img float-start">
                <img
                    [src]="card.footer.userProfilePicture"
                    class="card-user-img"
                    alt="profile image"
                    *ngIf="card.footer.userProfilePicture"
                    (click)="onClickUserProfilePicture()"
                />
                <img
                    src="assets/img/card-icons/default-profile-image.jpg"
                    class="card-user-img"
                    alt="profile image"
                    *ngIf="!card.footer.userProfilePicture"
                    (click)="onClickUserProfilePicture()"
                />
            </div>
            <a
                class="project-name float-start"
                [title]="card.footer.title"
                (click)="onClickCardPicture()"
            >
                {{
                    card.footer.title.length > 15
                        ? (card.footer.title | slice: 0:15) + '...'
                        : card.footer.title
                }}
            </a>
        </div>
        <div *ngIf="card.options?.showPrivacyStatus" class="right-side">
            <div class="icon-container">
                <div class="img-icons">
					<img *ngIf="card.rootObj?.openly;else notPublic"
					    src="assets/svg/card/lock_open.svg" alt="lock_open"/>
                    <ng-template #notPublic>
						<img src="assets/svg/card/lock_closed.svg" alt="lock_close" />
                    </ng-template>  
                </div>
            </div>
        </div>
    </div>
    <div
        class="delete-button"
        (click)="openDeletionDialog()"
        *ngIf="!card.options?.disableDeleteButton"
    >
        <span class="material-icons"> delete </span>
    </div>
    <div
        class="badge"
        *ngIf="card.rootObj && card.rootObj.membershipStatus !== 'NONE'"
    >
        <div class="text">
            <span>{{
                'card.membershipStatus.' + card.rootObj.membershipStatus
                    | translate
            }}</span>
        </div>
    </div>
</div>
