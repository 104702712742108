import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-urdf-lister',
    templateUrl: './urdf-lister.component.html',
    styleUrls: ['./urdf-lister.component.scss'],
})
export class UrdfListerComponent {
    @Input() public urdfs;
    @Input() public id;
    public keys;

    constructor(private modalService: NgbModal) {}

    onSubmit() {
        this.modalService.dismissAll();
    }
}
