import { ExampleMotion } from './example-motion';
import { LoadedSceneElement } from './loaded-scene-element';
import { SceneElement } from './scene-element';
import { Snapshot } from './snapshot';

export class Scene {
    id: number;
    image?: string;
    name?: string;
    selectedSceneElementId: string;
    sceneElements: SceneElement[];
    base: Snapshot;
    exampleMotions: ExampleMotion[];
    loadedSceneElements?: LoadedSceneElement[];
}
