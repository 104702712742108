<div class="row">
    <div class="col d-flex flex-row justify-content-end pe-5">
        <img class="user-image" [src]="userReview.image" alt="userReview" />
    </div>
    <div class="col text-block">
        <p class="row user-review">
            <span class="gradient">
                <img src="assets/svg/chat-quote.svg" alt="chat quote icon" />
                {{ userReview.review }}
            </span>
        </p>
        <p class="row user-info">{{ userInfo() }}</p>
    </div>
</div>
