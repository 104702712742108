<div class="navbar-wrapper">
    <div class="navbar main-section justify-content-end">
        <div class="row search-bar align-items-center">
            <mat-icon class="input-icon" (click)="initSearch()"
                >search</mat-icon
            >
            <input
                type="text"
                class="input-field"
                [placeholder]="placeholder"
                [(ngModel)]="searchText"
                (keyup)="keyupEvent($event)"
            />
        </div>
    </div>
</div>
