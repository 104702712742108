import { Observable } from 'rxjs';
import { Scene } from 'src/app/scenes/models/scene';
import { CardUser } from '../card-user';
import { Card, CardFooter } from './card';

export class SceneCard extends Card {
    readonly typeName: string;
    readonly cardTypeTranslationKey: string;

    constructor(
        modelId: string,
        userId: string,
        userName: string,
        head: CardFooter,
        pictures: string[]
    ) {
        super(modelId, userId, userName, head, pictures);
        this.cardTypeTranslationKey = 'card.type.scene';
        this.navigationUrl = '/scenes/' + modelId;
    }

    public static mapToScene(scene: Scene, user: CardUser): SceneCard {
        const pictures = scene.image ? [scene.image] : [];
        return new SceneCard(
            String(scene.id),
            user.id,
            user.name,
            new CardFooter(scene.name, user.picture),
            pictures
        );
    }

    public deleteCard(): Observable<unknown> {
        return this.deleteCardService.deleteScene(+this.itemId);
    }

    public onCardPictureClick(): void {
        this.router.navigate([this.navigationUrl], {
            queryParams: this.navigationUrlParams,
        });
    }
}
