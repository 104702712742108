import { Component, Input, OnInit } from '@angular/core';
import { ProjectActivityService } from './project-activity.service';
import { Project } from '../../../../../project/models/project';
import { ActivityPage } from './model/activity-page';
import { ActivityResponse } from './model/activity-response';
import { ActivityCreator } from './model/activities/activity-creator';
import { Activity } from './model/activities/activity';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-project-activity',
    templateUrl: './project-activity.component.html',
    styleUrls: ['./project-activity.component.scss'],
})
export class ProjectActivityComponent implements OnInit {
    @Input()
    project: Project;

    activities: Array<Activity> = [];
    currentPage: ActivityPage;
    pageSize: number = 10;

    constructor(
        private projectActivityService: ProjectActivityService,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.projectActivityService
            .getActivities(this.project.id, 0, this.pageSize)
            .subscribe((activityPage: ActivityPage) => {
                this.appendActivities(activityPage);
                this.currentPage = activityPage;
            });
    }

    onScrollDown() {
        if (!this.isLastPage()) {
            this.projectActivityService
                .getActivities(
                    this.project.id,
                    this.currentPage.pageable.pageNumber + 1,
                    this.pageSize
                )
                .subscribe((activityPage: ActivityPage) => {
                    this.currentPage = activityPage;
                    this.appendActivities(activityPage);
                });
        }
    }

    private appendActivities(activityPage: ActivityPage) {
        activityPage.content.forEach((activityResponse: ActivityResponse) => {
            this.activities.push(
                ActivityCreator.createActivity(activityResponse)
            );
        });
    }

    private isLastPage(): boolean {
        return (
            this.currentPage.pageable.pageNumber ===
            this.currentPage.totalPages - 1
        );
    }
}
