<h2>{{getUsersProjectActivityHeader()}}</h2>
<div class="infinite-list">
    <ul infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollUpDistance]="1.5"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScrollDown()">
        <li *ngFor="let activity of activities">
            <div class="event">
                <div class="profile-img-container">
                    <div *ngIf="activity.type!=='JOINED_TRYB'">
                        <div *ngIf="activity.projectUUID!==''; else privateImage">
                            <img class="profile-img" src="{{getImageSrc(activity)}}"
                                 alt="{{activity.projectName}}" title="{{activity.projectName}}"
                                 [routerLink]="activity.routerLink" [queryParams]="activity.queryParams"
                            ></div>
                        <ng-template #privateImage>
                            <img class="profile-img"
                                 src="{{'/assets/img/private-project/'+translate.currentLang+'.png'}}"
                                 alt="{{'user.private_project'|translate}}"
                                 title="{{'user.private_project'|translate}}">
                        </ng-template>
                    </div>
                </div>
                <div class="event-description">
                    {{ activity.activityText | translate}}
                    <a [routerLink]="activity.afterDescLink"
                       [queryParams]="activity.afterDescParams"
                       *ngIf="activity.afterDescText">{{activity.afterDescText}}</a>
                </div>
                <div class="time">
                    {{ activity.generateActivityTime(translate) }}
                </div>
            </div>
        </li>
    </ul>
</div>
