import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
    ContainerCreateRequest,
    ContainerType,
} from 'src/app/shared/services/container/container.types';
import { ImgDbWizardSlideCreationService } from '../../img-db/component/services/img-db-wizard-slide-creation.service';
import {
    RobotType,
    WizardSlide,
    WizardSlideContentElement,
    WizardType,
} from '../models/wizard-slide';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';

const visualTrainingImgPath =
    'assets/img/create-project-modal/visuelles-training.jpg';
const motoricTrainingImgPath =
    'assets/img/create-project-modal/motorisches-training.jpg';
const expertModePath = 'assets/img/create-project-modal/experten-modus.jpg';
const pibPath = 'assets/img/create-project-modal/pib.jpg';
const antPath = 'assets/img/create-project-modal/ant.jpg';
const nanosaurPath = 'assets/img/create-project-modal/nanosaur.jpeg';
const frankaPandaPath = 'assets/img/create-project-modal/frankaPanda.png';
const cocoPath = 'assets/img/create-project-modal/image-database.jpg';
// TODO: Later better suitable image will replace this
const emptyPath = cocoPath;

@Injectable()
export class ProjectWizardSlideCreationService {
    readonly robotSlideContent = [
        {
            image: pibPath,
            cardTitle: 'project.model.wizardSlideData.cardTypePib',
            cardDisc: 'project.model.wizardSlideData.cardTypePibText',
            redirectSlideId: 3,
            robotType: RobotType.PIB,
            cardValue: RobotType.PIB,
        },
        {
            image: antPath,
            cardTitle: 'project.model.wizardSlideData.cardTypeAnt',
            cardDisc: 'project.model.wizardSlideData.cardTypeAntText',
            redirectSlideId: 3,
            robotType: RobotType.ANT,
            cardValue: RobotType.ANT,
        },
        {
            image: nanosaurPath,
            cardTitle: 'project.model.wizardSlideData.cardTypeNanosaur',
            cardDisc: 'project.model.wizardSlideData.cardTypeNanosaurText',
            redirectSlideId: 3,
            robotType: RobotType.NANOSAUR,
            cardValue: RobotType.NANOSAUR,
        },
        {
            image: frankaPandaPath,
            cardTitle: 'project.model.wizardSlideData.cardTypeFrankaPanda',
            cardDisc: 'project.model.wizardSlideData.cardTypeFrankaPandaText',
            redirectSlideId: 3,
            robotType: RobotType.FRANKA_PANDA,
            cardValue: RobotType.FRANKA_PANDA,
        },
    ];

    constructor(
        private imgDbSlideCreationService: ImgDbWizardSlideCreationService,
        private authenticationService: AuthenticationService
    ) {}

    createWizardSlides(): WizardSlide[] {
        let firstSlideContent = [];

        if (
            this.authenticationService.currentUserValue.roles.includes(
                'ROLE_USER_VISUAL_TRAINING'
            )
        ) {
            firstSlideContent.push({
                image: visualTrainingImgPath,
                cardTitle:
                    'project.model.wizardSlideData.cardTypeVisualTraining',
                cardDisc:
                    'project.model.wizardSlideData.cardTypeVisualTrainingText',
                redirectSlideId: 2,
                cardValue: ContainerType.PROJECT_VISUAL,
            });
        }

        if (
            this.authenticationService.currentUserValue.roles.includes(
                'ROLE_USER_MOTORIC_TRAINING'
            )
        ) {
            firstSlideContent.push({
                image: motoricTrainingImgPath,
                cardTitle:
                    'project.model.wizardSlideData.cardTypeMotoricTraining',
                cardDisc:
                    'project.model.wizardSlideData.cardTypeMotoricTrainingText',
                redirectSlideId: 2,
                cardValue: ContainerType.PROJECT_MOTORIC,
            });
        }
        let first_slide_data = {
            id: 1,
            root: true,
            type: WizardType.CARD,
            slideTitle: 'project.model.wizardSlideData.chooseATraining',
            slideContent: new BehaviorSubject<WizardSlideContentElement[]>(
                firstSlideContent
            ),
        };
        let second_slide_data = {
            id: 2,
            root: false,
            type: WizardType.CARD,
            slideTitle: '',
            slideContent: new BehaviorSubject<WizardSlideContentElement[]>([]),
        };

        return [
            first_slide_data,
            second_slide_data,
            {
                id: 3,
                root: false,
                type: WizardType.FORM,
                slideContent: new BehaviorSubject<WizardSlideContentElement[]>(
                    []
                ),
            },
        ];
    }

    changeSlideData(
        wizardSlideData: WizardSlide[],
        createDto: ContainerCreateRequest
    ): WizardSlide[] {
        let second_slide_data = {
            id: 2,
            root: false,
            type: WizardType.CARD,
            slideTitle: '',
            slideContent: new BehaviorSubject<WizardSlideContentElement[]>([]),
        };

        if (createDto.containerType === ContainerType.PROJECT_VISUAL) {
            second_slide_data.slideTitle =
                'project.model.wizardSlideData.chooseADatabase';
            second_slide_data.slideContent =
                this.imgDbSlideCreationService.createCards(3, 'projectWizard');
        }

        if (createDto.containerType === ContainerType.PROJECT_MOTORIC) {
            second_slide_data.slideTitle =
                'project.model.wizardSlideData.chooseARobot';
            second_slide_data.slideContent.next(this.robotSlideContent);
        }

        return [
            wizardSlideData[0],
            second_slide_data,
            {
                id: 3,
                root: false,
                type: WizardType.FORM,
                slideContent: new Subject(),
            },
        ];
    }
}
