import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    catchError,
    forkJoin,
    Observable,
    Subject,
    take,
} from 'rxjs';
import { UserAuth } from '../profile/models/user-profile';
import { UserService } from './user.service';
import { handleError } from 'src/app/shared/http-utilities';
import { IdmOption } from '../profile/models/idm-option';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private isLoggedInSubject$: BehaviorSubject<boolean> = new BehaviorSubject(
        false
    );
    public logoutPreparationsFinished$: Subject<boolean> = new Subject();

    private currentUser$: BehaviorSubject<UserAuth>;
    public idmOption$: Subject<IdmOption> = new Subject();
    public isLoggedIn$: Observable<boolean> =
        this.isLoggedInSubject$.asObservable();
    public logoutTriggered$ = new Subject();
    public initializationFinished$ = new Subject<boolean>();

    constructor(private userService: UserService) {
        forkJoin([userService.getMe(), userService.getIdmOption()])
            .pipe(catchError(handleError))
            .subscribe(([userAuth, idmOption]: [UserAuth, IdmOption]) => {
                this.currentUser$ = new BehaviorSubject(userAuth);
                this.idmOption$.next(idmOption);
                this.isLoggedInSubject$.next(!!userAuth.id);

                if (this.isLoggedIn()) {
                    userService.getProfilePicture(userAuth.id);
                }
                this.initializationFinished$.next(true);
            });
    }

    signOut(): void {
        if (this.logoutTriggered$.observed) {
            this.logoutPreparationsFinished$.pipe(take(1)).subscribe(() => {
                this.sendLogoutRequest();
            });
        }
        this.logoutTriggered$.next(true);
        this.currentUser$.next({} as UserAuth);
        this.isLoggedInSubject$.next(false);
        if (!this.logoutTriggered$.observed) {
            this.sendLogoutRequest();
        }
    }

    private sendLogoutRequest() {
        this.userService.logoutFromIdm().subscribe((resp) => {
            const logoutUri = resp.headers.get('Location');
            if (!!logoutUri) {
                window.location.href = logoutUri;
            }
        });
    }

    public observableUserAuth(): Observable<UserAuth> {
        return this.currentUser$.asObservable();
    }

    public saveUser(user: UserAuth): void {
        this.currentUser$.next(user);
        this.isLoggedInSubject$.next(!!user.id);

        if (!!user.id) {
            this.userService.getProfilePicture(user.id);
        }
    }

    public get currentUserValue(): UserAuth {
        return this.currentUser$?.value;
    }

    public isLoggedIn(): boolean {
        return this.isLoggedInSubject$.value;
    }

    public isAdmin(): boolean {
        return !!this.currentUserValue.roles.includes('ROLE_CONFIG_ADMIN');
    }
}
