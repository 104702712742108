<mat-card *ngIf="!submitted">
    <mat-card-title>{{
        'authentication.resetPassword.forgetPassword.forgotPassword' | translate
    }}</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="submit()">
            <p>
                <mat-form-field>
                    <input
                        type="email"
                        matInput
                        placeholder="{{
                            'authentication.resetPassword.forgetPassword.email'
                                | translate
                        }}"
                        formControlName="email"
                    />
                </mat-form-field>
            </p>

            <p *ngIf="error" class="error">
                {{ error }}
            </p>

            <div class="button">
                <button type="submit" mat-button>
                    {{
                        'authentication.resetPassword.forgetPassword.send'
                            | translate
                    }}
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
<mat-card *ngIf="submitted">
    <mat-card-content>
        <mat-label>{{
            'authentication.resetPassword.forgetPassword.emailSent' | translate
        }}</mat-label>
    </mat-card-content>
</mat-card>
