<mat-tab-group animationDuration="0ms">
    <mat-tab>
        <ng-template mat-tab-label>{{
            'admin.components.adminUser.overview' | translate
        }}</ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>{{
            'admin.components.adminUser.permissions' | translate
        }}</ng-template>
        <app-user-settings></app-user-settings>
    </mat-tab>
</mat-tab-group>
