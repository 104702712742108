import * as d3 from 'd3';
import { D3ZoomEvent, ZoomedElementBaseType } from 'd3';

/**
 * Interface for Accessing information
 * of viewable area and contained graph.
 * See also TransformData for graph transformData.
 */
export interface TransformViewInfo {
    getViewableWidth(): number;

    getViewableHeight(): number;

    getGraphWidth(): number;

    getGraphHeight(): number;

    getCurrentScale(): number;
}

/** Abstract Data Structure to describe a translate(x,y) and zoom(scale),
 * where scale 1 means 100 percent.
 */
export interface TransformData {
    x: number;
    y: number;
    scale: number;
}

export class TransformDataUtil {
    public static createTransformData(
        event: D3ZoomEvent<ZoomedElementBaseType, any>
    ): TransformData {
        return {
            x: event.transform.x,
            y: event.transform.y,
            scale: event.transform.k,
        } as TransformData;
    }

    public static createD3Transform(data: TransformData): any {
        let transform = 'translate(' + data.x + ', ' + data.y + ')';
        if (data.scale > 0) {
            transform += ' scale(' + data.scale + ')';
        }
        return transform;
    }
}
