import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WizardSlide } from '../../models/wizard-slide';
import { ProjectWizardSlideCreationService } from '../../services/project-wizard-slide-creation.service';
import { ImgDbWizardSlideCreationService } from '../../../img-db/component/services/img-db-wizard-slide-creation.service';
import { WizardSlideComponent } from '../wizard-slide/wizard-slide.component';

@Component({
    selector: 'app-project-wizard',
    templateUrl: './project-wizard.component.html',
    styleUrls: ['./project-wizard.component.scss'],
    providers: [
        ProjectWizardSlideCreationService,
        ImgDbWizardSlideCreationService,
    ],
})
export class ProjectWizardComponent implements OnInit {
    @ViewChild('childComponent') slideComponent: WizardSlideComponent;
    wizardIndexActive: number = 1;
    wizardDataList: Array<WizardSlide>;

    wizardPointPath: string = 'assets/img/create-project-modal/';
    wizardPointImages: string[] = [
        this.wizardPointPath + '1.jpg',
        this.wizardPointPath + '2.jpg',
        this.wizardPointPath + '3.jpg',
    ];

    constructor(
        public modalService: NgbModal,
        private projectWizardService: ProjectWizardSlideCreationService
    ) {}

    ngOnInit(): void {
        this.wizardIndexActive = 1;
        this.changeActivePositionPoint();
        this.wizardDataList = this.projectWizardService.createWizardSlides();
    }

    changeWizardPosition(event: number) {
        this.wizardIndexActive = event;
        this.changeActivePositionPoint();
    }

    private changeActivePositionPoint() {
        this.wizardPointImages = this.wizardPointImages
            .map((image) => image.replace('-active', ''))
            .map((image) =>
                image.replace(
                    this.wizardIndexActive + '.jpg',
                    this.wizardIndexActive + '-active.jpg'
                )
            );
    }

    addProjectSuccessfully(event: string) {
        if (event === 'closeModal') {
            this.modalService.dismissAll();
        }
    }

    onBackButton() {
        this.slideComponent.onGoBack();
        this.changeWizardPosition(--this.wizardIndexActive);
    }
}
