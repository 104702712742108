import { TrainingStatus } from 'src/app/shared/models/training-status';
import { TrainingType } from '../../shared/enums/training-type.enum';

export class TrainingTreeNode {
    constructor(
        public readonly uuid: string,
        public name: string,
        public status: TrainingStatus,
        public type: TrainingType,
        public children: TrainingTreeNode[],
        public readonly isRoot: boolean,
        public progress: number,
        public started: string,
        public lastUpdated: string,
        public duration: number,
        public x?: number,
        public y?: number
    ) {}

    public static findInTree(
        node: TrainingTreeNode,
        uuid: string
    ): TrainingTreeNode {
        if (!node) {
            return undefined;
        }
        if (node.uuid === uuid) {
            return node;
        }
        for (let child of node.children) {
            const result = this.findInTree(child, uuid);
            if (result) {
                return result;
            }
        }
        return null;
    }

    public static findParentInTree(
        node: TrainingTreeNode,
        childNodeUuid: string
    ): TrainingTreeNode {
        if (!node) {
            return undefined;
        }
        if (!node.children) {
            return undefined;
        }
        for (let child of node.children) {
            if (child.uuid === childNodeUuid) {
                return node;
            }
            const result = this.findParentInTree(child, childNodeUuid);
            if (result) {
                return result;
            }
        }
        return null;
    }

    static updateNodeName(
        root: TrainingTreeNode,
        uuid: string,
        newName: string
    ): TrainingTreeNode {
        const nodeToChange = TrainingTreeNode.findInTree(root, uuid);
        nodeToChange.name = newName;
        return nodeToChange;
    }

    private static isFinished(treeNode: TrainingTreeNode): boolean {
        switch (treeNode.status) {
            case TrainingStatus.SUCCEEDED:
            case TrainingStatus.FAILED:
            case TrainingStatus.CANCELLED:
                return true;
            default:
                return false;
        }
    }

    private static getFromattedTimeString(time: number): string {
        const dayTime = 1000 * 60 * 60 * 24;
        const hourTime = 1000 * 60 * 60;
        const minuteTime = 1000 * 60;

        let timeString = '';
        let remainingTime = time;
        const days = (remainingTime / dayTime) | 0;
        if (days) {
            timeString = days + 'splitScreen.components.contextMenu.Days ';
            remainingTime -= days * dayTime;
        }

        const hours = (remainingTime / hourTime) | 0;
        if (hours) {
            timeString += hours + 'splitScreen.components.contextMenu.Hours ';
            remainingTime -= hours * hourTime;
            if (days) {
                return timeString;
            }
        }

        const minutes = (remainingTime / minuteTime) | 0;
        if (minutes) {
            timeString +=
                minutes + 'splitScreen.components.contextMenu.Minutes';
        }

        return timeString
            ? timeString
            : 0 + 'splitScreen.components.contextMenu.Minutes';
    }

    public static getRunningTime(treeNode: TrainingTreeNode): string {
        if (treeNode.started) {
            return TrainingTreeNode.getFromattedTimeString(treeNode.duration);
        }
        return '0min';
    }
}

export const emptyTrainingTreeNode = new TrainingTreeNode(
    '',
    '',
    TrainingStatus.NEW,
    null,
    [],
    false,
    0,
    new Date().toDateString(),
    new Date().toDateString(),
    0
);
