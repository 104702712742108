import { Component, Input, OnInit } from '@angular/core';
import { CmsBlockComponent } from '../cms-block.component';
import { CmsAbstractBlockComponent } from '../cms-abstract-block.component';
import { Category } from '../../../models/category';
import { ShopwareCmsService } from '../../../services/shopware-cms.service';
import { ActivatedRoute } from '@angular/router';
import { CmsBlock } from '../../../models/cms-block';

@Component({
    selector: 'app-cms-category-navigation',
    templateUrl: './cms-category-navigation.component.html',
    styleUrls: ['./cms-category-navigation.component.scss'],
})
export class CmsCategoryNavigationComponent
    extends CmsAbstractBlockComponent
    implements OnInit, CmsBlockComponent
{
    @Input()
    block: CmsBlock;

    categories: Category[];
    activeCategoryId: string;
    constructor(
        private cmsService: ShopwareCmsService,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {
        this.initBlock();
        this.activeCategoryId = this.cmsService.getActiveCategoryId(
            this.route.snapshot.paramMap.get('categoryId')
        );
        this.cmsService
            .getMainNavigation()
            .subscribe((categories: Category[]) => {
                this.categories = categories;
                this.categories.forEach((category) => {
                    category.inPath = this.isActiveCategoryInPath(category);
                });
            });
    }

    initBlock() {
        this.position = this.block.position;
        this.type = this.block.type;
        this.slots = this.block.slots;
        this.marginBottom = this.block.marginBottom;
        this.marginRight = this.block.marginRight;
        this.marginTop = this.block.marginTop;
        this.marginLeft = this.block.marginLeft;
        this.backgroundMedia = this.block.backgroundMedia;

        super.initBlock();
    }

    isActiveCategoryInPath(category: Category): boolean {
        if (category.id === this.activeCategoryId) {
            return true;
        }

        const found = category.children.find((item) => {
            if (item.children && this.isActiveCategoryInPath(item)) {
                return true;
            }
        });

        return Boolean(found);
    }
}
