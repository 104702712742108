<div id="lang" class="nav-item" [class.expand]="langExpand">
    <div class="d-block lang-container">
        <div id="expand-menue" (click)="toogleExpandMenu()">
            <p class="float-start main d-flex">
                <span>{{ setLanguage | uppercase }}</span>
                <mat-icon class="float-end">expand_more</mat-icon>
            </p>
        </div>
        <div *ngFor="let lang of languages">
            <p [id]="lang" *ngIf="lang !== setLanguage" (click)="changeLanguage(lang)">
                {{ lang | uppercase }}
            </p>
        </div>
    </div>
</div>
<div
    class="overview"
    [class.show]="langExpand"
    (click)="toogleExpandMenu()"
></div>
