import { ActivityResponse } from '../activity-response';
import { ActivityType } from '../activity-type';
import { ProjectCreated } from './project-created';
import { MemberJoined } from './member-joined';
import { TrainingStartedActivity } from './training-started-activity';
import { TrainingCompletedActivity } from './training-completed-activity';

export class ActivityCreator {
    public static createActivity(activityResponse: ActivityResponse) {
        switch (activityResponse.activityType) {
            case ActivityType.CREATED_PROJECT:
                return new ProjectCreated(activityResponse);
            case ActivityType.JOINED_PROJECT:
                return new MemberJoined(activityResponse);
            case ActivityType.TRAINING_STARTED:
                return new TrainingStartedActivity(activityResponse);
            case ActivityType.TRAINING_COMPLETED:
                return new TrainingCompletedActivity(activityResponse);
            default:
                throw Error('The specified activity has not been defined');
        }
    }
}
