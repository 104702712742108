<div class="member-content">
    <div class="d-flex">
        <div class="d-flex flex-grow-1 mr-1 mr-md-0">
            {{ joinRequest?.userAccountDisplayName }}
        </div>
        <div class="d-flex flex-column flex-md-row">
            <button
                id="decline-btn-{{ memberIdx }}"
                class="btn btn-tryb-ice-blue-outlined btn-member-request"
                (click)="answerMemberRequest(false)"
            >
                {{
                    'project.components.projectOverview.projectMembersComponent.declineButtonTxt'
                        | translate
                }}
            </button>
            <button
                id="accept-btn-{{ memberIdx }}"
                class="btn btn-tryb-ice-blue btn-member-request"
                (click)="answerMemberRequest(true)"
            >
                {{
                    'project.components.projectOverview.projectMembersComponent.acceptButtonTxt'
                        | translate
                }}
            </button>
        </div>
    </div>
</div>
