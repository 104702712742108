<div class="d-flex flex-direction-column">
    <div
        class="btn-page-container"
        [ngClass]="{ disabled: inputControl.value === 1 }"
    >
        <button
            [id]="'btn-previous-page-' + tag"
            class="page-button previous-button"
            (click)="onPreviousClick()"
        >
            <span class="icon material-symbols-outlined">
                navigate_before
            </span>
        </button>
    </div>
    <div>
        <p class="page-texts">
            {{ 'shared.components.paging.' + tag | translate }}
        </p>
    </div>
    <div>
        <input
            type="text"
            [id]="'current-page-input-' + tag"
            class="current-page-input"
            [formControl]="inputControl"
            (input)="formatValue()"
            (keyup.enter)="onEnterPress()"
            (blur)="onSubmit()"
            #numberInput
        />
    </div>
    <div>
        <p class="page-texts">
            {{ 'shared.components.paging.of' | translate }}
            <span>{{ maxValue }}</span>
        </p>
    </div>
    <div
        class="btn-page-container"
        [ngClass]="{ disabled: inputControl.value === maxValue }"
    >
        <button
            [id]="'btn-next-page-' + tag"
            class="page-button next-button"
            (click)="onNextClick()"
        >
            <span class="icon material-symbols-outlined"> navigate_next </span>
        </button>
    </div>
</div>
