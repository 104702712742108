import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { pillsData, PillsObject } from '../../models/nav-pills-data';
import { UserService } from '../../../authentication/services/user.service';

@Component({
    selector: 'app-workspace',
    templateUrl: './workspace.component.html',
    styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements OnInit {
    selectedNavItem: string = 'projects';
    workspacePages: PillsObject[] = pillsData;
    profilePicture: string;

    constructor(
        public location: Location,
        private route: ActivatedRoute,
        private userService: UserService
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(
            (param) => (this.selectedNavItem = param.selected)
        );

        this.userService.profilePicture$.subscribe(
            (pic) => (this.profilePicture = pic)
        );
    }

    public changeSelectedNavItem(changeNavItemEvent: any) {
        this.location.go(`workspace?selected=${changeNavItemEvent.nextId}`);
    }

    public changeSelectedSelectBox(selectedValue: string) {
        this.selectedNavItem = selectedValue;
        this.location.go(`workspace?selected=${selectedValue}`);
    }
}
