import { Injectable } from '@angular/core';
import { TrainingTreeNode } from '../models/training-tree-node';
import { TreeStateService } from './tree-state.service';
import { TranslateService } from '@ngx-translate/core';
import { TrainingStatus } from 'src/app/shared/models/training-status';

@Injectable({
    providedIn: 'root',
})
export class TreeNodeStructureService {
    getProgressBarClasses(treeNode: TrainingTreeNode) {
        switch (treeNode.status) {
            case TrainingStatus.SUCCEEDED:
                return 'progress-bar progress-bar-success';
            case TrainingStatus.FAILED:
                return 'progress-bar progress-bar-error';
            case TrainingStatus.CANCELLING:
            case TrainingStatus.CANCELLED:
                return 'progress-bar progress-bar-stop';
            case TrainingStatus.QUEUED:
            case TrainingStatus.RUNNING:
            case TrainingStatus.REQUESTING:
            case TrainingStatus.PREPARING:
                return 'progress-bar progress-bar-striped progress-bar-animated progress-bar-running';
        }
        return 'progress-bar';
    }

    translateTimeText(time: string) {
        time = time.replace(
            'splitScreen.components.contextMenu.Minutes',
            this.translateService.instant(
                'splitScreen.components.contextMenu.Minutes'
            )
        );
        time = time.replace(
            'splitScreen.components.contextMenu.Hours',
            this.translateService.instant(
                'splitScreen.components.contextMenu.Hours'
            )
        );
        return time.replace(
            'splitScreen.components.contextMenu.Days',
            this.translateService.instant(
                'splitScreen.components.contextMenu.Days'
            )
        );
    }

    progress: number = 0;

    constructor(
        private treeStateService: TreeStateService,
        private translateService: TranslateService
    ) {}

    public toHTML(treeNode: TrainingTreeNode): string {
        const time = this.translateTimeText(
            TrainingTreeNode.getRunningTime(treeNode)
        );
        this.progress = treeNode.progress;
        return `
            <div style="background-color:transparent">
                <div class="${this.getCardSelection(treeNode)}">
                    <div class="card-header">
                        <div class="card-title m-0">
                            <p
                                class="training-title">${treeNode.name}
                            </p>
                        </div>
                    </div>
                    <div class="card-body py-1">
                        <div class="time-text-container">
                            <span class="material-icons float-start">
                                schedule
                            </span>
                            <span class="ms-1 time-text">
                                ${time}
                            </span>
                        </div>
                        <div class="progress my-2">
                            <div class="progress-bar-wrapper">
                                <div class="${this.getProgressBarClasses(
                                    treeNode
                                )}" aria-valuenow="${this.progress}"
                                    aria-valuemin="0" aria-valuemax="100"
                                    style="width:${+this.progress}%">
                                </div>
                            </div>
                            <div class="progress-text-wrapper">
                                <p class="progress-text progress-text-left">
                                    ${+this.progress}%
                                </p>
                                <p class="progress-text progress-text-right">
                                    ${this.getTrainingStatusText(treeNode)}
                                </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    getTrainingStatusText(treeNode: TrainingTreeNode): string {
        switch (treeNode.status) {
            case TrainingStatus.NEW:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.New'
                );
            case TrainingStatus.RUNNING:
            case TrainingStatus.QUEUED:
            case TrainingStatus.PREPARING:
            case TrainingStatus.REQUESTING:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.Running'
                );
            case TrainingStatus.SUCCEEDED:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.Finished'
                );
            case TrainingStatus.CANCELLED:
            case TrainingStatus.CANCELLING:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.Aborted'
                );
            case TrainingStatus.FAILED:
                return this.translateService.instant(
                    'splitScreen.components.contextMenu.Failed'
                );
        }
    }

    private getCardSelection(treeNode: TrainingTreeNode) {
        return this.treeStateService.selectedTreeNode.uuid === treeNode.uuid
            ? 'card selected'
            : 'card';
    }
}
