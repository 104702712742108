<button
    type="button"
    class="btn-close"
    aria-label="Schließen"
    [mat-dialog-close]="false"
></button>
<h3 mat-dialog-title>{{ title }}</h3>
<div mat-dialog-content>{{ message }}</div>
<div mat-dialog-actions class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn btn-light me-md-2 btn-confirm" [mat-dialog-close]="true" type="button">
        {{ confirmButtonText }}
    </button>
    <button class="btn btn-danger btn-cancel" [mat-dialog-close]="false" type="button">
        {{ cancelButtonText }}
    </button>
</div>
