import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/shared/http-utilities';
import { environment } from 'src/environments/environment';
import {
    ExpertDatasetSelectionDTO,
    ExpertDatasetUsageType,
} from '../models/expert-data';

@Injectable({
    providedIn: 'root',
})
export class ExpertDatasetService {
    readonly restUrl: string;

    constructor(private http: HttpClient) {
        this.restUrl = environment.backendUrl + '/rest/expert-dataset/training';
    }

    getExpertDataById(trainingId: string) {
        const url = `${this.restUrl}/${trainingId}/expertDataSelection`;
        return this.http
            .get<ExpertDatasetSelectionDTO>(url)
            .pipe(catchError(handleError));
    }

    saveExpertDataSelection(
        trainingId: string,
        datasetName: string,
        usageType: ExpertDatasetUsageType
    ) {
        const url = `${this.restUrl}/${trainingId}/expertDataSelection`;
        return this.http.post(url, {
            datasetName: datasetName,
            usageType: usageType,
        });
    }
}
