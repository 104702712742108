import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { MessageService } from 'src/app/message/services/message.service';
import { environment } from 'src/environments/environment';
import { TrainingTreeNode } from '../models/training-tree-node';

@Injectable({
    providedIn: 'root',
})
export class JobService {
    private readonly baseUrl: string;

    constructor(
        private http: HttpClient,
        private messageService: MessageService
    ) {
        this.baseUrl = `${environment.backendUrl}/rest/job`;
    }

    start(trainingNode: TrainingTreeNode): Observable<void> {
        const url = `${this.baseUrl}/${trainingNode.uuid}`;
        return this.http.post<void>(url, {}).pipe(
            catchError((error) => {
                this.messageService.displayTranslatedErrorMessage(
                    'splitScreen.components.contextMenu.notStarted',
                    { name: trainingNode.name }
                );
                return throwError(() => error);
            }),
            tap(() => {
                this.messageService.displayTranslatedSuccessMessage(
                    'splitScreen.components.contextMenu.wasStarted',
                    { name: trainingNode.name }
                );
            })
        );
    }

    stop(trainingNode: TrainingTreeNode): Observable<void> {
        const url = `${this.baseUrl}/${trainingNode.uuid}`;
        return this.http.delete<void>(url).pipe(
            catchError((error) => {
                this.messageService.displayTranslatedErrorMessage(
                    'splitScreen.components.contextMenu.notStopped',
                    { name: trainingNode.name }
                );
                return throwError(() => error);
            }),
            tap(() => {
                this.messageService.displayTranslatedSuccessMessage(
                    'splitScreen.components.contextMenu.wasStopped',
                    { name: trainingNode.name }
                );
            })
        );
    }
}
