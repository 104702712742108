import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VerifyService } from './service/verify-service';

@Component({
    selector: 'app-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
    success: boolean;
    token: any;

    constructor(
        private route: ActivatedRoute,
        private verifyService: VerifyService
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.token) {
                this.token = params.token;
                this.verifyService.verify(params.token).subscribe(
                    () => {
                        this.success = true;
                    },
                    () => {
                        this.success = false;
                    }
                );
            }
        });
    }
}
