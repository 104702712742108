import { ActivityResponse } from '../activity-response';
import { Activity } from './activity';

export class TrainingCompletedActivity extends Activity {
    constructor(activityResponse: ActivityResponse) {
        super(activityResponse);
    }

    public override selectActivityText(): string {
        return 'project.components.projectEvents.completed';
    }

    public override getActivityParameter(): string {
        return '';
    }
}
