<img [src]="item.image" class="main-image" alt=""/>
<h1 class="title">{{ item.title }}</h1>
<span class="separator"></span>
<div class="description">{{ item.description }}</div>
<div class="btn-line d-flex flex-row justify-content-between mt-auto">
    <span class="heart">
        <mat-icon>favorite</mat-icon>
    </span>
    <span class="btn-move-right">
        <mat-icon>arrow_forward</mat-icon>
    </span>
</div>
