import { Component, Input, OnInit } from '@angular/core';
import * as Skill from '../models/layer-namespace';

import { getEnumValues } from 'src/app/shared/utility';
import { SkillArchitectureCategory } from '../models/layer-namespace';

@Component({
    selector: 'app-skill-architecture-selection-row',
    templateUrl: './skill-architecture-selection-row.component.html',
    styleUrls: ['./skill-architecture-selection-row.component.scss'],
})
export class SkillArchitectureSelectionRowComponent implements OnInit {
    @Input() editingIsDisabled: boolean;
    selectableCategories = Skill.selectableCategories;
    selectedCategory: SkillArchitectureCategory;
    layers: string[];

    ngOnInit(): void {
        this.updateCategory();
    }

    onCategoryClick(selectableCategory) {
        this.updateCategory(selectableCategory);
    }

    private updateCategory(
        category: SkillArchitectureCategory = Skill.selectableCategories[0]
    ) {
        this.selectedCategory = category;
        this.getLayers();
    }

    private getLayers() {
        const selectedLayerType = this.selectableCategories.find(
            (layer) => layer.name === this.selectedCategory.name
        );
        this.layers = [...getEnumValues(selectedLayerType?.type)];
    }
}
