<section class="tab-content">
    <table class="table">
        <caption hidden>
            List of scene elements with orientation info and weight
        </caption>
        <tr>
            <th scope="col">
                <p>
                    {{
                        'costFunction.distanceTabContent.elementName1'
                            | translate
                    }}
                </p>
            </th>
            <th scope="col">
                <p>
                    {{ 'costFunction.distanceTabContent.distance' | translate }}
                </p>
            </th>
            <th scope="col">
                <p>
                    {{
                        'costFunction.distanceTabContent.elementName2'
                            | translate
                    }}
                </p>
            </th>
            <th scope="col">
                <p>
                    {{ 'costFunction.distanceTabContent.weight' | translate }}
                </p>
            </th>
            <th scope="col delete"></th>
        </tr>
        <tr
            *ngFor="let row of rows; let i = index"
            class="table-row"
            [formGroup]="row"
        >
            <td>
                <div class="dropzone-wrapper">
                    <div
                        [ngClass]="{
                            'selected-dropzone': row.get(
                                'sourceSceneElementName'
                            ).value,
                            dropzone: !row.get('sourceSceneElementName').value
                        }"
                        cdkDropList
                        [cdkDropListData]="rows"
                        (cdkDropListDropped)="
                            dropInExistingElement($event, 'source', i)
                        "
                        id="distance-from-existing-dropzone"
                    >
                        <p *ngIf="row.get('sourceSceneElementName').value">
                            {{ row.get('sourceSceneElementName').value }}
                        </p>
                        <p *ngIf="!row.get('sourceSceneElementName').value">
                            {{
                                'costFunction.distanceTabContent.dragSceneElement1'
                                    | translate
                            }}
                        </p>
                    </div>
                </div>
            </td>
            <td>
                <div
                    class="tryb-dropdown"
                    [ngClass]="{
                        'pe-none':
                            isWeightBeingEditted || row.get('weight').invalid
                    }"
                >
                    <select
                        #select
                        formControlName="maximize"
                        (change)="updateRow(row, i)"
                    >
                        <option value="false">
                            {{
                                'costFunction.distanceTabContent.reduce1'
                                    | translate
                            }}
                        </option>
                        <option value="true">
                            {{
                                'costFunction.distanceTabContent.increase1'
                                    | translate
                            }}
                        </option>
                    </select>
                </div>
            </td>
            <td>
                <div class="dropzone-wrapper">
                    <div
                        [ngClass]="{
                            'selected-dropzone': row.get(
                                'targetSceneElementName'
                            ).value,
                            dropzone: !row.get('targetSceneElementName').value
                        }"
                        cdkDropList
                        [cdkDropListData]="rows"
                        (cdkDropListDropped)="
                            dropInExistingElement($event, 'target', i)
                        "
                        id="distance-to-existing-dropzone"
                    >
                        <p *ngIf="row.get('targetSceneElementName').value">
                            {{ row.get('targetSceneElementName').value }}
                        </p>
                        <p *ngIf="!row.get('targetSceneElementName').value">
                            {{
                                'costFunction.distanceTabContent.dragSceneElement2'
                                    | translate
                            }}
                        </p>
                    </div>
                </div>
            </td>
            <td>
                <app-cost-function-input-group
                    [valueControl]="row.get('weight')"
                    [disabled]="
                        !(
                            row.value['sourceSceneElementId'] &&
                            row.value['targetSceneElementId']
                        )
                    "
                    [hasCheckbox]="false"
                    [decimalPoint]="weightDecimalPoint"
                    [minValue]="weightMinValue"
                    [maxValue]="weightMaxValue"
                    [showDisabledValue]="true"
                    (startedEditting)="onWeightEditStarted()"
                    (finishedEditting)="onWeightEditFinished()"
                    (valueChanged)="updateRow(row, i)"
                    [hasSteppers]="true"
                ></app-cost-function-input-group>
            </td>
            <td>
                <div class="delete-button-container" *ngIf="!editingIsDisabled">
                    <div class="delete-button" (click)="removeElement(i)">
                        <span class="material-icons">delete_forever</span>
                    </div>
                </div>
            </td>
        </tr>
        <tr
            *ngIf="showNewElementRow && !editingIsDisabled"
            class="new-element-row"
        >
            <td>
                <div class="dropzone-wrapper">
                    <div
                        class="dropzone"
                        cdkDropList
                        [cdkDropListData]="rows"
                        (cdkDropListDropped)="drop($event, 'source')"
                        id="distance-from-dropzone"
                    >
                        {{
                            'costFunction.distanceTabContent.dragSceneElement3'
                                | translate
                        }}
                    </div>
                </div>
            </td>
            <td>
                <div class="tryb-dropdown disabled">
                    <select disabled>
                        <option value="verkleinern">
                            {{
                                'costFunction.distanceTabContent.reduce2'
                                    | translate
                            }}
                        </option>
                        <option value="vergrößern">
                            {{
                                'costFunction.distanceTabContent.increase2'
                                    | translate
                            }}
                        </option>
                    </select>
                </div>
            </td>
            <td>
                <div class="dropzone-wrapper">
                    <div
                        class="dropzone"
                        cdkDropList
                        [cdkDropListData]="rows"
                        (cdkDropListDropped)="drop($event, 'target')"
                        id="distance-to-dropzone"
                    >
                        {{
                            'costFunction.distanceTabContent.dragSceneElement4'
                                | translate
                        }}
                    </div>
                </div>
            </td>
            <td>
                <app-cost-function-input-group
                    [disabled]="true"
                    [hasCheckbox]="false"
                    [decimalPoint]="weightDecimalPoint"
                    [minValue]="weightMinValue"
                    [maxValue]="weightMaxValue"
                    [showDisabledValue]="true"
                    [valueControl]="emptyWeightControl"
                ></app-cost-function-input-group>
            </td>
            <td>
                <div class="delete-button-container">
                    <div class="delete-button">X</div>
                </div>
            </td>
        </tr>
    </table>
</section>
