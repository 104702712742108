<div class="side">
    <div class="left-hand-side">
        <div class="upper-side">
            <div class="neue-mitgliederanfragen">
                <img
                    src="/assets/img/workspace-overview/u121_div.png"
                    id="background-img-1"
                />
                <div class="titel">
                    <p>
                        {{
                            'projectWorkspace.components.projectWorkspaceOverview.newMemberRequests'
                                | translate
                        }}
                    </p>
                </div>
                <div class="bottom-line">
                    <div class="neue-anfragen">
                        <p>
                            {{
                                'projectWorkspace.components.projectWorkspaceOverview.newRequests'
                                    | translate
                            }}
                        </p>
                    </div>
                    <div class="profile-images">
                        <img
                            src="/assets/img/workspace-overview/u123.svg"
                            class="profile-image-1"
                        />
                        <img
                            src="/assets/img/workspace-overview/u124.svg"
                            class="profile-image-2"
                        />
                        <img
                            src="/assets/img/workspace-overview/u125.svg"
                            class="profile-image-3"
                        />
                    </div>
                </div>
            </div>
            <div class="trainings">
                <img
                    src="/assets/img/workspace-overview/u129_div.png"
                    id="background-img-2"
                />
                <div class="titel">
                    <p>
                        {{
                            'projectWorkspace.components.projectWorkspaceOverview.trainings'
                                | translate
                        }}
                    </p>
                </div>
                <div class="overview-all-trainings">
                    <p>
                        {{
                            'projectWorkspace.components.projectWorkspaceOverview.overviewTrainings'
                                | translate
                        }}
                    </p>
                </div>
                <div class="chart">
                    <div class="chart-circle">
                        <img class="outer-circle"
                             src="/assets/img/workspace-overview/background_color_u134.svg"
                        />
                        <img class="inner-circle"
                             src="/assets/img/workspace-overview/u136.svg"
                        />
                    </div>
                    <div class="chart-number">
                        <p>25</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-side">
            <app-project-activity [project]="project"></app-project-activity>
        </div>
    </div>
</div>
