import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'newLineToBr',
})
export class NewLineToBrPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;
        return value
            .replace(/\n/g, '<br>')
            .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
            .replace(/\s/g, '&nbsp;');
    }
}
