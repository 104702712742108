import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
    @Input()
    numberOfPages: number;
    @Input()
    currentPage: number;

    @Output()
    pageChanged: EventEmitter<number> = new EventEmitter<number>();

    readonly displayPerArray = 5;
    pageArrayFirst: number[] = [];
    pageArraySecond: number[] = [];
    pageArrayThird: number[] = [];

    constructor() {}

    ngOnInit(): void {
        this.fillPaginationArrays();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.currentPage === undefined ||
            changes.currentPage.currentValue !==
                changes.currentPage.previousValue
        ) {
            this.fillPaginationArrays();
        }
    }

    changePage(newPage: number): void {
        this.pageChanged.emit(newPage);
    }

    backward(): void {
        if (this.currentPage > 1) {
            this.pageChanged.emit(this.currentPage - 1);
        }
    }

    forward(): void {
        if (this.currentPage < this.numberOfPages) {
            this.pageChanged.emit(this.currentPage + 1);
        }
    }

    private fillPaginationArrays(): void {
        this.pageArrayFirst = [];
        this.pageArraySecond = [];
        this.pageArrayThird = [];

        const half = this.displayPerArray / 2;
        if (this.numberOfPages <= this.displayPerArray + 1) {
            // e.g.: 1 2 3
            for (let i = 1; i <= this.numberOfPages; i++) {
                this.pageArrayFirst.push(i);
            }
        } else if (this.currentPage <= Math.ceil(half) + 1) {
            // e.g.: 1 2 3 4 5 ... 15
            for (let i = 1; i <= this.displayPerArray; i++) {
                this.pageArrayFirst.push(i);
            }
            this.pageArraySecond.push(this.numberOfPages);
        } else if (this.currentPage >= this.numberOfPages - Math.ceil(half)) {
            // e.g.: 1 ... 11 12 13 14 15
            this.pageArrayFirst.push(1);
            for (
                let i = this.numberOfPages - this.displayPerArray + 1;
                i <= this.numberOfPages;
                i++
            ) {
                this.pageArraySecond.push(i);
            }
        } else {
            // e.g.: 1 ... 4 5 6 7 8 ... 15
            this.pageArrayFirst.push(1);
            for (
                let i = this.currentPage - Math.floor(half);
                i <= this.currentPage + Math.floor(half);
                i++
            ) {
                this.pageArraySecond.push(i);
            }
            this.pageArrayThird.push(this.numberOfPages);
        }
    }
}
