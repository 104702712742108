<div class="container p-0">
    <div class="nav-header">
        <div class="row header mx-0">
            <h4 class="nav-name nav-link">
                <span class="side-navbar-text">{{ projectName }}</span>
            </h4>
        </div>
    </div>
    <div class="nav flex-column">
        <a
            [ngClass]="getStyleClassForNavItem('Überblick')"
            (click)="clickNavigation('Überblick')"
        >
            <div class="icon-wrapper">
                <span class="material-icons icon" style="font-size: 17px">
                    dashboard
                </span>
            </div>
            <span class="side-navbar-text">{{
                'projectWorkspace.components.projectWorkspaceNavigation.overview'
                    | translate
            }}</span>
        </a>
        <a
            [ngClass]="getStyleClassForNavItem('Trainings')"
            (click)="clickNavigation('Trainings')"
        >
            <div class="icon-wrapper">
                <span class="material-icons icon" style="font-size: 21px">
                    model_training
                </span>
            </div>
            <span class="side-navbar-text">{{
                'projectWorkspace.components.projectWorkspaceNavigation.trainings'
                    | translate
            }}</span>
        </a>
        <a
            [ngClass]="getStyleClassForNavItem('Mitglieder')"
            (click)="clickNavigation('Mitglieder')"
        >
            <div class="icon-wrapper">
                <span class="material-icons icon" style="font-size: 20px">
                    people_alt
                </span>
            </div>
            <span class="side-navbar-text">{{
                'projectWorkspace.components.projectWorkspaceNavigation.members'
                    | translate
            }}</span>
        </a>
    </div>
</div>
