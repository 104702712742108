import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import {
    Observable,
    Subject,
    from,
    mergeMap,
    takeUntil,
    tap,
    toArray,
} from 'rxjs';
import { ImgDbUploadUtilService } from './img-db-upload-util.service';
import { ImgUpload } from '../model/img-upload';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ImgDbUploadService implements OnDestroy {
    validatedFiles: File[] = [];
    maxConcurrentUpload = 3;
    uploadedCount = 0;
    interval = null;
    uploadProcessState: EventEmitter<boolean> = new EventEmitter<boolean>();

    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        private http: HttpClient,
        private imgDbUploadUtilService: ImgDbUploadUtilService,
        private translateService: TranslateService,
        private toastMessageService: ToastMessageService
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    async processImageFilesUpload(
        imgDbId: string,
        filesParam: NgxFileDropEntry[]
    ) {
        await this.imgDbUploadUtilService
            .validateAndCheckFiles(filesParam)
            .then((files) => (this.validatedFiles = files));

        const listOfSignedUrls$: Observable<ImgUpload[]> =
            this.imgDbUploadUtilService.generateSignedUrls(
                this.validatedFiles,
                imgDbId
            );

        listOfSignedUrls$.pipe(takeUntil(this.unsubscribe$)).subscribe({
            next: (data) => {
                if (data.length === 0) {
                    return;
                }
                this.toastMessageService.showSuccessMsg(
                    this.translateService.instant(
                        'imgDb.upload.uploadStartMessage'
                    )
                );
                this.uploadFileInBatches(data);
            },
        });
    }

    private uploadFile(url: string, file: File): Observable<any> {
        // CORS problem, if sent is aborted see google bucket CORS config
        return this.http.put(url, file, {
            headers: { 'Content-Type': 'image/jpeg' },
        });
    }

    private uploadFileInBatches(files: ImgUpload[]) {
        if (files.length === 0) {
            return;
        }

        from(files)
            .pipe(
                mergeMap(
                    (file: ImgUpload) => this.uploadFile(file.url, file.file),
                    this.maxConcurrentUpload
                ),
                tap(() => {
                    this.uploadedCount++;
                }),
                toArray(),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    setTimeout(() => {
                        this.toastMessageService.showSuccessMsg(
                            this.translateService.instant(
                                'imgDb.upload.uploadSuccessMessage'
                            )
                        );
                        clearInterval(this.interval);
                    }, 18000);
                },
                error: (err) => {
                    console.error(err);
                },
            });

        this.refreshImages();
    }

    private refreshImages() {
        this.interval = setInterval(() => {
            this.uploadProcessState.emit(true);
        }, 2000);
    }
}
