import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-header-box',
    templateUrl: './header-box.component.html',
    styleUrls: ['./header-box.component.scss'],
})
export class HeaderBoxComponent {
    @Input()
    name: string = '';
    @Input()
    showBackButton = true;

    constructor(private router: Router, private route: ActivatedRoute) {}

    onGoBack() {
        const trainingId = this.route.snapshot.paramMap.get('trainingId');
        const projectId = this.route.snapshot.paramMap.get('projectId');
        const url = this.route.snapshot.url.join('/');

        if (this.checkRedirectionToSplitScreen(projectId, trainingId, url)) {
            this.backToSplitScreen(projectId, trainingId);
        } else if (url.includes('summary')) {
            this.backToCommunityGallery();
        } else if (url.includes('project')) {
            this.backToWorkspace('projects');
        } else if (url.includes('imgdb')) {
            this.backToWorkspace('image-databases');
        } else {
            console.warn("Couldn't find route for back button, chose default.");
            this.backToWorkspace('projects');
        }
    }

    private checkRedirectionToSplitScreen(
        projectId: string,
        trainingId: string,
        url: string
    ): boolean {
        return (trainingId && projectId) ||
            url.includes('settings') ||
            url.includes('members')
            ? true
            : false;
    }

    backToSplitScreen(projectId: string, trainingId: string): void {
        this.router.navigate([`/project/${projectId}`], {
            queryParams: {
                trainingUuid: trainingId,
            },
        });
    }

    backToWorkspace(workspaceSelection: string): void {
        this.router.navigate(['/workspace'], {
            queryParams: {
                selected: workspaceSelection,
            },
        });
    }

    backToCommunityGallery(): void {
        this.router.navigate(['/communityprojects']);
    }
}
