import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
    private redirectIn: number;
    private redirectTo = '';
    private interval: number;
    private timeout: number;

    constructor(private router: Router) {
        this.redirectIn = 5;
    }

    get redirectionMessage(): string {
        return this.redirectIn !== 1
            ? `Redirection in ${this.redirectIn} seconds`
            : `Redirection in ${this.redirectIn} second`;
    }

    ngOnInit(): void {
        this.interval = window.setInterval(() => {
            this.redirectIn -= 1;
        }, 1000);

        this.timeout = window.setTimeout(() => {
            this.router.navigate([this.redirectTo]);
        }, 5100);
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeout);
        clearInterval(this.interval);
    }
}
