import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile } from '../../authentication/profile/models/user-profile';
import { UserActivityResponse } from './model/user-activity-response';
import { UserActivityService } from './user-activity-service';
import { UserActivity } from './model/activities/user-activity';
import { UserActivityCreator } from './model/user-activity-creator';

@Component({
    selector: 'app-user-activities',
    templateUrl: './user-activities.component.html',
    styleUrls: [
        '../../project-workspace/components/project-workspace-overview/' +
            'components/project-activity/project-activity.component.scss',
        './user-activities.component.scss',
    ],
    providers: [UserActivityService],
})
export class UserActivitiesComponent implements OnInit {
    activities: UserActivity[] = [];
    @Input() user: UserProfile;

    constructor(
        public translate: TranslateService,
        private userActivityService: UserActivityService
    ) {}

    ngOnInit(): void {
        this.userActivityService.setUserName(this.user.displayName);
        this.userActivityService.subscribe(this.appendActivity.bind(this));
        this.onScrollDown();
    }

    getUsersProjectActivityHeader() {
        let firstName = this.user.firstName;
        if (firstName.endsWith('x') || firstName.endsWith('s')) {
            return this.translate.instant(
                'user.project_activity_header.ends_in_hiss',
                { name: firstName }
            );
        } else {
            return this.translate.instant(
                'user.project_activity_header.no_hiss_end',
                { name: firstName }
            );
        }
    }

    onScrollDown() {
        this.userActivityService.getNextActivities();
    }

    private appendActivity(newActivity: UserActivityResponse) {
        this.activities.push(UserActivityCreator.createActivity(newActivity));
    }

    getImageSrc(activity: UserActivity) {
        if (activity.projectPicture) {
            return activity.projectPicture;
        } else {
            return '/assets/img/card-icons/default-image.png';
        }
    }
}
