import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Observer, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserActivityPage } from './model/user-activity-page';
import { UserActivityResponse } from './model/user-activity-response';

const PAGE_SIZE: number = 10;

@Injectable()
export class UserActivityService {
    private readonly baseUrl: string;
    private userName: string;
    private fetchedPages: number = 0;
    private totalPages: number = 2; //So we do not think we have reached the last page on load.
    private lastPage: Subject<UserActivityResponse>;

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.backendUrl}/rest/v1/users`;
        this.lastPage = new Subject<UserActivityResponse>();
    }

    private getActivities(
        userName: string,
        page: number,
        size: number
    ): Observable<UserActivityPage> {
        const url = `${this.baseUrl}/${userName}/activities`;
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('size', size);
        return this.http
            .get<UserActivityPage>(url, { params: params })
            .pipe(this.mapTimes());
    }

    private mapTimes() {
        return map((activityPage: UserActivityPage) => {
            activityPage.content.forEach((activity: UserActivityResponse) => {
                //The HttpService cannot convert the dates we get from the backend (ISO 8601 form) to
                //proper Date objects and just returns strings, so we need to do this conversion here.
                activity.occurTime = new Date(activity.occurTime);
            });
            return activityPage;
        });
    }

    getNextActivities(): void {
        if (!this.fetchedLastPage()) {
            this.getActivities(
                this.userName,
                this.fetchedPages,
                PAGE_SIZE
            ).subscribe((value: UserActivityPage) => {
                this.fetchedPages++;
                this.totalPages = value.totalPages;
                value.content.forEach((x) => this.lastPage.next(x));
            });
        }
    }

    setUserName(name: string) {
        this.userName = name;
    }

    private fetchedLastPage(): boolean {
        return this.fetchedPages === this.totalPages;
    }

    subscribe(listener: Observer<UserActivityResponse>) {
        this.lastPage.subscribe(listener);
    }
}
