import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    ImageCroppedEvent,
    ImageTransform,
    base64ToFile,
} from 'ngx-image-cropper';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent {
    @Input()
    imageFile: File;

    @Input()
    roundCropper = true;

    @Input()
    maintainAspectRatio = true;

    @Input()
    aspectRatio = 1;

    resizeToWidth = 400; //px
    resizeToHeight = 400; //px

    scale = 1;

    constructor(private activeModal: NgbActiveModal) {}

    imageChangedEvent: any = '';
    croppedImage: any = '';
    transform: ImageTransform = {};

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    zoomOut(): void {
        this.scale -= 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    zoomIn(): void {
        this.scale += 0.1;
        this.transform = {
            ...this.transform,
            scale: this.scale,
        };
    }

    blobToFile = (theBlob: Blob, fileName: string): File => {
        const b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return <File>theBlob;
    };

    confirmImage(): void {
        const imageBlob = base64ToFile(this.croppedImage);
        const name = (Math.random() + 1).toString(36).substring(7) + '';
        const imgFile = this.blobToFile(imageBlob, name);
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.transform = {};
        this.activeModal.close(imgFile);
    }

    cancel(): void {
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.transform = {};
        this.activeModal.close();
    }
}
