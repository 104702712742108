<mat-card *ngIf="token">
    <div *ngIf="success; else noSuccess">

        <mat-card-title>Success!</mat-card-title>
        <mat-card-content>You have successfully verified your E-Mail. Click <a href="/login">here</a> to login.
        </mat-card-content>
    </div>
    <ng-template #noSuccess>
        <mat-card-title>Sorry!</mat-card-title>
        <mat-card-content>The server did not accept your verification token. Please check the token and try again.
        </mat-card-content>
    </ng-template>
</mat-card>
