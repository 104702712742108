<div class="labeling-central-tile">
    <div
        class="current-image"
        [ngClass]="{
            active: showImageContainer,
            shrink: !selectedImage?.processedUrl || !selectedImage?.error
        }"
    >
        <div #panzoomParent class="current-image-inner">
            <pan-zoom [config]="panZoomConfig" class="pan-zoom">
                <img
                    #currentImage
                    (load)="updateSvgStatus()"
                    [src]="selectedImage?.processedUrl"
                    [alt]="selectedImage?.id"
                    *ngIf="selectedImage?.processedUrl && !selectedImage?.error"
                />
                <svg
                    [ngStyle]="{
                        cursor: cursorView
                    }"
                    (mousedown)="
                        cursorView = isDraggingActive ? 'grabbing' : 'default'
                    "
                    (mouseup)="
                        cursorView = isDraggingActive ? 'grab' : 'default'
                    "
                    *ngIf="svgStatus"
                    id="labeling"
                    #labeling
                    [attr.width]="svgStatus.w"
                    [attr.height]="svgStatus.h"
                    [attr.viewBox]="svgStatus.viewBox"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <g
                        *ngFor="let annotation of annotations"
                        class="annotation-box"
                        [ngClass]="{
                            active: annotation.active,
                            selectable: !isDraggingActive
                        }"
                        (click)="selectAnnotation(annotation)"
                    >
                        <rect
                            [ngStyle]="{
                                stroke: annotation.active
                                    ? 'white'
                                    : getColor(
                                          getImgCategoryName(
                                              annotation.categoryId
                                          )
                                      )
                            }"
                            [attr.x]="annotation.bbox.x"
                            [attr.y]="annotation.bbox.y"
                            [attr.width]="annotation.bbox.width"
                            [attr.height]="annotation.bbox.height"
                        ></rect>
                        <rect
                            [ngStyle]="{
                                fill: annotation.active
                                    ? 'white'
                                    : getColor(
                                          getImgCategoryName(
                                              annotation.categoryId
                                          )
                                      ),
                                stroke: annotation.active
                                    ? 'white'
                                    : getColor(
                                          getImgCategoryName(
                                              annotation.categoryId
                                          )
                                      )
                            }"
                            [attr.x]="annotation.bbox.x"
                            [attr.y]="annotation.bbox.y - 20"
                            [attr.width]="annotation.bbox.width"
                            height="20"
                            class="title-box"
                        ></rect>
                        <text
                            [attr.x]="annotation.bbox.x + 3"
                            [attr.y]="annotation.bbox.y - 6"
                            class="title"
                        >
                            {{ getImgCategoryName(annotation.categoryId) }}
                        </text>
                    </g>
                </svg>
            </pan-zoom>
        </div>
    </div>
    <div
        *ngIf="
            showImageContainer &&
            !selectedImage?.processedUrl &&
            !selectedImage?.error
        "
        class="w-100"
    >
        <img
            alt="spinner"
            src="assets/img/img-db/spinner.gif"
            class="img-fluid spinner"
        />
        <div class="text-center message">
            {{ 'imgDb.imgLabeling.stillProcess' | translate }}
        </div>
    </div>
    <div *ngIf="showImageContainer && selectedImage?.error">
        <div class="text-center message">
            {{ 'imgDb.imgLabeling.imgError' | translate }}
        </div>
    </div>
    <div class="list-wrapper">
        <div class="img-wrapper">
            <div
                class="prev-arrow arrow"
                [ngClass]="{ hidden: !isLeftArrowVisible }"
                (click)="getPrevPage()"
            >
                <div class="arrow-wrapper">
                    <img
                        src="assets/svg/img-db/left_arrow.svg"
                        alt="arrow-left"
                        class="left_arrow"
                    />
                    <img
                        src="assets/svg/img-db/left_arrow_mouseover.svg"
                        alt="mouseover_left_arrow"
                        class="mouseover_left_arrow"
                    />
                </div>
            </div>
            <div class="items-list" id="img-items-list">
                <div
                    class="item"
                    *ngFor="let img of images; let i = index"
                    [ngClass]="{
                        active: selectedImage && selectedImage?.id === img?.id
                    }"
                    (click)="onSelectImage(i)"
                    #img
                >
                    <div class="image-wrapper">
                        <img
                            [src]="
                                img.thumbnailUrl === null
                                    ? img.url
                                    : img.thumbnailUrl
                            "
                            [alt]="img.id"
                            class="img-fluid"
                            [ngClass]="{
                                processing: !img.processedUrl,
                                error: img.error
                            }"
                        />
                    </div>
                    <p>{{ img.name }}</p>
                    <mat-icon *ngIf="img.isBookmarked" class="icon"
                        >bookmark_black_24dp
                    </mat-icon>
                </div>
            </div>
            <div
                class="next-arrow arrow"
                [ngClass]="{ hidden: !isRightArrowVisible }"
                (click)="getNextPage()"
            >
                <div class="arrow-wrapper">
                    <img
                        src="assets/svg/img-db/right_arrow.svg"
                        alt="arrow-right"
                        class="right_arrow"
                    />
                    <img
                        src="assets/svg/img-db/right_arrow_mouseover.svg"
                        alt="mouseover_right_arrow"
                        class="mouseover_right_arrow"
                    />
                </div>
            </div>
        </div>
    </div>
    <div
        class="paging-container d-flex justify-content-center"
        *ngIf="images.length"
    >
        <app-input-paging-bar
            tag="label"
            [maxValue]="totalElements"
            [currentValue]="currentImageNumber"
            (nextValue)="onNextValue($event)"
        ></app-input-paging-bar>
    </div>
</div>
