import { Component, Input } from '@angular/core';
import { Project } from 'src/app/project/models/project';

@Component({
    selector: 'app-project-workspace-overview',
    templateUrl: './project-workspace-overview.component.html',
    styleUrls: ['./project-workspace-overview.component.scss'],
})
export class ProjectWorkspaceOverviewComponent {
    @Input()
    project: Project;

    constructor() {}
}
