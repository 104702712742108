<app-header-box [name]="project?.name"></app-header-box>
<app-project-overview-layout [headerText]="project?.name">
    <div leftSide>
        <app-user-info [userInfo]="getUserInfo()"></app-user-info>
        <h5 class="description-header">
            {{
                'project.components.projectSettings.projectDescription.header'
                    | translate
            }}
        </h5>
        <form [formGroup]="projectForm">
            <div class="d-flex flex-row justify-content-between">
                <div class="textarea-label-container">
                    <label
                        class="textarea-label"
                        for="projectDescriptionInput"
                        >{{
                            'project.components.projectSettings.projectDescription.label'
                                | translate
                        }}</label
                    >
                </div>

                <div class="d-flex flex-row project_availability_container">
                    <div class="d-flex flex-row justify-content-center">
                        <label class="custom-radio d-flex flex-row pa_radio">
                            <input
                                type="radio"
                                id="cpw_public"
                                name="openly"
                                formControlName="openly"
                                [value]="true"
                                #radio_public
                                (change)="onRadioButtonChange()"
                            />
                            <div class="outer-circle"></div>
                            <div
                                class="inner-circle"
                                [ngClass]="{ checked: radio_public.checked }"
                            ></div>
                        </label>
                        <label for="cpw_public" class="radio_spacer">
                            <div>
                                {{
                                    'project.components.wizardForm.public'
                                        | translate
                                }}
                            </div>
                        </label>
                    </div>
                    <div class="d-flex flex-row justify-content-center">
                        <label class="custom-radio d-flex flex-row pa_radio">
                            <input
                                type="radio"
                                id="cpw_private"
                                name="openly"
                                formControlName="openly"
                                [value]="false"
                                #radio_private
                                (change)="onRadioButtonChange()"
                            />
                            <div class="outer-circle"></div>
                            <div
                                class="inner-circle"
                                [ngClass]="{ checked: radio_private.checked }"
                            ></div>
                        </label>
                        <label for="cpw_private">
                            <div>
                                {{
                                    'project.components.wizardForm.private'
                                        | translate
                                }}
                            </div>
                        </label>
                    </div>
                </div>
            </div>

            <div
                class="
                    d-flex
                    flex-column
                    justify-content-start
                    textarea-container
                "
            >
                <textarea
                    maxlength="500"
                    class="description-textarea"
                    name="description"
                    id="projectDescriptionInput"
                    [placeholder]="
                        'project.components.projectSettings.projectDescription.placeholder'
                            | translate
                    "
                    formControlName="description"
                    (blur)="onTextAreaSaveChanges()"
                    (keydown.escape)="onTextAreaDiscardChanges()"
                ></textarea>
                <span class="helper-text">{{
                    'project.components.projectSettings.projectDescription.helperText'
                        | translate
                }}</span>
            </div>
        </form>
    </div>
    <div rightSide></div>
</app-project-overview-layout>
