<div class="row cards-container">
    <app-add-card
        *ngIf="cardPage.addCard"
        [addCard]="cardPage.addCard"
        class="col-12 col-sm-6 col-md-6 col-lg-4 card-spacer"
    ></app-add-card>
    <app-card
        *ngFor="let itemCard of cardPage.itemCards"
        [card]="itemCard"
        class="col-12 col-sm-6 col-md-6 col-lg-4 card-spacer"
        (deleteCardEvent)="removeItem($event)"
    >
    </app-card>
</div>
<div class="row mt-4 cards-container">
    <div class="col">
        <app-pagination
            *ngIf="cardPage.numberOfPages"
            [numberOfPages]="cardPage.numberOfPages"
            [currentPage]="cardPage.pageable.page"
            (pageChanged)="changePage($event)"
        ></app-pagination>
    </div>
</div>
