import { Component, Input } from '@angular/core';
import { ImgDbInfo } from '../model/img-db-info';

@Component({
    selector: 'app-img-db-info',
    templateUrl: './img-db-info.component.html',
    styleUrls: ['./img-db-info.component.scss'],
})
export class ImgDbInfoComponent {
    @Input()
    dbInfo: ImgDbInfo;
}
