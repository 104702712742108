<div>
    <div class="row m-0" *ngIf="project">
        <div class="col-12 px-0">
            <app-header-box name="{{ getTranslationKey() | translate }}">
                <button
                    id="project-members-button"
                    type="button"
                    *ngIf="memberDetail?.roleName === 'OWNER'"
                    class="btn btn-tryb-ice-blue project-member-container"
                    (click)="redirectToProjectMembers()"
                >
                    {{
                        'project.components.projectOverview.projectMembersComponent.projectMembersButtonTxt'
                            | translate
                    }}
                </button>
                <span
                    *ngIf="memberDetail?.roleName === 'OWNER'"
                    class="
                        btn btn-tryb-ice-blue
                        project-settings-container
                        d-flex
                        justify-content-center
                        align-items-center
                    "
                    (click)="redirectToProjectSettings()"
                        id="project-settings-button"
                >
                    <img
                        class="project-settings-icon"
                        src="assets/svg/project-settings.svg"
                        alt="Project settings icon"
                /></span>
            </app-header-box>
        </div>
    </div>
    <div class="workspace-content-wrapper">
        <div
            *ngIf="showNavigation()"
            class="side-navbar text-center navigation px-0"
            [class.smallnavside]="sideNavStatus"
            id="sticky-sidebar"
        >
            <app-project-workspace-navigation
                [projectName]="project.name"
                [projectId]="project.id"
                [projectPicture]="getProjectPictureLocation()"
                (selectNavigationItemEvent)="handleNavigationItemEvent($event)"
                (sidenavStatusEvent)="checkNavSideStatus($event)"
            >
            </app-project-workspace-navigation>
        </div>
        <div class="container-fluid p-0">
            <div class="row m-0" *ngIf="project">
                <div
                    class="col p-0"
                    [class.smallnavside]="sideNavStatus"
                    id="main"
                >
                    <app-project-workspace-overview
                        [project]="project"
                        *ngIf="isOverviewSelected()"
                    ></app-project-workspace-overview>
                    <div
                        class="split-screen-wrapper"
                        *ngIf="isTrainingsSelected()"
                    >
                        <div class="row m-0">
                            <div class="col-12 p-0">
                                <div class="row m-0">
                                    <div class="col-7 p-0">
                                        <div class="quest-charts">
                                            <app-tree></app-tree>
                                        </div>
                                    </div>
                                    <div class="col-5 p-0">
                                        <app-training-control
                                            [tileNameSuffixes]="
                                                tileNameSuffixes
                                            "
                                            [resizeElemSplite]="resizeElemPage"
                                            [treeNode]="selectedTraining"
                                            (tileClicked)="onTileClick($event)"
                                            (trainingMediaButtonClicked)="
                                                onTrainingMediaButtonClick(
                                                    $event
                                                )
                                            "
                                        ></app-training-control>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
