import { Component, HostBinding, TemplateRef } from '@angular/core';
import { ToastMessageService } from '../../services/toast-message.service';

@Component({
    selector: 'app-toasts',
    template: `
        <ngb-toast
            *ngFor="let toast of getToasts()"
            [class]="toast.classname"
            [autohide]="true"
            [delay]="toast.delay"
            (hidden)="removeToast(toast)"
        >
            <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
                <ng-template
                    [ngTemplateOutlet]="toast.textOrTpl"
                    ]
                ></ng-template>
            </ng-template>
            <ng-template #text>{{ toast.textOrTpl }}</ng-template>
        </ngb-toast>
    `,
    styleUrls: ['./toast-message-container.component.scss'],
})
export class ToastMessageContainerComponent {
    @HostBinding('class') class =
        'toast-container position-fixed top-2 end-0 p-3';

    @HostBinding('style.z-index') styleZIndex = '1200';
    constructor(private toastMessageService: ToastMessageService) {}

    isTemplate(toast) {
        return toast.textOrTpl instanceof TemplateRef;
    }

    removeToast(toast) {
        this.toastMessageService.remove(toast);
    }

    getToasts() {
        return this.toastMessageService.toasts;
    }
}
