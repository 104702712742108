<div class="container-fluid">
    <div class="cms-page">
        <div class="cms-sections">
            <div class="cms-section pos-{{ cmsSection.position }} cms-section-{{ cmsSection.type }}"
                 *ngFor="let cmsSection of cmsSections">
                <app-cms-sidebar
                        *ngIf="cmsSection.type === 'sidebar'"
                        [section]="cmsSection"
                >
                </app-cms-sidebar>
            </div>
        </div>
    </div>
</div>
