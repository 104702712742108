import { CmsSlot } from '../../models/cms-slot';
import { CmsBlockComponent } from './cms-block.component';
import { Media } from '../../models/media';

export abstract class CmsAbstractBlockComponent implements CmsBlockComponent {
    position: number;
    type: string;
    slots: CmsSlot[];
    marginBottom: string;
    marginLeft: string;
    marginRight: string;
    marginTop: string;
    style: string;
    backgroundMedia: Media;

    initBlock() {
        this.style = `padding: ${this.marginTop} ${this.marginRight} ${this.marginBottom} ${this.marginLeft};`;
    }
}
