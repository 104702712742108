import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastMessageService {
    private readonly CONFIG_ERROR_MSG = {
        classname: 'alert alert-danger',
        delay: 5000,
    };
    private readonly CONFIG_SUCCESS_MSG = {
        classname: 'alert alert-success',
        delay: 5000,
    };

    toasts: any[] = [];

    showSuccessMsg(textOrTpl: string | TemplateRef<any>, options?: any) {
        const finalOptions = options
            ? this.mergeOptions(this.CONFIG_SUCCESS_MSG, options)
            : this.CONFIG_SUCCESS_MSG;
        this.toasts.push({ textOrTpl, ...finalOptions });
    }

    showErrorMsg(textOrTpl: string | TemplateRef<any>, options?: any) {
        const finalOptions = options
            ? this.mergeOptions(this.CONFIG_ERROR_MSG, options)
            : this.CONFIG_ERROR_MSG;
        this.toasts.push({ textOrTpl, ...finalOptions });
    }

    private mergeOptions(defaultOpt, opt) {
        return { ...defaultOpt, ...opt };
    }

    remove(toast) {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }

    clear() {
        this.toasts.splice(0, this.toasts.length);
    }
}
