import { Component, OnDestroy, OnInit } from '@angular/core';
import { Scene } from '../../../scenes/models/scene';
import { ActivatedRoute } from '@angular/router';
import { SceneStateService } from 'src/app/scenes/services/scene-state.service';
import { SceneVisualService } from 'src/app/scenes/services/scene-visual.service';
import { TrainingService } from 'src/app/shared/services/training.service';
import { TrainingStatus } from 'src/app/shared/models/training-status';
import { CostFunctionService } from '../../services/cost-function.service';
import { TrainingInfoWithCode } from 'src/app/shared/models/training-info-with-code';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-cost-function',
    templateUrl: './cost-function.component.html',
    styleUrls: ['./cost-function.component.scss'],
    providers: [SceneVisualService, SceneStateService, CostFunctionService],
})
export class CostFunctionComponent implements OnInit, OnDestroy {
    scene: Scene;
    dataLoaded: boolean;
    isFixedElementDragDisabled: boolean = true;
    selectedTabIndex: number = 0;
    editingIsDisabled: boolean = false;
    trainingName: string;
    trainingId: string;
    customCode: string;
    isToggleDisabled: boolean;
    isCustomCodeMode: boolean;

    sceneStateServiceSub: Subscription;

    constructor(
        private route: ActivatedRoute,
        private sceneStateService: SceneStateService,
        private trainingService: TrainingService
    ) {}

    ngOnInit(): void {
        this.trainingId = this.route.snapshot.paramMap.get('trainingId');
        this.sceneStateService.trainingId = this.trainingId;
        this.sceneStateServiceSub =
            this.sceneStateService.observeScene$.subscribe((data) => {
                if (data) {
                    this.scene = data;
                    this.dataLoaded = true;
                    this.sceneStateService.loadSceneElementsForCostFunction();
                }
            });

        this.trainingService
            .loadTrainingInfoForCostFunction(this.trainingId)
            .subscribe((training: TrainingInfoWithCode) => {
                this.editingIsDisabled = training.status !== TrainingStatus.NEW;
                this.trainingName = training.name;
                this.customCode = training.code;
                this.isCustomCodeMode = !!this.customCode;
                this.isToggleDisabled = !!this.customCode;
            });
    }

    ngOnDestroy(): void {
        this.sceneStateServiceSub?.unsubscribe();
    }

    onTabIndexChange($event: number) {
        this.isFixedElementDragDisabled = $event !== 1;
        this.selectedTabIndex = $event;
    }

    onDisableFixedElementDrag($event: boolean) {
        this.isFixedElementDragDisabled = $event;
    }

    onDisableToggle($event: boolean) {
        this.isToggleDisabled = $event;
    }

    onSlideToggleChange($event) {
        this.isCustomCodeMode = $event.checked;
    }
}
