<div
    class="scene-elements-container"
    [ngClass]="{
        'is-in-costfunction': type !== sceneCompositionTypes.SCENE
    }"
>
    <p class="title">
        {{
            'scenes.components.sceneComposition.sceneCompositionTitle'
                | translate
        }}
    </p>
    <div class="scene-elements-content">
        <div class="search-bar">
            <input
                type="search"
                placeholder="{{
                    'scenes.components.sceneComposition.placeholder' | translate
                }}"
                (input)="onInput($event)"
                (keydown)="onKeydown($event.key)"
            />
            <span class="material-icons search-icon">search</span>
        </div>
        <div
            class="scrollable-container elements-content"
            #listContainer
            [ngClass]="{ 'disable-fixation': isFixationDisabled }"
        >
            <ul
                class="level0"
                id="list-1"
                cdkDropList
                [cdkDropListDisabled]="
                    type !== sceneCompositionTypes.COSTFUNCTION
                "
                [cdkDropListSortingDisabled]="true"
            >
                <li
                    (click)="
                        type === sceneCompositionTypes.SCENE
                            ? selectRobot(loadedRobot)
                            : null
                    "
                    *ngIf="loadedRobot?.object3D$"
                    [ngStyle]="{ color: getObjectColor(loadedRobot) }"
                    [cdkDrag]="
                        type === sceneCompositionTypes.COSTFUNCTION
                            ? true
                            : null
                    "
                    [cdkDragData]="
                        type === sceneCompositionTypes.COSTFUNCTION
                            ? loadedRobot
                            : null
                    "
                    [cdkDragDisabled]="
                        type !== sceneCompositionTypes.COSTFUNCTION ||
                        (disableFixedElementDrag &&
                            loadedRobot.sceneElement.fixed) ||
                        editingIsDisabled
                    "
                    class="scene-element d-flex align-items-center"
                >
                    <div
                        (click)="toggleVisibilityJoints()"
                        class="element-name d-flex flex-fill"
                        [ngClass]="{
                            disabled:
                                disableFixedElementDrag &&
                                loadedRobot.sceneElement.fixed
                        }"
                        (mouseenter)="
                            loadedRobot.sceneElement.fixed &&
                            type === sceneCompositionTypes.SCENE
                                ? showFixationInfoTextPopup($event)
                                : null
                        "
                        (mouseleave)="
                            this.isFixationInfoTextPopupVisible = false
                        "
                    >
                        <span
                            *ngIf="type === sceneCompositionTypes.SCENE"
                            class="material-icons dropdown-icon"
                            [ngClass]="{ closed: !showListJoints }"
                            >arrow_drop_down</span
                        >
                        <img
                            src="assets/svg/scene/robot.svg"
                            class="robot-icon"
                            alt=""
                        />
                        <span
                            [innerHTML]="
                                loadedRobot.sceneElement
                                    .browserAndPhysicsLoadable.name
                                    | searchHighlight
                                        : searchTerm
                                        : searchResults
                                        : searchIndex
                            "
                        ></span>
                    </div>
                    <div
                        class="checkbox-scene-composition"
                        [ngClass]="{ disabled: editingIsDisabled }"
                        (click)="onIsFixedChange($event, loadedRobot)"
                        (mouseenter)="showInfoTextPopup($event)"
                        (mouseleave)="this.isInfoTextPopupVisible = false"
                    >
                        <input
                            type="checkbox"
                            [checked]="loadedRobot.sceneElement.fixed"
                        />
                        <span class="mark"></span>
                    </div>
                </li>
                <p
                    *ngIf="type === sceneCompositionTypes.CUSTOMCODE"
                    class="uuid-info-robot"
                >
                    {{ loadedRobot.sceneElement.id }}
                </p>
                <ul
                    *ngIf="
                        loadedRobot?.object3D$.value &&
                        type === sceneCompositionTypes.SCENE &&
                        showListJoints
                    "
                    class="robot1"
                    [ngStyle]="{
                        'list-style':
                            showListRobots === true
                                ? 'down-arrow'
                                : 'right-arrow'
                    }"
                >
                    <li
                        (click)="toggleVisibilityRobots()"
                        class="scene-element"
                    >
                        <span
                            class="material-icons dropdown-icon"
                            [ngClass]="{ closed: !showListRobots }"
                            >arrow_drop_down</span
                        >
                        <span class="material-icons item-icon">webhook</span>
                        {{
                            'scenes.components.sceneComposition.joints'
                                | translate
                        }}
                    </li>

                    <app-node-tree-view
                        [loadedRobot]="loadedRobot.object3D$.asObservable()"
                        [showListRobots]="showListRobots"
                        [searchTerm]="searchTerm"
                        [searchResults]="searchResults"
                        [searchIndex]="searchIndex"
                    >
                    </app-node-tree-view>
                </ul>
            </ul>

            <ul
                *ngIf="sceneStateService.observeLoadedSceneElements$ | async"
                class="level0"
                cdkDropList
                id="list-2"
                [cdkDropListData]="
                    sceneStateService.observeLoadedSceneElements$ | async
                "
                [cdkDropListDisabled]="
                    type !== sceneCompositionTypes.COSTFUNCTION
                "
                [cdkDropListSortingDisabled]="true"
            >
                <li
                    [ngStyle]="{
                        color:
                            object === selectedSceneElement &&
                            type === sceneCompositionTypes.SCENE &&
                            !ikRuns
                                ? '#e6007e'
                                : '#000000'
                    }"
                    *ngFor="
                        let object of sceneStateService.observeLoadedSceneElements$
                            | async
                    "
                    [cdkDrag]="
                        type === sceneCompositionTypes.COSTFUNCTION
                            ? true
                            : null
                    "
                    [cdkDragData]="
                        type === sceneCompositionTypes.COSTFUNCTION
                            ? object
                            : null
                    "
                    [cdkDragDisabled]="
                        type !== sceneCompositionTypes.COSTFUNCTION ||
                        (disableFixedElementDrag &&
                            object.sceneElement.fixed) ||
                        editingIsDisabled
                    "
                >
                    <div class="scene-element non-robot align-items-center">
                        <div
                            class="custom-row"
                            [ngClass]="{
                                'clickable-region':
                                    type !== sceneCompositionTypes.COSTFUNCTION
                            }"
                        >
                            <div
                                class="element-name d-flex"
                                [ngClass]="{
                                    disabled:
                                        disableFixedElementDrag &&
                                        object.sceneElement.fixed
                                }"
                                (click)="
                                    type === sceneCompositionTypes.SCENE
                                        ? selectObject(object)
                                        : null
                                "
                                (mouseenter)="
                                    object.sceneElement.fixed &&
                                    type !== sceneCompositionTypes.SCENE
                                        ? showFixationInfoTextPopup($event)
                                        : null
                                "
                                (mouseleave)="
                                    this.isFixationInfoTextPopupVisible = false
                                "
                            >
                                <img
                                    src="assets/svg/scene/object3d.svg"
                                    alt=""
                                />
                                <span
                                    [innerHTML]="
                                        object.sceneElement
                                            .browserAndPhysicsLoadable.name
                                            | searchHighlight
                                                : searchTerm
                                                : searchResults
                                                : searchIndex
                                    "
                                ></span>
                            </div>
                            <div
                                class="
                                    d-flex
                                    justify-content-end
                                    actions-wrapper
                                "
                            >
                                <span
                                    class="delete"
                                    *ngIf="!editingIsDisabled"
                                    [ngClass]="{
                                        disabled: !isObjectRemovable(object)
                                    }"
                                    (mouseenter)="
                                        showDeleteInfoTextPopup($event)
                                    "
                                    (mouseleave)="
                                        this.isDeleteInfoTextPopupVisible = false
                                    "
                                >
                                    <i
                                        (click)="
                                            removeObjectFromScene(
                                                object,
                                                $event
                                            )
                                        "
                                        *ngIf="
                                            type === sceneCompositionTypes.SCENE
                                        "
                                        class="bi bi-trash-fill trash-icon"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                                <span
                                    class="
                                        checkbox-scene-composition
                                        ms-2
                                        flex-shrink-0
                                    "
                                    [ngClass]="{ disabled: editingIsDisabled }"
                                    (click)="onIsFixedChange($event, object)"
                                    (mouseenter)="showInfoTextPopup($event)"
                                    (mouseleave)="
                                        this.isInfoTextPopupVisible = false
                                    "
                                >
                                    <input
                                        [checked]="object.sceneElement.fixed"
                                        [attr.disabled]="editingIsDisabled"
                                        type="checkbox"
                                    />
                                    <span class="mark"></span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="type === sceneCompositionTypes.CUSTOMCODE"
                        class="uuid-info-object"
                    >
                        {{ object.sceneElement.id }}
                    </div>
                </li>
            </ul>
            <div
                class="info-text"
                [ngClass]="{ show: isInfoTextPopupVisible }"
                #infoText
            >
                {{
                    (isFixationDisabled
                        ? disabledCheckboxInfoText
                        : 'scenes.components.sceneComposition.infoText'
                    ) | translate
                }}
            </div>
            <div
                class="info-text fixation-info-text"
                [ngClass]="{ show: isFixationInfoTextPopupVisible }"
                #fixationInfoText
            >
                {{
                    (disableFixedElementDragInfoText
                        ? disableFixedElementDragInfoText
                        : 'scenes.components.sceneComposition.disableFixedElementDragInfoText'
                    ) | translate
                }}
            </div>
            <div
                class="info-text delete-info-text"
                [ngClass]="{ show: isDeleteInfoTextPopupVisible }"
                #deleteInfoText
            >
                {{
                    'scenes.components.sceneComposition.deleteInfoText'
                        | translate
                }}
            </div>
        </div>
    </div>
</div>
