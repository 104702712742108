import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ComparePassword } from '../shared/username-validators.directive';
import { ResetPasswordService } from './service/reset-password.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['../abstract/password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    submitted = false;
    token: string;
    message: string;
    error: string | null;
    visible = false;

    form: UntypedFormGroup = this.formBuilder.group(
        {
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: [
                '',
                [Validators.required, Validators.minLength(6)],
            ],
        },
        {
            validator: ComparePassword('password', 'confirmPassword'),
        }
    );

    submit() {
        if (this.form.valid) {
            this.resetPasswordService
                .resetPassword(this.token, this.form.value.password)
                .subscribe(
                    () => {
                        this.submitted = true;
                        this.message =
                            'authentication.resetPassword.resetPassword.success';
                        this.visible = false;
                    },
                    (error1) => {
                        this.submitted = true;
                        this.error = error1.error.message;
                    }
                );
        }
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private resetPasswordService: ResetPasswordService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.token !== undefined) {
                this.visible = true;
                this.token = params.token;
            }
        });
    }
}
