export interface SideContainerData {
    contentItems: ContentItem[];
    contentType: ContentType;
}

export interface ContentItem {
    value: string;
    translationKey?: string;
    checked?: boolean;
    tagForm?: string[];
    infoText?: string;
}

export enum ContentType {
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    NON_INPUT = 'non_input',
}

export enum SideContainerPosition {
    RIGHT = 'right',
    LEFT = 'left',
}

export enum ToggleMode {
    MEDIA = 'media',
    DIAGRAMS = 'diagrams',
}
