import {
    AbstractControl,
    FormControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { environment } from 'src/environments/environment';

type JointLimit = {
    lower: number;
    upper: number;
};

export class OrientationValidator {
    static validateRange(control: AbstractControl): ValidationErrors | null {
        if (isEmptyField(control)) {
            return null;
        }

        let value: string = control.value;
        value = value.replace(',', '.');
        const parsedValue: number = parseFloat(value);

        if (!isNaN(parsedValue)) {
            const min = environment.costFunction.orientation.min;
            const max = environment.costFunction.orientation.max;

            const isWithinRange = min <= parsedValue && parsedValue <= max;
            return isWithinRange
                ? null
                : { invalidRange: { inputValue: control.value } };
        }
        return null;
    }

    static validateRangeWithLimits(limits: JointLimit): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if (isEmptyField(control)) {
                return null;
            }

            const value: string = control.value.replace(',', '.');
            const parsedValue: number = parseFloat(value);

            if (!isNaN(parsedValue)) {
                const isWithinRange =
                    limits.lower <= parsedValue && parsedValue <= limits.upper;
                return isWithinRange ? null : { invalidRange: limits };
            }
            return null;
        };
    }
}

function isEmptyField(control: AbstractControl): boolean {
    return !control.value;
}
