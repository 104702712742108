import { CostFunctionRow } from './cost-function-row';

export class DistanceCostFunctionRow extends CostFunctionRow {
    sourceSceneElementId: string = '';
    sourceSceneElementName: string = '';
    maximize: boolean = false;
    targetSceneElementId: string = '';
    targetSceneElementName: string = '';
    sourceSceneElementFixed: boolean = false;
    targetSceneElementFixed: boolean = false;
}
