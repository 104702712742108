export interface MessageData {
    message: Message;
    type: MessageType;
}

export class Message {
    text: string;
    list?: string[];
}

export enum MessageType {
    SUCCESS,
    ERROR,
}
