import { Component, Input, OnInit } from '@angular/core';
import { URDFJoint } from 'urdf-loader';
import { Reference } from '../../../shared/utility';
import { SceneVisualService } from '../../services/scene-visual.service';

@Component({
    selector: 'app-joint-tree-node',
    templateUrl: './joint-tree-node.component.html',
    styleUrls: ['./joint-tree-node.component.scss'],
})
export class JointTreeNodeComponent implements OnInit {
    @Input() child: URDFJoint;
    @Input() searchTerm: string;
    @Input() searchIndex: number;
    @Input() searchResults: Reference<number>;
    @Input() showListRobots = true;
    @Input() index = 0;
    ikRuns: boolean;
    currentSelection: URDFJoint;

    constructor(private sceneVisualService: SceneVisualService) {}
    ngOnInit(): void {
        this.sceneVisualService.ikRuns.subscribe((val: boolean) => {
            this.ikRuns = val;
        });
        this.sceneVisualService.selectedJoint.subscribe((val: URDFJoint) => {
            this.currentSelection = val;
        });
    }

    jointSelected(joint: URDFJoint) {
        this.sceneVisualService.jointSelected(joint);
    }

    toggleVisibilityRobots() {
        this.showListRobots = !this.showListRobots;
    }
}
