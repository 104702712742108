import { Injectable } from '@angular/core';
import { CmsSlot } from '../models/cms-slot';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class CmsElementService {
    constructor(private sanitizer: DomSanitizer) {}

    renderContent(slot: CmsSlot): SafeHtml {
        if (slot.type === 'text') {
            return this.sanitizer.bypassSecurityTrustHtml(slot.data.content);
        }

        if (slot.type === 'image') {
            const imageContent = `
                <div class="cms-image-container is-standard">
                    <img src="${slot.data.media.url}" class="cms-image">
                </div>
            `;

            return this.sanitizer.bypassSecurityTrustHtml(imageContent);
        }

        return `<code>Cms slot type not support: ${slot.type}</code>`;
    }
}
