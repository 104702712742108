<div class="split-menu">
    <div class="offy-5">
        <form [formGroup]="trainingNameForm">
            <div class="tryb-input-container">
                <input
                    #trainingNameInputField
                    formControlName="name"
                    (blur)="onBlur(trainingNameFormValue)"
                    class="training-name-label"
                    (keydown)="onKeyDown($event)"
                    placeholder="Training name"
                />
                <app-display-errors
                    [control]="trainingNameControl"
                    translationKey="splitScreen.components.questSplitMenu"
                ></app-display-errors>
            </div>
        </form>
    </div>

    <div id="split-menu-section" #splitMenu class="split-menu-section">
        <div class="circle" *ngIf="managedTileSuffixes">
            <div
                class="rotator"
                *ngFor="let tileNameSuffix of managedTileSuffixes"
            >
                <div class="hex-button">
                    <div class="hexagon" (click)="onTileClick(tileNameSuffix)">
                        <img
                            class="poly regular"
                            src="assets/img/context-menu/poly-regular.png"
                            alt="hexagon tile unhovered"
                        />
                        <img
                            class="poly hover"
                            src="assets/img/context-menu/poly-hover.png"
                            alt="hexagon tile hovered"
                        />
                        <div class="overlay">
                            <p class="title">
                                {{
                                    getTileNameTranslationKey(tileNameSuffix)
                                        | translate
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="button-wrapper"
                [ngClass]="{ 'stop-wrapper': centralButtonState === 'stop' }"
            >
                <div
                    *ngIf="centralButtonState === 'play'"
                    class="button play"
                    (click)="start()"
                >
                    <div class="icon"></div>
                </div>
                <div
                    *ngIf="centralButtonState === 'stop'"
                    class="button stop"
                    (click)="stop()"
                >
                    <div class="icon"></div>
                </div>
                <div
                    *ngIf="centralButtonState === 'success'"
                    class="button success"
                    (click)="downloadTrainingSkill()"
                >
                    <span class="material-symbols-outlined download"
                        >download</span
                    >
                </div>
                <div *ngIf="centralButtonState === 'fail'" class="button fail">
                    <span class="material-icons task_alt">block</span>
                </div>
            </div>
        </div>
    </div>
</div>
