import { EventEmitter, Injectable } from '@angular/core';
import {
    BehaviorSubject,
    filter,
    interval,
    mergeMap,
    Observable,
    Subscription,
    tap,
} from 'rxjs';
import { MessageService } from 'src/app/message/services/message.service';
import {
    emptyTrainingTreeNode,
    TrainingTreeNode,
} from '../models/training-tree-node';
import { RightClickMenuPosition } from '../models/right-click-menu-position';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from 'src/app/project/services/project.service';
import { TrainingService } from 'src/app/shared/services/training.service';
import { TrainingDTO } from 'src/app/shared/models/training-dto';
import { TrainingStatus } from 'src/app/shared/models/training-status';

@Injectable({
    providedIn: 'root',
})
export class TreeStateService {
    private _isRightClickMenuOpen = new BehaviorSubject<boolean>(false);
    private _rightClickMenuPosition =
        new BehaviorSubject<RightClickMenuPosition>(
            new RightClickMenuPosition('0', '0')
        );
    private _selectedTreeNode = new BehaviorSubject<TrainingTreeNode>(
        emptyTrainingTreeNode
    );
    private _isTrainingNameValid = new BehaviorSubject<boolean>(false);
    private _treeState = new BehaviorSubject<TrainingTreeNode>(
        emptyTrainingTreeNode
    );
    private _selectedTrainingId = new BehaviorSubject<string>('');
    public onForceChangeSelected = new EventEmitter();

    public onTrainingCloned = new EventEmitter();

    private treeUpdateSubscription$: Subscription = new Subscription();

    private treeStateUpdateInterval: number = 10000;

    get isRightClickMenuOpen(): boolean {
        return this._isRightClickMenuOpen.value;
    }

    set isRightClickMenuOpen(newValue: boolean) {
        this._isRightClickMenuOpen.next(newValue);
    }

    set rightClickMenuPosition(newPosition: RightClickMenuPosition) {
        this._rightClickMenuPosition.next(newPosition);
    }

    get rightClickMenuPosition(): RightClickMenuPosition {
        return this._rightClickMenuPosition.value;
    }

    get rightClickMenuPosition$(): Observable<RightClickMenuPosition> {
        return this._rightClickMenuPosition.asObservable();
    }

    get selectedTreeNode(): TrainingTreeNode {
        return this._selectedTreeNode.value;
    }

    get selectedTreeNode$(): Observable<TrainingTreeNode> {
        return this._selectedTreeNode.asObservable();
    }

    set selectedTreeNode(newValue: TrainingTreeNode) {
        this._selectedTreeNode.next(newValue);
    }

    set treeState(newTree: TrainingTreeNode) {
        this._treeState.next(newTree);
    }

    get treeState(): TrainingTreeNode {
        return this._treeState.value;
    }

    get treeState$(): Observable<TrainingTreeNode> {
        return this._treeState.asObservable();
    }

    set selectedTrainingId(value: string) {
        this._selectedTrainingId.next(value);
    }

    get selectedTrainingId(): string {
        return this._selectedTrainingId.value;
    }

    get isTrainingNameValid(): boolean {
        return this._isTrainingNameValid.value;
    }

    set isTrainingNameValid(newValue: boolean) {
        this._isTrainingNameValid.next(newValue);
    }

    private treeHasRunningTraining: boolean = false;

    constructor(
        private trainingService: TrainingService,
        private messageService: MessageService,
        private projectService: ProjectService
    ) {}

    public startTreeUpdates(projectId: string) {
        this.treeUpdateSubscription$ = interval(this.treeStateUpdateInterval)
            .pipe(
                filter(() => this.treeHasRunningTraining),
                switchMap(() => this.refreshTreeState(projectId))
            )
            .subscribe();
    }

    public cloneTreeNode(
        nodeUuid: string,
        cloneSuffix: string,
        projectId: string
    ): void {
        this.trainingService.clone(nodeUuid, cloneSuffix).subscribe({
            next: (clonedTraining: TrainingDTO) => {
                this.currentTree(projectId).subscribe(
                    (updatedRoot: TrainingTreeNode) => {
                        this.treeState = updatedRoot;
                        this.onForceChangeSelected.emit();
                        this.selectedTreeNode = TrainingTreeNode.findInTree(
                            updatedRoot,
                            clonedTraining.uuid
                        );
                        this.onTrainingCloned.emit();
                    }
                );
            },
        });
    }

    public deleteTreeNode(nodeUuid: string, projectId: string): void {
        const parentOfDeletion = TrainingTreeNode.findParentInTree(
            this.treeState,
            nodeUuid
        );
        this.trainingService.delete(nodeUuid).subscribe({
            next: () => {
                this.currentTree(projectId).subscribe(
                    (updatedRoot: TrainingTreeNode) => {
                        this.treeState = updatedRoot;
                        this.onForceChangeSelected.emit();
                        setTimeout(() => {
                            this.selectedTreeNode = parentOfDeletion;
                        }, 1100);
                    }
                );
            },
            error: () => {
                this.messageService.displayErrorMessage(
                    'Training kann nicht gelöscht werden.'
                );
            },
        });
    }

    public openRightClickMenu(event: any, treeNode: TrainingTreeNode) {
        this.rightClickMenuPosition = new RightClickMenuPosition(
            event.layerY,
            event.layerX
        );
        this.isRightClickMenuOpen = true;
        this.selectedTreeNode = treeNode;
    }

    public closeRightClickMenu() {
        this.isRightClickMenuOpen = false;
    }

    // TODO: fix initial loading
    public refreshTreeState(projectId: string): Observable<TrainingTreeNode> {
        return this.currentTree(projectId).pipe(
            tap((tree: TrainingTreeNode) => {
                this.setTreeHasRunningTraining(this.hasRunningTraining(tree));
                this._treeState.next(tree);
            }),
            tap((tree: TrainingTreeNode) => {
                if (this.selectedTrainingId) {
                    this.selectedTreeNode = TrainingTreeNode.findInTree(
                        tree,
                        this.selectedTrainingId
                    );
                } else {
                    this.selectedTreeNode = tree;
                }
            })
        );
    }

    setTreeHasRunningTraining(treeHasRunningTraining: boolean) {
        this.treeHasRunningTraining = treeHasRunningTraining;
    }

    hasRunningTraining(tree: TrainingTreeNode) {
        const runningStatuses = [
            TrainingStatus.RUNNING,
            TrainingStatus.QUEUED,
            TrainingStatus.CANCELLING,
            TrainingStatus.PREPARING,
        ];
        if (runningStatuses.includes(tree.status)) {
            return true;
        }
        for (let child of tree.children) {
            if (this.hasRunningTraining(child)) {
                return true;
            }
        }
        return false;
    }

    public updateSelectedTreeNodeName(newName: string) {
        this.selectedTreeNode = TrainingTreeNode.updateNodeName(
            this.treeState,
            this.selectedTreeNode.uuid,
            newName
        );

        return this.notifyTrainingService(newName);
    }

    private notifyTrainingService(newName: string): Observable<TrainingDTO> {
        // TODO: replace with patch endpoint
        return this.trainingService.get(this.selectedTreeNode.uuid).pipe(
            mergeMap((training: TrainingDTO) => {
                training.name = newName;
                return this.trainingService.update(training);
            })
        );
    }

    public getTrainingOfSelectedNode(): Observable<TrainingDTO> {
        return this.trainingService.get(this.selectedTreeNode.uuid);
    }

    cleanup() {
        this.isRightClickMenuOpen = false;
        this.selectedTreeNode = emptyTrainingTreeNode;
        this.treeState = emptyTrainingTreeNode;
        this.treeUpdateSubscription$.unsubscribe();
    }

    currentTree(projectId: string) {
        return this.projectService.getTrainingTree(projectId);
    }
}
