import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayerContextMenuComponent } from './skill-architecture/layer-context-menu/layer-context-menu.component';
import { SkillArchitectureComponent } from './skill-architecture/skill-architecture.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ArchitectureVisualizationComponent } from './skill-architecture/architecture-visualization/architecture-visualization.component';
import { SkillArchitectureSelectionRowComponent } from './skill-architecture/skill-architecture-selection-row/skill-architecture-selection-row.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { NeuralNetworkComponent } from './neural-network/neural-network.component';
import { ObservationElementDetailsComponent } from './observation-element-details/observation-element-details.component';
import { CustomRandomizationComponent } from './custom-randomization/custom-randomization.component';
import { RandomizationSidebarComponent } from './randomization-sidebar/randomization-sidebar.component';

@NgModule({
    declarations: [
        SkillArchitectureComponent,
        LayerContextMenuComponent,
        ArchitectureVisualizationComponent,
        SkillArchitectureSelectionRowComponent,
        NeuralNetworkComponent,
        ObservationElementDetailsComponent,
        CustomRandomizationComponent,
        RandomizationSidebarComponent,
    ],
    imports: [
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        DragDropModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    exports: [SkillArchitectureComponent],
})
export class SkillArchitectureEditorModule {}
