import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ImgUpload } from '../model/img-upload';
import { SignedUrlResponse } from '../model/signed-url';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';

@Injectable()
export class ImgDbUploadUtilService {
    private readonly MAX_FILE_SIZE: number = 10485760;
    private readonly MIN_FILE_SIZE: number = 0;

    readonly restUrl: string;
    readonly VALID_FILE_FORMATS: string[] = ['image/jpeg'];

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
        private toastMessageService: ToastMessageService
    ) {
        this.restUrl = `${environment.backendUrl}/rest/img-db`;
    }

    generateSignedUrls(
        files: File[],
        imgDbId: string
    ): Observable<ImgUpload[]> {
        const fileNames = files.map((file) => file.name);
        return this.http
            .post<SignedUrlResponse>(`${this.restUrl}s/${imgDbId}/signed`, {
                names: fileNames,
            })
            .pipe(map((urls) => this.mapToUrls(urls.urlMap, files)));
    }

    private mapToUrls(urls: Map<string, string>, files: File[]): ImgUpload[] {
        let imageUploadObj: ImgUpload[] = [];
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            imageUploadObj.push({ url: urls[file.name], file: file });
        }
        return imageUploadObj;
    }

    async validateAndCheckFiles(droppedFiles: NgxFileDropEntry[]) {
        let validatedFiles = [];
        for (const file of droppedFiles) {
            // Check the file is not directory
            if (!file.fileEntry.isFile) {
                this.toastMessageService.showErrorMsg(
                    this.translateService.instant(
                        'imgDb.upload.error.notFile',
                        { filename: file.fileEntry.name }
                    )
                );
                return;
            }
            await this.getFileFromFileEntry(
                file.fileEntry as FileSystemFileEntry
            ).then((respFile) => {
                let validatedFile: boolean =
                    this.validateFileBeforUpload(respFile);
                if (validatedFile) {
                    validatedFiles.push(respFile);
                }
            });
        }

        return validatedFiles;
    }

    private async getFileFromFileEntry(
        fileEntry: FileSystemFileEntry
    ): Promise<File> {
        return new Promise((resolve) => fileEntry.file(resolve));
    }

    private validateFileBeforUpload(file: File): boolean {
        let result: boolean = true;
        this.VALID_FILE_FORMATS.forEach((value) => {
            if (file.type !== value) {
                this.toastMessageService.showErrorMsg(
                    this.translateService.instant(
                        'imgDb.upload.error.fileType',
                        { filename: file.name }
                    )
                );
                console.error(
                    `File ${file.name} has invalid type "${file.type}"`
                );
                result = false;
            }
        });

        if (file.size > this.MAX_FILE_SIZE) {
            this.toastMessageService.showErrorMsg(
                this.translateService.instant('imgDb.upload.error.fileSize', {
                    filename: file.name,
                })
            );
            console.error(`File ${file.name} is larger than 10MB`);
            return false;
        }

        if (file.size === this.MIN_FILE_SIZE) {
            this.toastMessageService.showErrorMsg(
                this.translateService.instant(
                    'imgDb.upload.error.fileNotEmpty',
                    { filename: file.name }
                )
            );
            console.error(`File ${file.name} is empty`);
            return false;
        }
        return result;
    }
}
