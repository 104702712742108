import { Observable } from 'rxjs';
import { Card, CardFooter } from 'src/app/card/model/card-implementations/card';
import { Container } from '../../../shared/services/container/container.types';

export class ImgDbCard extends Card {
    constructor(
        itemId: string,
        userId: string,
        userName: string,
        footer: CardFooter,
        container: Container
    ) {
        super(itemId, userId, userName, footer, [], container);
        this.navigationUrlParams = {};
        this.navigationUrl = 'imgdb/' + itemId;
    }

    public deleteCard(): Observable<void> {
        // TODO: later a delete is also Required
        throw 'Not yet available';
    }

    public onCardPictureClick(): void {
        /* Similar to delete, Card-View should fire event, clicked(item) */
        this.router.navigate([this.navigationUrl], {
            queryParams: this.navigationUrlParams,
        });
    }

    static convertToCard(data: Container, picture: string): ImgDbCard {
        return new ImgDbCard(
            data.id,
            data.creator,
            null,
            new CardFooter(data.name, picture),
            data
        );
    }
}
