<div class="sticky-offset col-md-9 ms-sm-auto col-lg-10 pt-3 px-4">
    <div class="row">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 text-center">
                            <img
                                [src]="profilePicture"
                                class="picture"
                                alt="{{
                                    'authentication.resetPassword.profilePicture1'
                                        | translate
                                }}"
                                *ngIf="profilePicture"
                            />
                            <img
                                src="https://via.placeholder.com/150"
                                class="rounded"
                                alt="{{
                                    'authentication.resetPassword.profilePicture2'
                                        | translate
                                }}"
                                *ngIf="!profilePicture"
                            />
                        </div>
                        <div class="col-md-7" *ngIf="forumUserProfile">
                            <h6 id="user-name">
                                {{ forumUserProfile.lastName }},
                                {{ forumUserProfile.firstName }}
                            </h6>
                            <p id="display-name" class="text-secondary">
                                {{ forumUserProfile.displayName }}
                            </p>
                        </div>
                        <div class="col-md-7" *ngIf="!forumUserProfile">
                            <h6>{{ 'user.userError' | translate }}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <app-user-activities
                *ngIf="forumUserProfile"
                [user]="forumUserProfile"
            ></app-user-activities>
        </div>
    </div>
</div>
