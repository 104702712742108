import { nonNil } from 'src/app/shared/utility';

/**
 * Image-Annotation from ImageDB
 * !!!Name is shorten becaus of colision with default dom.Image!!!
 */
export class ImgAnnotation {
    id: string;
    imageId: string;
    categoryId: string;
    active: boolean = false;

    public bbox: DOMRect;
    public polygon: number[];

    constructor(
        id?: string,
        imageId?: string,
        categoryId?: string,
        x?: number,
        y?: number,
        width?: number,
        height?: number,
        polygon?: number[]
    ) {
        this.id = id;
        this.imageId = imageId;
        this.categoryId = categoryId;
        if (nonNil(x) && nonNil(y) && nonNil(width) && nonNil(height))
            this.bbox = { x, y, width, height } as DOMRect;
        this.polygon = polygon;
    }
}
