<app-header-box [name]="project?.name"> </app-header-box>
<div class="wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="d-md-flex justify-content-md-center main-spacer">
                    <div class="content">
                        <h3 class="header-text spacer">
                            {{
                                'project.components.projectOverview.projectMembersComponent.projectMembersHeaderTxt'
                                    | translate
                            }}
                        </h3>
                        <div
                            *ngIf="
                                memberRequests.length === 0;
                                else listOfMembers
                            "
                        >
                            {{
                                'project.components.projectOverview.projectMembersComponent.noMemberRequestMessage'
                                    | translate
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #listOfMembers>
    <p style="margin-bottom: 32px">
        <strong>{{
            'project.components.projectOverview.projectMembersComponent.projectMembersSubHeaderTxt'
                | translate
        }}</strong>
    </p>
    <app-member-request
        *ngFor="let memberRequest of memberRequests; index as i"
        [joinRequest]="memberRequest"
        [memberIdx]="i"
        (refreshMemberListTrigger)="onAnswerMemberRequest($event)"
    ></app-member-request>
</ng-template>
