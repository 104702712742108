<ng-template [ngIf]="child.jointType">
    <ng-template
        [ngIf]="
            child.jointType !== 'fixed' && child['mimicJoint'] === undefined
        "
    >
        <ol>
            <li (click)="toggleVisibilityRobots()">
                <a
                    (click)="jointSelected(child)"
                    [ngStyle]="{
                        color:
                            currentSelection === child ? '#e6007e' : '#000000'
                    }"
                >
                    <em class="material-icons item-icon">timeline</em>
                    <span
                        [innerHTML]="
                            child.name
                                | searchHighlight
                                    : searchTerm
                                    : searchResults
                                    : searchIndex
                        "
                    >
                    </span
                ></a>
            </li>
        </ol>
    </ng-template>
</ng-template>
<app-node-tree-view
    *ngIf="child.children"
    [searchTerm]="searchTerm"
    [searchResults]="searchResults"
    [searchIndex]="searchIndex"
    [children]="child.children"
    (onJointSelect)="jointSelected($event)"
></app-node-tree-view>
