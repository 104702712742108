import { Injectable } from '@angular/core';
import { hierarchy, HierarchyPointNode, tree } from 'd3';
import { BehaviorSubject, combineLatest, filter, Observable } from 'rxjs';
import {
    emptyTrainingTreeNode,
    TrainingTreeNode,
} from '../models/training-tree-node';
import { emptyTreeDimension, TreeDimension } from '../models/tree-dimension';
import {
    NODE_HEIGHT,
    NODE_MARGIN_LEFT_RIGHT,
    NODE_MARGIN_TOP_BOTTOM,
    NODE_WIDTH,
} from '../models/tree-drawing-properties';
import { TreeStateService } from './tree-state.service';

@Injectable({
    providedIn: 'root',
})
export class TreeNodeToDrawableService {
    private _drawableTree = new BehaviorSubject(null);
    private _treeDimension = new BehaviorSubject<TreeDimension>(
        emptyTreeDimension
    );

    get drawableTree$(): Observable<HierarchyPointNode<TrainingTreeNode>> {
        return this._drawableTree.asObservable();
    }

    set drawableTree(newTree: HierarchyPointNode<TrainingTreeNode>) {
        this._drawableTree.next(newTree);
    }

    get drawableTree() {
        return this._drawableTree.value;
    }

    set treeDimension(newDimension: TreeDimension) {
        this._treeDimension.next(newDimension);
    }

    get treeDimension(): TreeDimension {
        return this._treeDimension.value;
    }

    get treeDimension$(): Observable<TreeDimension> {
        return this._treeDimension.asObservable();
    }

    constructor(private treeStateService: TreeStateService) {
        combineLatest([this.treeStateService.treeState$, this.treeDimension$])
            .pipe(
                filter(
                    ([treeState, dimension]) =>
                        treeState !== emptyTrainingTreeNode &&
                        dimension !== emptyTreeDimension
                )
            )
            .subscribe(([treeState, dimension]) => {
                let rootNode = hierarchy<TrainingTreeNode>(treeState);
                const buildTreeLayout = tree<TrainingTreeNode>()
                    .nodeSize([
                        NODE_WIDTH + NODE_MARGIN_LEFT_RIGHT,
                        NODE_HEIGHT + NODE_MARGIN_TOP_BOTTOM,
                    ])
                    .separation(() => 1);

                const drawableTree = buildTreeLayout(rootNode);
                this.drawableTree = this.centerTreeLayout(
                    drawableTree,
                    dimension
                );
            });
    }

    private centerTreeLayout(
        rootNode: HierarchyPointNode<TrainingTreeNode>,
        containerDimension: TreeDimension
    ): HierarchyPointNode<TrainingTreeNode> {
        const allTreeNodes = rootNode.descendants();
        let maxDepth = 0;
        let minX = 10000000000;
        let maxX = 0;
        allTreeNodes.forEach(
            (treeNode: HierarchyPointNode<TrainingTreeNode>) => {
                if (treeNode.x < minX) {
                    minX = treeNode.x;
                }
                if (treeNode.x > maxX) {
                    maxX = treeNode.x;
                }
            }
        );
        const treeWidth = Math.abs(minX) + Math.abs(maxX) + NODE_WIDTH;
        const horizontallyCenteredTreeNodes = allTreeNodes.map(
            (treeNode: HierarchyPointNode<TrainingTreeNode>) => {
                treeNode.x = this.calcCenteredXCoordinate(
                    treeNode,
                    containerDimension,
                    treeWidth,
                    minX
                );
                if (treeNode.depth > maxDepth) {
                    maxDepth = treeNode.depth;
                }
                return treeNode;
            }
        );
        const treeHeight = maxDepth * (NODE_HEIGHT + NODE_MARGIN_TOP_BOTTOM);
        const centeredTreeNodes = horizontallyCenteredTreeNodes.map(
            (treeNode: HierarchyPointNode<TrainingTreeNode>) => {
                treeNode.y = this.calcCenteredYCoordinate(
                    treeNode,
                    containerDimension,
                    treeHeight
                );
                return treeNode;
            }
        );
        return centeredTreeNodes[0];
    }

    private calcCenteredXCoordinate(
        treeNode: HierarchyPointNode<TrainingTreeNode>,
        containerDimension: TreeDimension,
        treeWidth: number,
        minX: number
    ): number {
        let newX = 0;
        if (treeNode.x !== minX) {
            newX = treeNode.x + Math.abs(minX);
        }
        return newX + containerDimension.width / 2 - treeWidth / 2;
    }

    private calcCenteredYCoordinate(
        treeNode: HierarchyPointNode<TrainingTreeNode>,
        containerDimension: TreeDimension,
        treeHeight: number
    ): number {
        return (
            treeNode.y -
            treeHeight / 2 +
            containerDimension.height / 2 -
            NODE_HEIGHT / 2
        );
    }
}
