<div class="search-bar">
    <input
        [(ngModel)]="filterTextValue"
        type="search"
        (keyup)="filterText()"
        (keyup.escape)="clearText()"
        (search)="onSearch()"
        placeholder="{{
            'scenes.components.editScene.contextArea.objectLibrary.tryTypingObject'
                | translate
        }}"
    />
    <span class="material-icons search-icon">search</span>
    <!-- <button
        class="add-object-btn"
        (click)="addObject()"
        *ngIf="!editingIsDisabled"
    >
        <span class="material-icons">add</span>
    </button> -->
    <!--Temporary commented due to LEA-1402-->
</div>
<div
    #listContainer
    class="
        object3d-container
        scrollable-container scrollable-container-align-right
    "
    cdkDropList
    [cdkDropListData]="tmpLoadableObjects"
    (cdkDropListDropped)="drop($event)"
    [ngClass]="{ 'has-scrollbar': listHasScrollbar }"
>
    <div
        cdkDrag
        [cdkDragDisabled]="editingIsDisabled"
        class="object-list-item"
        *ngFor="let objectFromLibrary of tmpLoadableObjects"
    >
        <img
            class="object-img"
            [src]="getObjectThumbnailSrc(objectFromLibrary)"
            [alt]="objectFromLibrary.name"
        />
        <p class="object-name">{{ objectFromLibrary.name }}</p>
    </div>
</div>
<ng-template #content>
    <app-add-zip-object
        [uploadFileType]="uploadType"
        (uploadObjectItem)="addNewObject($event)"
    ></app-add-zip-object>
</ng-template>
