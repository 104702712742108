<div class="row">
    <div class="col d-flex flex-column">
        <div class="d-flex flex-row-reverse bd-highlight">
            <div class="p-2 bd-highlight" (click)="closeDialog()">
                <button mat-raised-button color="warn">
                    {{ 'player.components.playerDialog.close' | translate }}
                </button>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="d-flex justify-content-center">
            <h2>Current State Point: {{ this.currentPoint.y }}</h2>
        </div>
        <div class="d-flex justify-content-center">
            <div class="container" #container></div>
        </div>
        <mat-divider></mat-divider>
        <div class="d-flex justify-content-around dots">
            <div *ngFor="let point of state.points">
                <div
                    class="dot"
                    *ngIf="currentPoint.x !== point.x"
                    (click)="onClickTimePoint(point)"
                >
                    {{ point.x + 1 }}
                </div>
                <div
                    class="dot red-dot"
                    *ngIf="currentPoint.x === point.x"
                    (click)="onClickTimePoint(point)"
                >
                    {{ point.x + 1 }}
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <button
                mat-fab
                aria-label="Example icon button with a bookmark icon"
                (click)="startOrPause()"
            >
                <mat-icon *ngIf="!running">play_arrow</mat-icon>
                <mat-icon *ngIf="running">pause</mat-icon>
            </button>
        </div>
    </div>
</div>
