<ul class="heroes">
    <li *ngFor="let urdf of urdfs">
        >
        <a
            [routerLink]="['/urdf']"
            [queryParams]="{ url: urdf, id: id }"
            (click)="onSubmit()"
            class="badge"
            >{{ urdf }}</a
        >
    </li>
</ul>
