import { Component, Input, OnInit } from '@angular/core';
import { CardPage } from 'src/app/card/model/card-page';
import { ImageDatabaseAddCard } from '../../../card/model/add-card-implementations/image-database-add-card';
import { AuthenticationService } from '../../../authentication/services/authentication.service';
import { ImgDbService } from 'src/app/img-db/services/img-db.service';
import { Card } from 'src/app/card/model/card-implementations/card';
import { ImgDbCard } from './image-database.card';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Container,
    ContainerPage,
} from '../../../shared/services/container/container.types';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-image-database-library',
    templateUrl: './image-database-library.component.html',
})
export class ImageDatabaseLibraryComponent implements OnInit {
    cardPage: CardPage = new CardPage();
    @Input() profilePicture: string;

    constructor(
        private authenticationService: AuthenticationService,
        private service: ImgDbService,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {
        let currentUser = this.authenticationService.isLoggedIn();
        if (currentUser) {
            this.cardPage.addCard = new ImageDatabaseAddCard(this.modalService);

            let fetchSub: Subscription = this.service
                .getMyImageDbs()
                .subscribe((containerPage: ContainerPage) => {
                    this.populate(containerPage.values);
                    fetchSub?.unsubscribe();
                });
        } else {
            console.error('There is an internal error.');
            this.authenticationService.signOut();
        }
    }

    populate(col: Container[]): void {
        col.forEach((container: Container) => {
            let card = this.convertToCard(container, this.profilePicture);
            this.cardPage.itemCards.push(card);
        });
    }

    convertToCard(container: Container, picture: string): Card {
        return ImgDbCard.convertToCard(container, picture);
    }
}
