import {
    ContentItem,
    ContentType,
    SideContainerData,
    ToggleMode,
} from './models/side-container-data';

const visualNavigation: SideContainerData = {
    contentItems: [
        {
            value: 'precision',
            translationKey: 'evaluation.SL.diagrams.precision.nav',
            checked: true,
        } as ContentItem,
        {
            value: 'recall',
            translationKey: 'evaluation.SL.diagrams.recall.nav',
            checked: false,
        } as ContentItem,
        {
            value: 'loss',
            translationKey: 'evaluation.SL.diagrams.loss.nav',
            checked: false,
        } as ContentItem,
        {
            value: 'precisionRecallCurve',
            translationKey: 'evaluation.SL.diagrams.precisionRecallCurve.nav',
            checked: false,
        } as ContentItem,
    ],
    contentType: ContentType.RADIO,
};

const visualLegendLoss: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'loss$val',
            translationKey: 'evaluation.SL.diagrams.loss.totalLoss.val',
            checked: true,
            tagForm: ['square'],
        } as ContentItem,
        {
            value: 'loss$train',
            translationKey: 'evaluation.SL.diagrams.loss.totalLoss.train',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'class_loss$val',
            translationKey:
                'evaluation.SL.diagrams.loss.classificationLoss.val',
            checked: true,
            tagForm: ['square'],
        } as ContentItem,
        {
            value: 'class_loss$train',
            translationKey:
                'evaluation.SL.diagrams.loss.classificationLoss.train',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
        {
            value: 'box_loss$val',
            translationKey: 'evaluation.SL.diagrams.loss.localizationLoss.val',
            checked: true,
            tagForm: ['square'],
        } as ContentItem,
        {
            value: 'box_loss$train',
            translationKey:
                'evaluation.SL.diagrams.loss.localizationLoss.train',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

function createContentItems(values: string[], metric: string): ContentItem[] {
    let contentItems: ContentItem[] = [];
    values.forEach((value) => {
        let prefix: string[] = value.split('$');
        contentItems.push({
            value: value,
            tagForm: [prefix[1] === 'val' ? 'square' : 'line'],
            checked: true,
            translationKey: `evaluation.SL.diagrams.${metric}.${prefix[0]}.${prefix[1]}`,
        });
    });
    return contentItems;
}
const visualLegendPrecision: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: createContentItems(
        [
            'AP$val',
            'AP&IOU=0.5&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
            'AP&IOU=0.75&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
            'AP&IOU=0.95&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
        ],
        'precision'
    ),
    contentType: ContentType.CHECKBOX,
};

const visualLegendRecall: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: createContentItems(
        [
            'ARmax100$val',
            'AR&IOU=0.5&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
            'AR&IOU=0.75&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
            'AR&IOU=0.95&confidence=0.5&category=mean&area_range=all&max_detections=100$val',
        ],
        'recall'
    ),
    contentType: ContentType.CHECKBOX,
};

const precisionRecallCurveLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: createContentItems(
        [
            'AP&IOU=0.5&recall=[0:1.0:0.01]&category=mean&area_range=all&max_detections=100$val',
            'AP&IOU=0.75&recall=[0:1.0:0.01]&category=mean&area_range=all&max_detections=100$val',
            'AP&IOU=0.95&recall=[0:1.0:0.01]&category=mean&area_range=all&max_detections=100$val',
        ],
        'precisionRecallCurve'
    ),
    contentType: ContentType.CHECKBOX,
};

const visualLabelMarkers: SideContainerData = {
    contentItems: [
        {
            value: 'preTrain',
            translationKey: 'evaluation.SL.media.markers.preTrain.label',
            tagForm: ['square', 'border-square'],
            infoText: 'evaluation.SL.media.markers.preTrain.info',
        },
        {
            value: 'postTrain',
            translationKey: 'evaluation.SL.media.markers.postTrain.label',
            tagForm: ['square'],
            infoText: 'evaluation.SL.media.markers.postTrain.info',
        },
    ],
    contentType: ContentType.NON_INPUT,
};

const slDataObj: SideContainerStates = {
    visualNavigation: visualNavigation,
    precision: visualLegendPrecision,
    recall: visualLegendRecall,
    loss: visualLegendLoss,
    categories: {} as SideContainerData,
    labelMarkers: visualLabelMarkers,
    precisionRecallCurve: precisionRecallCurveLegend,
};

const viewingOptions: SideContainerData = {
    contentItems: [
        {
            value: 'modelUpdates',
            translationKey: 'evaluation.RL.diagrams.allModelUpdates',
            checked: true,
        } as ContentItem,
        {
            value: 'episodes',
            translationKey: 'evaluation.RL.diagrams.allEpisodes',
            checked: false,
        } as ContentItem,
        // TODO: comment out when reward per iteration functionality is there
        // {
        //     value: 'singleIteration',
        //     translationKey: 'evaluation.RL.diagrams.singleIteration',
        //     checked: false,
        // } as ContentItem,
    ],
    contentType: ContentType.RADIO,
};
const motoricEpochLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'sum$train',
            translationKey: 'evaluation.RL.diagrams.sumEpisodes',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

const motoricModelUpdateLegend: SideContainerData = {
    // value must be: column name (in log file)+ $ + (train|val)
    // otherwise plotting won't work (mapping from metric to logged data)
    contentItems: [
        {
            value: 'sum$train',
            translationKey: 'evaluation.RL.diagrams.sumModelUpdates',
            checked: true,
            tagForm: ['line'],
        } as ContentItem,
    ],
    contentType: ContentType.CHECKBOX,
};

const rlDataObj: SideContainerStates = {
    '': {
        contentItems: [],
        contentType: ContentType.NON_INPUT,
    } as SideContainerData,
    viewingOptions: viewingOptions,
    modelUpdates: motoricModelUpdateLegend,
    episodes: motoricEpochLegend,
};

export const sideContainerDataMapping: { [key: string]: EvaluationState } = {
    SL: {
        media: {
            left: 'categories',
            right: 'labelMarkers',
            imageIndex: 0,
        },
        diagrams: {
            left: 'visualNavigation',
            right: 'precision',
        },
        data: slDataObj,
    },
    RL: {
        media: {
            left: '',
            right: '',
        },
        diagrams: {
            left: 'viewingOptions',
            right: 'modelUpdates',
        },
        data: rlDataObj,
    },
};

export interface EvaluationState {
    diagrams: { left: string; right: string };
    media: { left: string; right: string; imageIndex?: number };
    data: SideContainerStates;
}

export interface SideContainerStates {
    [key: string]: SideContainerData;
}

export interface SavedEvaluationState {
    toggleMode: ToggleMode;
    diagrams: { left: string; right: string };
    media: { left: string; right: string; imageIndex?: number };
    data: SavedSideContainerStates;
}

export interface SavedSideContainerStates {
    [key: string]: boolean[];
}
