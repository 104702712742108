import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PlayerDialogComponent } from '../player-dialog/player-dialog.component';
import { ChartItem } from 'src/app/shared/components/charts/models/chart-item';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MessageService } from '../../../message/services/message.service';

@Component({
    selector: 'app-player-select',
    templateUrl: './player-select.component.html',
    styleUrls: ['./player-select.component.scss'],
})
export class PlayerSelectComponent {
    @Input()
    states: ChartItem[];
    stateForm = new UntypedFormGroup({
        state: new UntypedFormControl('', Validators.required),
    });
    constructor(
        private dialog: MatDialog,
        private messageService: MessageService
    ) {}

    startDialog() {
        if (this.stateForm.valid) {
            this.dialog.open(PlayerDialogComponent, {
                data: {
                    state: this.states.find(
                        (a) => (a.id = this.stateForm.value.state)
                    ),
                },
            });
        } else {
            this.messageService.displayErrorMessage(
                'Bitte wählen Sie eine Option'
            );
        }
    }
}
