export interface ExpertDatasetDTO {
    projectId: string;
    datasetName: string;
    uploadedFileNames: string[];
}

export enum ExpertDatasetUsageType {
    NO_EXPERT_DATA = 'NO_EXPERT_DATA',
    COMBINED = 'COMBINED',
    ONLY_EXPERT_DATA = 'ONLY_EXPERT_DATA',
}

export interface ExpertDatasetSelectionDTO {
    expertDatasetDto: ExpertDatasetDTO;
    expertDatasetUsageType: ExpertDatasetUsageType;
    availableOptions: string[];
}
