import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Output,
    ViewChild,
    EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NumberTranslateService } from 'src/app/shared/services/number-translate.service';
import { CostFunctionService } from '../../services/cost-function.service';

@Component({
    selector: 'app-cost-function-input-group',
    templateUrl: './cost-function-input-group.component.html',
    styleUrls: ['./cost-function-input-group.component.scss'],
})
export class CostFunctionInputGroupComponent implements OnInit {
    @Input() hasCheckbox: boolean = true;
    @Input() valueControl: UntypedFormControl;
    @Input() disabled: boolean = true;
    @Input() statusControl: UntypedFormControl;
    @Input() decimalPoint: number;
    @Input() minValue: number;
    @Input() maxValue: number;
    @Input() convertNegativeValue: boolean = false;
    @Input() showDisabledValue: boolean = false;
    @Input() hasSteppers: boolean = false;

    @Output() startedEditting: EventEmitter<any> = new EventEmitter();
    @Output() finishedEditting: EventEmitter<any> = new EventEmitter();
    @Output() statusChanged: EventEmitter<any> = new EventEmitter();
    @Output() valueChanged: EventEmitter<any> = new EventEmitter();

    @ViewChild('textInput') textInput: ElementRef = null;

    private lastValidValue: string = '';
    private step = 0.01;

    public forceFocus: boolean = false;

    isCostFunctionInvalid: boolean = false;

    constructor(
        private translate: TranslateService,
        private numberService: NumberTranslateService,
        private costFunctionService: CostFunctionService
    ) {
        this.translate.onLangChange.subscribe((lang) => {
            this.formatAndUpdateValue(false);
        });
        this.costFunctionService.isInvalid$.subscribe((nextValue: boolean) => {
            this.isCostFunctionInvalid = nextValue;
        });
    }

    ngOnInit(): void {
        if (this.valueControl) {
            this.lastValidValue = this.valueControl.value;
        }
        if (this.disabled) {
            this.valueControl.disable();
        }
    }

    // Toggle checkbox and update form controls accordingly
    toggleCheckboxAndInput() {
        this.disabled = !this.disabled;
        this.statusControl.setValue(!this.disabled);
        if (this.disabled) {
            this.valueControl.setValue(this.lastValidValue);
            this.valueControl.disable();
        } else {
            this.valueControl.enable();
        }
        this.statusChanged.emit();
    }

    /**
     * On value change, convert the new value to floating point number,
     * replace . with , and update form control
     */
    formatAndUpdateValue(blurInput: boolean) {
        const val = this.valueControl.value.replace(',', '.');
        if (val === '') {
            this.valueControl.setValue('');
            this.lastValidValue = this.valueControl.value;
            this.valueChanged.emit();
            if (blurInput) {
                this.textInput.nativeElement.blur();
            }
        } else {
            let numberVal = Number(val);
            if (!isNaN(numberVal)) {
                this.setFormControlValue(numberVal);
                if (!this.valueControl.invalid) {
                    if (this.convertNegativeValue && numberVal < 0) {
                        this.setFormControlValue(this.maxValue + numberVal);
                    }
                    this.lastValidValue = this.valueControl.value;
                    this.valueChanged.emit();
                    if (blurInput) {
                        this.textInput.nativeElement.blur();
                    }
                }
            }
        }
    }

    setFormControlValue(numberVal: Number) {
        this.valueControl.setValue(
            this.numberService.translateNumber(
                numberVal.toFixed(this.decimalPoint).replace('.', ',')
            )
        );
    }

    limitDecimalPlaces() {
        this.numberService.limitDecimalPlaces(
            this.valueControl,
            this.decimalPoint
        );
        this.costFunctionService.isInvalid$.next(this.valueControl.invalid);
    }

    // Keep focus on the input field if the value is invalid
    keepFocusOnInvalidInput(event: FocusEvent) {
        const input = this.textInput.nativeElement;
        if (!!this.valueControl.errors) {
            event.preventDefault();
            input.focus();
        } else {
            this.finishedEditting.emit('');
        }
    }

    inputFocused() {
        this.startedEditting.emit('');
    }

    forceInputBlur() {
        this.forceFocus = false;
    }

    changeValueWithStepper(operation: string) {
        const value = parseFloat(this.valueControl.value);
        const exceedsLowerLimit = value - this.step < this.minValue;
        const exceedsUpperLimit = value + this.step > this.maxValue;
        const exceedsLimit =
            operation === '+' ? exceedsUpperLimit : exceedsLowerLimit;
        if (!this.disabled && !this.isCostFunctionInvalid && !exceedsLimit) {
            this.forceFocus = true;
            this.textInput.nativeElement.focus();
            let strValue = this.valueControl.value.toString();
            strValue = strValue.replace(',', '.');
            if (!strValue) {
                strValue = '0';
            }
            const numberValue = parseFloat(strValue);
            const newValue =
                operation === '+'
                    ? numberValue + this.step
                    : numberValue - this.step;
            const roundedNewValue = parseFloat(newValue.toFixed(4));
            let newValueString = this.numberService.translateNumber(
                roundedNewValue.toString()
            );
            this.valueControl.setValue(newValueString);
            this.valueControl.markAsDirty();
            this.valueControl.updateValueAndValidity();
            if (
                this.valueControl.valid &&
                this.textInput.nativeElement.value !== this.lastValidValue
            ) {
                this.formatAndUpdateValue(false);
            }
        }
    }

    /**
     * Error messages for the input field
     *
     * @param control {FormControl}
     * @returns
     */
    getErrorMessage(control: UntypedFormControl) {
        if (control.hasError('required')) {
            return this.translate.instant(
                'costFunction.costFunctionEditor.errors.numberRequired'
            );
        }
        if (control.hasError('invalidFormat')) {
            return this.translate.instant(
                'costFunction.costFunctionEditor.errors.numberInvalid'
            );
        }
        return control.hasError('invalidRange')
            ? this.translate.instant(
                  'costFunction.costFunctionEditor.errors.numberRange',
                  { min: this.minValue, max: this.maxValue }
              )
            : '';
    }
}
