import { Observable } from 'rxjs';
import { Card, CardFooter, CardOptions } from './card';
import { Container } from 'src/app/shared/services/container/container.types';

export class ProjectCard extends Card {
    readonly navigationUrl = 'project';
    readonly cardTypeTranslationKey = 'card.type.project';
    readonly options = new CardOptions(false, false, true);

    constructor(
        itemId: string,
        userId: string,
        userName: string,
        head: CardFooter,
        pictures: string[],
        container: Container
    ) {
        super(itemId, userId, userName, head, pictures, container);
    }

    public deleteCard(): Observable<unknown> {
        return this.deleteCardService.deleteProject(this.itemId);
    }

    public onCardPictureClick(): void {
        this.router.navigate([this.navigationUrl, this.itemId]);
    }

    static mapContainerToProjectCard(
        project: Container,
        userId: string,
        userDisplayName: string,
        picture: string
    ): ProjectCard {
        //TODO: check what pictures are expected here
        const pics = !!project.pictureLocation
            ? new Array(project.pictureLocation)
            : null;
        return new ProjectCard(
            project.id,
            userId,
            userDisplayName,
            new CardFooter(project.name, picture),
            pics,
            project
        );
    }
}
