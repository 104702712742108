import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CmsPageComponent } from './components/cms-page/cms-page.component';
import { CmsSidebarComponent } from './components/section/cms-sidebar/cms-sidebar.component';

import { CmsCategoryNavigationComponent } from './components/block/cms-category-navigation/cms-category-navigation.component';
import { CmsCenterTextComponent } from './components/block/cms-center-text/cms-center-text.component';
import { CmsTextTwoColumnComponent } from './components/block/cms-text-two-column/cms-text-two-column.component';
import { CmsTextComponent } from './components/block/cms-text/cms-text.component';

import { CmsBlockDirective } from './directive/cms-block.directive';
import { CmsElementDirective } from './directive/cms-element.directive';
import { CmsSnippetBackgroundImageComponent } from './components/block/cms-snippet-background-image/cms-snippet-background-image.component';

@NgModule({
    declarations: [
        CmsPageComponent,
        CmsSidebarComponent,
        CmsCategoryNavigationComponent,
        CmsCenterTextComponent,
        CmsTextTwoColumnComponent,
        CmsTextComponent,
        CmsBlockDirective,
        CmsElementDirective,
        CmsSnippetBackgroundImageComponent,
    ],
    imports: [CommonModule],
})
export class ShopwareCmsModule {}
