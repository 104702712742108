<div class="layer-context">
    <h4 class="layer-context__title">
        {{ selectedLayer.class_name }}
    </h4>

    <div class="scrollable-container">
        <form [formGroup]="layerContext">
            <div
                class="layer-context__input-wrapper"
                *ngFor="
                    let propertyToInputTypeMap of propertiesToInputTypesMap;
                    let i = index
                "
            >
                <div
                    *ngIf="
                        propertyToInputTypeMap[1] === InputFieldTypeEnum.TEXT
                    "
                    class="
                        tryb-input-container
                        d-flex
                        justify-content-between
                        align-items-center
                    "
                >
                    <label class="input-label mb-0" [for]="'input-label-' + i">
                        {{
                            'skillArchitectureEditor.fields.' +
                                propertyToInputTypeMap[0] | translate
                        }}
                    </label>
                    <input
                        [id]="'input-label-' + i"
                        type="text"
                        class="text-start ps-2"
                        [formControlName]="propertyToInputTypeMap[0]"
                        [attr.aria-describedby]="'input-label-' + i"
                        (keydown.enter)="$event.target.blur()"
                    />
                </div>

                <div
                    *ngIf="
                        propertyToInputTypeMap[1] === InputFieldTypeEnum.NUMBER
                    "
                    class="tryb-input-container"
                >
                    <div class="input-label" [attr.id]="'input-label-' + i">
                        {{
                            'skillArchitectureEditor.fields.' +
                                propertyToInputTypeMap[0] | translate
                        }}
                    </div>
                    <div class="d-flex">
                        <div class="input-wrapper">
                            <input
                                type="number"
                                class="has-steppers"
                                [formControlName]="propertyToInputTypeMap[0]"
                                [attr.aria-describedby]="'input-label-' + i"
                                (keydown.enter)="$event.target.blur()"
                            />
                            <div class="steppers position-absolute">
                                <div
                                    class="stepper up"
                                    (click)="
                                        changeValueWithStepper(
                                            '+',
                                            propertyToInputTypeMap[0]
                                        )
                                    "
                                >
                                    <img
                                        class="info-icon d-block"
                                        src="assets/svg/algorithm/stepper-arrow.png"
                                        alt="upward arrow"
                                    />
                                </div>
                                <div
                                    class="stepper down"
                                    (click)="
                                        changeValueWithStepper(
                                            '-',
                                            propertyToInputTypeMap[0]
                                        )
                                    "
                                >
                                    <img
                                        class="info-icon d-block"
                                        src="assets/svg/algorithm/stepper-arrow.png"
                                        alt="downward arrow"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    *ngIf="
                        propertyToInputTypeMap[1] ===
                        InputFieldTypeEnum.SELECTBOX
                    "
                    class="d-flex flex-column"
                >
                    <div class="select-label" [attr.id]="'input-label-' + i">
                        {{
                            'skillArchitectureEditor.fields.' +
                                propertyToInputTypeMap[0] | translate
                        }}
                    </div>
                    <div
                        class="tryb-dropdown"
                        [ngClass]="{
                            disabled: layerContext.get(
                                propertyToInputTypeMap[0]
                            ).disabled
                        }"
                    >
                        <select
                            [formControlName]="propertyToInputTypeMap[0]"
                            [attr.aria-describedby]="'input-label-' + i"
                            (keydown.enter)="$event.target.blur()"
                        >
                            <option
                                *ngFor="
                                    let data of selectBoxValues[
                                        propertyToInputTypeMap[0]
                                    ]
                                "
                                value="{{ data }}"
                            >
                                {{ data }}
                            </option>
                        </select>
                    </div>
                </div>

                <div
                    *ngIf="
                        propertyToInputTypeMap[1] ===
                        InputFieldTypeEnum.CHECKBOX
                    "
                >
                    <div
                        class="
                            tryb-input-container
                            checkbox-group
                            d-flex
                            align-items-center
                        "
                    >
                        <label class="tryb-checkbox">
                            <span class="input-label">{{
                                'skillArchitectureEditor.fields.' +
                                    propertyToInputTypeMap[0] | translate
                            }}</span>
                            <input
                                [formControlName]="propertyToInputTypeMap[0]"
                                type="checkbox"
                                [attr.id]="'checkbox-' + i"
                                [(checked)]="
                                    this.selectedLayer.config[
                                        propertyToInputTypeMap[0]
                                    ]
                                "
                            />
                            <span
                                class="checkmark"
                                [class.disabled]="editingIsDisabled"
                            >
                            </span>
                        </label>
                    </div>
                </div>

                <div
                    *ngIf="
                        propertyToInputTypeMap[1] ===
                        InputFieldTypeEnum.INITIALIZER
                    "
                    class="tryb-input-container"
                >
                    <div class="select-label" [attr.id]="'initializer' + i">
                        {{
                            'skillArchitectureEditor.fields.' +
                                propertyToInputTypeMap[0] | translate
                        }}
                    </div>
                    <div
                        class="tryb-dropdown"
                        [ngClass]="{
                            disabled: layerContext.get(
                                propertyToInputTypeMap[0]
                            ).disabled
                        }"
                    >
                        <select
                            [formControlName]="propertyToInputTypeMap[0]"
                            [attr.aria-describedby]="'initializer' + i"
                            (keydown.enter)="$event.target.blur()"
                        >
                            <option
                                *ngFor="let data of selectBoxValues.initializer"
                                value="{{ data }}"
                            >
                                {{ data }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <button
                *ngIf="
                    !skillArchitectureStateService.isSelectedLayerInputOrOutput
                        .value
                "
                class="mt-3 btn btn-tryb-gray delete-btn"
                (click)="deleteSelectedLayer()"
            >
                Löschen
            </button>
        </form>
    </div>
</div>
