import { Component, OnInit } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ThemePalette } from '@angular/material/core';
import { GlobalConfigService } from '../../services/global-config.service';
import { GlobalConfig } from '../../models/global-config';

@Component({
    selector: 'app-global-config',
    templateUrl: './global-config.component.html',
    styleUrls: ['./global-config.component.scss'],
})
export class GlobalConfigComponent implements OnInit {
    globalConfig: GlobalConfig;
    colorTrainingActive: ThemePalette = 'primary';
    disabledTrainingActive = false;
    checkedTrainingActive: boolean;
    titleTrainingActive: string;

    colorCloudActive: ThemePalette = 'primary';
    disabledCloudActive = false;
    checkedCloudActive: boolean;
    titleCloudActive: string;

    constructor(private globalConfigService: GlobalConfigService) {}

    ngOnInit(): void {
        this.globalConfigService.getConfig().subscribe({
            next: (data) => {
                this.globalConfig = data;
                this.checkedTrainingActive = this.globalConfig.trainingActive;
                if (this.checkedTrainingActive) this.setTrainingActive();
                else this.setTrainingInactive();
                this.checkedCloudActive = this.globalConfig.trainingInCloud;
                if (this.checkedCloudActive) this.setCloudActive();
                else this.setCloudInactive();
            },
            error: (error) => {
                console.log('error' + error);
            },
        });
    }

    onChangeTrainingActive($event: MatSlideToggleChange) {
        this.globalConfig.trainingActive = $event.checked;
        if ($event.checked) this.setTrainingActive();
        else this.setTrainingInactive();
        this.globalConfigService
            .updateConfig(this.globalConfig)
            .subscribe((g) => (this.globalConfig = g));
    }

    onChangeCloudActive($event: MatSlideToggleChange) {
        this.globalConfig.trainingInCloud = $event.checked;
        if ($event.checked) this.setCloudActive();
        else this.setCloudInactive();
        this.globalConfigService
            .updateConfig(this.globalConfig)
            .subscribe((g) => (this.globalConfig = g));
    }

    setTrainingActive() {
        this.titleTrainingActive =
            'admin.components.globalConfig.trainingActive';
        this.disabledCloudActive = false;
    }

    setTrainingInactive() {
        this.titleTrainingActive =
            'admin.components.globalConfig.trainingInactive';
        this.disabledCloudActive = true;
    }

    setCloudActive() {
        this.titleCloudActive = 'admin.components.globalConfig.cloudActive';
    }

    setCloudInactive() {
        this.titleCloudActive = 'admin.components.globalConfig.cloudInactive';
    }
}
