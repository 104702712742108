import { map, take } from 'rxjs';
import { inject } from '@angular/core';
import { Router, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { LoginRedirectionService } from './login-redirection.service';
import { AuthenticationService } from './authentication.service';

export const loginGuard: CanActivateFn = (_, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService, { host: true });
    const loginRedirectionService = inject(LoginRedirectionService);

    const notLoginInitialized = !authenticationService.currentUserValue;
    if (notLoginInitialized) {
        return authenticationService.initializationFinished$.pipe(
            take(1),
            map(() => {
                return handleIsLoggedInCheck();
            })
        );
    }
    return handleIsLoggedInCheck();

    function handleIsLoggedInCheck() {
        if (authenticationService.isLoggedIn()) {
            return true;
        } else {
            loginRedirectionService.setRedirectionURL(state.url);
            loginRedirectionService.loginTriggered$.next(true);
            router.navigate(['/home']);
            return false;
        }
    }
};
