import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectWizardComponent } from 'src/app/project/component/project-wizard/project-wizard.component';
import { AddCard } from './add-card';

export class ProjectAddCard extends AddCard {
    private _modalService: NgbModal;

    public constructor(modalService: NgbModal) {
        super(
            'card.model.addCardImplementations.projectAddCard.startNewProject'
        );
        this._modalService = modalService;
    }

    public createNewModel(): void {
        this._modalService.open(ProjectWizardComponent, {
            size: 'lg',
            modalDialogClass: 'add-project-wizard-modal',
        });
    }
}
