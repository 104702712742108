import { Component, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddZipObjectComponent } from '../add-zip-object/add-zip-object.component';
import { AddGitObjectComponent } from '../add-git-object/add-git-object.component';
import { UploadFileType } from '../../../object3-d/models/upload-file-type';

@Component({
    selector: 'app-upload-type-dialog',
    templateUrl: './upload-type-dialog.component.html',
    styleUrls: ['./upload-type-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UploadTypeDialogComponent {
    constructor(private modalService: NgbModal) {}

    OpenDialog(value: string) {
        this.modalService.dismissAll();
        if (value === 'zip') {
            const modalref = this.modalService.open(AddZipObjectComponent, {
                windowClass: 'add-scene-dialog',
            });
            modalref.componentInstance.uploadFileType = UploadFileType.ROBOT;
        } else if (value === 'git') {
            this.modalService.open(AddGitObjectComponent, {
                windowClass: 'add-scene-dialog',
            });
        }
    }
}
