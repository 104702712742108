import {
    composeInitializer,
    WithGain,
    WithMean,
    WithSeed,
    WithStddev,
} from './tf-properties';
import { TfVarMode, TfDistribution } from './tf-types';

export class Constant {
    value = 0.0;
}

export class VarianceScaling extends composeInitializer([WithSeed]) {
    scale = 1.0;
    mode: TfVarMode = 'fan_in';
    distribution: TfDistribution = 'truncated_normal';
}

export class GlorotUniform extends VarianceScaling {}
export class GlorotNormal extends VarianceScaling {}

export class HeUniform extends VarianceScaling {}
export class HeNormal extends VarianceScaling {}

export class LecunUniform extends VarianceScaling {}
export class LecunNormal extends VarianceScaling {}

export class Identity extends composeInitializer([WithGain]) {}

export class Ones {}
export class Zeros {}

export class Orthogonal extends composeInitializer([WithGain, WithSeed]) {}

export class RandomUniform {
    minval = -0.05;
    maxval = 0.05;
}

export class RandomNormal extends composeInitializer([
    WithMean,
    WithSeed,
    WithStddev,
]) {}

export class TruncatedNormal extends composeInitializer([
    WithMean,
    WithSeed,
    WithStddev,
]) {}

export enum InitializerType {
    CONSTANT = 'Constant',
    GLOROTNORMAL = 'GlorotNormal',
    GLOROTUNIFORM = 'GlorotUniform',
    HENORMAL = 'HeNormal',
    HEUNIFORM = 'HeUniform',
    IDENTITY = 'Identity',
    LECUNNORMAL = 'LecunNormal',
    LECUNUNIFORM = 'LecunUniform',
    ONES = 'Ones',
    ORTHOGONAL = 'Orthogonal',
    RANDOMNORMAL = 'RandomNormal',
    RANDOMUNIFORM = 'RandomUniform',
    TRUNCATEDNORMAL = 'TruncatedNormal',
    VARIANCESCALING = 'VarianceScaling',
    ZEROS = 'Zeros',
}

export type InitializerUnion =
    | Constant
    | GlorotNormal
    | GlorotUniform
    | HeNormal
    | HeUniform
    | Identity
    | LecunNormal
    | LecunUniform
    | Ones
    | Orthogonal
    | RandomNormal
    | RandomUniform
    | TruncatedNormal
    | VarianceScaling
    | Zeros;

export class Initializer {
    class_name: InitializerType;
    config: InitializerUnion;
}
