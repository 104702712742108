import { Component, OnInit } from '@angular/core';
import { CmsBlockComponent } from '../cms-block.component';
import { CmsSlot } from '../../../models/cms-slot';
import { CmsElementService } from '../../../services/cms-element.service';
import { SafeHtml } from '@angular/platform-browser';
import { CmsAbstractBlockComponent } from '../cms-abstract-block.component';

@Component({
    selector: 'app-cms-center-text',
    templateUrl: './cms-center-text.component.html',
})
export class CmsCenterTextComponent
    extends CmsAbstractBlockComponent
    implements OnInit, CmsBlockComponent
{
    left: CmsSlot;
    center: CmsSlot;
    right: CmsSlot;

    leftContent: SafeHtml;
    centerContent: SafeHtml;
    rightContent: SafeHtml;

    constructor(private cmsElementService: CmsElementService) {
        super();
    }

    ngOnInit(): void {
        this.initBlock();
        this.loadComponent();
    }

    loadComponent() {
        this.left = this.slots.find((slot) => slot.slot === 'left');
        this.center = this.slots.find((slot) => slot.slot === 'center');
        this.right = this.slots.find((slot) => slot.slot === 'right');

        this.leftContent = this.cmsElementService.renderContent(this.left);
        this.centerContent = this.cmsElementService.renderContent(this.center);
        this.rightContent = this.cmsElementService.renderContent(this.right);
    }
}
