<div class="container-fluid p-0 d-none d-sm-block">
    <div class="col-12 p-0">
        <div class="slide-text container">
            <div class="tryb-intro">
                <div class="introduction-text-title">
                    <span class="text-style-1"> TRYB </span>
                    {{
                        'navigation.landingPage.introductionTextTitle'
                            | translate
                    }}
                </div>
                <div>
                    <div class="introduction-text-subtitle">
                        <span class="text-style-2">
                            {{
                                'navigation.landingPage.introductionTextSubtitle1'
                                    | translate
                            }}
                        </span>
                    </div>
                    <div>
                        <span class="text-style-3">
                            {{
                                'navigation.landingPage.introductionTextSubtitle2'
                                    | translate
                            }}
                        </span>
                        <br />
                        <button
                            [routerLink]="['/signup']"
                            class="btn btn-tryb-blue"
                        >
                            <div class="start-now">
                                {{
                                    'navigation.landingPage.introductionTextButton'
                                        | translate
                                }}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <picture>
            <img
                src="assets/img/landing-page/startpage_img.png"
                alt="start page image"
                class="img-fluid"
            />
        </picture>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="what-is-tryb-for-title">
                <div class="tryb-dots">
                    <span class="dot first-circle"></span>
                    <span class="dot second-circle"></span>
                    <span class="dot third-circle"></span>
                </div>
                <p class="text-uppercase">
                    <span class="what">what</span>
                    <br />
                    <span class="is-tryb-for text-uppercase">is tryb for</span>
                </p>
            </div>
        </div>
    </div>
</div>

<div class="what-is-tryb-for">
    <div class="container">
        <div class="row tryb-points">
            <div class="col-12 col-lg-4 tryb-point">
                <img
                    class="space-left img-fluid"
                    src="assets/img/landing-page/what-is-tryb-for-img1.png"
                    alt=""
                />
                <p class="what-is-tryb-text spacer10">
                    {{ 'navigation.landingPage.whatIsTrybFor1' | translate }}
                </p>
            </div>
            <div class="col-12 col-lg-4 tryb-point">
                <img
                    class="space-left img-fluid"
                    src="assets/img/landing-page/what-is-tryb-for-img2.png"
                    alt=""
                />
                <p class="what-is-tryb-text spacer10">
                    {{ 'navigation.landingPage.whatIsTrybFor2' | translate }}
                </p>
            </div>
            <div class="col-12 col-lg-4 tryb-point">
                <img
                    class="space-left img-fluid"
                    src="assets/img/landing-page/what-is-tryb-for-img3.png"
                    alt=""
                />
                <p class="what-is-tryb-text spacer10">
                    {{ 'navigation.landingPage.whatIsTrybFor3' | translate }}
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="float-end">
                <div class="how-it-works-title">
                    <div class="tryb-dots">
                        <span class="first-circle"></span>
                        <span class="second-circle"></span>
                        <span class="third-circle"></span>
                    </div>
                    <p class="how text-uppercase">how</p>
                    <p class="it-works text-uppercase">it works</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container mb-5">
    <div class="row">
        <div class="col-12 col-lg-8 col-xl-9 mb-3 mb-lg-0">
            <div class="explanation-video-img">
                <img
                    src="assets/img/landing-page/video-how-it-works.png"
                    class="explanation-video img-fluid"
                    alt=""
                />
            </div>
        </div>

        <div class="col-12 col-xl-3 col-lg-4 right-hand-side">
            <div class="side-text-wrapper">
                <p class="side-text">
                    {{ 'navigation.landingPage.howItWorksTxt1' | translate }}
                    <br /><br />
                    {{ 'navigation.landingPage.howItWorksTxt2' | translate }}
                    <br /><br />
                    {{ 'navigation.landingPage.howItWorksTxt3' | translate }}
                    <br />
                    {{ 'navigation.landingPage.howItWorksTxt4' | translate }}

                    <a routerLink="/signup">
                        {{
                            'navigation.landingPage.howItWorksSignUp'
                                | translate
                        }}
                    </a>
                    {{ 'navigation.landingPage.howItWorksTxt5' | translate }}
                </p>
                <button class="btn btn-tryb-blue">
                    <div class="start-now">
                        {{
                            'navigation.landingPage.howItWorksSignUpButton'
                                | translate
                        }}
                    </div>
                </button>
            </div>
        </div>
    </div>

    <div class="info-banner d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center">
            <h1 class="banner-header">
                Wir beantworten gerne noch mehr Fragen!
            </h1>
            <button class="btn btn-outline-light banner-button">
                Zu den FAQs
            </button>
        </div>
    </div>

    <div
        class="home-section d-flex align-items-center justify-content-center mx-0"
    >
        <div>
            <img
                src="assets/img/landing-page/videopic.png"
                srcset="
                    assets/img/landing-page/videopic@2x.png 2x,
                    assets/img/landing-page/videopic@3x.png 3x
                "
                class="video img-fluid"
                alt="pic of robot hand"
            />
        </div>
        <p class="video-paragraph">
            Erlebe unsere hauseigene <br />
            Roboterhand <strong class="video-text-gradient">BEX</strong> in
            Aktion!
            <br />
            Auch sie wurde mit <strong>TRYB</strong> trainiert.
        </p>
    </div>

    <div class="home-section bg-darker">
        <h1 class="section-header">Marketplace Bestseller</h1>
        <div class="row justify-content-center">
            <app-slider-item
                *ngFor="let item of bestsellers"
                [item]="item"
            ></app-slider-item>
        </div>
    </div>

    <div class="d-flex flex-row home-section mx-0 justify-content-center">
        <div id="quick-navigation-description">
            Jetzt starten und TRYB kennenlernen!<br />
            KI war noch nie so einfach.
        </div>
        <div class="quick-navigation">
            <div class="quick-navigation-item">
                <span class="btn-move-right me-3">
                    <mat-icon>arrow_forward</mat-icon>
                </span>
                <span>Roboter konfigurieren</span>
            </div>
            <div class="quick-navigation-item">
                <a href="scenes/add">
                    <span class="btn-move-right me-3">
                        <mat-icon>arrow_forward</mat-icon>
                    </span>
                    <span>Szene erstellen</span>
                </a>
            </div>
            <div class="quick-navigation-item">
                <a href="/project/add">
                    <span class="btn-move-right me-3">
                        <mat-icon>arrow_forward</mat-icon>
                    </span>
                    <span>Projekt starten</span>
                </a>
            </div>
            <div class="quick-navigation-item">
                <span class="btn-move-right me-3">
                    <mat-icon>arrow_forward</mat-icon>
                </span>
                <span>Marketplace durchsuchen</span>
            </div>
        </div>
    </div>

    <div class="info-banner d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center">
            <em class="bi bi-envelope-fill"></em>
            <h1 class="banner-header">
                Immer informiert mit dem TRYB-Newsletter
            </h1>
            <input
                type="text"
                class="input-group-text"
                placeholder="E-Mail-Adresse"
            />
            <button type="button" class="btn btn-outline-light banner-button">
                Anmelden
            </button>
        </div>
    </div>

    <div class="review-section">
        <h1 class="section-header">Das sagen TRYB-Anwender</h1>
        <div class="mt-5">
            <app-user-review [userReview]="userReviews[0]"></app-user-review>
        </div>
    </div>

    <div class="home-section bg-darker short-links-section">
        <h1 class="row short-links-header justify-content-center">
            DAS WICHTIGSTE IN KÜRZE
        </h1>
        <div class="row short-links justify-content-center">
            <div class="col-4">
                <p>
                    <em class="bi bi-caret-right"></em>Was kann ich hier machen?
                </p>
                <p>
                    <em class="bi bi-caret-right"></em>Wo erstelle ich ein Objekt?
                </p>
                <p>
                    <em class="bi bi-caret-right"></em>Wie erstelle ich eine
                    Szene?
                </p>
                <p>
                    <em class="bi bi-caret-right"></em>Wie erstelle ich ein
                    Training?
                </p>
                <p><em class="bi bi-caret-right"></em>Was ist ein Skill?</p>
            </div>
        </div>
    </div>

    <div class="home-section text-section">
        <div class="row seo-paragraph-section">
            <p class="col">
                Hier steht noch ein sinnvoller Text für alle Suchmaschinen
                dieser Welt. Minions ipsum belloo! Bananaaaa uuuhhh aaaaaah
                bappleees tulaliloo aaaaaah hana dul sae jeje uuuhhh. Ti aamoo!
                poopayee jiji para tú tatata bala tu para tú tulaliloo
                tulaliloo. Belloo! po kass chasy hahaha. Ti aamoo! poopayee tank
                yuuu!
                <br />
                <br />
                Para tú poulet tikka masala bananaaaa tank yuuu! Hahaha poulet
                tikka masala. Jiji gelatooo hana dul sae daa hana dul sae pepete
                aaaaaah jiji tatata bala tu ti aamoo! Daa underweaaar po kass
                gelatooo chasy jiji hana dul sae aaaaaah po kass.
            </p>
            <p class="col ms-2 pe-0">
                Minions ipsum quis sed po kass labore et nisi tulaliloo hahaha
                bananaaaa irure. Pepete occaecat dolor wiiiii underweaaar
                bappleees cillum po kass. Aute chasy adipisicing jeje wiiiii
                poulet tikka masala et dolore underweaaar belloo!
                <br />
                <br />
                Incididunt aliquip bananaaaa uuuhhh irure uuuhhh consectetur me
                want bananaaa! Occaecat voluptate ut tank yuuu! Voluptate tatata
                bala tu jeje butt tatata bala tu laboris enim sed daa bappleees
                quis.
            </p>
        </div>
    </div>
</div>
