import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { handleError } from 'src/app/shared/http-utilities';
import { Pageable } from 'src/app/shared/models/pageable';
import { UserInfoMember } from 'src/app/shared/models/user-info';
import { ContainerService } from 'src/app/shared/services/container/container.service';
import {
    Container,
    ContainerCreateRequest,
    ContainerCreateResponse,
    ContainerPage,
    ContainerType,
} from 'src/app/shared/services/container/container.types';
import { environment } from 'src/environments/environment';
import { TrainingTreeNode } from '../../split-screen/models/training-tree-node';
import { JoinProject } from '../models/join-project';
import { Project } from '../models/project';
import { ProjectFileUpload } from '../models/project-file-upload';
import { ProjectJoinRequestList } from '../models/project-join-request-list';
import { ProjectMembership } from '../models/project-membership';
import { ProjectMembershipList } from '../models/project-membership-list';
import { UserProject } from '../models/user-project';

@Injectable({
    providedIn: 'root',
})
export class ProjectService {
    private readonly restUrl: string;
    private readonly projectsUrl: string;

    selectedProject$: BehaviorSubject<Container> =
        new BehaviorSubject<Container>(null);
    constructor(
        private http: HttpClient,
        private containerService: ContainerService
    ) {
        this.restUrl = environment.backendUrl;
        this.projectsUrl = this.restUrl + '/rest/containers';
    }

    getProjectByName(projectName: string): Observable<Project> {
        const url = `${this.projectsUrl}/${projectName}`;
        return this.http.get<Project>(url).pipe(catchError(handleError));
    }

    getProjectById(projectId: string): Observable<Container> {
        return this.containerService.getById(projectId);
    }

    updateProjectById(
        projectId: string,
        projectForm: FormGroup
    ): Observable<Project> {
        const url = `${this.projectsUrl}/${projectId}`;
        return this.http
            .patch<Project>(url, projectForm.value)
            .pipe(catchError(handleError));
    }

    getMyProjectMembership(projectId: string): Observable<ProjectMembership> {
        return this.containerService.getMyMembership(projectId);
    }

    getProjectMemberships(
        projectId: string
    ): Observable<ProjectMembershipList> {
        const url = `${this.projectsUrl}/${projectId}/memberships`;

        return this.http.get<ProjectMembershipList>(url);
    }

    getOwner(projectId: string): Observable<UserInfoMember> {
        const url = `${this.restUrl}/rest/${projectId}/member/owner`;
        return this.http.get<UserInfoMember>(url);
    }

    getProjects(pageable: Pageable): Observable<ContainerPage> {
        const filterTypes = [
            ContainerType.PROJECT_MOTORIC,
            ContainerType.PROJECT_VISUAL,
            ContainerType.IMAGE_DB,
        ];
        return this.containerService.getAllCommunityContainers(
            filterTypes,
            pageable
        );
    }

    getMyProjects(
        userId: string,
        pageable: Pageable
    ): Observable<ContainerPage> {
        return this.containerService.getAllUserContainers(
            [ContainerType.PROJECT_MOTORIC, ContainerType.PROJECT_VISUAL],
            pageable
        );
    }

    createProject(
        projectDefinition: ContainerCreateRequest
    ): Observable<ContainerCreateResponse> {
        return this.containerService.create(projectDefinition);
    }

    saveProjectPicture(
        projectId: string,
        projectPicture: File
    ): Observable<ProjectFileUpload> {
        const url = `${this.projectsUrl}addPicture`;
        const formData = new FormData();
        formData.append('projectId', projectId);
        formData.append('file', projectPicture);
        return this.http.post<ProjectFileUpload>(url, formData);
    }

    deleteProject(projectId: string): Observable<unknown> {
        return this.containerService.delete(projectId);
    }

    getOpenJoinRequestsByProject(
        containerId: string
    ): Observable<ProjectJoinRequestList> {
        const url = `${this.projectsUrl}/${containerId}/requests`;
        return this.http.get<ProjectJoinRequestList>(url);
    }

    answerJoinRequest(joinProject: JoinProject): Observable<void> {
        const url = `${this.projectsUrl}/${joinProject.projectId}/requests/answer`;
        return this.http.post<any>(url, joinProject);
    }

    getUserProjects(userId: number): Observable<UserProject[]> {
        const url = `${this.restUrl}/rest/users/${userId}/project-memberships`;
        return this.http.get<UserProject[]>(url);
    }

    leaveProject(userId: number, projectUUID: string): Observable<void> {
        const url = `${this.restUrl}/rest/users/${userId}/project-memberships/${projectUUID}`;
        return this.http.delete<void>(url);
    }

    deleteJoinRequest(userId: number, projectUUID: string): Observable<void> {
        const url = `${this.restUrl}/rest/users/${userId}/project-memberships/${projectUUID}/request`;
        return this.http.delete<void>(url);
    }

    getTrainingTree(id: string): Observable<TrainingTreeNode> {
        const url = `${this.projectsUrl}/${id}/tree`;
        return this.http.get<TrainingTreeNode>(url);
    }
}
