<div class="card h-100">
    <div (click)="onAddButtonClick()" class="click-area">
        <span class="material-icons plus-icon"> add </span>
    </div>
    <div class="card-footer">
        <div class="user-profile-img float-start">
            <img
                src="assets/img/card-icons/default-profile-image.jpg"
                class="card-user-img"
                alt="profile image"
            />
        </div>
        <p class="add-new-card-title float-start">
            {{ addCard?.description | translate }}
        </p>
    </div>
</div>
