<div id="image-db-wizard" class="modal-body">
    <button
        #closeModal
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModalWindow()"
    >
        <span class="material-icons-outlined">close</span>
    </button>
    <div class="head-title text-center">
        <div class="point-container">
            <p class="dot blue mb-0"></p>
            <p class="dot pink mb-0"></p>
            <p class="dot violet mb-0"></p>
        </div>
        <div class="head-title-text mb-0">
            {{ 'imgDb.wizard.createNewDb' | translate }}
        </div>
    </div>
    <!-- ## wizard dots ## -->
    <div class="row mt-3">
        <div
            class="
                button-container
                col-5
                d-flex
                justify-content-end
                align-items-center
            "
        >
            <span
                (click)="onBackButton()"
                *ngIf="activeWizardSlideIndex !== 1"
                class="back-btn"
                ><mat-icon
                    class="
                        d-flex
                        justify-content-center
                        align-items-center
                        arrow-back-icon
                    "
                    >arrow_back</mat-icon
                ></span
            >
        </div>
        <div
            class="slider-points text-center col-7 d-flex justify-content-start"
        >
            <div
                class="slide-point-container"
                *ngFor="let wizardPointImage of wizardPointImages"
            >
                <img
                    [src]="wizardPointImage"
                    alt="wizardPointImage"
                    class="slide-point"
                />
            </div>
        </div>
    </div>

    <!-- ## wizard points containers ## -->
    <app-img-db-wizard-slide
        #childComponent
        [wizardList]="wizardSlides"
        (activeWizardSlideIndex)="changeWizardPosition($event)"
        (addDb)="closeModalWindow()"
    ></app-img-db-wizard-slide>
</div>
