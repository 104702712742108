import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { fadeIn, fadeOut } from '../../animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-display-errors',
    templateUrl: './display-errors.component.html',
    styleUrls: ['./display-errors.component.scss'],
    animations: [fadeIn, fadeOut],
})
export class DisplayErrorsComponent {
    @Input()
    control: FormControl;

    @Input()
    translationKey: string;

    constructor(private translateService: TranslateService) {}

    get errors() {
        return this.control.errors;
    }

    get errorKeys() {
        return Object.keys(this.errors);
    }

    public getErrorMessage(errorPrefix: string): string {
        const errorKey = `${this.translationKey}.${errorPrefix}Error`;

        const error = this.errors[errorPrefix];

        return this.translateService.instant(errorKey, error);
    }
}
