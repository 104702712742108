<p-carousel
    id="carousel"
    [value]="slides.slideContent | async"
    [numVisible]="numVisible"
    [numScroll]="numScroll"
    [showIndicators]="false"
    [showNavigators]="true"
>
    <ng-template let-elt pTemplate="item">
        <div class="carousel-container">
            <div
                class="card-container card"
                (click)="chooseOption(elt)"
                [class.active]="isActive(elt)"
            >
                <div class="card-head">
                    <img
                        class="card-img-top"
                        [src]="elt.image"
                        [alt]="elt.cardDisc"
                    />
                </div>
                <div class="card-body">
                    <div>
                        <p class="font-weight-bold card-title">
                            {{ elt.cardTitle | translate }}
                        </p>
                    </div>
                    <p class="card-text">{{ elt.cardDisc | translate }}</p>
                </div>
            </div>
        </div>
    </ng-template>
</p-carousel>
