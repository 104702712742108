import * as THREE from 'three';
import { Euler } from 'three';
import { degreesToRadians, radiansToDegrees } from '../../shared/utility';

export class Position {
    x: number;
    y: number;
    z: number;
}

export class Orientation {
    x: number;
    y: number;
    z: number;
}

export function convertOrientation({ x, y, z }: Orientation): THREE.Quaternion {
    return new THREE.Quaternion().setFromEuler(
        new Euler(degreesToRadians(x), degreesToRadians(y), degreesToRadians(z))
    );
}

export function convertEuler({ x, y, z }: Euler): Orientation {
    const orientation = new Orientation();
    orientation.x = radiansToDegrees(x);
    orientation.y = radiansToDegrees(y);
    orientation.z = radiansToDegrees(z);
    return orientation;
}

export function convertPosition(position: Position): THREE.Vector3 {
    return new THREE.Vector3(position.x, position.y, position.z);
}

export class StateOfSceneElement {
    elementId: string;
    snapshotId: number;
    sceneId: number;
    id: number;
    position: Position;
    orientation: Orientation;
    jointStates?: Map<string, number>;
}
