<div class="main-container">
    <div class="container page-container">
        <div class="row">
            <div class="col">
                <div class="row photo-card-row">
                    <app-image-update
                        (uploadFile)="savePicture($event)"
                        [originalPicture]="profilePicture"
                        [editable]="true"
                    >
                    </app-image-update>
                </div>
                <hr />
                <div class="row person-data">
                    <div class="col-6 title-div">
                        <h2>
                            {{
                                'authentication.profile.profilePageForm.personalData.header'
                                    | translate
                            }}
                        </h2>
                    </div>
                    <div class="col-6 input-container">
                        <div class="row input-row">
                            <label for="username">{{
                                'authentication.profile.profilePageForm.personalData.username'
                                    | translate
                            }}</label>
                            <input
                                type="text"
                                id="username"
                                [value]="username"
                                disabled
                            />
                        </div>
                        <div class="row input-row">
                            <label for="email">{{
                                'authentication.profile.profilePageForm.personalData.email'
                                    | translate
                            }}</label>
                            <input
                                type="text"
                                id="email"
                                disabled
                                [value]="user?.email"
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <form [formGroup]="profileForm">
                    <div class="row company">
                        <div class="col-6 title-div">
                            <h2>
                                {{
                                    'authentication.profile.profilePageForm.company.header'
                                        | translate
                                }}
                            </h2>
                        </div>
                        <div class="col-6 input-container">
                            <div class="row input-row">
                                <label for="company">{{
                                    'authentication.profile.profilePageForm.company.companyInput.labelName'
                                        | translate
                                }}</label>
                                <input
                                    #company
                                    formControlName="company"
                                    type="text"
                                    id="company"
                                    (blur)="
                                        updateUser(ControlName.COMPANY, company)
                                    "
                                    (keydown.escape)="
                                        onEscape(ControlName.COMPANY, company)
                                    "
                                    (keydown.enter)="onEnter(company)"
                                />
                                <app-display-errors
                                    *ngIf="getControl('company').errors"
                                    [control]="getControl('company')"
                                    translationKey="authentication.profile.profilePageForm.company.companyInput.error"
                                ></app-display-errors>
                            </div>
                            <div class="row input-row">
                                <label for="country">{{
                                    'authentication.profile.profilePageForm.company.countryInput.labelName'
                                        | translate
                                }}</label>
                                <input
                                    #country
                                    type="text"
                                    formControlName="country"
                                    id="country"
                                    (keydown.escape)="
                                        onEscape(ControlName.COUNTRY, country)
                                    "
                                    (keydown.enter)="onEnter(country)"
                                />
                            </div>
                            <div class="row input-row">
                                <label for="city">{{
                                    'authentication.profile.profilePageForm.company.cityInput.labelName'
                                        | translate
                                }}</label>
                                <input
                                    #city
                                    formControlName="city"
                                    type="text"
                                    id="city"
                                    (blur)="updateUser(ControlName.CITY, city)"
                                    (keydown.escape)="
                                        onEscape(ControlName.CITY, city)
                                    "
                                    (keydown.enter)="onEnter(city)"
                                />
                                <app-display-errors
                                    *ngIf="getControl('city').errors"
                                    [control]="getControl('city')"
                                    translationKey="authentication.profile.profilePageForm.company.cityInput.error"
                                ></app-display-errors>
                            </div>
                            <div class="row input-row">
                                <label for="phone">{{
                                    'authentication.profile.profilePageForm.company.phoneInput.labelName'
                                        | translate
                                }}</label>
                                <input
                                    #phone
                                    formControlName="phone"
                                    type="text"
                                    id="phone"
                                    (blur)="
                                        updateUser(ControlName.PHONE, phone)
                                    "
                                    (keydown.escape)="
                                        onEscape(ControlName.PHONE, phone)
                                    "
                                    (keydown.enter)="onEnter(phone)"
                                />
                                <app-display-errors
                                    *ngIf="getControl('phone').errors"
                                    [control]="getControl('phone')"
                                    translationKey="authentication.profile.profilePageForm.company.phoneInput.error"
                                ></app-display-errors>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row settings">
                        <div class="col-6 title-div">
                            <h2>
                                {{
                                    'authentication.profile.profilePageForm.settings.header'
                                        | translate
                                }}
                            </h2>
                        </div>
                        <div class="col-6 input-container">
                            <div class="row input-row">
                                <label for="license">{{
                                    'authentication.profile.profilePageForm.settings.license'
                                        | translate
                                }}</label>
                                <input
                                    #license
                                    type="text"
                                    id="license"
                                    formControlName="license"
                                    (keydown.escape)="
                                        onEscape(ControlName.LICENSE, license)
                                    "
                                    (keydown.enter)="onEnter(license)"
                                />
                            </div>
                            <div class="row input-row">
                                <label for="language">{{
                                    'authentication.profile.profilePageForm.settings.language.labelName'
                                        | translate
                                }}</label>
                                <div class="input-select">
                                    <select
                                        id="language"
                                        formControlName="language"
                                        (blur)="
                                            updateUser(ControlName.LANGUAGE)
                                        "
                                    >
                                        <option value="English">
                                            {{
                                                'authentication.profile.profilePageForm.settings.language.eng'
                                                    | translate
                                            }}
                                        </option>
                                        <option value="German">
                                            {{
                                                'authentication.profile.profilePageForm.settings.language.de'
                                                    | translate
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row input-row">
                                <label for="datetime">{{
                                    'authentication.profile.profilePageForm.settings.dateTime'
                                        | translate
                                }}</label>
                                <input
                                    #dateTime
                                    type="text"
                                    id="datetime"
                                    formControlName="dateTime"
                                    (keydown.escape)="
                                        onEscape(
                                            ControlName.DATE_TIME,
                                            dateTime
                                        )
                                    "
                                    (keydown.enter)="onEnter(dateTime)"
                                />
                            </div>
                            <div class="row input-row">
                                <label for="two-way-auth">{{
                                    'authentication.profile.profilePageForm.settings.twoFactor.labelName'
                                        | translate
                                }}</label>
                                <div class="input-select">
                                    <select
                                        id="two-way-auth"
                                        formControlName="twoFactor"
                                        (blur)="
                                            updateUser(ControlName.TWO_FACTOR)
                                        "
                                    >
                                        <option [value]="true">
                                            {{
                                                'authentication.profile.profilePageForm.settings.twoFactor.yes'
                                                    | translate
                                            }}
                                        </option>
                                        <option [value]="false">
                                            {{
                                                'authentication.profile.profilePageForm.settings.twoFactor.no'
                                                    | translate
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <hr />
                <div class="row delete-acc mt-5">
                    <div class="col-6 title-div">
                        <h2>
                            {{
                                'authentication.profile.profilePageForm.deleteAccount'
                                    | translate
                            }}
                        </h2>
                    </div>
                    <div class="col-6">
                        <div class="row del-but">
                            <button>
                                {{
                                    'authentication.profile.profilePageForm.deleteAccountButton'
                                        | translate
                                }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
