import { Path } from 'typescript';
import { Layer } from './layer';
import { TfOutputMode } from './tf-types';
import { composeLayer, WithSparse } from './tf-properties';

export class Discretisation extends Layer {
    bin_boundaries = null;
    num_bins = 0;
    epsilon = 0.01;
    is_adapted = false;
}

export class StringLookup extends composeLayer([WithSparse]) {
    max_tokens: number = null;
    num_oov_indices = 1;
    mask_token: string = null;
    oov_token = '[UNK]';
    vocabulary: Path | string[] = null;
    idf_weights: number[] = null;
    encoding = null;
    invert = false;
    output_mode: TfOutputMode = 'int';
    pad_to_max_tokens = false;
}

export class IntegerLookup extends StringLookup {}

export class CategoryEncoding extends composeLayer([WithSparse]) {
    num_tokens = null;
    output_mode: TfOutputMode = 'multi_hot';
}
