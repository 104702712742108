<div class="container-fluid layout-container">
    <div class="row">
        <div class="col-12">
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center title-container">
                        <h1 class="title">{{ headerText }}</h1>
                    </div>
                    <div class="col-6">
                        <div class="left-side-container">
                            <ng-content select="[leftSide]"></ng-content>
                        </div>
                    </div>
                    <div class="col-6">
                        <div>
                            <ng-content select="[rightSide]"></ng-content>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
