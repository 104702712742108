import { Component, OnInit, ViewChild } from '@angular/core';
import { WizardSlide } from '../../../project/models/wizard-slide';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImgDbWizardSlideCreationService } from '../services/img-db-wizard-slide-creation.service';
import { ImgDbWizardSlideComponent } from '../img-db-wizard-slide/img-db-wizard-slide.component';

@Component({
    selector: 'app-img-db-wizard',
    templateUrl: './img-db-wizard.component.html',
    styleUrls: ['./img-db-wizard.component.scss'],
    providers: [ImgDbWizardSlideCreationService],
})
export class ImgDbWizardComponent implements OnInit {
    @ViewChild('childComponent') slideComponent: ImgDbWizardSlideComponent;
    activeWizardSlideIndex: number = 1;
    wizardSlides: Array<WizardSlide>;

    wizardPointImages: string[];

    constructor(
        public modalService: NgbModal,
        private cardService: ImgDbWizardSlideCreationService
    ) {}

    ngOnInit(): void {
        this.activeWizardSlideIndex = 1;
        this.wizardPointImages = this.cardService.imgDbPointImages();
        this.changeActivePositionPoint();
        this.wizardSlides = this.cardService.createWizardSlides();
    }

    changeWizardPosition(newWizardIndex: number) {
        this.activeWizardSlideIndex = newWizardIndex;
        this.changeActivePositionPoint();
    }

    private changeActivePositionPoint() {
        this.wizardPointImages = this.cardService.imgDbPointImages();

        let currentImage =
            this.wizardPointImages[this.activeWizardSlideIndex - 1];
        currentImage = currentImage.replace('.jpg', '-active.jpg');

        this.wizardPointImages[this.activeWizardSlideIndex - 1] = currentImage;
    }

    closeModalWindow() {
        this.modalService.dismissAll();
    }

    onBackButton() {
        this.slideComponent.onGoBack();
        this.changeWizardPosition(--this.activeWizardSlideIndex);
    }
}
