import { environment } from 'src/environments/environment';
import { CostFunctionRow } from './cost-function-row';

export class OrientationCostFunctionRow extends CostFunctionRow {
    sceneElementId: string;
    name: string;
    angleX: string = environment.costFunction.orientation.default;
    angleY: string = environment.costFunction.orientation.default;
    angleZ: string = environment.costFunction.orientation.default;
}
