import { Component, HostBinding, Input } from '@angular/core';
import { SliderItem } from '../models/slider-item';

@Component({
    selector: 'app-slider-item',
    templateUrl: './slider-item.component.html',
    styleUrls: ['./slider-item.component.scss'],
})
export class SliderItemComponent {
    @HostBinding('class') class = 'd-flex flex-column';

    @Input()
    item: SliderItem = { image: '', icon: '', title: '', description: '' };

    constructor() {}
}
