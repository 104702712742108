import { Observable } from 'rxjs';
import { UserProfileMin } from 'src/app/authentication/profile/models/user-profile-min';
import { Container } from 'src/app/shared/services/container/container.types';
import { Card, CardFooter, CardOptions } from './card';

export class CommunityCard extends Card {
    readonly cardTypeTranslationKey = 'card.type.communityProject';
    readonly options = new CardOptions(true, false, false);

    constructor(
        projectId: string,
        userId: string,
        userName: string,
        head: CardFooter,
        pictures: string[],
        container: Container
    ) {
        super(projectId, userId, userName, head, pictures, container);
        this.navigationUrl = 'communityprojects/' + projectId + '/summary';
    }

    public deleteCard(): Observable<unknown> {
        return;
    }

    public onCardPictureClick(): void {
        this.router.navigate([this.navigationUrl], {
            queryParams: this.navigationUrlParams,
        });
    }

    static mapToCommunityCard(project: Container, user: UserProfileMin) {
        const pics = !!project.pictureLocation
            ? new Array(project.pictureLocation)
            : null;

        return new CommunityCard(
            project.id,
            user.id,
            user.displayName,
            new CardFooter(project.name, user.profilePicture),
            pics,
            project
        );
    }
}
