import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ResetPasswordService } from '../service/reset-password.service';

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['../../abstract/password.component.scss'],
})
export class ForgetPasswordComponent {
    submitted = false;

    form: UntypedFormGroup = new UntypedFormGroup({
        email: new UntypedFormControl(''),
    });

    submit() {
        if (this.form.valid) {
            this.resetPasswordService
                .forgetPassword(this.form.value.email)
                .subscribe();
            this.submitted = true;
        }
    }
    @Input() error: string | null;

    constructor(private resetPasswordService: ResetPasswordService) {}
}
