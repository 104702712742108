import { Drawable } from './drawable';
import * as d3 from 'd3';
import { BaseType } from 'd3';
import { ParsedEntity } from './parsed-entity';
import { InputLayerSceneElement } from './input-layer-scene-element';
import { Options } from './options';
import { LayerDrawingInfo } from '../../models/layer-drawing-info';
import { LayerLink } from '../../models/layer-link';
import { OPTIONS_DEFAULT } from './options-default';
import { TransformData, TransformDataUtil } from './transform';

export class DrawableNetwork implements Drawable {
    private readonly _options: Options = OPTIONS_DEFAULT;
    private _layers;
    private _links;
    private _sceneElements;
    private _draggedPosition = { x: 0, y: 0 };
    private _zoomScale: number = 1;
    private _drawspace: d3.Selection<
        SVGGElement,
        unknown,
        HTMLElement,
        unknown
    >;
    private _selection: d3.Selection<BaseType, unknown, HTMLElement, unknown>;

    constructor(
        selection: d3.Selection<BaseType, unknown, HTMLElement, unknown>
    ) {
        this._drawspace = this.initializeDrawspace(selection);
    }

    get width(): number {
        return this._drawspace.node().getBBox().width;
    }

    get height(): number {
        return this._drawspace.node().getBBox().height;
    }

    get scaledWidth(): number {
        return this.width * this._zoomScale;
    }

    get scaledHeight(): number {
        return this.height * this._zoomScale;
    }

    public get options() {
        return this._options;
    }

    public get layers() {
        return this._layers;
    }

    public get links() {
        return this._links;
    }

    public get zoomScale() {
        return this._zoomScale;
    }

    public get draggedPosition() {
        return this._draggedPosition;
    }

    draw(
        parsedEntity: ParsedEntity,
        sceneElements: InputLayerSceneElement[] = []
    ) {
        this._layers = parsedEntity.layers;
        this._links = parsedEntity.links;
        this._sceneElements = sceneElements;

        const layersWithCoordinates = this.calculateCoordinatesOfLayers(
            this._layers
        );
        const layerGroups = DrawableNetwork.appendLayerGroupsTo(
            this._drawspace,
            layersWithCoordinates
        );
        const parsedLinksWithIndices = this._links.map((link) =>
            this.parseIndicesIntoCoordinates(link, layersWithCoordinates)
        );
        const networkLayers = layerGroups.filter(
            (d: any) => d.type !== 'InputLayer'
        );
        const inputLayer = layerGroups.filter(
            (d: any) => d.type === 'InputLayer'
        );
        this.appendLayerBlocksTo(networkLayers);
        this.appendInputLayerBlocksTo(inputLayer);
        this.appendLinksTo(this._drawspace, parsedLinksWithIndices);
    }

    clear() {
        this._drawspace.selectAll('*').remove();
    }

    private initializeDrawspace(
        selection: d3.Selection<BaseType, unknown, HTMLElement, unknown>
    ) {
        this._selection = selection;
        const node = <HTMLInputElement>selection.node();
        if (node) {
            const boundingClientRect = node.getBoundingClientRect();

            this._options.width = boundingClientRect.width;
            this._options.height = boundingClientRect.height;
        }

        const svg = this._selection
            .append('svg')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('id', 'architecture-svg');
        const drawingSpace = svg.append('g').attr('class', 'main');
        this.addDefsToSvg(svg);

        return drawingSpace;
    }

    private addDefsToSvg(
        selection: d3.Selection<BaseType, unknown, HTMLElement, unknown>
    ) {
        var lg = selection
            .append('defs')
            .append('linearGradient')
            .attr('id', 'input-layer-gradient');

        lg.append('stop').style('stop-color', '#00abe7');

        lg.append('stop').attr('offset', '1').style('stop-color', '#e60060');
    }

    private static appendLayerGroupsTo(
        drawspace: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
        layers
    ) {
        return drawspace
            .selectAll('g')
            .data(layers)
            .enter()
            .append('g')
            .attr('class', (layer: any) =>
                layer.type === 'InputLayer'
                    ? 'input-layer-box layer-box'
                    : 'network-layer-box layer-box'
            )
            .attr(
                'transform',
                (layer: any) => 'translate(' + layer.x + ',' + layer.y + ')'
            );
    }

    private appendLayerBlocksTo(
        drawSpace: d3.Selection<SVGGElement, unknown, BaseType, unknown>
    ) {
        drawSpace
            .append('path')
            .attr('d', 'M 24 1 h15 q10,0 10,10 v158 q0,10 -10,10 h-15 z')
            .attr('fill', (layer: LayerDrawingInfo) => layer.color)
            .attr('class', 'layer__background');

        drawSpace
            .append('path')
            .attr('d', 'M 25 1 h -15 q -10 0 -10 10 v 158 q 0 10 10 10 h 15 z')
            .attr('fill', '#fff');

        drawSpace
            .append('rect')
            .attr('id', (layer: LayerDrawingInfo) => `rect-${layer.id}`)
            .attr('stroke', (layer: LayerDrawingInfo) => layer.color)
            .attr('width', (layer: LayerDrawingInfo) => layer.width)
            .attr('height', this._options.layerHeight)
            .attr('class', 'layer network-layer')
            .attr('rx', '10')
            .attr('yx', '10');

        const textContainer = drawSpace
            .append('g')
            .attr('transform', 'translate(18, 174) rotate(-90)')
            .attr('y', this._options.layerHeight);

        textContainer
            .append('text')
            .attr('id', (layer: LayerDrawingInfo) => `text-type-${layer.id}`)
            .text((layer: LayerDrawingInfo) => layer.type)
            .attr('fill', (layer: LayerDrawingInfo) => layer.color)
            .attr('dy', '0')
            .attr('dx', '0')
            .attr('class', 'layer__type')
            .attr('text-anchor', 'start');

        textContainer
            .append('text')
            .attr('id', (layer: LayerDrawingInfo) => `text-name-${layer.id}`)
            .text((layer: LayerDrawingInfo) => layer.name)
            .attr('dy', '22')
            .attr('dx', '0')
            .attr('text-anchor', 'start')
            .attr('class', 'layer__title')
            .each(this.textOverflowNetworkLayer);
    }

    private appendInputLayerBlocksTo(
        drawSpace: d3.Selection<SVGGElement, unknown, BaseType, unknown>
    ) {
        const layerHeight = this._sceneElements.length * 21 + 36;
        const inputLayerTranslateY =
            (layerHeight - this._options.layerHeight) / -2;
        d3.select('.input-layer-box').attr(
            'transform',
            `translate(0, ${inputLayerTranslateY})`
        );

        drawSpace
            .append('rect')
            .attr('fill', 'url(#input-layer-gradient)')
            .attr('width', this._options.layerHeight)
            .attr('height', layerHeight)
            .attr('id', (layer: LayerDrawingInfo) => `rect-${layer.id}`)
            .attr('class', 'layer input-layer')
            .attr('rx', '10')
            .attr('yx', '10');

        drawSpace
            .append('path')
            .attr(
                'd',
                'm 2 27 h 176 v -15 q 0 -10 -10 -10 h -156 q -10 0 -10 10 z'
            )
            .attr('fill', '#fff');

        const textContainer = drawSpace.append('g');

        textContainer
            .append('text')
            .attr('id', (layer: LayerDrawingInfo) => `text-name-${layer.id}`)
            .text((layer: LayerDrawingInfo) => layer.name)
            .attr('dy', '20')
            .attr('dx', '8')
            .attr('text-anchor', 'start')
            .attr('class', 'layer__title')
            .each(this.textOverflowInputLayer);

        const sceneElements = drawSpace
            .append('g')
            .attr('transform', 'translate(0, 46)')
            .attr('class', 'scene-elements');

        this._sceneElements.forEach((element, index) => {
            const sceneElement = sceneElements
                .append('g')
                .attr('transform', `translate(0, ${21 * index})`);

            sceneElement
                .append('svg:image')
                .attr('x', 7)
                .attr('y', -12)
                .attr('width', 15)
                .attr('height', 13)
                .attr(
                    'xlink:href',
                    `/assets/svg/scene/${
                        element.isRobot ? 'robot' : 'object3d'
                    }-white.svg`
                );
            const name = element.name;
            sceneElement
                .append('text')
                .text(name)
                .attr('dx', '26')
                .each(this.textOverflowSceneElements);
        });
    }

    private appendLinksTo(
        drawspace: d3.Selection<BaseType, unknown, HTMLElement, unknown>,
        drawableLinks
    ) {
        const linkGroup = drawspace.append('g');
        DrawableNetwork.appendLinesTo(linkGroup, drawableLinks);
        this.appendDropTargetsTo(linkGroup, drawableLinks);
    }

    private static appendLinesTo(
        linkGroup: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
        drawableLinks
    ) {
        const marginBeforeLayer = 10;

        const arrow = linkGroup
            .selectAll('g')
            .data(drawableLinks)
            .enter()
            .append('g')
            .attr('class', 'arrow');

        arrow
            .append('polygon')
            .attr('points', `${0},0 7,4 0,8`)
            .attr(
                'transform',
                (d: LayerLink) =>
                    `translate(${d.target.x - 14}, ${d.source.y - 4})`
            );

        arrow
            .append('line')
            .style('stroke', 'black')
            .style('stroke-width', 2)
            .attr('x1', (d: any) => d.source.x + marginBeforeLayer)
            .attr('y1', (d: any) => d.source.y)
            .attr('x2', (d: any) => d.target.x - marginBeforeLayer)
            .attr('y2', (d: any) => d.target.y);
    }

    private appendDropTargetsTo(
        linkGroup: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>,
        drawableLinks
    ) {
        const dropTarget = linkGroup
            .selectAll('g')
            .data(drawableLinks)
            .append('g')
            .attr('class', 'drop-target');

        dropTarget
            .append('rect')
            .attr('x', (link: LayerLink) => link.dropTarget.x)
            .attr('y', (link: LayerLink) => link.dropTarget.y)
            .attr('rx', 5)
            .attr('ry', 5)
            .attr('width', this._options.dropTargetSize)
            .attr('height', this._options.dropTargetSize);

        dropTarget
            .append('path')
            .attr('d', 'M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z')
            .attr(
                'transform',
                (link: LayerLink) =>
                    `translate(${
                        link.dropTarget.x +
                        (this._options.dropTargetSize * 0.3) / 2
                    } ${
                        link.dropTarget.y +
                        (this._options.dropTargetSize * 0.3) / 2
                    }) scale(0.7) `
            );
    }

    private parseIndicesIntoCoordinates(
        linkWithIndices: {
            source: number;
            target: number;
        },
        drawableLayers
    ): LayerLink {
        const xSourceLayer = drawableLayers[linkWithIndices.source].x;
        const xLinkSource =
            xSourceLayer + drawableLayers[linkWithIndices.source].width;
        const xTargetLayer = drawableLayers[linkWithIndices.target].x;
        const xDroptarget =
            (xLinkSource + xTargetLayer) / 2 - this._options.dropTargetSize / 2;
        const yBothLayers = this._options.layerHeight / 2;
        const yDroptarget = yBothLayers - this._options.dropTargetSize / 2;
        return {
            source: {
                x: xLinkSource,
                y: yBothLayers,
            },
            target: {
                x: xTargetLayer,
                y: yBothLayers,
            },
            dropTarget: {
                x: xDroptarget,
                y: yDroptarget,
            },
        };
    }

    private calculateCoordinatesOfLayers(layers) {
        let nextLayerX = 0;
        const layersWithCoordinates = layers.map((layer, index) => {
            const width =
                layer.type === 'InputLayer'
                    ? this._options.inputLayerWidth
                    : this._options.layerWidth;
            const newLayer = {
                ...layer,
                width: width,
                x: nextLayerX,
                y: 0,
            };
            nextLayerX += width + this._options.marginBetweenLayers;
            return newLayer;
        });

        return layersWithCoordinates;
    }

    private calcXCoordinateOfLayer(layer, layerIndex: number): number {
        const width =
            layer.type === 'InputLayer'
                ? this._options.layerHeight
                : this._options.layerWidth;
        const x =
            layerIndex *
            (this.options.layerWidth + this.options.marginBetweenLayers);
        return {
            ...layer,
            width: width,
            x: (width + this._options.marginBetweenLayers) * layerIndex,
            y: 0,
        };
    }

    public transform(e: TransformData) {
        this._draggedPosition.x = e.x;
        this._draggedPosition.y = e.y;
        this._zoomScale = e.scale;

        this._drawspace.attr(
            'transform',
            TransformDataUtil.createD3Transform(e)
        );
    }

    private textOverflowNetworkLayer() {
        const el = d3.select(this as any).node();
        const rectSize =
            el.parentElement.previousElementSibling.getBoundingClientRect()[
                'height'
            ];
        DrawableNetwork.textOverflow(el, rectSize);
    }

    private textOverflowInputLayer() {
        const el = d3.select(this as any).node();
        const rectSize =
            el.parentElement.previousElementSibling.getBoundingClientRect()[
                'width'
            ];
        DrawableNetwork.textOverflow(el, rectSize);
    }

    private textOverflowSceneElements() {
        const el = d3.select(this as any).node();
        const rectSize = document
            .querySelector('.input-layer')
            .getBoundingClientRect()['width'];

        DrawableNetwork.textOverflow(el, rectSize - 20);
    }

    private static textOverflow(el: any, rectSize: number) {
        let textLength = el.getComputedTextLength();

        if (!el.textContent || !textLength || !rectSize) {
            return;
        }

        while (textLength > rectSize - 10) {
            const newText = el.textContent.slice(0, -4).concat('...');
            el.textContent = newText;
            textLength = el.getComputedTextLength();
        }
    }
}
