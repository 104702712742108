<div class="container-fluid p-0 mt-2">
    <div class="custom-randomization-wrapper row">
        <div class="col-2">
            <app-randomization-sidebar
                [trainingId]="trainingId"
            ></app-randomization-sidebar>
        </div>
        <div class="col-10">
            <div class="code-container">
                <textarea
                    [(ngModel)]="customCode"
                    (keydown)="handleKeydown($event)"
                    spellcheck="false"
                    autocapitalize="off"
                    autocomplete="off"
                ></textarea>
                <div
                    class="
                        save-button-container
                        d-flex
                        justify-content-end
                        mx-3
                    "
                >
                    <button class="btn btn-tryb-blue" (click)="onSaveCode()">
                        {{
                            'neuralNetwork.codeEditor.customRandomization.save'
                                | translate
                        }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
