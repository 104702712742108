import { Component, Input } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NumberFormatValidator } from 'src/app/shared/validators/number-format-validator';
import { WeightingValidator } from 'src/app/shared/validators/weighting-validator';
import { CostFunctionService } from '../../services/cost-function.service';
import { CartesianCoordinateValidator } from 'src/app/shared/validators/cartesian-coordinate-validator';
import { AbstractTabComponent } from '../abstract-tab/abstract-tab.component';
import { environment } from 'src/environments/environment';
import { PositionCostFunctionRow } from '../../models/position-cost-function-row';
import { NumberTranslateService } from 'src/app/shared/services/number-translate.service';

@Component({
    selector: 'app-position-tab-content',
    templateUrl: './position-tab-content.component.html',
    styleUrls: ['./position-tab-content.component.scss'],
})
export class PositionTabContentComponent extends AbstractTabComponent {
    @Input() xAxisLabel: string;
    @Input() yAxisLabel: string;
    @Input() zAxisLabel: string;

    type: string = 'position';

    axisDecimalPoint: number = environment.costFunction.position.numDecimals;
    axisMinValue: number = environment.costFunction.position.min;
    axisMaxValue: number = environment.costFunction.position.max;
    rows = [];

    constructor(
        costFunctionService: CostFunctionService,
        private numberService: NumberTranslateService
    ) {
        super(costFunctionService);
    }

    // Handle dropping an scene composition element in a dropzone
    drop($event: any) {
        const dragSceneElement = $event.item.data.sceneElement;

        const element: PositionCostFunctionRow = new PositionCostFunctionRow();
        element.name = dragSceneElement.browserAndPhysicsLoadable.name;
        element.sceneElementId = dragSceneElement.id;

        const elementFormGroup = this.getElementFormGroup(element);
        this.rows.push(elementFormGroup);
        this.saveRow(elementFormGroup, this.sceneId, this.type);
    }

    // Get a form group for an existing element
    protected getElementFormGroup(element: any): UntypedFormGroup {
        const positionValidators = [
            NumberFormatValidator.validateFormat,
            CartesianCoordinateValidator.validateRange,
        ];

        return new UntypedFormGroup({
            name: new UntypedFormControl({
                value: element.name,
                disabled: false,
            }),
            sceneElementId: new UntypedFormControl(element.sceneElementId),
            positionX: new UntypedFormControl(
                this.numberService.translateNumber(
                    this.convertElementValueToString(
                        element.positionX,
                        this.axisDecimalPoint
                    )
                ),
                positionValidators
            ),
            positionY: new UntypedFormControl(
                this.numberService.translateNumber(
                    this.convertElementValueToString(
                        element.positionY,
                        this.axisDecimalPoint
                    )
                ),
                positionValidators
            ),
            positionZ: new UntypedFormControl(
                this.numberService.translateNumber(
                    this.convertElementValueToString(
                        element.positionZ,
                        this.axisDecimalPoint
                    )
                ),
                positionValidators
            ),
            weight: new UntypedFormControl(
                {
                    value: this.numberService.translateNumber(
                        this.convertElementValueToString(
                            element.weight,
                            this.weightDecimalPoint
                        )
                    ),
                    disabled: false,
                },
                [
                    Validators.required,
                    NumberFormatValidator.validateFormat,
                    WeightingValidator.validateRange,
                ]
            ),
        });
    }
}
