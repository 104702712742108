import { Pageable } from 'src/app/shared/models/pageable';
import { AddCard } from './add-card-implementations/add-card';
import { Card } from './card-implementations/card';

export class CardPage {
    addCard: AddCard;
    itemCards: Card[] = [];
    numberOfPages: number;
    pageable: Pageable;
}
