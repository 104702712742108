import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectWorkspaceComponent } from './project-workspace.component';
import { ProjectMembersComponent } from './components/project-members/project-members.component';
import { ProjectWorkspaceNavigationComponent } from './components/project-workspace-navigation/project-workspace-navigation.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { SplitScreenModule } from '../split-screen/split-screen.module';
import { ProjectSettingsComponent } from './components/project-settings/project-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MemberRequestComponent } from './components/project-members/member-request/member-request.component';

@NgModule({
    declarations: [
        ProjectWorkspaceComponent,
        ProjectMembersComponent,
        ProjectWorkspaceNavigationComponent,
        ProjectSettingsComponent,
        MemberRequestComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        SharedModule,
        SplitScreenModule,
    ],
})
export class ProjectWorkspaceModule {}
