export enum TrainingStatus {
    NEW = 'NEW',
    RUNNING = 'RUNNING',
    REQUESTING = 'REQUESTING',
    QUEUED = 'QUEUED',
    PREPARING = 'PREPARING',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
    CANCELLING = 'CANCELLING',
    CANCELLED = 'CANCELLED',
    STATE_UNSPECIFIED = 'State not Specified',
}
