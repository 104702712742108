import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadTypeDialogComponent } from 'src/app/robots/components/upload-type-dialog/upload-type-dialog.component';
import { AddCard } from './add-card';

export class RobotAddCard extends AddCard {
    private modal: NgbModal;

    public constructor(modal: NgbModal) {
        super('card.model.addCardImplementations.robotAddCard.uploadNewRobot');
        this.modal = modal;
    }

    public createNewModel(): void {
        this.modal.open(UploadTypeDialogComponent, {
            windowClass: 'add-scene-dialog',
        });
    }
}
