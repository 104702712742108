<h2>Player</h2>
<div class="d-flex flex-row">
    <div class="p-2">
        <mat-form-field appearance="fill" [formGroup]="stateForm">
            <mat-label>States</mat-label>
            <select matNativeControl required formControlName="state">
                <option *ngFor="let state of states" value="{{ state.id }}">
                    {{ state.label }}
                </option>
            </select>
        </mat-form-field>
    </div>
    <button mat-button (click)="startDialog()">Play</button>
</div>
