<label for="label-name" class="label-name">
    {{ 'imgDb.labelManager.input.label' | translate }}
</label>
<div class="input-div">
    <input
        #labelName
        id="label-name"
        type="text"
        class="input-field"
        [formControl]="categoryInput"
        (keyup.enter)="onEnterKeypressAddCategory(categoryInput.value?.trim())"
        [placeholder]="'imgDb.labelManager.input.placeholder' | translate"
    />
    <span
        *ngIf="categoryInput.value"
        (click)="onEnterKeypressAddCategory(categoryInput.value?.trim())"
        class="material-icons tick-icon"
        >check</span
    >
    <span
        *ngIf="categoryInput.value"
        class="material-icons clear-icon"
        (click)="resetForm()"
        >clear</span
    >
</div>
<div class="info-labels">
    <span class="left">{{
        'imgDb.labelManager.categoryListLabels.labelClass' | translate
    }}</span>
    <span class="right">{{
        'imgDb.labelManager.categoryListLabels.imageAmount' | translate
    }}</span>
</div>
<ul
    class="list-group categories-list"
    *ngIf="categories?.length > 0"
    [ngClass]="{ 'from-training': isDbOfTraining }"
>
    <li
        *ngFor="let category of categories"
        class="list-group-item category-item"
        (click)="categoryClicked.emit(category.name)"
        [class.selected]="selectedCategoryNames.includes(category.name)"
    >
        <div class="category-name">
            <span
                class="dot"
                [style]="{
                    backgroundColor: getColor(category.name)
                }"
            >
            </span>
            <span>{{ category.name }}</span>
        </div>
        <div class="info-labels">
            <div class="image-count">
                <span>
                    {{ category.imageCount }}
                </span>
            </div>
            <div
                class="trash-icon"
                (click)="onDeleteCategoryClick($event, category)"
            >
                <mat-icon>delete</mat-icon>
            </div>
        </div>
    </li>
</ul>
<ul
    class="list-group"
    [ngStyle]="{ 'margin-top': categories?.length > 0 ? '10px' : '0' }"
>
    <li
        class="list-group-item"
        (click)="emptyCategorySelected.emit()"
        [class.selected]="isEmptyCategorySelected"
    >
        <div class="category-name">
            <span class="no-label">
                {{ 'imgDb.labelManager.noLabel' | translate }}</span
            >
        </div>
        <div class="info-labels">
            <div class="image-count">
                <span>{{ numberOfUncategorizedImages }}</span>
            </div>
        </div>
    </li>
</ul>
