import { AbstractControl, UntypedFormGroup } from '@angular/forms';

const ends: string[] = [
    'js',
    'json',
    'css',
    'htm',
    'html',
    'xml',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'ico',
    'tif',
    'tiff',
    'woff',
];
const re = /^(?!.*[\%\/\\\&\?\,\'\;\:\!\-\_\.]{2}).*$/;
const regex = /^([A-Za-z0-9\-\_\.]+)$/;
const regexEnd = /^([A-Za-z0-9]+)$/;
const usernameEmpty = 'authentication.shared.usernameError1';
const usernameTooShort = 'authentication.shared.usernameError2';
const usernameTooLong = 'authentication.shared.usernameError3';
const usernameCanOnlyContain = 'authentication.shared.usernameError4';
const usernameMustEndWithLetterOrNumber =
    'authentication.shared.usernameError5';
const usernameCannotEndWithAFileExtension =
    'authentication.shared.usernameError6';
const usernameCannotContain2ConsecutiveSpecialCharacters =
    'authentication.shared.usernameError7';
const usernameMustStartWithNumberOrLetter =
    'authentication.shared.usernameError8';

export function ValidateUsername(control: AbstractControl) {
    if (control.value.length < 1) {
        return { validFname: false, message: usernameEmpty };
    } else if (control.value.length < 3) {
        return { validFname: false, message: usernameTooShort };
    } else if (control.value.length > 20) {
        return { validFname: false, message: usernameTooLong };
    } else if (!regex.test(control.value)) {
        return { validFname: false, message: usernameCanOnlyContain };
    } else if (
        !regexEnd.test(control.value.substring(control.value.length - 1))
    ) {
        return {
            validFname: false,
            message: usernameMustEndWithLetterOrNumber,
        };
    } else if (usernameEndsWith(control.value, ends)) {
        return {
            validFname: false,
            message: usernameCannotEndWithAFileExtension,
        };
    } else if (!control.value.match(/^[A-Z0-9]/i)) {
        return {
            validFname: false,
            message: usernameMustStartWithNumberOrLetter,
        };
    } else if (!re.test(control.value)) {
        return {
            validFname: false,
            message: usernameCannotContain2ConsecutiveSpecialCharacters,
        };
    }

    return null;
}

const usernameEndsWith = (username, endsVar) => {
    let value = false;
    value = endsVar.some((element) => {
        return username.endsWith(element);
    });
    return value;
};

export function ComparePassword(
    controlName: string,
    matchingControlName: string
) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }

        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}
