import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { FormValidatorService } from '../../services/form-validator.service';
import { WizardFormData } from './wizard-form.data';

@Component({
    selector: 'app-wizard-form',
    templateUrl: './wizard-form.component.html',
    styleUrls: ['./wizard-form.component.scss'],
})
export class WizardFormComponent implements OnInit, OnDestroy, AfterViewInit {
    form: FormGroup;

    formControlName: string = 'name';
    projectAvailability: boolean = true;

    @ViewChild('inputName') inputName: ElementRef;

    @Input() hasSubmitFailed: Subject<boolean>;

    // TODO: remove input when private/public functionality for imgDb added
    @Input() formForProject: boolean = true;

    @Output() newData = new EventEmitter<WizardFormData>();

    submitButtonTxt: string = this.translate.instant(
        'imgDb.wizard.wizardSlideTS.hereWeGo'
    );

    sub: Subscription;

    @Output() backClicked = new EventEmitter<void>();

    constructor(
        private formValidatorService: FormValidatorService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        let control = this.formValidatorService.getControlByType('name');
        this.form = new FormGroup({ [this.formControlName]: control });

        this.sub = this.hasSubmitFailed.subscribe((hasSubmitFailed) => {
            if (hasSubmitFailed) {
                this.submitButtonTxt = this.translate.instant(
                    'imgDb.wizard.wizardSlideTS.hereWeGo'
                );
                this.resetHasSubmitFailed();
            }
        });
    }

    ngAfterViewInit(): void {
        this.inputName.nativeElement.focus();
    }

    onSubmit() {
        if (this.nameControl.valid) {
            this.submitButtonTxt = this.translate.instant(
                'imgDb.wizard.wizardSlideTS.pleaseWait'
            );

            const formData: WizardFormData = {
                name: this.inputValue,
                openly: this.projectAvailability,
                description: '',
            };

            this.newData.emit(formData);
        }
    }

    get nameControl(): FormControl {
        return this.form.get(this.formControlName) as FormControl;
    }

    get inputValue(): string {
        return this.nameControl.value;
    }

    onBlur() {
        if (this.inputValue !== null) {
            this.nameControl.setValue(this.inputValue.trim());
        }
    }

    resetHasSubmitFailed() {
        this.hasSubmitFailed.next(false);
    }

    onBackClick() {
        this.backClicked.emit();
        this.form.reset({ [this.formControlName]: '' });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
