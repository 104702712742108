import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrainingType } from 'src/app/shared/enums/training-type.enum';
import { TrainingDTO } from 'src/app/shared/models/training-dto';
import { TrainingStatus } from 'src/app/shared/models/training-status';
import { TrainingService } from 'src/app/shared/services/training.service';

@Component({
    selector: 'app-neural-network',
    templateUrl: './neural-network.component.html',
    styleUrls: ['./neural-network.component.scss'],
})
export class NeuralNetworkComponent implements OnInit {
    selectedIndex: number = 0;
    editingIsDisabled: boolean = false;
    trainingName: string;
    trainingId: string;
    trainingType: TrainingType;

    constructor(
        private route: ActivatedRoute,
        private trainingService: TrainingService
    ) {}
    ngOnInit(): void {
        this.trainingId = this.route.snapshot.paramMap.get('trainingId');

        this.trainingService
            .get(this.trainingId)
            .subscribe((training: TrainingDTO) => {
                this.trainingType = training.trainingType;
                if (this.trainingType === TrainingType.VISUAL) {
                    this.selectedIndex = 1;
                }
                this.editingIsDisabled = training.status !== TrainingStatus.NEW;
                this.trainingName = training.name;
            });
    }

    changeSelection(selectedIndex: number) {
        this.selectedIndex = selectedIndex;
    }
}
