import { TranslateService } from '@ngx-translate/core';
import { ActivityTimeFormatter } from '../../../../shared/activity-time-formatter';
import { UserActivityResponse } from '../user-activity-response';
import { UserActivityType } from '../user-activity-type';
import { Params } from '@angular/router';

export abstract class UserActivity {
    protected readonly occurTime: Date;
    private readonly projectUUID_: string;
    private readonly projectName_: string;
    private readonly projectPicture_: string;
    private readonly trainingName_: string;
    protected readonly trainingUUID: string;
    protected readonly questUUID: string;
    private readonly type_: UserActivityType;
    private readonly isMember_: boolean;

    protected constructor(response: UserActivityResponse) {
        this.projectName_ = response.projectName;
        this.projectUUID_ = response.projectUUID;
        this.projectPicture_ =
            response.projectPictures.length > 0
                ? response.projectPictures[0]
                : '';
        this.occurTime = response.occurTime;
        this.type_ = response.activityType;
        this.trainingName_ = response.trainingName;
        this.trainingUUID = response.trainingUUID;
        this.questUUID = response.questUUID;
        this.isMember_ = response.isProjectMember;
    }

    get projectUUID(): string {
        return this.projectUUID_;
    }

    get projectName(): string {
        return this.projectName_;
    }

    get projectPicture(): string {
        return this.projectPicture_;
    }

    get type(): UserActivityType {
        return this.type_;
    }

    get trainingName(): string {
        return this.trainingName_;
    }

    get isMember(): boolean {
        return this.isMember_;
    }

    public abstract get activityText(): string;

    public generateActivityTime(translate: TranslateService): string {
        return ActivityTimeFormatter.formatTime(this.occurTime, translate);
    }

    get routerLink(): string[] {
        if (this.isMember) {
            return ['/project/workspace'];
        } else {
            return ['/project/overview/', this.projectUUID];
        }
    }

    get queryParams(): Params {
        if (this.isMember) {
            return { projectUuid: this.projectUUID };
        } else {
            return {};
        }
    }

    public get afterDescLink(): string[] {
        return [];
    }

    public get afterDescParams(): Params {
        return {};
    }

    public get afterDescText(): string {
        return '';
    }
}
