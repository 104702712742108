import { Injectable } from '@angular/core';
import { ImgWithSelection } from '../../img-db/model/img-with-selection';
import { PagingResult } from '../../shared/models/pageable';
import { BehaviorSubject, filter, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ImgAnnotation } from '../../img-db/model/img-annotation';
import { VideoAndIterationFileService } from './video-and-iteration-file.service';
import { EvaluationStorageService } from './evaluation-storage.service';
import {
    VisualEvalCategory,
    VisualEvalDataSet,
    VisualEvalSet,
} from '../models/visual-eval-data-set';
import { ContentItem, SideContainerData } from '../models/side-container-data';

export interface ImageData {
    annotations: ImgAnnotation[];
    categories: Set<string>;
}

@Injectable()
export class ImageViewerStorageService {
    PAGE_SIZE: number = 8;

    dataInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    annotations$: BehaviorSubject<ImgAnnotation[]> = new BehaviorSubject([]);
    selectedImageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(
        null
    );

    imageAnnotationMap: Map<string, ImageData> = new Map<string, ImageData>();
    images: ImgWithSelection[] = [];
    categories: Map<string, string> = new Map<string, string>();

    trainingId: string;

    totalElements: number;
    totalPages: number;

    currentPage: number = 1;

    constructor(
        private service: VideoAndIterationFileService,
        private evaluationDataService: EvaluationStorageService
    ) {}

    init(trainingId: string) {
        this.trainingId = trainingId;
        this.loadData();

        this.evaluationDataService.leftContainerData.subscribe(
            (sideContainer: SideContainerData) => {
                if (sideContainer?.contentItems) {
                    let selectedCategories = [];
                    sideContainer.contentItems.forEach((item: ContentItem) => {
                        if (item.checked) {
                            selectedCategories.push(item.value);
                        }
                    });
                    this.setAnnotations(selectedCategories);
                }
            }
        );

        this.selectedImageIndex$.subscribe((index: number) => {
            if (index !== null) {
                this.setCategories(this.images[index].id, index);
            }
        });
    }

    loadData() {
        this.service
            .getEvalVisualData(this.trainingId)
            .subscribe((data: VisualEvalDataSet) => {
                if (data) {
                    this.extractData(data);
                    this.dataInitialized.next(true);
                }
            });
    }

    setCategories(imgId: string, index: number) {
        const currentCategories: string[] = [];
        this.imageAnnotationMap
            .get(imgId)
            ?.categories.forEach((categoryId: string) =>
                currentCategories.push(this.categories.get(categoryId))
            );

        this.evaluationDataService.setCategories(currentCategories, index);
    }

    setAnnotations(selectedCategories: string[]) {
        const selectedCategoriesIds: string[] = [];

        this.categories.forEach((value: string, key: string) => {
            if (selectedCategories.includes(value))
                selectedCategoriesIds.push(key);
        });

        let annotations: ImgAnnotation[] = [];
        if (this.selectedImageIndex$.value !== null) {
            annotations = this.imageAnnotationMap
                .get(this.images[this.selectedImageIndex$.value].id)
                ?.annotations.filter((annotation: ImgAnnotation) => {
                    return selectedCategoriesIds.includes(
                        annotation.categoryId
                    );
                });
        }

        this.annotations$.next(annotations);
    }

    private extractData(data: VisualEvalDataSet) {
        data.categories.forEach((category: VisualEvalCategory) =>
            this.categories.set(category.id.toString(), category.name)
        );

        data.evalSet.forEach((imgSet: VisualEvalSet) => {
            this.images.push(imgSet.image);

            const annotations: ImgAnnotation[] = [];
            const categories: Set<string> = new Set<string>();

            this.extractAnnotationsAndCategories(
                imgSet.annotations,
                annotations,
                categories
            );
            this.extractAnnotationsAndCategories(
                imgSet.modelAnnotations,
                annotations,
                categories
            );

            this.imageAnnotationMap.set(imgSet.image.id, {
                annotations,
                categories,
            } as ImageData);
        });

        this.totalElements = this.images.length;
        this.totalPages = Math.ceil(this.totalElements / this.PAGE_SIZE);
    }

    private extractAnnotationsAndCategories(
        providedAnnotations: ImgAnnotation[],
        annotations: ImgAnnotation[],
        categories: Set<string>
    ) {
        providedAnnotations.forEach((annotation: ImgAnnotation) => {
            annotations.push(annotation);
            categories.add(annotation.categoryId.toString());
        });
    }

    getImages(): Observable<PagingResult<ImgWithSelection>> {
        return this.dataInitialized.pipe(
            filter((value: boolean) => value === true),
            switchMap((_) => {
                const page: PagingResult<ImgWithSelection> =
                    new PagingResult<ImgWithSelection>();
                page.totalPages = this.totalPages;
                page.totalElements = this.totalElements;
                page.values = this.getImageOffset();

                return of(page);
            })
        );
    }

    private getImageOffset(): ImgWithSelection[] {
        const start: number = (this.currentPage - 1) * 8;
        return this.images.slice(start, start + 8);
    }
}
