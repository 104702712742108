import {
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { Scene } from 'src/app/scenes/models/scene';
import { SceneElement } from 'src/app/scenes/models/scene-element';
import { SceneService } from 'src/app/scenes/services/scene.service';
import { TrainingService } from 'src/app/shared/services/training.service';
import {
    Observation,
    ObservationForElement,
    ObservationSpace,
} from '../interfaces/observation';

@Component({
    selector: 'app-observation-element-details',
    templateUrl: './observation-element-details.component.html',
    styleUrls: ['./observation-element-details.component.scss'],
})
export class ObservationElementDetailsComponent implements OnInit, OnDestroy {
    @Input()
    editingIsDisabled: boolean;
    @Input()
    trainingId: string;
    subscriptions: Subscription[] = [];
    sceneElements: SceneElement[] = [];
    targetElements: SceneElement[] = [];
    selectedElementIndex: number = 0;
    observations: ObservationSpace;
    form: FormGroup;
    changed: boolean = false;
    changedState: boolean = false;
    previousStateName: string = 'previous_obs_active';

    constructor(
        private trainingService: TrainingService,
        private sceneService: SceneService,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        this.trainingService
            .getObservations(this.trainingId)
            .pipe(
                tap((observations: ObservationSpace) => {
                    this.observations = observations;
                }),
                switchMap(() => this.sceneService.getScene(this.trainingId))
            )
            .subscribe((scene: Scene) => {
                this.observationFormInit(scene);
            });

        this.subscriptions.push(
            this.authenticationService.logoutTriggered$.subscribe(() => {
                if (this.changedState) {
                    this.saveSelectionState().subscribe(() => {
                        this.authenticationService.logoutPreparationsFinished$.next(
                            true
                        );
                    });
                } else {
                    this.authenticationService.logoutPreparationsFinished$.next(
                        true
                    );
                }
            })
        );
    }

    private observationFormInit(scene: Scene) {
        if (this.observations !== null) {
            this.sceneElements = [];
            this.constructFormControlsForSelectedSceneElement(
                this.selectedElementIndex
            );
            this.observations.observations.forEach(
                (observation: ObservationForElement) => {
                    this.sceneElements.push(
                        scene.sceneElements[
                            scene.sceneElements.findIndex(
                                (sceneElement: SceneElement) =>
                                    sceneElement.id ===
                                    observation.sceneElementId
                            )
                        ]
                    );
                }
            );
            this.targetElements = this.sceneElements.filter(
                (sceneElement: SceneElement) =>
                    !sceneElement.browserAndPhysicsLoadable.isRobot
            );
            if (this.targetElements.length === 0) {
                [
                    'gripper_to_target-active',
                    'gripper_to_target-target',
                ].forEach((name: string) => {
                    this.form
                        .get(name)
                        .disable({ onlySelf: true, emitEvent: false });
                });
            }
        }
    }

    onChangeValue() {
        this.changed = true;
        this.changedState = true;
    }

    onSelectElement(sceneElementIndex: number) {
        if (this.changed) {
            this.updateObservationSelection();
        }

        this.constructFormControlsForSelectedSceneElement(sceneElementIndex);
        this.selectedElementIndex = sceneElementIndex;
    }

    private constructFormControlsForGeneralObservations() {
        this.form.addControl(
            this.previousStateName,
            new FormControl<boolean>(this.observations.previousObsActive)
        );
    }

    private constructFormControlsForSelectedSceneElement(
        sceneElementIndex: number
    ) {
        this.form = new FormGroup({});
        this.constructFormControlsForGeneralObservations();

        const observationsForElement: ObservationForElement =
            this.observations.observations[sceneElementIndex];
        Object.keys(observationsForElement)
            .filter(
                (observationType: string) =>
                    observationType !== 'sceneElementId'
            )
            .forEach((observationType: string) => {
                observationsForElement[observationType].forEach(
                    (observation: Observation) => {
                        this.sceneElementObservationInit(observation);
                    }
                );
            });
    }

    private sceneElementObservationInit(observation: Observation) {
        Object.keys(observation)
            .filter((key: string) => this.filterKeys(key))
            .forEach((key: string) => {
                let formControl;
                if (key.startsWith('target')) {
                    formControl = new FormControl<string>(
                        observation[key] as string
                    );
                } else {
                    formControl = new FormControl<boolean>(
                        observation[key] as boolean
                    );
                }
                if (this.editingIsDisabled) {
                    formControl.disable({
                        onlySelf: true,
                        emitEvent: false,
                    });
                }
                this.form.addControl(`${observation.name}-${key}`, formControl);
            });
    }

    updateObservationSelection() {
        const observationsForElement: ObservationForElement =
            this.observations.observations[this.selectedElementIndex];
        Object.keys(observationsForElement)
            .filter(
                (observationType: string) =>
                    observationType !== 'sceneElementId'
            )
            .forEach((observationType: string) => {
                observationsForElement[observationType].forEach(
                    (observation: Observation) => {
                        Object.keys(observation)
                            .filter((key: string) => this.filterKeys(key))
                            .forEach((key: string) => {
                                const control = this.form.get(
                                    `${observation.name}-${key}`
                                );
                                if (control !== null) {
                                    observation[key] = control.value;
                                }
                            });
                    }
                );
            });
        this.observations.previousObsActive = this.form.get(
            `${this.previousStateName}`
        ).value;
        this.changed = false;
    }

    private filterKeys(key: string): unknown {
        return key !== 'name' && key !== 'poseObservationType';
    }

    saveSelectionState(): Observable<ObservationSpace> {
        if (this.observations !== null) {
            if (this.changed) {
                this.updateObservationSelection();
            }
            this.changedState = false;
            return this.trainingService.updateObservations(
                this.trainingId,
                this.observations
            );
        } else {
            return of(null);
        }
    }

    ngOnDestroy(): void {
        if (this.changedState) {
            this.saveSelectionState().subscribe();
        }
        this.subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event) {
        if (this.changedState) {
            this.saveSelectionState().subscribe();
            event.preventDefault();
            event.returnValue = false;
        }
    }
}
