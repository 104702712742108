import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class VerifyService {
    private readonly databaseUrl: string;

    constructor(private http: HttpClient) {
        this.databaseUrl = environment.backendUrl;
    }

    verify(token: string): Observable<string> {
        const formData = new FormData();
        formData.append('token', token);
        return this.http.post<string>(
            `${this.databaseUrl}/auth/verify`,
            formData
        );
    }
}
