<table id="users-list" class="table table-striped">
    <caption hidden>
        Users List
    </caption>
    <thead class="table-dark">
        <tr>
            <th scope="col">
                {{ 'admin.components.userSettings.name' | translate }}
            </th>
            <th scope="col">
                {{ 'admin.components.userSettings.activeStatus' | translate }}
            </th>
            <th scope="col">
                {{ 'admin.components.userSettings.adminStatus' | translate }}
            </th>
            <th scope="col">
                {{ 'admin.components.userSettings.training' | translate }}
            </th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let user of users | async; let i = index">
            <tr>
                <td scope="row">
                    {{ user['displayName'] }}
                </td>
                <td scope="row">
                    <mat-slide-toggle
                        *ngIf="user.id !== currentUserId"
                        class="user-settings user-toggle"
                        [color]="color"
                        [disabled]="userSettingsArray[i].disable"
                        [checked]="userSettingsArray[i].active"
                        (change)="onChangeActive(user, $event, i)"
                    >
                        {{
                            userSettingsArray[i].active ? 'Active' : 'Inactive'
                        }}
                    </mat-slide-toggle>
                </td>
                <td scope="row">
                    <mat-slide-toggle
                        *ngIf="user.id !== currentUserId"
                        class="user-settings user-toggle"
                        [color]="color"
                        [disabled]="userSettingsArray[i].disable"
                        [checked]="userSettingsArray[i].isAdmin"
                        (change)="onChangeAdmin(user, $event, i)"
                    >
                        {{ userSettingsArray[i].isAdmin ? 'Admin' : 'User' }}
                    </mat-slide-toggle>
                </td>
                <td scope="row">
                    <mat-slide-toggle
                        class="user-settings user-toggle"
                        [color]="color"
                        [disabled]="userSettingsArray[i].disable"
                        [checked]="userSettingsArray[i].canTrain"
                        (change)="onChangeCanTrain(user, $event, i)"
                    >
                        {{
                            userSettingsArray[i].canTrain
                                ? 'Can Start'
                                : 'Cannot Start'
                        }}
                    </mat-slide-toggle>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>
