import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpParams,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BrowserAndPhysicsLoadable } from 'src/app/object3-d/models/browser-and-physics-loadable';
import { BrowserAndPhysicsLoadableList } from '../models/robot-list';

@Injectable({
    providedIn: 'root',
})
export class RobotService {
    private readonly backendUrl: string;

    constructor(private http: HttpClient) {
        this.backendUrl = environment.backendUrl + '/rest';
    }

    getRobots(
        userid: string,
        page: number,
        pageSize: number,
        currentPage: number
    ): Observable<BrowserAndPhysicsLoadableList> {
        const url = `${this.backendUrl}/robots/user/` + userid;
        let httpParams = new HttpParams();
        if (page !== undefined) {
            httpParams = httpParams.set('page', page + '');
        }
        if (pageSize !== undefined) {
            httpParams = httpParams.set('pageSize', pageSize + '');
        }
        if (currentPage !== undefined) {
            httpParams = httpParams.set('currentPage', currentPage + '');
        }
        return this.http.get<BrowserAndPhysicsLoadableList>(url, {
            params: httpParams,
        });
    }

    // Todo: refactor in urdf.component.ts
    deleteRobotByID(id: string): Observable<BrowserAndPhysicsLoadable> {
        const url = `${this.backendUrl}/robots/${id}`;
        return this.http
            .delete<BrowserAndPhysicsLoadable>(url)
            .pipe(catchError(this.handleError));
    }

    deleteRobotById(robotId: string): Observable<BrowserAndPhysicsLoadable> {
        const url = `${this.backendUrl}/robots/${robotId}`;
        return this.http
            .delete<BrowserAndPhysicsLoadable>(url)
            .pipe(catchError(this.handleError));
    }

    createRobotsByRepo(
        robot: BrowserAndPhysicsLoadable,
        uri: string,
        username: string,
        password: string
    ): Observable<HttpEvent<any>> {
        const url = `${this.backendUrl}/robots/repository`;
        const formData: FormData = new FormData();
        formData.append('uri', uri);
        formData.append('name', robot.name);
        if (username && password) {
            formData.append('username', username);
            formData.append('password', password);
        }
        return this.http
            .post(url, formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(catchError(this.handleError));
    }

    createRobot(
        file: File,
        robot: BrowserAndPhysicsLoadable
    ): Observable<HttpEvent<any>> {
        const url = `${this.backendUrl}/robots`;
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('name', robot.name);
        return this.http
            .post(url, formData, {
                reportProgress: true,
                observe: 'events',
            })
            .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`
            );
        }
        return throwError(() => error);
    }
}
