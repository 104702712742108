import { Initializer, InitializerType, Ones, Zeros } from './tf-initializers';
import { composeLayer, WithAxis, WithMean } from './tf-properties';

export class Normalization extends composeLayer([WithAxis, WithMean]) {
    variance: number | number[] = null;
}

export class BatchNormalization extends composeLayer([WithAxis]) {
    momentum = 0.99;
    epsilon = 0.001;
    center = true;
    scale = true;
    // beta_initializer: Initializer = {
    //     class_name: InitializerType.ZEROS,
    //     config: Zeros,
    // };
    // gamma_initializer: Initializer = {
    //     class_name: InitializerType.ONES,
    //     config: Ones,
    // };
    // moving_mean_initializer: Initializer = {
    //     class_name: InitializerType.ZEROS,
    //     config: Zeros,
    // };
    // moving_variance_initializer = {
    //     class_name: InitializerType.ONES,
    //     config: Ones,
    // };
    beta_regularizer = null;
    gamma_regularizer = null;
    beta_constraint = null;
    gamma_constraint = null;
}
