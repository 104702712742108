export enum InputFieldType {
    TEXT,
    CHECKBOX,
    ACTIVATION,
    INITIALIZER,
    REGULARIZER,
    CONSTRAINT,
    NUMBER,
    SELECTBOX,
    UNKNOWN,
}
