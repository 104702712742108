import { Component, OnInit } from '@angular/core';
import { ShopwareCmsService } from '../../services/shopware-cms.service';
import { CmsPage } from '../../models/cms-page';
import { CmsSection } from '../../models/cms-section';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-cms-page',
    templateUrl: './cms-page.component.html',
    styleUrls: ['./cms-page.component.scss'],
})
export class CmsPageComponent implements OnInit {
    cmsPage: CmsPage;
    cmsSections: CmsSection[];
    constructor(
        private cmsService: ShopwareCmsService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.initPage();
    }

    private initPage(): void {
        const categoryId = this.cmsService.getActiveCategoryId(
            this.route.snapshot.paramMap.get('categoryId')
        );
        this.cmsService.getCategory(categoryId).subscribe((category) => {
            this.cmsPage = category.cmsPage;
            this.cmsSections = this.cmsPage.sections;
        });
    }
}
