import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileMin } from 'src/app/authentication/profile/models/user-profile-min';
import { CommunityCard } from 'src/app/card/model/card-implementations/community-card';
import { CardPage } from 'src/app/card/model/card-page';
import { SortValue } from 'src/app/card/model/sort-value';
import {
    createPageableByRouterParameters,
    Pageable,
} from 'src/app/shared/models/pageable';
import { TrainingType } from 'src/app/shared/enums/training-type.enum';
import { ContainerService } from 'src/app/shared/services/container/container.service';
import { ContainerType } from 'src/app/shared/services/container/container.types';

@Component({
    selector: 'app-community-project',
    templateUrl: './community-project.component.html',
    styleUrls: ['./community-project.component.scss'],
})
export class CommunityProjectComponent implements OnInit {
    //HTML import
    protected readonly TrainingType = TrainingType;
    protected readonly ContainerType = ContainerType;

    cardPage: CardPage = new CardPage();

    numberOfPages: number;

    currentPage = 1;
    currentPageSize = 6;
    currentSortBy = 'created';
    currentFilter: string[] = [
        ContainerType.PROJECT_MOTORIC,
        ContainerType.PROJECT_VISUAL,
    ];

    translatedTexts: any;
    // titleText = 'Finde tolle Projekte zum Mitmachen';

    // TODO: extract to its own file
    sortValues: SortValue[] = [
        { id: 0, sortBy: 'Sortieren nach', serverSort: '', isSelected: true },
        { id: 1, sortBy: 'Neueste', serverSort: 'created', isSelected: false },
        { id: 2, sortBy: 'Beliebheit', serverSort: 'loved', isSelected: false },
        {
            id: 3,
            sortBy: 'Letzte Aktivität',
            serverSort: 'activity',
            isSelected: false,
        },
        {
            id: 4,
            sortBy: 'Anzahl der Trainings',
            serverSort: 'trainings',
            isSelected: false,
        },
        {
            id: 5,
            sortBy: 'Anzahl der Teilnehmer',
            serverSort: 'members',
            isSelected: false,
        },
    ];

    constructor(
        private containerService: ContainerService,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.fetchProjects();
        this.changeLanguageStrings();
        this.translate.onLangChange.subscribe((translations) => {
            this.changeLanguageStrings();
        });
    }

    private changeLanguageStrings() {
        let key = 'project.components.communityProjectTS';
        this.translate.get([key]).subscribe((translations) => {
            this.sortValues[0].sortBy = translations[key].sortBy;
            this.sortValues[1].sortBy = translations[key].latest;
            this.sortValues[2].sortBy = translations[key].popularity;
            this.sortValues[3].sortBy = translations[key].lastActivity;
            this.sortValues[4].sortBy = translations[key].numberOfTrainings;
            this.sortValues[5].sortBy = translations[key].numberOfParticipants;
        });
    }

    private fetchProjects(): void {
        this.route.queryParams.subscribe((params: Params) => {
            this.cardPage.pageable = createPageableByRouterParameters(
                new Pageable(),
                params
            );
            if (this.cardPage.pageable.page > 0) {
                this.cardPage.pageable.page = this.cardPage.pageable.page - 1;
            }

            this.syncFilterWithUrlParam();

            this.cardPage.pageable.pageSize = 12;

            const userMap: Map<string, UserProfileMin> = new Map<
                string,
                UserProfileMin
            >();

            let castedStringToContainerType: ContainerType[] =
                this.currentFilter.map(
                    (containerTypeAsString) =>
                        ContainerType[containerTypeAsString]
                );
            this.containerService
                .getAllCommunityContainers(
                    castedStringToContainerType,
                    this.cardPage.pageable
                )
                .subscribe({
                    next: (data) => {
                        this.cardPage.itemCards = [];
                        this.cardPage.numberOfPages = data.totalPages;
                        this.cardPage.pageable.page = data.currentPage;
                        data.values.forEach((project) => {
                            let user: UserProfileMin = userMap.get(
                                project.creator
                            );
                            if (!user) {
                                data.users.forEach((profile) => {
                                    if (
                                        profile.displayName === project.creator
                                    ) {
                                        user = profile;
                                        userMap.set(
                                            profile.displayName,
                                            profile
                                        );
                                    }
                                });
                            }
                            const communityProjectCard =
                                CommunityCard.mapToCommunityCard(project, user);
                            this.cardPage.itemCards.push(communityProjectCard);
                        });
                    },
                    error: (error) => {
                        console.error(
                            'Request to get all openly errors failed with with error: ' +
                                error
                        );
                    },
                });
        });
    }

    private syncFilterWithUrlParam() {
        if (this.cardPage.pageable.types !== undefined) {
            this.currentFilter = this.cardPage.pageable.types.split(',');
        } else {
            this.cardPage.pageable.types = this.currentFilter.join(',');
        }
    }

    changeSort(newSort: number): void {
        this.currentSortBy = this.sortValues[newSort].serverSort;
        this.navigateToSelf(
            this.currentPage,
            this.cardPage.pageable.pageSize,
            this.sortValues[newSort].serverSort,
            this.cardPage.pageable.types
        );
    }

    changeFilter(filter: string): void {
        const indexOfType = this.currentFilter.indexOf(filter);
        if (indexOfType !== -1) {
            this.currentFilter.splice(indexOfType, 1);
        } else {
            this.currentFilter.push(filter);
        }
        this.navigateToSelf(
            this.currentPage,
            this.cardPage.pageable.pageSize,
            this.cardPage.pageable.sortBy,
            this.currentFilter.length > 0
                ? this.currentFilter.join(',')
                : undefined
        );
    }

    private navigateToSelf(
        page: number,
        pageSize: number,
        sortBy: string,
        types: string
    ): void {
        this.router.navigate(['/communityprojects'], {
            queryParams: { page, pageSize, sortBy, types },
        });
    }
}
