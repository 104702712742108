export const SkillSelectboxValues = {
    padding: ['valid', 'same'],
    data_format: ['channels_first', 'channels_last'],
    activation: [
        'elu',
        'exponential',
        'gelu',
        'hard_sigmoid',
        'linear',
        'relu',
        'leaky_relu',
        'selu',
        'sigmoid',
        'softmax',
        'softplus',
        'softsign',
        'swish',
        'tanh',
    ],
    interpolation: ['nearest', 'bilinear'],
    var_mode: ['fan_in', 'fan_out', 'fan_avg'],
    distribution: ['truncated_normal', 'untruncated_normal', 'uniform'],
    flip_mode: ['horizontal', 'vertical', 'horizontal_and_vertical'],
    fill_mode: ['constant', 'reflect', 'wrap', 'nearest'],
    merge_mode: ['sum', 'mul', 'concat', 'ave'],
    output_mode: ['int', 'one_hot', 'multi_hot', 'count', 'tf_idf'],
    dtype: [
        'bfloat16',
        'complex128',
        'complex64',
        'float16',
        'float32',
        'float64',
        'int16',
        'int32',
        'int64',
        'int8',
        'uint16',
        'uint32',
        'uint64',
        'uint8',

        'qint16',
        'qint16_ref',
        'qint32',
        'qint32_ref',
        'qint8',
        'qint8_ref',
        'quint16',
        'quint16_ref',
        'quint8',
        'quint8_ref',

        'bool',
        'half',
        'resource',
        'string',
        'variant',
    ],
    initializer: [
        'Constant',
        'VarianceScaling',
        'GlorotUniform',
        'GlorotNormal',
        'HeUniform',
        'HeNormal',
        'LecunUniform',
        'LecunNormal',
        'Identity',
        'Ones',
        'Zeros',
        'Orthogonal',
        'RandomUniform',
        'RandomNormal',
        'TruncatedNormal',
    ],
};

export interface SkillSelectboxValuesType {
    activation: string[];
    data_format: string[];
    distribution: string[];
    dtype: string[];
    fill_mode: string[];
    flip_mode: string[];
    interpolation: string[];
    merge_mode: string[];
    output_mode: string[];
    padding: string[];
    var_mode: string[];
}
