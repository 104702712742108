import { UserProfileMin } from 'src/app/authentication/profile/models/user-profile-min';
import { ImgDb } from '../../../img-db/model/img-db';
import { Project } from '../../../project/models/project';

export enum ContainerType {
    IMAGE_DB = 'IMAGE_DB',
    PROJECT_MOTORIC = 'PROJECT_MOTORIC',
    PROJECT_VISUAL = 'PROJECT_VISUAL',
}

export enum MembershipStatus {
    OWNER = 'OWNER',
    MEMBER = 'MEMBER',
    NONE = 'NONE',
    REQUESTED = 'REQUESTED',
}

export interface Container {
    id: string; //uuid
    name: string;
    openly: boolean;
    containerType: ContainerType;
    creator: string;
    membershipStatus: MembershipStatus;
    description?: string;
    pictureLocation?: string;
    imageDb?: ImgDb;
    project?: Project;
}

export interface ContainerPage {
    values: Container[];
    currentPage: number;
    totalItems: number;
    totalPages: number;
    users: UserProfileMin[];
}

export interface ContainerCreateResponse {
    id: string;
    name: string;
}

export class ContainerCreateRequest {
    name: string;
    openly: boolean;
    containerType: ContainerType;
    description?: string;

    parameter?: string;
    defaultTrainingName?: string;
}

export interface ContainerMembership {
    memberName: string;
    roleName: string;
    memberSince: Date;
}

export class JoinRequest {
    containerId: string;
    containerName: string;
    userAccountDisplayName: string;
    userAccountId: string;

    created: Date;
}

export class JoinRequestCreateResponse {
    containerId: string;
    userAccountDisplayName: string;
}
