export interface PillsObject {
    value: string;
    text: string;
    translationKey: string;
}

export const pillsData: PillsObject[] = [
    {
        value: 'projects',
        text: 'Projekte',
        translationKey: 'workspace.model.navPillsData.projects',
    },
    /* Temporary commented due to LEA-1402 */
    // {
    //     value: 'objects',
    //     text: 'Objekte',
    //     translationKey: 'workspace.model.navPillsData.objects',
    // },
    /* Temporary commented due to LEA-633 */

    // {
    //     value: 'scenes',
    //     text: 'Szenen',
    //     translationKey: 'workspace.model.navPillsData.scenes',
    // },
    // {
    //     value: 'robots',
    //     text: 'Roboter',
    //     translationKey: 'workspace.model.navPillsData.robots',
    // },
    {
        value: 'image-databases',
        text: 'Bilddatenbanken',
        translationKey: 'workspace.model.navPillsData.image-databases',
    },
];
