import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CardPage } from 'src/app/card/model/card-page';
import { CardUser } from 'src/app/card/model/card-user';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { UserService } from 'src/app/authentication/services/user.service';
import {
    createPageableByRouterParameters,
    Pageable,
} from 'src/app/shared/models/pageable';
import { ObjectCard } from 'src/app/card/model/card-implementations/object-card';
import { Object3DService } from 'src/app/object3-d/service/object3-d.service';
import { ObjectAddCard } from 'src/app/card/model/add-card-implementations/object-add-card';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProfile } from '../../../authentication/profile/models/user-profile';

@Component({
    selector: 'app-object-workspace-library',
    templateUrl: './object-workspace-library.component.html',
    styleUrls: ['./object-workspace-library.component.scss'],
})
export class ObjectWorkspaceLibraryComponent implements OnInit {
    cardPage: CardPage = new CardPage();
    @Input() profilePicture: string;

    constructor(
        private object3DService: Object3DService,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private modal: NgbModal
    ) {}

    ngOnInit(): void {
        if (this.authenticationService.isLoggedIn()) {
            this.userService
                .getUser(this.authenticationService.currentUserValue.id)
                .subscribe((user: UserProfile) => {
                    const cardUser = new CardUser(
                        user.id,
                        user.displayName,
                        this.profilePicture
                    );

                    this.route.queryParams.subscribe((params: Params) => {
                        this.cardPage.pageable =
                            createPageableByRouterParameters(
                                new Pageable(),
                                params
                            );

                        if (this.cardPage.pageable.page > 0) {
                            this.cardPage.pageable.page =
                                this.cardPage.pageable.page - 1;
                        }

                        this.cardPage.addCard = new ObjectAddCard(this.modal);
                        this.object3DService
                            .getObject3DPage(user.id, this.cardPage.pageable)
                            .subscribe((object3DPage) => {
                                this.cardPage.itemCards = [];
                                this.cardPage.numberOfPages =
                                    object3DPage.totalPages + 1;
                                this.cardPage.pageable.page =
                                    object3DPage.currentPage;
                                object3DPage.content.forEach(
                                    (browserAndPhysicsLoadable) => {
                                        //TODO:FIX WHEN Object3ds HAVE PICTURES
                                        //let cardPictues = [String(robot.picture)];
                                        const objectCard =
                                            ObjectCard.mapToObjectCard(
                                                browserAndPhysicsLoadable,
                                                cardUser
                                            );
                                        this.cardPage.itemCards.push(
                                            objectCard
                                        );
                                    }
                                );
                            });
                    });
                });
        } else {
            console.log('There is an internal error.');
            this.authenticationService.signOut();
        }
    }
}
