import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { nonNil, Reference } from '../../../shared/utility';
import { URDFJoint, URDFRobot } from 'urdf-loader';
import { RobotInScene } from '../../models/robot-in-scene';

@Component({
    selector: 'app-node-tree-view',
    templateUrl: './node-tree-view.component.html',
    styleUrls: ['./node-tree-view.component.scss'],
})
export class NodeTreeViewComponent implements OnInit {
    @Input() children: URDFJoint[];
    @Output() robotSelectedEvent = new EventEmitter<RobotInScene>();
    @Input() showListRobots = true;
    @Input() loadedRobot: Observable<URDFRobot>;
    @Input() searchTerm: string;
    @Input() searchIndex: number;
    @Input() searchResults: Reference<number>;

    constructor() {}

    ngOnInit(): void {
        this.loadedRobot
            ?.pipe(filter(nonNil))
            .subscribe((loadedRobot: URDFRobot) => {
                this.children = loadedRobot.children as URDFJoint[];
            });
    }
    toggleVisibilityRobots() {
        this.showListRobots = !this.showListRobots;
    }

    robotSelected(robot: RobotInScene) {
        this.robotSelectedEvent.emit(robot);
    }
}
