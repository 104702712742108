import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { AdminNavigationItem } from '../../models/admin-navigation-itm';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
    isUserAdmin = false;
    private selectedNavItem: AdminNavigationItem;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.redirectIfUserisNotAdmin();
    }

    redirectIfUserisNotAdmin() {
        if (!this.authenticationService.isAdmin()) {
            this.router.navigateByUrl('**');
        } else {
            this.isUserAdmin = true;
            this.selectedNavItem = AdminNavigationItem.User;
        }
    }

    handleNavigationItemEvent(event: AdminNavigationItem): void {
        this.selectedNavItem = event;
    }

    isUserSelected(): boolean {
        return AdminNavigationItem.User === this.selectedNavItem;
    }

    isTrainingsSelected(): boolean {
        return AdminNavigationItem.Trainings === this.selectedNavItem;
    }

    isPerformanceSelected(): boolean {
        return AdminNavigationItem.Performance === this.selectedNavItem;
    }

    isTokenSelected(): boolean {
        return AdminNavigationItem.Token === this.selectedNavItem;
    }
}
