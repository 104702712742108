<div class="editable-lbl form-group">
    <span class="tryb-input-container">
        <form (ngSubmit)="onValueSubmit()" [formGroup]="inputGroup">
            <input
                #dbName
                formControlName="{{ formControlName }}"
                type="text"
                [attr.aria-label]="label"
                (blur)="onValueSubmit()"
                (keydown.escape)="onEscape()"
            />
            <div>
                <app-display-errors
                    [control]="dbNameControl"
                    translationKey="imgDb.wizard.wizardSlide"
                ></app-display-errors>
            </div>
        </form>
    </span>
</div>
