import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrainingService } from 'src/app/shared/services/training.service';

@Component({
    selector: 'app-custom-code-editor',
    templateUrl: './custom-code-editor.component.html',
    styleUrls: ['./custom-code-editor.component.scss'],
})
export class CustomCodeEditorComponent implements OnInit {
    @Input() trainingId: string;
    @Input() editingIsDisabled: boolean;
    @Input() customCode: string;

    @Output() disableToggle: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    constructor(private trainingService: TrainingService) {}

    ngOnInit(): void {
        if (this.customCode === null) {
            this.customCode =
                'def custom_reward_function(obs_before_sim, obs_after_sim, action, d_t, data):\n\treturn custom_reward, terminated, healthy_reward, control_cost_weight';
        }
    }

    onSaveCode() {
        this.disableToggle.emit(true);
        this.trainingService
            .saveCustomCode(this.trainingId, this.customCode)
            .subscribe();
    }

    handleKeydown(event: any) {
        if (event.key === 'Tab') {
            event.preventDefault();
            const start = event.target.selectionStart;
            const end = event.target.selectionEnd;
            event.target.value =
                event.target.value.substring(0, start) +
                '\t' +
                event.target.value.substring(end);
            event.target.selectionStart = event.target.selectionEnd = start + 1;
        }
    }
}
