import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivityPage } from './model/activity-page';
import { ActivityResponse } from './model/activity-response';

@Injectable({
    providedIn: 'root',
})
export class ProjectActivityService {
    private readonly baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.backendUrl}/rest/project`;
    }

    getActivities(
        projectUUID: string,
        page: number,
        size: number
    ): Observable<ActivityPage> {
        const url = `${this.baseUrl}/${projectUUID}/activities`;
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('size', size);
        return this.http.get<ActivityPage>(url, { params: params }).pipe(
            map((activityPage: ActivityPage) => {
                activityPage.content.forEach((activity: ActivityResponse) => {
                    activity.createTime = new Date(activity.createTime);
                });
                return activityPage;
            })
        );
    }
}
