import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class NumberTranslateService {
    constructor(private translate: TranslateService) {}

    translateNumber(number: string): string {
        const separator = this.translate.instant('numberSeparator');
        return number.replace(/[,.]/g, separator);
    }

    limitDecimalPlaces(control: AbstractControl, decimalPoint: number) {
        const val = control.value.replace(',', '.').split('.');
        if (val.length > 1) {
            const decimals = val[1];
            const base = val[0];
            if (decimals.length > decimalPoint) {
                const newDecimals = decimals.substring(0, decimalPoint);
                control.setValue(base + ',' + newDecimals);
            }
        }
    }

    /**
     * Reduce the amount decimal places without executing any round operation.
     * @param num number
     * @param decimalPoint number of wanted decimal places
     * @returns number with wanted decimal places, or simple integer if integer provided
     */
    limitNumberToDecimalPlaces(num: number, decimalPoint: number): number {
        let numberAsString = num.toString();
        let indexOfPoint = numberAsString.indexOf('.');

        if (indexOfPoint === -1) {
            return num;
        }

        return parseFloat(
            numberAsString.slice(0, indexOfPoint + decimalPoint + 1)
        );
    }

    /**
     * Convert numValue of specific unit to meter representation
     * @param numValue
     * @param unit allowed values dm, cm, mm
     * @returns number as known unit, or numValue itself
     */
    convertValueToMeter(numValue: number, unit: string = 'm'): number {
        if (0 === numValue) {
            return 0;
        }
        switch (unit) {
            case 'dm':
                return numValue / 10;
            case 'cm':
                return numValue / 100;
            case 'mm':
                return numValue / 1000;
            default:
                return numValue;
        }
    }

    /**
     * Convert a meter to specific unit:
     * @param meter
     * @param unit allowed values dm, cm, mm
     * @returns convert meter value to specific unit
     */
    convertMeterToValue(meter: number, unit: string = 'm'): number {
        if (0 === meter) {
            return 0;
        }
        switch (unit) {
            case 'dm':
                return meter * 10;
            case 'cm':
                return meter * 100;
            case 'mm':
                return meter * 1000;
            default:
                return meter;
        }
    }
}

/**
 * Round positive numbers to floor and negative
 * to ceil integer value.
 * Meaning 54.9999 results in 54 and -1.59 to -1!
 * @param number
 * @returns number as integer without the decimal values
 */
export function roundWithSafetyMargin(number: number): number {
    if (number >= 0) {
        return Math.floor(number);
    }
    return Math.ceil(number);
}

/**
 * Special round which will correctly execute rounding with specific digits,
 * see https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
 * @param n number to round
 * @param digits number of decimal places after rounding
 * @returns number with specific digits
 */
export function roundTo(n: number, digits: number): number {
    const negative = n < 0;
    if (!digits || digits < 0) {
        digits = 0;
    }
    if (negative) {
        n = n * -1;
    }
    const multiplicator = Math.pow(10, digits);
    // use 17, based on smallest Float after 1 (1.00000011920928955)
    // but will lead to other problems 15 - digits best try so far!
    const strFloat = (n * multiplicator).toFixed(15 - digits);
    // workaround with parseFloat to get correct value
    n = parseFloat(strFloat);
    n = Math.round(n) / multiplicator;
    if (negative) {
        n = n * -1;
    }
    return Number(n.toFixed(digits));
}

/**
 * Convert Degree to Radiant value, based on formula
 * radiant = (deg * Math.PI) / 180
 * @param deg number
 * @returns number representing Radiant
 */
export function convertDegreeToRad(deg: number): number {
    return (deg * Math.PI) / 180;
}

/**
 * Convert Radiant to Degree value, based on formula
 * degree = (rad * 180) / Math.PI
 * @param rad number
 * @returns number representing Degree
 */
export function convertRadToDegree(rad: number): number {
    return (rad * 180) / Math.PI;
}

/**
 * Return a value within the (included) limits of min and max.
 * @param value number
 * @param min number lower limit
 * @param max number upper limit
 * @returns value if inside limits, else lower or upper limit
 */
export function limit(value: number, min: number, max: number): number {
    if (value < min) {
        return min;
    } else {
        if (value > max) {
            return max;
        } else {
            return value;
        }
    }
}
