import {
    composeLayer,
    WithActivationLinear,
    WithActivityRegularizer,
    WithBias,
    WithDataFormat,
    WithKernel,
    WithNoiseShape,
    WithRate,
    WithSeed,
    WithUnits,
    WithUseBias,
} from './tf-properties';

export class Dense extends composeLayer([
    WithActivationLinear,
    WithActivityRegularizer,
    // WithBias,
    // WithKernel,
    WithUnits,
    WithUseBias,
]) {}

// export class DenseFeatures extends LayerConfig {
//     feature_columns: ??
// }

export class Dropout extends composeLayer([
    WithNoiseShape,
    WithRate,
    WithSeed,
]) {}

export class SpatialDropout1D extends composeLayer([
    WithNoiseShape,
    WithRate,
    WithSeed,
]) {}

export class SpatialDropout2D extends composeLayer([
    WithDataFormat,
    WithNoiseShape,
    WithRate,
    WithSeed,
]) {}

export class SpatialDropout3D extends composeLayer([
    WithDataFormat,
    WithNoiseShape,
    WithRate,
    WithSeed,
]) {}

export class GaussianDropout extends composeLayer([WithRate]) {}

export class AlphaDropout extends composeLayer([
    WithNoiseShape,
    WithRate,
    WithSeed,
]) {}
