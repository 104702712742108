<div class="header-box d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
        <span class="back-btn btn" (click)="onGoBack()" *ngIf="showBackButton">
            <span class="arrow-back-icon material-icons"> arrow_back </span>
        </span>
        <span class="span-name-content">
            <span>{{ name }}</span>
        </span>
    </div>
    <div class="d-flex align-items-center">
        <ng-content></ng-content>
    </div>
</div>
