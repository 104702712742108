import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdminNavigationItem } from '../../models/admin-navigation-itm';

@Component({
    selector: 'app-admin-navigation',
    templateUrl: './admin-navigation.component.html',
    styleUrls: ['./admin-navigation.component.scss'],
})
export class AdminNavigationComponent implements OnInit {
    selectedNavItem: AdminNavigationItem;

    @Output()
    selectNavigationItemEvent = new EventEmitter<AdminNavigationItem>();

    constructor() {}

    ngOnInit(): void {
        this.selectedNavItem = AdminNavigationItem.User;
    }

    clickNavigation(item: string): void {
        const oldItem = this.selectedNavItem;
        if (AdminNavigationItem.User === item) {
            this.selectedNavItem = AdminNavigationItem.User;
        } else if (AdminNavigationItem.Trainings === item) {
            this.selectedNavItem = AdminNavigationItem.Trainings;
        } else if (AdminNavigationItem.Performance === item) {
            this.selectedNavItem = AdminNavigationItem.Performance;
        } else if (AdminNavigationItem.Token === item) {
            this.selectedNavItem = AdminNavigationItem.Token;
        }

        if (oldItem !== this.selectedNavItem) {
            this.selectNavigationItemEvent.emit(this.selectedNavItem);
        }
    }

    getStyleClassForNavItem(item: string): string {
        if (
            this.selectedNavItem !== undefined &&
            this.selectedNavItem === item
        ) {
            return 'nav-link nav-item item-text active';
        }

        return 'nav-link nav-item item-text';
    }
}
