import {
    composeLayer,
    WithActivityRegularizer,
    WithBias,
    WithDropoutRate,
    WithKernel,
    WithUseBias,
    WithUseScale,
} from './tf-properties';

export class AdditiveAttention extends composeLayer([WithUseScale]) {}

export class Attention extends composeLayer([WithUseScale]) {}

export class MultiHeadAttention extends composeLayer([
    WithActivityRegularizer,
    WithBias,
    WithDropoutRate,
    WithKernel,
    WithUseBias,
]) {
    attention_axes: number[] = null;
    key_dim = 2;
    num_heads = 2;
    output_shape: number[] = null;
    value_dim: number = null;
}
