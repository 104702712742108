import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Scene } from '../models/scene';
import { ScenePage } from '../models/scene-page';
import { httpByPageable, Pageable } from 'src/app/shared/models/pageable';
import { SceneElement } from '../models/scene-element';
import { handleError } from 'src/app/shared/http-utilities';
import { StateOfSceneElement } from '../models/state-of-scene-element';
import { BrowserAndPhysicsLoadable } from 'src/app/object3-d/models/browser-and-physics-loadable';
import { SceneDiversitySelectionRequest } from '../models/scene-diversity-selection-request';
import { UserCacheService } from 'src/app/xtra/user-cache/user-cache-service';

@Injectable({
    providedIn: 'root',
})
export class SceneService {
    private readonly backendUrl: string;
    private readonly userCachePathToSelectedSceneElementPrefix: string =
        'selectedSceneElementOf';

    public constructor(
        private http: HttpClient,
        private userCacheService: UserCacheService
    ) {
        this.backendUrl = environment.backendUrl + '/rest';
    }

    public getScene(trainingId: string): Observable<Scene> {
        const url = `${this.backendUrl}/training/${trainingId}/scene`;
        return this.http.get<Scene>(url).pipe(
            catchError(handleError),
            switchMap((scene: Scene) => {
                return this.userCacheService
                    .loadData(
                        `${this.userCachePathToSelectedSceneElementPrefix}${scene.id}`
                    )
                    .pipe(
                        map((sceneElementSelectionObject) => {
                            if (sceneElementSelectionObject !== null) {
                                scene.selectedSceneElementId =
                                    sceneElementSelectionObject.selectedSceneElementId;
                            }

                            return scene;
                        })
                    );
            })
        );
    }

    public getScenePage(pageable: Pageable): Observable<ScenePage> {
        const url = `${this.backendUrl}/scenes`;
        const httpParams = httpByPageable(new HttpParams(), pageable);
        return this.http
            .get<ScenePage>(url, { params: httpParams })
            .pipe(catchError(handleError));
    }

    public addSceneElement(
        sceneId: number,
        selection: SceneDiversitySelectionRequest,
        loadableObject: BrowserAndPhysicsLoadable
    ): Observable<Scene> {
        const url = `${this.backendUrl}/scene/add-element`;
        return this.http
            .post<Scene>(url, {
                loadableToAdd: loadableObject,
                selection: selection,
                sceneId: sceneId,
            })
            .pipe(catchError(handleError));
    }

    private getSceneElementUrl(sceneElementId: string): string {
        return `${this.backendUrl}/scene-element/${sceneElementId}`;
    }

    public patchSceneElementScale(sceneElementId: string, scale: Number) {
        const url = this.getSceneElementUrl(sceneElementId);
        return this.http
            .patch<SceneElement>(url, { scale: scale })
            .pipe(catchError(handleError));
    }

    public patchSceneElementFixed(sceneElementId: string, isFixed: boolean) {
        const url = this.getSceneElementUrl(sceneElementId);
        return this.http
            .patch<SceneElement>(url, { isFixed: isFixed })
            .pipe(catchError(handleError));
    }

    public patchSceneElementSelected(selectedSceneElementId: string) {
        const url = `${this.backendUrl}/scene/selected-element/${selectedSceneElementId}`;
        return this.http.patch(url, {}).pipe(catchError(handleError));
    }

    public deleteSceneElementSelected(sceneId: number) {
        const url = `${this.backendUrl}/scene/selected-element/${sceneId}`;
        return this.http.delete(url).pipe(catchError(handleError));
    }

    public removeSceneElement(sceneElementId: string): Observable<Scene> {
        const url = this.getSceneElementUrl(sceneElementId);
        return this.http.delete<Scene>(url).pipe(catchError(handleError));
    }

    public patchSceneElementState(state: StateOfSceneElement) {
        const url = `${this.backendUrl}/scene/state-of-scene-element`;
        return this.http
            .patch<StateOfSceneElement>(url, state)
            .pipe(catchError(handleError));
    }

    public updateScene(scene: Scene): Observable<Scene> {
        const url = `${this.backendUrl}/scene/${scene.id}`;
        return this.http.patch<Scene>(url, scene).pipe(catchError(handleError));
    }

    public deleteScene(id: number): Observable<Scene> {
        const url = `${this.backendUrl}/scene/${id}`;
        return this.http.delete<Scene>(url).pipe(catchError(handleError));
    }

    public createScene(scene: Scene): Observable<Scene> {
        const url = `${this.backendUrl}/scene`;
        return this.http.post<Scene>(url, scene).pipe(catchError(handleError));
    }

    public createNewExampleMotion(
        sceneId: number,
        selector: SceneDiversitySelectionRequest
    ): Observable<Scene> {
        const url = `${this.backendUrl}/scene/${sceneId}/example-motion`;
        return this.http
            .post<Scene>(url, selector)
            .pipe(catchError(handleError));
    }

    public deleteExampleMotion(exampleMotionId: number): Observable<Scene> {
        const url = `${this.backendUrl}/scene/example-motion/${exampleMotionId}`;
        return this.http.delete<Scene>(url);
    }

    public getUnfixedElements(trainingId: string): Observable<SceneElement[]> {
        const url = `${this.backendUrl}/scene/${trainingId}/unfixed-scene-elements`;
        return this.http.get<SceneElement[]>(url);
    }

    public getUnfixedElementsByTraining(
        trainingId: string
    ): Observable<SceneElement[]> {
        const url = `${this.backendUrl}/training/${trainingId}/scene/unfixed-scene-elements`;
        return this.http.get<SceneElement[]>(url);
    }
}
