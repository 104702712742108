import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProjectJoinRequest } from '../../../project/models/project-join-request';
import { ProjectService } from '../../../project/services/project.service';
import { Container } from 'src/app/shared/services/container/container.types';

@Component({
    selector: 'app-project-members',
    templateUrl: './project-members.component.html',
    styleUrls: ['./project-members.component.scss'],
})
export class ProjectMembersComponent implements OnInit, OnDestroy {
    project: Container;
    memberRequests: ProjectJoinRequest[] = [];
    subscriptions: Subscription[] = [];

    constructor(
        private projectService: ProjectService,
        private route: ActivatedRoute
    ) {}
    ngOnInit(): void {
        if (this.projectService.selectedProject$.value) {
            this.subscriptions.push(
                this.projectService.selectedProject$.subscribe((project) => {
                    this.project = project;
                    this.getOpenJoinRequests();
                })
            );
        } else {
            this.subscriptions.push(
                this.projectService
                    .getProjectById(
                        this.route.snapshot.paramMap.get('projectId')
                    )
                    .subscribe((project) => {
                        this.project = project;
                        this.getOpenJoinRequests();
                    })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    onAnswerMemberRequest(displayName: string): void {
        this.memberRequests.splice(
            this.memberRequests.findIndex(
                (x) => x.userAccountDisplayName === displayName
            ),
            1
        );
    }

    private getOpenJoinRequests(): void {
        this.projectService
            .getOpenJoinRequestsByProject(this.project.id)
            .subscribe((openRequests) => {
                this.memberRequests = openRequests.values;
            });
    }
}
