import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Object3DService } from '../../object3-d/service/object3-d.service';
import { ProjectService } from '../../project/services/project.service';
import { RobotService } from '../../robots/services/robot.service';
import { SceneService } from '../../scenes/services/scene.service';

@Injectable({
    providedIn: 'root',
})
export class DeleteService {
    constructor(
        private projectService: ProjectService,
        private object3dService: Object3DService,
        private robotService: RobotService,
        private sceneService: SceneService
    ) {}

    deleteProject(projectId: string): Observable<unknown> {
        return this.projectService.deleteProject(projectId);
    }

    deleteObject3D(object3dId: string): Observable<unknown> {
        return this.object3dService.deleteObject3D(object3dId);
    }

    deleteRobot(robotId: string): Observable<unknown> {
        return this.robotService.deleteRobotById(robotId);
    }

    deleteScene(sceneID: number): Observable<unknown> {
        return this.sceneService.deleteScene(sceneID);
    }
}
