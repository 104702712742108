<div class="cms-block pos-{{ position }} cms-block-{{ type }}" [ngClass]="{'bg-image': backgroundMedia}" >
    <app-cms-snippet-background-image
            [backgroundMedia]="backgroundMedia"></app-cms-snippet-background-image>
    <div class="cms-block-container" style="{{ style }}">
        <div class="cms-block-container-row row cms-row ">
            <div class="col-12">
                <div class="cms-element-{{ contentSlot.type }}" [innerHTML]="content"></div>
            </div>
        </div>
    </div>
</div>