import { Injectable } from '@angular/core';
import { HierarchyPointLink, HierarchyPointNode } from 'd3';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';
import {
    DThreeLinkElement,
    DThreeLinkEnterElement,
    DThreeNodeElement,
    DThreeNodeEnterElement,
    DThreeSvgElement,
} from 'src/app/shared/models/d-three-types';
import {
    emptyTrainingTreeNode,
    TrainingTreeNode,
} from '../models/training-tree-node';
import {
    NODE_HEIGHT,
    NODE_WIDTH,
    NODE_MARGIN_LEFT_RIGHT,
    NODE_MARGIN_TOP_BOTTOM,
} from '../models/tree-drawing-properties';
import { TreeTransitionCreatorService } from './tree-transition-creator-service';
import { TreeActionsService } from './tree-actions.service';
import { TreeNodeStructureService } from './tree-node-structure.service';
import { TreeNodeToDrawableService } from './tree-node-to-drawable.service';
import { TreeStateService } from './tree-state.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class TreeDrawingService {
    treeData: BehaviorSubject<Record<string, number>> = new BehaviorSubject({
        minScale: 0,
        maxLeftOffset: 1,
        maxTopOffset: 1,
        treeHeight: 0,
    });

    scale: number = 1;

    private _svgElement = new BehaviorSubject<DThreeSvgElement>(null);

    public set svgElement(newElement: DThreeSvgElement) {
        this._svgElement.next(newElement);
    }

    public get svgElement(): DThreeSvgElement {
        return this._svgElement.value;
    }

    drawableTree: HierarchyPointNode<TrainingTreeNode>;

    constructor(
        private treeNodeToDrawableService: TreeNodeToDrawableService,
        private treeActionService: TreeActionsService,
        private treeStateService: TreeStateService,
        private treeNodeStructureService: TreeNodeStructureService,
        private treeTransitionCreator: TreeTransitionCreatorService,
        private translateService: TranslateService
    ) {
        this.redrawTree();
        this.translateService.onLangChange.subscribe(() => {
            this.redraw(this.treeNodeToDrawableService.drawableTree);
        });
    }

    redrawTree() {
        combineLatest([
            this.treeStateService.selectedTreeNode$,
            this.treeNodeToDrawableService.drawableTree$,
        ])
            .pipe(
                filter(
                    ([selectedNode, drawableTree]) =>
                        selectedNode !== emptyTrainingTreeNode &&
                        drawableTree !== null
                )
            )
            .subscribe(([_, drawableTree]) => this.redraw(drawableTree));
    }

    redraw(drawableTree) {
        this.drawableTree = drawableTree;
        this.renderTree(drawableTree);
        this.treeActionService.addCloseMenuActionTo(this.svgElement);
    }

    calculateMeasurements() {
        const initialParentWidth =
            this.treeNodeToDrawableService.treeDimension.width;
        const initialParentHeight =
            this.treeNodeToDrawableService.treeDimension.height;
        const nodeWidth = NODE_WIDTH;
        const nodeHeight = NODE_HEIGHT;
        let maxLeftX = 0;
        let maxRightX = 0;
        let maxTopY = 0;
        let maxBottomY = 0;
        this.svgElement
            .selectAll<SVGForeignObjectElement, TrainingTreeNode>(
                'foreignObject'
            )
            .data(
                this.drawableTree,
                (node: HierarchyPointNode<TrainingTreeNode>) => {
                    let nodeX = node.x;
                    let nodeY = node.y;

                    if (nodeX < 0 && Math.abs(nodeX) > Math.abs(maxLeftX)) {
                        maxLeftX = nodeX;
                    }
                    if (nodeX > 0 && nodeX + nodeWidth > maxRightX) {
                        maxRightX = nodeX + nodeWidth;
                    }
                    if (nodeY < 0 && Math.abs(nodeY) > Math.abs(maxTopY)) {
                        maxTopY = nodeY;
                    }
                    if (nodeY >= 0 && nodeY + nodeHeight > maxBottomY) {
                        maxBottomY = nodeY + nodeHeight;
                    }
                    return node.data.uuid;
                }
            );

        const totalWidth =
            Math.abs(maxLeftX) + Math.abs(maxRightX) - NODE_MARGIN_LEFT_RIGHT;
        const totalHeight = Math.abs(maxTopY) + Math.abs(maxBottomY);

        const minScale = this.calculateMinScale(
            initialParentWidth,
            initialParentHeight,
            totalWidth,
            totalHeight
        );

        if (this.scale < minScale) {
            this.scale = minScale;
        }

        this.treeData.next({
            minScale,
            treeHeight: totalHeight,
            maxLeftOffset: this.calculateMaxLeftOffset(
                totalWidth,
                initialParentWidth,
                this.scale
            ),
            maxTopOffset: this.calculateMaxTopOffset(
                totalHeight,
                initialParentHeight,
                this.scale
            ),
        });
    }

    private calculateMinScale(
        initialParentWidth: number,
        initialParentHeight: number,
        totalWidth: number,
        totalHeight: number
    ) {
        const newYScale = initialParentHeight / totalHeight;
        const newXScale = initialParentWidth / totalWidth;
        return Math.min(newXScale, newYScale, 1);
    }

    private calculateMaxLeftOffset(
        totalWidth: number,
        initialParentWidth: number,
        scale: number
    ) {
        return Math.min(-(totalWidth * scale - initialParentWidth) / 2, 0);
    }

    private calculateMaxTopOffset(
        totalHeight: number,
        initialParentHeight: number,
        scale: number
    ) {
        return Math.min(-(totalHeight * scale - initialParentHeight) / 2, 0);
    }

    private renderTree(drawableTree: HierarchyPointNode<TrainingTreeNode>) {
        const nodeSelection = this.svgElement
            .selectAll<SVGForeignObjectElement, TrainingTreeNode>(
                'foreignObject'
            )
            .data(
                drawableTree,
                (node: HierarchyPointNode<TrainingTreeNode>) => node.data.uuid
            );

        this.calculateMeasurements();

        const linkSelection = this.svgElement
            .selectAll<SVGPathElement, TrainingTreeNode>('path')
            .data(
                drawableTree.links(),
                (node: HierarchyPointLink<TrainingTreeNode>) => {
                    return node.target.data.uuid;
                }
            );

        this.handleEnter(nodeSelection, linkSelection);
        this.handleUpdate(nodeSelection, linkSelection);
        this.handleExit(nodeSelection, linkSelection);
    }

    private handleEnter(
        nodeSelection: DThreeNodeElement,
        linkSelection: DThreeLinkElement
    ) {
        this.handleNodeEnter(nodeSelection);
        this.handleLinkEnter(linkSelection);
    }

    private handleNodeEnter(nodeSelection: DThreeNodeElement) {
        const nodeEnterSelection = nodeSelection.enter();
        this.drawNewNodes(nodeEnterSelection);
        this.addActionsToNodes(nodeEnterSelection);
    }

    private handleLinkEnter(linkSelection: DThreeLinkElement) {
        const linkEnterSelection = linkSelection.enter();
        this.drawNewLinks(linkEnterSelection);
    }

    private drawNewLinks(enterSelection: DThreeLinkEnterElement) {
        const linkEnterSelection = enterSelection
            .append<SVGPathElement>('path')
            .attr('fill', 'none')
            .attr('stroke', '#929292')
            .attr('stroke-width', '2px');

        this.treeTransitionCreator.addLinkEnterTransition(linkEnterSelection);
    }

    private drawNewNodes(enterSelection: DThreeNodeEnterElement) {
        const selection = enterSelection
            .append('foreignObject')
            .attr('class', 'tree-chart-node')
            .attr('height', NODE_HEIGHT)
            .attr('width', NODE_WIDTH)
            //.attr('node-id', enterSelection.)
            .html((node) => {
                return this.treeNodeStructureService.toHTML(node.data);
            });
        this.treeTransitionCreator.addNodeEnterTransition(selection);
    }

    private addActionsToNodes(enterSelection: DThreeNodeEnterElement) {
        this.treeActionService.addOpenMenuAction(enterSelection);
        this.treeActionService.addNodeClickAction(enterSelection);
    }

    private handleUpdate(
        nodeUpdateSelection: DThreeNodeElement,
        linkUpdateSelection: DThreeLinkElement
    ) {
        nodeUpdateSelection.html((node) => {
            return this.treeNodeStructureService.toHTML(node.data);
        });
        this.treeTransitionCreator.addNodeUpdateTransition(nodeUpdateSelection);
        this.treeTransitionCreator.addLinkUpdateTransition(linkUpdateSelection);
    }

    private handleExit(
        nodeSelection: DThreeNodeEnterElement,
        linkSelection: DThreeLinkElement
    ) {
        const nodeExitSelection = nodeSelection.exit();
        nodeExitSelection.html((node: HierarchyPointNode<TrainingTreeNode>) => {
            return this.treeNodeStructureService.toHTML(node.data);
        });
        this.treeTransitionCreator.addNodeExitTransition(nodeExitSelection);

        const linkExitSelection = linkSelection.exit();
        this.treeTransitionCreator.addLinkExitTransition(linkExitSelection);
    }
}
