import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectLibraryComponent } from './components/project-library/project-library.component';
import { SceneLibraryComponent } from './components/scene-library/scene-library.component';
import { RobotLibraryComponent } from './components/robot-library/robot-library.component';
import { CardModule } from '../card/card.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkspaceComponent } from './components/workspace/workspace.component';
import { ObjectWorkspaceLibraryComponent } from './components/object-workspace-library/object-workspace-library.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { ImageDatabaseLibraryComponent } from './components/image-database-library/image-database-library.component';

@NgModule({
    declarations: [
        ProjectLibraryComponent,
        ObjectWorkspaceLibraryComponent,
        SceneLibraryComponent,
        RobotLibraryComponent,
        ImageDatabaseLibraryComponent,
        WorkspaceComponent,
    ],
    imports: [
        CommonModule,
        CardModule,
        SharedModule,
        NgbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,

                useFactory: HttpLoaderFactory,

                deps: [HttpClient],
            },
        }),
    ],
    exports: [
        ProjectLibraryComponent,
        ObjectWorkspaceLibraryComponent,
        SceneLibraryComponent,
        RobotLibraryComponent,
        ImageDatabaseLibraryComponent,
        WorkspaceComponent,
    ],
})
export class WorkspaceModule {}
