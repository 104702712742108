import { Component, Input, OnInit } from '@angular/core';
import { CardUser } from 'src/app/card/model/card-user';
import { UserService } from 'src/app/authentication/services/user.service';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CardPage } from 'src/app/card/model/card-page';
import {
    Pageable,
    createPageableByRouterParameters,
} from 'src/app/shared/models/pageable';
import { SceneCard } from 'src/app/card/model/card-implementations/scene-card';
import { SceneService } from 'src/app/scenes/services/scene.service';
import { Scene } from 'src/app/scenes/models/scene';
import { SceneAddCard } from 'src/app/card/model/add-card-implementations/scene-add-card';
import { UserProfile } from '../../../authentication/profile/models/user-profile';

@Component({
    selector: 'app-scene-library',
    templateUrl: './scene-library.component.html',
    styleUrls: ['./scene-library.component.scss'],
})
export class SceneLibraryComponent implements OnInit {
    cardPage: CardPage = new CardPage();
    @Input() profilePicture: string;

    constructor(
        private sceneService: SceneService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private authenticationService: AuthenticationService
    ) {}

    ngOnInit(): void {
        if (this.authenticationService.isLoggedIn()) {
            this.userService
                .getUser(this.authenticationService.currentUserValue.id)
                .subscribe((user: UserProfile) => {
                    const cardUser = new CardUser(
                        user.id,
                        user.displayName,
                        this.profilePicture
                    );
                    this.route.queryParams.subscribe((params: Params) => {
                        this.cardPage.pageable =
                            createPageableByRouterParameters(
                                new Pageable(),
                                params
                            );
                        this.sceneService
                            .getScenePage(this.cardPage.pageable)
                            .subscribe((scenePage) => {
                                this.cardPage.itemCards = [];
                                this.cardPage.addCard = new SceneAddCard(
                                    this.sceneService,
                                    this.router
                                );
                                scenePage.content.forEach((scene) => {
                                    this.cardPage.itemCards.push(
                                        SceneCard.mapToScene(scene, cardUser)
                                    );
                                });

                                this.cardPage.numberOfPages =
                                    scenePage.numberOfPages;
                                this.cardPage.pageable.page =
                                    scenePage.currentPage;
                            });
                    });
                });
        } else {
            console.error('There is an internal error.');
            this.authenticationService.signOut();
        }
    }

    changePage(newPage: number): void {
        this.navigateToSelf(newPage, this.cardPage.pageable.pageSize);
    }

    createScene(): void {
        const scene: Scene = new Scene();
        scene.name = 'Noname';
        this.sceneService.createScene(scene).subscribe((retrievedScene) => {
            this.router.navigate(['/scenes/' + retrievedScene.id]);
        });
    }

    private navigateToSelf(page: number, pageSize: number): void {
        this.router.navigate([this.router.url], {
            queryParams: { page, pageSize },
        });
    }
}
