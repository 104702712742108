import { Component, EventEmitter, Output } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { NumberTranslateService } from 'src/app/shared/services/number-translate.service';
import { nonNil } from 'src/app/shared/utility';
import { NumberFormatValidator } from 'src/app/shared/validators/number-format-validator';
import { WeightingValidator } from 'src/app/shared/validators/weighting-validator';
import { environment } from 'src/environments/environment';
import { DistanceCostFunctionRow } from '../../models/distance-cost-function-row';
import { CostFunctionService } from '../../services/cost-function.service';
import { AbstractTabComponent } from '../abstract-tab/abstract-tab.component';

@Component({
    selector: 'app-distance-tab-content',
    templateUrl: './distance-tab-content.component.html',
    styleUrls: ['./distance-tab-content.component.scss'],
})
export class DistanceTabContentComponent extends AbstractTabComponent {
    @Output() disableFixedElementDrag: EventEmitter<boolean> =
        new EventEmitter<boolean>();

    type: string = 'distance';

    showNewElementRow: boolean = true;

    isWeightBeingEditted: boolean = false;

    emptyWeightControl: UntypedFormControl = new UntypedFormControl({
        value: environment.costFunction.weight.default,
        disabled: true,
    });

    constructor(
        costFunctionService: CostFunctionService,
        private numberService: NumberTranslateService
    ) {
        super(costFunctionService);
    }

    removeElement(index: number) {
        super.removeElement(index);

        if (index === this.rows.length) {
            this.showNewElementRow = true;
            this.disableFixedElementDrag.emit(false);
        }
    }

    drop($event: any, keyPrefix: string) {
        let nameKey = `${keyPrefix}SceneElementName`;
        let uuidKey = `${keyPrefix}SceneElementId`;
        let fixedKey = `${keyPrefix}SceneElementFixed`;

        const element: DistanceCostFunctionRow = new DistanceCostFunctionRow();

        const dragSceneElement = $event.item.data.sceneElement;

        if (dragSceneElement.fixed) {
            this.disableFixedElementDrag.emit(true);
        }

        element[nameKey] = dragSceneElement.browserAndPhysicsLoadable.name;
        element[uuidKey] = dragSceneElement.id;
        element[fixedKey] = dragSceneElement.fixed;

        this.rows.push(this.getElementFormGroup(element));

        this.showNewElementRow = false;
    }

    dropInExistingElement($event: any, keyPrefix: string, index: number) {
        let nameKey = `${keyPrefix}SceneElementName`;
        let uuidKey = `${keyPrefix}SceneElementId`;
        let fixedKey = `${keyPrefix}SceneElementFixed`;
        const dragSceneElement = $event.item.data.sceneElement;

        const element: UntypedFormGroup = this.rows[index];

        const isUpdate = !!(
            element.value['sourceSceneElementId'] &&
            element.value['targetSceneElementId']
        );

        if (!isUpdate) {
            this.disableFixedElementDrag.emit(dragSceneElement.fixed);
        }

        // Here we restrict user to change normal element with fixed element if the second element is fixed
        // TODO: notify user why the process was cancelled
        if (
            (keyPrefix === 'target' &&
                dragSceneElement.fixed &&
                element.value['sourceSceneElementFixed']) ||
            (keyPrefix === 'source' &&
                dragSceneElement.fixed &&
                element.value['targetSceneElementFixed'])
        )
            return;

        element
            .get(nameKey)
            .setValue(dragSceneElement.browserAndPhysicsLoadable.name);
        element.get(uuidKey).setValue(dragSceneElement.id);
        element.get(fixedKey).setValue(dragSceneElement.fixed);

        const isCompleteRow = !!(
            element.value['sourceSceneElementId'] &&
            element.value['targetSceneElementId']
        );

        if (index === this.rows.length - 1) {
            this.showNewElementRow = isCompleteRow;
        }

        if (isCompleteRow) {
            element.get('maximize').enable();
            element.get('weight').enable();
            this.disableFixedElementDrag.emit(false);
            if (isUpdate) {
                this.updateRow(element, index);
            } else {
                this.saveRow(element, this.sceneId, this.type);
            }
        }
    }

    // Get a form group for an existing element
    protected getElementFormGroup(element: DistanceCostFunctionRow) {
        this.showNewElementRow =
            nonNil(element.sourceSceneElementId) &&
            nonNil(element.targetSceneElementId);

        return new UntypedFormGroup({
            sourceSceneElementName: new UntypedFormControl(
                element.sourceSceneElementName
            ),
            targetSceneElementName: new UntypedFormControl(
                element.targetSceneElementName
            ),
            sourceSceneElementId: new UntypedFormControl(
                element.sourceSceneElementId
            ),
            targetSceneElementId: new UntypedFormControl(
                element.targetSceneElementId
            ),
            sourceSceneElementFixed: new UntypedFormControl(
                element.sourceSceneElementFixed
            ),
            targetSceneElementFixed: new UntypedFormControl(
                element.targetSceneElementFixed
            ),
            maximize: new UntypedFormControl({
                value: element.maximize,
                disabled: !(
                    element.sourceSceneElementId && element.targetSceneElementId
                ),
            }),
            weight: new UntypedFormControl(
                this.numberService.translateNumber(
                    this.convertElementValueToString(
                        element.weight,
                        this.weightDecimalPoint
                    )
                ),
                [
                    Validators.required,
                    NumberFormatValidator.validateFormat,
                    WeightingValidator.validateRange,
                ]
            ),
        });
    }

    onWeightEditStarted() {
        this.isWeightBeingEditted = true;
    }

    onWeightEditFinished() {
        this.isWeightBeingEditted = false;
    }
}
