<div class="row">
    <div class="col-12">
        <div class="slide-toggle expert-toggle">
            <p class="me-3 slide-toggle-title">
                <!-- “use scene and cost function as training input” vs. “use expert
                knowledge as training input” -->
                {{ 'expertDatasets.toggle' | translate }}
            </p>
            <mat-slide-toggle
                (change)="onToggle()"
                [checked]="usageType !== 'NO_EXPERT_DATA'"
                #toggle
            ></mat-slide-toggle>
        </div>
    </div>
</div>
<div class="row" *ngIf="dataLoaded">
    <div class="algorithm-selector">
        <p class="mr-3 selector-title">
            {{ 'expertDatasets.title' | translate }}
        </p>
        <div class="d-flex align-items-center">
            <div class="tryb-dropdown">
                <select
                    id="expert-data-dropdown"
                    [formControl]="dropdownControl"
                    (change)="setExpertDatasetValue($event.target['value'])"
                >
                    <option
                        *ngFor="let expertData of expertDatasets"
                        [value]="expertData"
                        [id]="expertData"
                    >
                        {{ expertData }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
