import { Component, OnDestroy, OnInit } from '@angular/core';
import { Scene } from '../../../../models/scene';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { environment } from '../../../../../../environments/environment';
import { filter, Subscription } from 'rxjs';
import { SceneStateService } from '../../../../services/scene-state.service';
import { nonNil } from 'src/app/shared/utility';

@Component({
    selector: 'app-scene-settings',
    templateUrl: './scene-settings.component.html',
    styleUrls: ['./scene-settings.component.scss'],
})
export class SceneSettingsComponent implements OnInit, OnDestroy {
    scene: Scene;
    sceneForm: UntypedFormGroup;
    validPattern = environment.namePattern;
    sceneFormSubscription: Subscription;

    constructor(private sceneStateService: SceneStateService) {}

    ngOnInit(): void {
        this.sceneStateService.observeScene$
            .pipe(filter(nonNil))
            .subscribe((currentScene) => {
                this.scene = currentScene;
                this.createSceneForm();
                this.sceneFormSubscription = this.listenToSceneFormChanges();
            });
    }

    ngOnDestroy(): void {
        this.sceneFormSubscription?.unsubscribe();
    }

    private listenToSceneFormChanges(): Subscription {
        return this.sceneForm.valueChanges.subscribe((newFormValues) => {
            if (this.sceneForm.valid) {
                // TODO: emit new scene via event instead of using side effects
                this.scene.name = newFormValues.sceneName;
                console.log('scene-settings Todo update scene');
            }
        });
    }

    private createSceneForm(): void {
        this.sceneForm = new UntypedFormGroup({
            sceneName: new UntypedFormControl(this.scene.name, {
                updateOn: 'blur',
                validators: [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.pattern(this.validPattern),
                ],
            }),
        });
    }
}
