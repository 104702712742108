<div *ngIf="scene">
    <mat-card class="example-card" [formGroup]="sceneForm">
        <input
            formControlName="sceneName"
            type="text"
            class="form-control"
            data-toggle="tooltip"
            data-placement="right"
            title="Szenenname"
        />
        <div
            class="alert alert-danger"
            *ngIf="
                sceneForm.get('sceneName').dirty &&
                sceneForm.get('sceneName').invalid
            "
            style="margin-top: 5px"
        >
            <span *ngIf="sceneForm.get('sceneName').errors.required"
                >{{
                    'scenes.components.editScene.contextArea.sceneSettings.sceneNameError1'
                        | translate
                }}
            </span>
            <span *ngIf="sceneForm.get('sceneName').errors.pattern">
                {{
                    'scenes.components.editScene.contextArea.sceneSettings.sceneNameError2'
                        | translate
                }}</span
            >
            <span *ngIf="sceneForm.get('sceneName').errors.minlength">
                {{
                    'scenes.components.editScene.contextArea.sceneSettings.sceneNameError3'
                        | translate
                }}
            </span>
        </div>
    </mat-card>
</div>
