import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LanguageCode } from '../data/laguage-code';

export enum Events {
    'changeLang',
}

@Injectable({
    providedIn: 'root',
})
export class ChangeLangEventService {
    private langCode = LanguageCode.DE;
    private selectedLanguage$: BehaviorSubject<LanguageCode>;

    constructor() {
        if (localStorage.getItem('change-language')) {
            this.langCode =
                LanguageCode[
                    localStorage.getItem('change-language').toUpperCase()
                ];
        }
        this.selectedLanguage$ = new BehaviorSubject<LanguageCode>(
            this.langCode
        );
    }

    public getChangeLang(): Observable<LanguageCode> {
        return this.selectedLanguage$.asObservable();
    }

    setLangSubject(data: LanguageCode): void {
        this.selectedLanguage$.next(data);
    }
}
