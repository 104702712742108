import { AbstractControl, ValidationErrors } from '@angular/forms';

export class NumberFormatValidator {
    // Use AbstractControl instead of FormControl, see https://githubmemory.com/repo/ngx-formly/ngx-formly/issues/2882
    static validateFormat(control: AbstractControl): ValidationErrors | null {
        let value: string = control.value;

        if (!value) {
            return null;
        }

        const errorToReturn = {
            invalidFormat: {
                inputValue: control.value,
            },
        };

        const number = parseFloat(value.replace(',', '.'));
        if (isNaN(number)) {
            return errorToReturn;
        }

        const acceptDotAndCommaAsSeperator: RegExp = new RegExp(
            '^[-+]?[0-9]*([.|,][0-9]*)?$'
        );
        const isNumberWithDotsOrComma =
            acceptDotAndCommaAsSeperator.test(value);
        return isNumberWithDotsOrComma ? null : errorToReturn;
    }
}
