<div class="img-db">
    <app-header-box [name]="getBackButtonName()"></app-header-box>
    <div class="img-db-content">
        <div class="left">
            <div class="header" *ngIf="!isDbFromTraining">
                <div class="col">
                    <app-editable-label
                        *ngIf="activeViewIdx === 0"
                        #leftLabel
                        [editable]="isNameEditable()"
                        [label]="getA11yForName()"
                        [value]="imageDb?.name"
                        (update)="onNameUpdate($event)"
                    ></app-editable-label>
                    <span class="label" *ngIf="activeViewIdx === 1">
                        {{ 'imgDb.labelManager.labelSet.labelSet' | translate }}
                    </span>
                </div>
            </div>
            <div class="data">
                <div class="side-content-wrapper">
                    <app-img-db-category-manager
                        [isDbOfTraining]="isDbFromTraining"
                        [imageDatabaseId]="imageDb?.id"
                        [categories]="categories"
                        [selectedCategoryNames]="selectedCategoryNames"
                        [numberOfUncategorizedImages]="uncategorizedImagesCount"
                        [isEmptyCategorySelected]="isEmptyCategorySelected"
                        (categoryCreated)="createAndPersistCategory($event)"
                        (categoryDeleted)="deleteCategoryAndPersist($event)"
                        (categoryClicked)="selectOrUnselectCategory($event)"
                        (emptyCategorySelected)="handleEmptyCategorySelected()"
                        *ngIf="activeViewIdx === 0"
                    >
                    </app-img-db-category-manager>
                    <app-classificator-tree
                        *ngIf="activeViewIdx === 1"
                        [categories]="categories"
                        (categorySelected)="handleCategorySelect($event)"
                        (categoryDeleted)="handleCategoryDelete($event)"
                    ></app-classificator-tree>
                </div>
            </div>
        </div>
        <div class="central">
            <div class="header">
                <div class="tabs">
                    <ul class="nav">
                        <li
                            class="nav-item"
                            *ngFor="let item of navItems; index as idx"
                            [class.active]="isViewActive(idx)"
                        >
                            <span
                                [id]="getUniqueNavItemId(item)"
                                class="nav-link"
                                [attr.aria-current]="isViewActive(idx)"
                                (click)="onNavClick(idx)"
                                >{{ item | translate }}</span
                            >
                        </li>
                    </ul>
                </div>
                <div class="header-central">
                    <div *ngIf="activeViewIdx === 1">
                        <button
                            [disabled]="!this.annotationStateService.hasUndo()"
                            [ngClass]="{
                                disabled: !this.annotationStateService.hasUndo()
                            }"
                            (click)="undo()"
                        >
                            <mat-icon class="icon">undo_black_24dp</mat-icon>
                        </button>
                        <button
                            [disabled]="!this.annotationStateService.hasRedo()"
                            [ngClass]="{
                                disabled: !this.annotationStateService.hasRedo()
                            }"
                            (click)="redo()"
                        >
                            <mat-icon class="icon">redo_black_24dp</mat-icon>
                        </button>
                        <button
                            [disabled]="currentZoomLevel === currentDefaultZoom"
                            (click)="onPanButtonClick()"
                            [ngClass]="{
                                disabled:
                                    currentZoomLevel === currentDefaultZoom,
                                active: isPanButtonActive
                            }"
                        >
                            <mat-icon class="icon"
                                >pan_tool_black_24dp
                            </mat-icon>
                        </button>
                        <button
                            (click)="toggleBookmark()"
                            [ngClass]="{ active: isSelectedImageBookmarked() }"
                        >
                            <mat-icon class="icon"
                                >bookmark_black_24dp
                            </mat-icon>
                        </button>
                        <button class="disabled">
                            <mat-icon class="icon"
                                >keyboard_black_24dp
                            </mat-icon>
                        </button>
                        <button class="disabled">
                            <mat-icon class="icon">delete_black_24dp</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="header-right">
                    <div class="selection">
                        <ul class="nav">
                            <li
                                class="nav-item sel-item"
                                [ngClass]="{ disabled: !selectButtonEnabled }"
                                (click)="
                                    selectButtonMode === 'Select'
                                        ? onSelectAllButtonClick(true)
                                        : onSelectAllButtonClick(false)
                                "
                                *ngIf="activeViewIdx === 0"
                            >
                                <span class="nav-link sel-but">{{
                                    (selectButtonMode === 'Select'
                                        ? 'imgDb.imgGallery.selectAll'
                                        : 'imgDb.imgGallery.deselectAll'
                                    ) | translate
                                }}</span>
                            </li>
                        </ul>
                        <div class="zoom-buttons">
                            <label
                                *ngIf="activeViewIdx === 0"
                                for="open_file_dialog"
                                class="upload_label"
                            >
                                <mat-icon class="upload"
                                    >upload_outline
                                </mat-icon>
                            </label>
                            <mat-icon
                                (click)="handleZoom('out')"
                                [ngClass]="{
                                    disabled:
                                        (activeViewIdx === 0 &&
                                            lengthOfImagesList === 0) ||
                                        currentZoomLevel === currentMinZoom
                                }"
                                class="minus"
                                >remove_circle_outline
                            </mat-icon>
                            <mat-icon
                                (click)="handleZoom('default')"
                                class="default"
                                [ngClass]="{
                                    disabled:
                                        (activeViewIdx === 0 &&
                                            lengthOfImagesList === 0) ||
                                        currentZoomLevel === currentDefaultZoom
                                }"
                                >apps
                            </mat-icon>
                            <mat-icon
                                (click)="handleZoom('in')"
                                class="plus"
                                [ngClass]="{
                                    disabled:
                                        (activeViewIdx === 0 &&
                                            lengthOfImagesList === 0) ||
                                        currentZoomLevel === currentMaxZoom
                                }"
                                >add_circle_outline
                            </mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data">
                <div class="central-content-wrapper">
                    <ngx-file-drop
                        (onFileDrop)="onItemDropped($event)"
                        [accept]="getValidImageFormats()"
                    >
                        <ng-template
                            ngx-file-drop-content-tmp
                            let-openFileSelector="openFileSelector"
                        >
                            <app-img-db-gallery
                                *ngIf="
                                    activeViewIdx === 0 &&
                                    imagesStorage.isStorageReady
                                "
                                (selectButtonStatusChanged)="
                                    changeSelectButtonMode($event)
                                "
                            ></app-img-db-gallery>
                            <button
                                id="open_file_dialog"
                                (click)="openFileSelector()"
                                class="d-none"
                            ></button>
                        </ng-template>
                    </ngx-file-drop>
                    <app-img-labeling-viewer
                        *ngIf="activeViewIdx === 1"
                        [selectedCategory]="selectedEditCategory"
                        [annotations]="annotations"
                        [categories]="categories"
                        (addAnnotation)="onAddAnnotation($event)"
                        (zoomLevelChanged)="onZoomLevelChanged($event)"
                        [isDraggingActive]="isPanButtonActive"
                    ></app-img-labeling-viewer>
                </div>
            </div>
        </div>
        <div class="right">
            <div class="header">
                <div class="col">
                    <span class="label info" *ngIf="activeViewIdx === 0">
                        {{ 'imgDb.dbInfo.title' | translate }}
                    </span>
                    <span class="label" *ngIf="activeViewIdx === 1">
                        {{ 'imgDb.dbLabelRight.title' | translate }}
                    </span>
                </div>
            </div>
            <div class="data">
                <div class="side-content-wrapper">
                    <app-img-db-info
                        [dbInfo]="dbInfo"
                        *ngIf="activeViewIdx === 0"
                    >
                    </app-img-db-info>
                    <app-img-db-categories-right
                        *ngIf="activeViewIdx === 1"
                        [categories]="categories"
                        (selectionChanged)="onSelectEditCategory($event)"
                    >
                    </app-img-db-categories-right>
                </div>
            </div>
        </div>
    </div>
</div>
