import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ImgCategory } from '../model/img-category';
import { ColorService } from '../../shared/services/color.service';

@Component({
    selector: 'app-img-db-categories-right',
    templateUrl: './img-db-categories-right.component.html',
    styleUrls: ['./img-db-categories-right.component.scss'],
})
export class ImgDbCategoriesRightComponent {
    @Input() categories: ImgCategory[] = [];
    @Output()
    selectionChanged: EventEmitter<ImgCategory> = new EventEmitter();

    selectedCategory: number;

    constructor(private colorService: ColorService) {}

    isCategorySelected(idx: number): boolean {
        return idx === this.selectedCategory;
    }

    setSelection(idx: number): void {
        this.selectedCategory = idx;
        this.selectionChanged.emit(this.categories[this.selectedCategory]);
    }

    getDefaultColor(categoryName: string): string {
        return this.colorService.getColorWithTransparency(categoryName);
    }

    getActiveColor(categoryName: string): string {
        return this.colorService.getColor(categoryName);
    }
}
