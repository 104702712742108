<div>
    <div class="row">
        <div class="sticky-offset col-md-2 ms-sm-auto col-lg-2"></div>
        <div class="sticky-offset col-md-8 ms-sm-auto col-lg-8">
            <div class="iframe">
                <iframe
                    width="100%"
                    height="100%"
                    title=""
                    [src]="urlSafe"
                ></iframe>
            </div>
        </div>
        <div class="sticky-offset col-md-2 ms-sm-auto col-lg-2">
            <button mat-raised-button (click)="onDelete(this.id)">
                {{ 'urdfLoader.delete' | translate }}
            </button>
        </div>
    </div>
</div>
