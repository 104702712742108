import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProjectMembership } from '../project/models/project-membership';
import { ProjectService } from '../project/services/project.service';
import { NavigationService } from '../shared/services/navigation.service';
import { nonNil } from '../shared/utility';
import { TrainingMediaAction } from '../split-screen/models/media-action';
import { TrainingTile } from '../split-screen/models/training-tile';
import { TrainingTreeNode } from '../split-screen/models/training-tree-node';
import { TreeStateService } from '../split-screen/services/tree-state.service';
import { ProjectWorkspaceNavigationItem } from './model/project-workspace-navigation-item';
import { TrainingStatus } from '../shared/models/training-status';
import { TrainingDTO } from '../shared/models/training-dto';
import { TrainingType } from '../shared/enums/training-type.enum';
import { JobService } from '../split-screen/services/job.service';
import { Container } from '../shared/services/container/container.types';

@Component({
    selector: 'app-project-workspace',
    templateUrl: './project-workspace.component.html',
    styleUrls: ['./project-workspace.component.scss'],
})
export class ProjectWorkspaceComponent implements OnInit, OnDestroy {
    projectId: string;
    selectedNavItem: ProjectWorkspaceNavigationItem;
    project: Container;
    memberDetail: ProjectMembership;
    sideNavStatus: boolean = false;
    resizeElemWorkspace: boolean = false;
    subscriptions: Subscription[] = [];
    selectedTraining: TrainingTreeNode;
    private switchAwayFromTraining: boolean = false;
    tileNameSuffixes: TrainingTile[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private projectService: ProjectService,
        private treeStateService: TreeStateService,
        private jobService: JobService,
        private navigationService: NavigationService
    ) {}

    ngOnInit(): void {
        this.selectedNavItem = ProjectWorkspaceNavigationItem.Trainings;
        this.projectId = this.route.snapshot.paramMap.get('projectId');
        this.route.queryParams.subscribe((params) => {
            if (params.trainingUuid && !this.switchAwayFromTraining) {
                this.selectedNavItem = ProjectWorkspaceNavigationItem.Trainings;
            }
        });

        if (this.projectId !== undefined) {
            let projectSubscription = this.projectService
                .getProjectById(this.projectId)
                .subscribe({
                    next: (data) => {
                        if (data !== undefined) {
                            this.project = data;
                            this.projectService.selectedProject$.next(data);
                        } else {
                            this.redirectToCommunityTab();
                        }
                    },
                    error: (error) => {
                        this.logWarn([
                            'issue fetching project',
                            this.projectId,
                            'error = ',
                            error,
                        ]);

                        this.redirectToCommunityTab();
                    },
                });
            this.subscriptions.push(projectSubscription);
            this.fetchMyProjectMemberData(this.projectId);
        } else {
            this.redirectToCommunityTab();
        }

        // check localstorage if the side navbar is small or normal
        if (localStorage.getItem('sideNavStatus') === 'true') {
            this.sideNavStatus = true;
        }

        let treeStateSubscription =
            this.treeStateService.selectedTreeNode$.subscribe(
                (treeNode: TrainingTreeNode) => {
                    this.selectedTraining = treeNode;
                    this.setTileNameSuffixesBasedOnTrainingType(treeNode);
                }
            );
        this.subscriptions.push(treeStateSubscription);
    }

    setTileNameSuffixesBasedOnTrainingType(treeNode: TrainingTreeNode) {
        if (treeNode.type === TrainingType.MOTORIC) {
            this.tileNameSuffixes = [
                TrainingTile.SCENE,
                TrainingTile.COSTFUNCTION,
                TrainingTile.ALGORITHM,
                TrainingTile.NETWORKARCHITECTURE,
                TrainingTile.EVALUATION,
            ];
        } else if (treeNode.type === TrainingType.VISUAL) {
            this.tileNameSuffixes = [
                TrainingTile.IMAGE_DB,
                TrainingTile.ALGORITHM,
                TrainingTile.NETWORKARCHITECTURE,
                TrainingTile.EVALUATION,
            ];
        } else {
            this.logWarn(
                'TrainingType is wrong. Hexagon titles cannot be set.'
            );
            this.tileNameSuffixes = [];
        }
    }

    redirectToCommunityTab(): void {
        this.router.navigate(['/communityprojects']);
    }

    redirectToProjectSettings(): void {
        this.router.navigate(['settings'], { relativeTo: this.route });
    }

    redirectToProjectMembers(): void {
        this.router.navigate(['members'], { relativeTo: this.route });
    }

    onHandleNavigation(event: ProjectWorkspaceNavigationItem): void {
        this.switchAwayFromTraining =
            event !== ProjectWorkspaceNavigationItem.Trainings;
        this.selectedNavItem = event;
    }

    isOverviewSelected(): boolean {
        return ProjectWorkspaceNavigationItem.Overview === this.selectedNavItem;
    }

    isTrainingsSelected(): boolean {
        return (
            ProjectWorkspaceNavigationItem.Trainings === this.selectedNavItem
        );
    }

    isMembersSelected(): boolean {
        return ProjectWorkspaceNavigationItem.Members === this.selectedNavItem;
    }

    showNavigation(): boolean {
        return false; //disabled was before !!this.project
    }

    getProjectPictureLocation(): string {
        if (
            nonNil(this.project) &&
            nonNil(this.project.pictureLocation) &&
            this.project.pictureLocation.length > 0
        ) {
            return this.project.pictureLocation;
        }
    }

    checkNavSideStatus(event): void {
        if (event === 'normal') {
            this.sideNavStatus = false;
            localStorage.removeItem('sideNavStatus');
            setTimeout(() => (this.resizeElemWorkspace = true), 300);
            setTimeout(() => (this.resizeElemWorkspace = false), 400);
        }

        if (event === 'small') {
            this.sideNavStatus = true;
            localStorage.setItem('sideNavStatus', 'true');
            setTimeout(() => (this.resizeElemWorkspace = true), 300);
            setTimeout(() => (this.resizeElemWorkspace = false), 400);
        }
    }

    getTranslationKey(): string {
        switch (this.selectedNavItem) {
            case ProjectWorkspaceNavigationItem.Members:
                return 'projectWorkspace.components.projectWorkspaceNavigation.members';
            case ProjectWorkspaceNavigationItem.Overview:
                return 'projectWorkspace.components.projectWorkspaceNavigation.overview';
            case ProjectWorkspaceNavigationItem.Trainings:
                return 'projectWorkspace.components.projectWorkspaceNavigation.trainings';
        }
    }

    logWarn(msgData: any) {
        if (console && console.warn) {
            console.warn(msgData);
        }
    }

    fetchMyProjectMemberData(projectId: string) {
        if (!this.memberDetail) {
            this.projectService.getMyProjectMembership(projectId).subscribe({
                next: (detail) => {
                    this.memberDetail = detail;
                },
                error: (error) => {
                    this.logWarn([
                        'issue fetching project-member',
                        projectId,
                        'error = ',
                        error,
                    ]);
                    this.redirectToCommunityTab();
                },
            });
        }
    }

    onTrainingMediaButtonClick(action: TrainingMediaAction) {
        if (action === TrainingMediaAction.START) {
            this.jobService.start(this.selectedTraining).subscribe(() => {
                this.selectedTraining.status = TrainingStatus.QUEUED;
                this.treeStateService.setTreeHasRunningTraining(true);
            });
        } else if (action === TrainingMediaAction.STOP) {
            this.jobService.stop(this.selectedTraining).subscribe(() => {
                this.selectedTraining.status = TrainingStatus.CANCELLING;
            });
        }
    }

    onTileClick(tileName: TrainingTile) {
        this.treeStateService
            .getTrainingOfSelectedNode()
            .subscribe((training: TrainingDTO) => {
                this.navigateBasedOnClickedTile(tileName, training);
            });
    }

    private navigateBasedOnClickedTile(
        tileName: TrainingTile,
        training: TrainingDTO
    ) {
        const url = `/project/${this.projectId}/training/${training.uuid}/`;
        switch (tileName) {
            case TrainingTile.SCENE:
                this.navigateToDetails(url + 'scene');
                break;
            case TrainingTile.NETWORKARCHITECTURE:
                this.navigateToDetails(url + 'skillarchitecture');
                break;
            case TrainingTile.EVALUATION:
                this.navigateToDetails(url + 'evaluation');
                break;
            case TrainingTile.COSTFUNCTION:
                this.navigateToDetails(url + 'costfunction');
                break;
            case TrainingTile.ALGORITHM:
                this.navigateToDetails(url + 'algorithm');
                break;
            case TrainingTile.IMAGE_DB:
                this.navigateToDetails(url + 'imagedb');
                break;
        }
    }

    private navigateToDetails(url: string) {
        this.router.navigate([url]);
        this.navigationService.saveTrainingHistory();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }
}
