import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { MessageData, MessageType } from '../models/message';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    private subject: Subject<MessageData> = new Subject();

    constructor(private translateService: TranslateService) {}

    get(): Observable<MessageData> {
        return this.subject.asObservable();
    }

    displayMessage(messageData: MessageData): void {
        this.subject.next(messageData);
    }

    deleteMessage(): void {
        this.subject.next(null);
    }

    displaySuccessMessage(messageText: string): void {
        this.displayMessageSimpleMessage(MessageType.SUCCESS, messageText);
    }

    displayErrorMessage(messageText: string): void {
        this.displayMessageSimpleMessage(MessageType.ERROR, messageText);
    }

    displayTranslatedErrorMessage(
        messageTranslationKey: string,
        interpolateParam: Object
    ) {
        let translatedMessage: string = this.translateService.instant(
            messageTranslationKey,
            interpolateParam
        );
        this.displayMessageSimpleMessage(MessageType.ERROR, translatedMessage);
    }

    displayTranslatedSuccessMessage(
        messageTranslationKey: string,
        interpolateParam: Object
    ) {
        let translatedMessage: string = this.translateService.instant(
            messageTranslationKey,
            interpolateParam
        );
        this.displayMessageSimpleMessage(
            MessageType.SUCCESS,
            translatedMessage
        );
    }

    displayHttpErrorOrDefault(httpError: any, defaultMessage: string): void {
        const errorMessage = httpError?.error?.message;
        if (errorMessage) {
            this.displayErrorMessage(errorMessage);
        } else {
            this.displayErrorMessage(defaultMessage);
        }
    }

    private displayMessageSimpleMessage(
        messageType: MessageType,
        messageText: string
    ): void {
        const messageData: MessageData = {
            type: messageType,
            message: {
                text: messageText,
            },
        };
        this.displayMessage(messageData);
    }
}
