import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './navigation/page-not-found/page-not-found.component';
import { LandingPageComponent } from './navigation/landing-page/landing-page.component';
import { loginGuard } from './authentication/services/login.guard';
import { ProfilePageComponent } from './authentication/profile/components/profile-page/profile-page.component';
import { AdminComponent } from './admin/components/admin/admin.component';
import { UrdfComponent } from './urdf-loader/components/urdf/urdf.component';
import { UserComponent } from './user/user.component';
import { CommunityProjectComponent } from './project/component/community-project/community-project.component';
import { EditSceneComponent } from './scenes/components/edit-scene/edit-scene.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './authentication/reset-password/forget-password/forget-password.component';
import { ProjectSummaryComponent } from './project/component/project-summary/project-summary.component';
import { ProjectWorkspaceComponent } from './project-workspace/project-workspace.component';
import { CostFunctionComponent } from './cost-function/components/cost-function/cost-function.component';
import { WorkspaceComponent } from './workspace/components/workspace/workspace.component';
import { VerifyComponent } from './authentication/verify/verify.component';
import { CmsPageComponent } from './shopware-cms/components/cms-page/cms-page.component';
import { ImgDbComponent } from './img-db/img-db.module';
import { AlgorithmComponent } from './algorithm/components/algorithm/algorithm.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { ProjectSettingsComponent } from './project-workspace/components/project-settings/project-settings.component';
import { projectOwnerGuard } from './project-workspace/project-owner.guard';
import { ProjectMembersComponent } from './project-workspace/components/project-members/project-members.component';
import { NeuralNetworkComponent } from './skill-architecture-editor/neural-network/neural-network.component';

const routes: Routes = [
    { path: 'home', component: LandingPageComponent },
    {
        path: 'user/:displayName',
        component: UserComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'profile',
        component: ProfilePageComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'project/:projectId/training/:trainingId/scene',
        component: EditSceneComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'project/:projectId/training/:trainingId/skillarchitecture',
        component: NeuralNetworkComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'project/:projectId/training/:trainingId/costfunction',
        component: CostFunctionComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'project/:projectId/training/:trainingId/algorithm',
        component: AlgorithmComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'project/:projectId/training/:trainingId/evaluation',
        component: EvaluationComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'project/:projectId/training/:trainingId/imagedb',
        component: ImgDbComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'urdf',
        component: UrdfComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'project/:projectId',
        component: ProjectWorkspaceComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'project/:projectId/settings',
        component: ProjectSettingsComponent,
        canActivate: [loginGuard, projectOwnerGuard],
    },
    {
        path: 'project/:projectId/members',
        component: ProjectMembersComponent,
        canActivate: [loginGuard, projectOwnerGuard],
    },
    {
        path: 'communityprojects',
        component: CommunityProjectComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'communityprojects/:projectId/summary',
        component: ProjectSummaryComponent,
    },
    {
        path: 'workspace',
        component: WorkspaceComponent,
        canActivate: [loginGuard],
    },
    {
        path: 'imgdb/:imgDbId',
        component: ImgDbComponent,
        canActivate: [loginGuard],
    },

    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
    },
    { path: 'reset/password', component: ResetPasswordComponent },
    { path: 'verify', component: VerifyComponent },
    { path: 'forget/password', component: ForgetPasswordComponent },
    { path: 'Doku-Test', component: CmsPageComponent },
    { path: 'cms/:categoryId', component: CmsPageComponent },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(routes, {
            /* scrollPositionRestoration: 'enabled', */
            onSameUrlNavigation: 'reload',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
