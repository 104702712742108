import { UserActivityResponse } from './user-activity-response';
import { UserActivity } from './activities/user-activity';
import { UserActivityType } from './user-activity-type';
import { UserJoinedActivity } from './activities/user-joined-activity';
import { StartTrainingActivity } from './activities/start-training-activity';
import { CompletedTrainingActivity } from './activities/completed-training-activity';
import { CreatedProjectActivity } from './activities/created-project-activity';
import { JoinedProjectActivity } from './activities/joined-project-activity';

export class UserActivityCreator {
    static createActivity(response: UserActivityResponse): UserActivity {
        switch (response.activityType) {
            case UserActivityType.JOINED_TRYB:
                return new UserJoinedActivity(response);
            case UserActivityType.TRAINING_STARTED:
                return new StartTrainingActivity(response);
            case UserActivityType.TRAINING_COMPLETED:
                return new CompletedTrainingActivity(response);
            case UserActivityType.JOINED_PROJECT:
                return new JoinedProjectActivity(response);
            case UserActivityType.CREATED_PROJECT:
                return new CreatedProjectActivity(response);
        }
    }
}
