import { AbstractControl, ValidationErrors } from '@angular/forms';

export class WeightingValidator {
    static validateRange(control: AbstractControl): ValidationErrors | null {
        if (isEmptyField(control)) {
            return {
                required: {
                    inputValue: control.value,
                },
            };
        }

        let value: string = control.value;
        //TODO: replace with real internationalization
        value = value.replace(',', '.');
        const parsedValue: number = parseFloat(value);

        const min = 0;
        const max = 999999.99;

        const isWithinRange = min <= parsedValue && parsedValue <= max;
        return isWithinRange
            ? null
            : {
                  invalidRange: {
                      inputValue: control.value,
                  },
              };
    }
}

function isEmptyField(control: AbstractControl): boolean {
    return !control.value;
}
