import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { PanZoomAPI, PanZoomConfig, PanZoomModel } from 'ngx-panzoom';
import { Subject, Subscription, switchMap, tap } from 'rxjs';
import { ImgWithSelection } from 'src/app/img-db/model/img-with-selection';
import { ImgAnnotation } from '../model/img-annotation';
import { ImgCategory } from '../model/img-category';
import { ImgDbZoomService } from '../services/img-db-zoom.service';
import { AnnotationStateService } from '../services/annotation-state.service';
import { ColorService } from '../../shared/services/color.service';
import { ImagesStorageService } from '../services/images-storage.service';
import { take, takeUntil } from 'rxjs/operators';
import { PagingResult } from '../../shared/models/pageable';

@Component({
    selector: 'app-img-labeling-viewer',
    templateUrl: './img-labeling-viewer.component.html',
    styleUrls: ['./img-labeling-viewer.component.scss'],
})
export class ImgLabelingViewerComponent
    implements OnInit, OnChanges, AfterViewChecked, OnDestroy
{
    readonly PAGE_SIZE: number = 8;

    @Output()
    addAnnotation: EventEmitter<ImgAnnotation> = new EventEmitter();

    @Output()
    zoomLevelChanged: EventEmitter<number> = new EventEmitter();

    @Input() selectedCategory: ImgCategory;
    @Input() categories: ImgCategory[];
    @Input() isDraggingActive: boolean = false;

    images: ImgWithSelection[] = [];
    selectedImage: ImgWithSelection;
    isLeftArrowVisible: boolean;
    isRightArrowVisible: boolean;
    totalPages: number;
    totalElements: number;
    currentImageNumber: number = 1;
    currentPage: number = 1;
    timer: any;

    @ViewChild('labeling') labeling: ElementRef;
    @ViewChild('currentImage') currentImage: ElementRef;
    @ViewChild('panzoomParent') panzoomParent: ElementRef;

    @ViewChildren('img') queryList: QueryList<ElementRef>;

    selectedAnnotation?: ImgAnnotation = null;
    serializedImgRefs: Element[] = [];
    svgStatus: any;
    annotations: ImgAnnotation[];
    drawingEventListenerRef: any;

    currentZoomLevel: number = 0;

    // panZoom configuration object
    panZoomConfig: PanZoomConfig = new PanZoomConfig({
        zoomLevels: 14, // default + 13 levels
        scalePerZoomLevel: 1.2, // 20% zoom per level
        initialZoomLevel: 0,
        zoomOnMouseWheel: true,
        zoomOnDoubleClick: false,
        invertMouseWheel: true,
        freeMouseWheel: false, // make mouse wheel zoom in 20% steps as defined above
        panOnClickDrag: false,
    });
    private panZoomAPI: PanZoomAPI;

    // check if panning was started and ended
    imagePanned: boolean = true;

    //check if zooming was started and ended
    imageZoomed: boolean = false;

    targetZoomLevel: number = 0;

    // used to set and clear delayed alignment after zoom
    zoomAlignmentTimeoutId: any;

    cursorView: string = 'default';

    private subscriptions: Subscription[] = [];
    private destroy$: Subject<void> = new Subject<void>();

    showImageContainer: boolean = false;

    constructor(
        private annotationStateService: AnnotationStateService,
        private colorService: ColorService,
        private zoomService: ImgDbZoomService,
        private storage: ImagesStorageService
    ) {
        this.isLeftArrowVisible = false;
        this.isRightArrowVisible = false;
    }

    ngOnInit() {
        this.subscriptions.push(
            this.storage
                .getImages()
                .pipe(
                    take(1),
                    tap((page: PagingResult<ImgWithSelection>) =>
                        this.initializeData(page)
                    ),
                    switchMap(() => {
                        return this.annotationStateService.$annotations.pipe(
                            tap(
                                (annotations: ImgAnnotation[]) =>
                                    (this.annotations = annotations)
                            ),
                            takeUntil(this.destroy$)
                        );
                    }),
                    switchMap(() => {
                        return this.panZoomConfig.api.pipe(
                            tap((api: PanZoomAPI) => {
                                this.panZoomAPI = api;
                            }),
                            takeUntil(this.destroy$)
                        );
                    }),
                    switchMap(() => {
                        return this.panZoomConfig.modelChanged.pipe(
                            tap((model: PanZoomModel) => {
                                this.onModelChanged(model);
                            }),
                            takeUntil(this.destroy$)
                        );
                    }),
                    switchMap(() => {
                        return this.zoomService.zoomLevelLabelDialog.pipe(
                            tap((zoomLevel: number) => {
                                this.onZoomChanged(zoomLevel);
                            }),
                            takeUntil(this.destroy$)
                        );
                    })
                )
                .subscribe()
        );
    }

    initializeData(page: PagingResult<ImgWithSelection>) {
        this.svgStatus = {
            w: '100%',
            h: '100%',
            viewBox: '0 0 0 0',
        };

        if (page?.values?.length) {
            this.images = page.values;
            this.selectImage(this.images[0]);
            this.showImageContainer = true;
            this.totalElements = page.totalElements;
            this.totalPages = page.totalPages;
            this.checkArrowsAvailability();
        }
    }

    ngOnDestroy(): void {
        // unsubscribe from the subscriptions
        this.destroy$.complete();
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
        this.annotationStateService.$annotations.next([]);
    }

    // this is called every time the image is dragged or zoomed (for each animation step)
    onModelChanged(model: PanZoomModel) {
        if (model.isPanning) {
            this.imagePanned = true;
            clearTimeout(this.zoomAlignmentTimeoutId); //abort alignment after zoom
        } else if (this.imagePanned) {
            this.imagePanned = false;
            this.keepImageInBounds();
        } else if (model.zoomLevel !== this.targetZoomLevel) {
            // go here if image is currently zooming
            this.imageZoomed = true;
            clearTimeout(this.zoomAlignmentTimeoutId); //abort alignment after zoom
            // set current zoom level to target zoom level of current animation
            if (model.zoomLevel > this.currentZoomLevel) {
                this.targetZoomLevel = Math.ceil(model.zoomLevel);
            } else {
                this.targetZoomLevel = Math.floor(model.zoomLevel);
            }
        } else if (this.imageZoomed) {
            // go here if image zoom is finished
            this.imageZoomed = false;
            this.currentZoomLevel = this.targetZoomLevel;
            this.zoomService.setCurrentZoomLevel(this.currentZoomLevel);
            this.zoomLevelChanged.emit(this.currentZoomLevel);
            // align image after zoom animation is finished with delay
            this.zoomAlignmentTimeoutId = setTimeout(() => {
                if (!this.imageZoomed) {
                    this.keepImageInBounds();
                }
            }, 400);
        }
    }

    private keepImageInBounds() {
        const model = this.panZoomAPI.model;
        const parentWidth = this.panzoomParent.nativeElement.offsetWidth;
        const parentHeight = this.panzoomParent.nativeElement.offsetHeight;
        const imgWidth =
            this.currentImage.nativeElement.getBoundingClientRect().width;
        const imgHeight =
            this.currentImage.nativeElement.getBoundingClientRect().height;

        // current zoomLevel. This will return 0,1,2,etc, NOT the actual scale like 1, 1.2, 1.44 and so on
        const zoomLevel = this.currentZoomLevel;

        // actual scale value
        const scaleValue = Math.pow(1.2, zoomLevel);

        let newX = model.pan.x;
        let newY = model.pan.y;

        // if the width of the image is less than or equal to the frame width
        // we need to center the image horizontally
        if (imgWidth <= parentWidth) {
            newX = parentWidth / 2 - imgWidth / 2;
        } else {
            // if the image width is larger than the frame, we need to make sure that edges
            // of the image don't go inside the frame, in other words, the image should always
            // cover the entire width of the frame.
            // x > 0 means the left side of the image inside the frame
            if (model.pan.x > 1) {
                newX = 0; // return the left side of the image on the left edge of the frame, , i.e. first valid value
            } else if (model.pan.x + imgWidth < parentWidth) {
                // check the right side of them image, x + imgWidth < parentWidth means the right side of the image is inside the frame.
                // return it to the position where right side of the image is on the right edge of the frame, i.e. first valid value.
                // x + imgWidth = parentWidth, i.e. x = parentWidth - imgWidth
                newX = parentWidth - imgWidth;
            }
        }
        // Do the exact same as above but for y coordinate and heights
        if (imgHeight <= parentHeight) {
            newY = parentHeight / 2 - imgHeight / 2;
        } else {
            if (model.pan.y > 1) {
                newY = 0;
            } else if (model.pan.y + imgHeight < parentHeight) {
                newY = parentHeight - imgHeight;
            }
        }

        // set the delta position of the image, i.e. the distance of x and y that the image needs to be panned by
        const dx = ((newX - model.pan.x) * -1) / scaleValue;
        const dy = ((newY - model.pan.y) * -1) / scaleValue;
        // We need to check if there are any differences and only call penDelta when necessary.
        // Calling penDelta all the time can disable zoom.
        // We're comparing the absolute values to 2 and not 0 in order to avoid jittering due to small margins like 0.1, 0.2, etc
        if (Math.abs(dx) > 2 || Math.abs(dy) > 2) {
            this.panZoomAPI.panDelta(
                {
                    x: dx,
                    y: dy,
                },
                0
            );
        }
    }

    private onZoomChanged(zoomLevel: number) {
        if (zoomLevel > 13 || zoomLevel < 0 || !this.selectedImage) {
            return;
        }
        const point = {
            x: this.panzoomParent.nativeElement.offsetWidth / 2,
            y: this.panzoomParent.nativeElement.offsetHeight / 2,
        };
        this.panZoomAPI.changeZoomLevel(zoomLevel, point);
    }

    resetZoom() {
        this.zoomService.setCurrentZoomLevel(0);
        this.onZoomChanged(0);
        this.keepImageInBounds();
    }

    // Disables and enables dragging the image

    toggleImageDragging(enable: boolean) {
        if (this.panZoomAPI) {
            this.panZoomAPI.config.panOnClickDrag = enable;
            this.cursorView = enable ? 'grab' : 'default';
        }
        this.enableDrawing(!enable);
    }

    updateSvgStatus() {
        this.svgStatus = {
            w: this.currentImage.nativeElement.clientWidth,
            h: this.currentImage.nativeElement.clientHeight,
            viewBox: `0 0 ${this.selectedImage.width} ${this.selectedImage.height}`,
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.selectedCategory && this.labeling) {
                this.enableDrawing(this.canEdit());
                this.toggleImageDragging(false);
            }
        }
    }

    ngAfterViewChecked(): void {
        this.serializedImgRefs = this.queryList.map((ref) => ref.nativeElement);
        this.queryList.changes.subscribe((_) => {
            this.serializedImgRefs = this.queryList.map(
                (ref) => ref.nativeElement
            );
        });
    }

    getSvgViewbox() {
        if (!this.selectedImage) return;

        return `0 0 ${this.selectedImage.width} ${this.selectedImage.height}`;
    }

    getSvgWidthAndHeight() {
        if (!this.currentImage || !this.currentImage.nativeElement) {
            return {
                w: '100%',
                h: '100%',
            };
        }

        return {
            w: this.currentImage.nativeElement.clientWidth,
            h: this.currentImage.nativeElement.clientHeight,
        };
    }

    enableDrawing(enable: boolean): void {
        const svg = this.labeling.nativeElement;

        if (enable && !this.drawingEventListenerRef && this.canEdit()) {
            this.drawingEventListenerRef = (evt) => {
                this.handleDrawing(evt);
            };
            svg.addEventListener('mousedown', this.drawingEventListenerRef);
        } else if (!enable) {
            svg.removeEventListener('mousedown', this.drawingEventListenerRef);
            this.drawingEventListenerRef = null;
        }
    }

    handleDrawing(event: MouseEvent) {
        const svg = this.labeling.nativeElement;
        const start = this.getSVGPoint(svg, event.clientX, event.clientY);
        const rect = this.createSVGElement('rect');
        const g = this.createSVGElement('g');
        svg.appendChild(g);
        g.appendChild(rect);

        const drawRect = (e: MouseEvent) => {
            const p = this.getSVGPoint(svg, e.clientX, e.clientY);
            const [x, y, w, h] = this.calculateRect(start, p);
            rect.setAttribute('x', x.toString());
            rect.setAttribute('y', y.toString());
            rect.setAttribute('width', w.toString());
            rect.setAttribute('height', h.toString());
            rect.setAttribute(
                'stroke',
                this.getColor(this.selectedCategory.name)
            );
        };

        const endDraw = (_) => {
            const rectWidth = parseFloat(rect.getAttribute('width'));
            const rectHeight: number = parseFloat(rect.getAttribute('height'));
            const rectX = parseFloat(rect.getAttribute('x'));
            const rectY = parseFloat(rect.getAttribute('y'));
            if (rectWidth > 0 && rectHeight > 0) {
                let annotation = new ImgAnnotation();
                annotation.imageId = this.selectedImage.id;
                annotation.categoryId = this.selectedCategory.id + '';
                annotation.bbox = new DOMRect(
                    rectX,
                    rectY,
                    rectWidth,
                    rectHeight
                );
                this.fireAddAnnotationEvent(annotation);
                this.selectedAnnotation = null;
                g.remove();
            }

            svg.removeEventListener('mousemove', drawRect);
            svg.removeEventListener('mouseup', endDraw);
        };

        svg.addEventListener('mousemove', drawRect);
        svg.addEventListener('mouseup', endDraw);
    }

    selectAnnotation(annotation: ImgAnnotation) {
        if (!this.panZoomAPI.config.panOnClickDrag) {
            this.annotationStateService.selectAnnotation(annotation.id);
        }
    }

    selectImage(img: ImgWithSelection) {
        this.selectedImage = img;
        this.storage.selectedImage$.next(this.selectedImage);
        this.onImagesChanged();
    }

    getSVGPoint(svg: SVGSVGElement, x: number, y: number): SVGPoint {
        const p = svg.createSVGPoint();
        p.x = x;
        p.y = y;
        return p.matrixTransform(svg.getScreenCTM().inverse());
    }

    createSVGElement(tagName: string): SVGElement {
        return document.createElementNS('http://www.w3.org/2000/svg', tagName);
    }

    calculateRect(
        start: DOMPoint,
        end: DOMPoint
    ): [number, number, number, number] {
        const x = Math.min(start.x, end.x);
        const y = Math.min(start.y, end.y);
        const w = Math.abs(end.x - start.x);
        const h = Math.abs(end.y - start.y);
        return [x, y, w, h];
    }

    fireAddAnnotationEvent(annotation: ImgAnnotation) {
        this.addAnnotation.emit(annotation);
    }

    getImgCategoryName(id: string) {
        return this.findImgCategoryById(id)?.name;
    }

    private findImgCategoryById(id: string) {
        return this.categories.find((category: ImgCategory) => {
            return category.id === id;
        });
    }

    canEdit(): boolean {
        return (
            this.selectedCategory !== null &&
            this.selectedCategory !== undefined
        );
    }

    onImagesChanged(): void {
        if (!this.labeling || !this.labeling.nativeElement) {
            return;
        }
        const rects = this.labeling.nativeElement.querySelectorAll('g');
        if (!rects || !rects.length) return;
        rects.forEach((element) => {
            element.remove();
        });
        this.resetZoom();
    }

    getNextPage() {
        if (this.currentPage + 1 <= this.totalPages) {
            this.currentPage++;
            this.storage.currentPage = this.currentPage;
            this.getNewPage();
        }
    }

    getPrevPage() {
        if (this.currentPage - 1 >= 1) {
            this.currentPage--;
            this.storage.currentPage = this.currentPage;
            this.getNewPage();
        }
    }

    getNewPage(indexOfImageToBeSelected?: number) {
        // Timeout to prevent unnecessary requests when user clicks repeatedly
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.storage
                .getImages()
                .pipe(take(1))
                .subscribe((page: PagingResult<ImgWithSelection>) => {
                    this.images = page.values;
                    this.checkArrowsAvailability();
                    if (
                        typeof indexOfImageToBeSelected === 'number' &&
                        indexOfImageToBeSelected >= 0
                    ) {
                        this.selectImage(this.images[indexOfImageToBeSelected]);
                    }
                });
        }, 300);
    }

    private checkArrowsAvailability() {
        this.isRightArrowVisible = this.currentPage < this.totalPages;
        this.isLeftArrowVisible = this.currentPage > 1;
    }

    getColor(categoryName: string) {
        return this.colorService.getColor(categoryName);
    }

    onNextValue(numberProvidedFromPagingBar: number) {
        if (this.currentImageNumber !== numberProvidedFromPagingBar) {
            this.currentImageNumber = numberProvidedFromPagingBar;

            const indexOfImageToBeSelected =
                this.findIndexOfImageToBeSelected(this.currentImageNumber) - 1;

            const newPage = this.calculateNextPage(numberProvidedFromPagingBar);
            if (newPage !== this.currentPage) {
                this.currentPage = newPage;
                this.storage.currentPage = this.currentPage;
                this.getNewPage(indexOfImageToBeSelected);
            } else {
                this.selectImage(this.images[indexOfImageToBeSelected]);
            }
        }
    }

    private calculateNextPage(val: number): number {
        return Math.ceil(val / this.PAGE_SIZE);
    }

    private findIndexOfImageToBeSelected(imageToGet: number): number {
        return imageToGet % this.PAGE_SIZE || this.PAGE_SIZE;
    }

    onSelectImage(indexOfSelectedImage: number) {
        this.selectImage(this.images[indexOfSelectedImage]);
        this.currentImageNumber = this.findImageIndexToProvide();
    }

    private findImageIndexToProvide(): number {
        const idx = this.images.findIndex(
            (img: ImgWithSelection) => img.id === this.selectedImage.id
        );

        return idx + this.PAGE_SIZE * (this.currentPage - 1) + 1;
    }
}
