import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MaterialModule } from '../material.module';
import { PaginationComponent } from './components/pagination/pagination.component';
import { HeaderBoxComponent } from './components/header-box/header-box.component';
import { DisplayErrorsComponent } from './components/display-errors/display-errors.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WizardFormComponent } from './components/wizard-form/wizard-form.component';
import { FloatingNumberInputComponent } from './components/floating-number-input/floating-number-input.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselModule } from 'primeng/carousel';
import { InputPagingBarComponent } from './components/input-paging-bar/input-paging-bar.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { ProjectOverviewLayoutComponent } from './components/project-overview-layout/project-overview-layout.component';
import { NewLineToBrPipe } from './pipes/new-line-to-br.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { ToastMessageContainerComponent } from './components/toast-message-container/toast-message-container.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        PaginationComponent,
        HeaderBoxComponent,
        DisplayErrorsComponent,
        WizardFormComponent,
        FloatingNumberInputComponent,
        LineChartComponent,
        CarouselComponent,
        InputPagingBarComponent,
        UserInfoComponent,
        ProjectOverviewLayoutComponent,
        NewLineToBrPipe,
        LocalizedDatePipe,
        ToastMessageContainerComponent,
    ],
    imports: [
        CommonModule,
        SharedRoutingModule,
        MaterialModule,
        ReactiveFormsModule,
        NgbToastModule,
        TranslateModule,
        CarouselModule,
        FormsModule,
    ],
    exports: [
        PaginationComponent,
        HeaderBoxComponent,
        DisplayErrorsComponent,
        WizardFormComponent,
        FloatingNumberInputComponent,
        LineChartComponent,
        CarouselComponent,
        InputPagingBarComponent,
        UserInfoComponent,
        ProjectOverviewLayoutComponent,
        NewLineToBrPipe,
        LocalizedDatePipe,
        ToastMessageContainerComponent,
    ],
})
export class SharedModule {}
