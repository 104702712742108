import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { handleError } from 'src/app/shared/http-utilities';
import { environment } from 'src/environments/environment';
import { AlgorithmDTO } from '../models/algorithm-form';

@Injectable({
    providedIn: 'root',
})
export class AlgorithmService {
    readonly restUrl: string;

    constructor(private http: HttpClient) {
        this.restUrl = environment.backendUrl + '/rest/training';
    }

    getAlgorithmFormData(trainingId: string) {
        const url = this.createUrl(trainingId);
        return this.http.get<AlgorithmDTO>(url).pipe(catchError(handleError));
    }

    saveAlgorithmFormData(trainingId: string, formData: AlgorithmDTO) {
        const url = this.createUrl(trainingId);

        const param = JSON.stringify(formData.parameter);

        const val = {
            type: formData.type,
            parameter: param,
            advancedSetting: formData.advancedSetting,
        };

        return this.http.put(url, val).pipe(catchError(handleError));
    }

    private createUrl(trainingId: string): string {
        return `${this.restUrl}/${trainingId}/algorithm`;
    }
}
