<mat-card class="global">
    <mat-card-content>
        <h2 class="global-title">
            {{ 'admin.components.globalConfig.training' | translate }}
        </h2>
        <section class="global-section">
            <mat-slide-toggle
                class="global-settings training-active-toggle"
                [color]="colorTrainingActive"
                [disabled]="disabledTrainingActive"
                [checked]="checkedTrainingActive"
                (change)="onChangeTrainingActive($event)"
            >
                {{ titleTrainingActive | translate}}
            </mat-slide-toggle>
            <mat-slide-toggle
                class="global-settings cloud-toggle"
                [color]="colorCloudActive"
                [disabled]="disabledCloudActive"
                [checked]="checkedCloudActive"
                (change)="onChangeCloudActive($event)"
            >
                {{ titleCloudActive  | translate}}
            </mat-slide-toggle>
        </section>
    </mat-card-content>
</mat-card>
