<mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step label="{{ 'robots.components.addGitObject.importFromGit' | translate }}" [completed]="uri">
        <mat-form-field appearance="fill">
            <mat-label>Git URL</mat-label>
            <input
                matInput
                [(ngModel)]="uri"
                [ngModelOptions]="{ standalone: true }"
            />
        </mat-form-field>
        <br />
        <mat-checkbox class="example-margin" [(ngModel)]="isPrivate">
            Private Repo
        </mat-checkbox>
        <br />
        <mat-form-field *ngIf="isPrivate" appearance="fill">
            <mat-label>{{
                'robots.components.addGitObject.username' | translate
                }}</mat-label>
            <input
                matInput
                [(ngModel)]="username"
                [ngModelOptions]="{ standalone: true }"
            />
        </mat-form-field>
        <br />
        <mat-form-field *ngIf="isPrivate" appearance="fill">
            <mat-label>{{
                'robots.components.addGitObject.enterPassword' | translate
                }}</mat-label>
            <input
                matInput
                [type]="hide ? 'password' : 'text'"
                [(ngModel)]="password"
                [ngModelOptions]="{ standalone: true }"
            />
            <button
                mat-icon-button
                matSuffix
                (click)="hide = !hide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide"
            >
                <mat-icon>{{
                    hide ? 'visibility_off' : 'visibility'
                    }}</mat-icon>
            </button>
            <br />
        </mat-form-field>
        <br />
        <br />
        <br />
        <div>
            <button mat-button matStepperNext>
                {{ 'robots.components.addGitObject.next1' | translate }}
            </button>
        </div>
    </mat-step>
    <mat-step [completed]="step2completed">
        <mat-progress-bar value="{{ progress }}"></mat-progress-bar>
        <ng-template matStepLabel>{{
            'robots.components.addGitObject.objectName1' | translate
            }}</ng-template>
        <mat-form-field appearance="fill">
            <mat-label>{{
                'robots.components.addGitObject.objectName2' | translate
                }}</mat-label>
            <mat-error *ngIf="objectFormControl.hasError('required')">
                {{ 'robots.components.addGitObject.objectNameIs' | translate }}
                <strong>{{
                    'robots.components.addGitObject.required' | translate
                    }}</strong>
            </mat-error>
            <mat-error *ngIf="objectFormControl.hasError('pattern')">
                {{
                'robots.components.addGitObject.objectNameError1'
                    | translate
                }}
            </mat-error>
            <mat-error *ngIf="objectFormControl.hasError('maxlength')">
                {{
                'robots.components.addGitObject.objectNameError2'
                    | translate
                }}
            </mat-error>
            <input
                matInput
                matInput
                [formControl]="objectFormControl"
                [errorStateMatcher]="matcher"
                placeholder="{{
                    'robots.components.addGitObject.exampleObject' | translate
                }}"
                required
            />
        </mat-form-field>
        <button
            [disabled]="!objectFormControl.valid"
            type="submit"
            mat-raised-button
            color="primary"
            (click)="onSubmit()"
        >
            {{ 'robots.components.addGitObject.import' | translate }}
        </button>

        <div>
            <button mat-button matStepperPrevious>
                {{ 'robots.components.addGitObject.back' | translate }}
            </button>
            <button mat-button [disabled]="!success">
                {{ 'robots.components.addGitObject.next2' | translate }}
            </button>
        </div>
    </mat-step>
    <mat-step label="{{ 'robots.components.addGitObject.result' | translate }}">
        <div class="alert alert-light object-message" role="alert">
            {{ message }}
        </div>
        <div>
            <button mat-button matStepperNext (click)="dismissModal()">
                {{ 'robots.components.addGitObject.exit' | translate }}
            </button>
        </div>
    </mat-step>
</mat-horizontal-stepper>
