<div mat-dialog-content>
    <p>{{ 'imgDb.labelManager.dialog.content' | translate }}</p>
</div>
<div mat-dialog-actions>
    <div class="mx-auto">
        <button (click)="onCancel()" class="btn btn-danger mx-3">
            {{ 'imgDb.labelManager.dialog.cancelText' | translate }}
        </button>
        <button [mat-dialog-close]="data" class="btn btn-primary">
            {{ 'imgDb.labelManager.dialog.confirmText' | translate }}
        </button>
    </div>
</div>
