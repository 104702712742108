import { ProjectMembership } from 'src/app/project/models/project-membership';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { ProjectService } from '../project/services/project.service';
import { inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { ProjectMemberRole } from '../project/models/project-member-role';

export const projectOwnerGuard = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const router = inject(Router);
    const projectService = inject(ProjectService);

    const projectId = route.params.projectId;
    return projectService.getMyProjectMembership(projectId).pipe(
        map((projectMembership: ProjectMembership) => {
            if (projectMembership.roleName === ProjectMemberRole.OWNER) {
                return true;
            }

            router.navigate(['/workspace']);
            return false;
        }),
        catchError(() => {
            router.navigate(['/not-found']);
            return of(false);
        })
    );
};
