import {
    ActionNoiseType,
    AlgorithmDTO,
    AlgorithmType,
    FrequencyUnit,
    ReplayBufferClass,
} from '../models/algorithm-form';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { AlgorithmValidator } from '../validators/algorithm-validator';
import { nonNil } from '../../shared/utility';

@Injectable()
export class AlgorithmFormService {
    getForm(dto: AlgorithmDTO): FormGroup {
        const parameter = new Map<string, Object>(
            Object.entries(dto.parameter)
        );

        switch (dto.type) {
            case AlgorithmType.PPO:
                return this.getPpoForm(parameter);
            case AlgorithmType.ADAM:
                return this.getAdamForm(parameter);
            case AlgorithmType.SAC:
                return this.getSacForm(parameter);
        }
    }

    private getPpoForm(parameter: Map<string, Object>): FormGroup {
        return new FormGroup({
            gamma: new FormControl<string>(this.asString('gamma', parameter), {
                validators: [
                    Validators.required,
                    AlgorithmValidator.validatePositiveFloatUpTo1Closed,
                ],
            }),
            learningRate: new FormControl<string>(
                this.asString('learningRate', parameter).toString(),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveFloatUpTo1Closed,
                    ],
                }
            ),
            nEpochs: new FormControl<string>(
                this.asString('nEpochs', parameter).toString(),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveInteger,
                    ],
                }
            ),
            nSteps: new FormControl<string>(
                this.asString('nSteps', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveIntegerGreaterOrEqualThanCustom(
                            2
                        ),
                    ],
                }
            ),
            totalTimesteps: new FormControl<string>(
                this.asString('totalTimesteps', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveInteger,
                    ],
                }
            ),
            //advanced params
            normalizeAdvantage: new FormControl<boolean>(
                parameter.get('normalizeAdvantage') as boolean
            ),
            entCoef: new FormControl<string>(
                this.asString('entCoef', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveFloatUpTo1Closed,
                    ],
                }
            ),
            vfCoef: new FormControl<string>(
                this.asString('vfCoef', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveFloatUpTo1Closed,
                    ],
                }
            ),
            gaeLambda: new FormControl<string>(
                this.asString('gaeLambda', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveFloatUpTo1Closed,
                    ],
                }
            ),
            clipRangeVf: new FormControl<string>(
                this.asString('clipRangeVf', parameter),
                {
                    validators: [
                        AlgorithmValidator.validatePositiveFloatUpTo1Closed,
                    ],
                }
            ),
            seed: new FormControl<string>(this.asString('seed', parameter), {
                validators: [AlgorithmValidator.validatePositiveInteger],
            }),
            clipRange: new FormControl<string>(
                this.asString('clipRange', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveFloatUpTo1Closed,
                    ],
                }
            ),
            maxGradNorm: new FormControl<string>(
                this.asString('maxGradNorm', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveFloatUpTo1Closed,
                    ],
                }
            ),
            targetKl: new FormControl<string>(
                this.asString('targetKl', parameter),
                { validators: AlgorithmValidator.validateNonNegativeFloat }
            ),
            batchSize: new FormControl<string>(
                this.asString('batchSize', parameter).toString(),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveInteger,
                    ],
                }
            ),
            useSde: new FormControl<boolean>(
                parameter.get('useSde') as boolean
            ),
            sdeSampleFreq: new FormControl<string>(
                this.asString('sdeSampleFreq', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveIntegerOrMinusOne,
                    ],
                }
            ),

            policy: new FormControl<string>(
                this.asString('policy', parameter),
                { validators: [Validators.required] }
            ),

            maxEpisodeLength: new FormControl<string>(
                this.asString('maxEpisodeLength', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveInteger,
                ]
            ),
        });
    }

    private getAdamForm(parameter: Map<string, Object>): FormGroup {
        return new FormGroup({
            nEpochs: new FormControl<string>(
                this.asString('nEpochs', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveInteger,
                    ],
                }
            ),
            learningRate: new FormControl<string>(
                this.asString('learningRate', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveFloatUpTo1Open,
                    ],
                }
            ),
            batchSize: new FormControl<string>(
                this.asString('batchSize', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveInteger,
                    ],
                }
            ),
            beta_1: new FormControl<string>(
                this.asString('beta_1', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveFloatUpTo1Open,
                    ],
                }
            ),
            beta_2: new FormControl<string>(
                this.asString('beta_2', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validatePositiveFloatUpTo1Open,
                    ],
                }
            ),
            epsilon: new FormControl<string>(
                this.asString('epsilon', parameter),
                {
                    validators: [
                        Validators.required,
                        AlgorithmValidator.validateInteger,
                        AlgorithmValidator.validateEpsilonInRange,
                    ],
                }
            ),
            amsgrad: new FormControl<boolean>(
                parameter.get('amsgrad') as boolean,
                { validators: [Validators.required] }
            ),
        });
    }

    private getSacForm(parameter: Map<string, Object>) {
        return new FormGroup({
            gamma: new FormControl<string>(this.asString('gamma', parameter), [
                Validators.required,
                AlgorithmValidator.validatePositiveFloatUpTo1Closed,
            ]),
            learningRate: new FormControl<string>(
                this.asString('learningRate', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveFloatUpTo1Closed,
                ]
            ),
            bufferSize: new FormControl<string>(
                this.asString('bufferSize', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveInteger,
                ]
            ),
            learningStarts: new FormControl<string>(
                this.asString('learningStarts', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveInteger,
                ]
            ),
            batchSize: new FormControl<string>(
                this.asString('batchSize', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveInteger,
                ]
            ),
            tau: new FormControl<string>(this.asString('tau', parameter), [
                Validators.required,
                AlgorithmValidator.validatePositiveFloatUpTo1Closed,
            ]),
            trainFreqValue: new FormControl<string>(
                this.asString('trainFreqValue', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveInteger,
                ]
            ),
            trainFreqUnit: new FormControl<FrequencyUnit>(
                parameter.get('trainFreqUnit') as FrequencyUnit,
                [Validators.required]
            ),
            gradientSteps: new FormControl<string>(
                this.asString('gradientSteps', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveInteger,
                ]
            ),
            actionNoiseType: new FormControl<ActionNoiseType>(
                parameter.get('actionNoiseType') as ActionNoiseType,
                [Validators.required]
            ),
            replayBufferClass: new FormControl<ReplayBufferClass>(
                parameter.get('replayBufferClass') as ReplayBufferClass,
                [Validators.required]
            ),
            optimizeMemoryUsage: new FormControl<boolean>(
                parameter.get('optimizeMemoryUsage') as boolean,
                [Validators.required]
            ),
            entCoef: new FormControl<string>(
                this.asString('entCoef', parameter),
                [AlgorithmValidator.validatePositiveFloatUpTo1Closed]
            ),
            targetUpdateInterval: new FormControl<string>(
                this.asString('targetUpdateInterval', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveInteger,
                ]
            ),
            targetEntropy: new FormControl<string>(
                this.asString('targetEntropy', parameter),
                [AlgorithmValidator.validatePositiveFloatUpTo1Closed]
            ),
            useSde: new FormControl<boolean>(
                parameter.get('useSde') as boolean,
                [Validators.required]
            ),
            sdeSampleFreq: new FormControl<string>(
                this.asString('sdeSampleFreq', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveIntegerOrMinusOne,
                ]
            ),
            useSdeAtWarmup: new FormControl<boolean>(
                parameter.get('useSdeAtWarmup') as boolean,
                [Validators.required]
            ),
            seed: new FormControl<string>(this.asString('seed', parameter), [
                AlgorithmValidator.validateNonNegativeInteger,
            ]),
            totalTimesteps: new FormControl<string>(
                this.asString('totalTimesteps', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveInteger,
                ]
            ),
            maxEpisodeLength: new FormControl<string>(
                this.asString('maxEpisodeLength', parameter),
                [
                    Validators.required,
                    AlgorithmValidator.validatePositiveInteger,
                ]
            ),
        });
    }

    private asString(controlName: string, params: Map<string, Object>): string {
        let value = params.get(controlName);
        if (controlName === 'epsilon') {
            value = Math.log10(+value);
        }
        return nonNil(value) ? value.toString() : '';
    }
}
