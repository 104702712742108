<div
    *ngIf="!showData"
    class="
        placeholder-text
        container-fluid
        d-flex
        align-items-center
        justify-content-center
        h-100
    "
>
    <p>{{ 'evaluation.SL.media.noDataMessage' | translate }}</p>
</div>
<div class="labeling-central-tile" *ngIf="showData">
    <div class="current-image">
        <div #panzoomParent class="current-image-inner">
            <pan-zoom [config]="panZoomConfig">
                <img
                    #currentImage
                    (load)="updateSvgStatus()"
                    [src]="selectedImage?.url"
                    [alt]="selectedImage?.id"
                />
                <svg
                    [ngStyle]="{
                        cursor: cursorView
                    }"
                    (mousedown)="cursorView = 'grabbing'"
                    (mouseup)="cursorView = 'grab'"
                    *ngIf="svgStatus"
                    id="labeling"
                    #labeling
                    [attr.width]="svgStatus.w"
                    [attr.height]="svgStatus.h"
                    [attr.viewBox]="svgStatus.viewBox"
                    preserveAspectRatio="xMidYMid meet"
                >
                    <g
                        *ngFor="let annotation of annotations"
                        class="annotation-box"
                    >
                        <rect
                            [ngStyle]="{
                                stroke: getColorByAnnotation(annotation),
                                fill: getColorByAnnotation(annotation, false)
                            }"
                            [attr.x]="annotation.bbox.x"
                            [attr.y]="annotation.bbox.y"
                            [attr.width]="annotation.bbox.width"
                            [attr.height]="annotation.bbox.height"
                        ></rect>
                        <rect
                            [ngStyle]="{
                                fill: annotation.active
                                    ? 'white'
                                    : getColorByAnnotation(annotation),

                                stroke: getColorByAnnotation(annotation)
                            }"
                            [attr.x]="annotation.bbox.x"
                            [attr.y]="annotation.bbox.y - 20"
                            [attr.width]="annotation.bbox.width"
                            height="20"
                            class="title-box"
                        ></rect>
                        <text
                            [attr.x]="annotation.bbox.x + 3"
                            [attr.y]="annotation.bbox.y - 6"
                            class="title"
                        >
                            {{ getImgCategoryName(annotation.categoryId) }}
                        </text>
                    </g>
                </svg>
            </pan-zoom>
        </div>
    </div>
    <div class="list-wrapper">
        <div class="img-wrapper">
            <div
                class="prev-arrow arrow"
                [ngClass]="{ hidden: !isLeftArrowVisible }"
                (click)="getPrevPage()"
            >
                <div class="arrow-wrapper">
                    <img
                        src="assets/svg/img-db/left_arrow.svg"
                        alt="arrow-left"
                        class="left_arrow"
                    />
                    <img
                        src="assets/svg/img-db/left_arrow_mouseover.svg"
                        alt="mouseover_left_arrow"
                        class="mouseover_left_arrow"
                    />
                </div>
            </div>
            <div class="items-list" id="img-items-list">
                <div
                    class="item"
                    *ngFor="let img of images; let i = index"
                    [ngClass]="{
                        active: selectedImage && selectedImage?.id === img?.id
                    }"
                    (click)="onSelectImage(i)"
                    #img
                >
                    <div class="image-wrapper">
                        <img [src]="img.url" [alt]="img.id" class="img-fluid" />
                    </div>
                    <p>{{ img.name }}</p>
                    <mat-icon *ngIf="img.isBookmarked" class="icon"
                        >bookmark_black_24dp
                    </mat-icon>
                </div>
            </div>
            <div
                class="next-arrow arrow"
                [ngClass]="{ hidden: !isRightArrowVisible }"
                (click)="getNextPage()"
            >
                <div class="arrow-wrapper">
                    <img
                        src="assets/svg/img-db/right_arrow.svg"
                        alt="arrow-right"
                        class="right_arrow"
                    />
                    <img
                        src="assets/svg/img-db/right_arrow_mouseover.svg"
                        alt="mouseover_right_arrow"
                        class="mouseover_right_arrow"
                    />
                </div>
            </div>
        </div>
    </div>
    <div
        class="paging-container d-flex justify-content-center"
        *ngIf="images.length"
    >
        <app-input-paging-bar
            tag="label"
            [maxValue]="totalElements"
            [currentValue]="currentImageNumber"
            (nextValue)="onNextValue($event)"
        ></app-input-paging-bar>
    </div>
</div>
