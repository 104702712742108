import { AbstractControl, ValidationErrors } from '@angular/forms';

export class AlgorithmValidator {
    static readonly acceptDotAndCommaAsSeperator: RegExp =
        /^[-+]?\d*([.|,]\d*)?$/;

    static maxInteger = Math.pow(2, 31) - 1;

    static validatePositiveFloat(
        control: AbstractControl
    ): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            positiveFloatFormatError: {
                inputValue: control.value,
            },
        };

        const number = parseFloat(value);
        if (
            isNaN(number) ||
            number <= 0 ||
            !AlgorithmValidator.acceptDotAndCommaAsSeperator.test(value)
        ) {
            return formatError;
        }

        return null;
    }

    static validateNonNegativeFloat(
        control: AbstractControl
    ): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            nonNegativeFloatFormat: {
                inputValue: control.value,
            },
        };

        const number = parseFloat(value);
        if (
            isNaN(number) ||
            number < 0 ||
            !AlgorithmValidator.acceptDotAndCommaAsSeperator.test(value)
        ) {
            return formatError;
        }

        return null;
    }

    static validatePositiveFloatUpTo1Closed(
        control: AbstractControl
    ): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            positiveFloatUpTo1ClosedFormat: {
                inputValue: control.value,
            },
        };

        const number = parseFloat(value);
        if (
            isNaN(number) ||
            number < 0 ||
            number > 1 ||
            !AlgorithmValidator.acceptDotAndCommaAsSeperator.test(value)
        ) {
            return formatError;
        }

        return null;
    }

    static validatePositiveFloatUpTo1Open(
        control: AbstractControl
    ): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            positiveFloatUpTo1OpenFormat: {
                inputValue: control.value,
            },
        };

        const number = parseFloat(value);
        if (
            isNaN(number) ||
            number <= 0 ||
            number >= 1 ||
            !AlgorithmValidator.acceptDotAndCommaAsSeperator.test(value)
        ) {
            return formatError;
        }

        return null;
    }

    static validatePositiveFloatUpTo1OpenRight(
        control: AbstractControl
    ): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            positiveFloatUpTo1OpenRightFormat: {
                inputValue: control.value,
            },
        };

        const number = parseFloat(value);
        if (
            isNaN(number) ||
            number < 0 ||
            number >= 1 ||
            !AlgorithmValidator.acceptDotAndCommaAsSeperator.test(value)
        ) {
            return formatError;
        }

        return null;
    }

    static validatePositiveInteger(
        control: AbstractControl
    ): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            positiveIntegerFormat: {
                inputValue: control.value,
            },
        };

        const number = Number(value);
        if (
            !Number.isInteger(number) ||
            number <= 0 ||
            number > AlgorithmValidator.maxInteger ||
            value.includes('.')
        ) {
            return formatError;
        }

        return null;
    }

    static validatePositiveIntegerUpToCustom(limit: number) {
        return (control: AbstractControl): ValidationErrors | null => {
            const value: string = AlgorithmValidator.controlValueParser(
                control.value
            );

            if (value === '') {
                return null;
            }

            const formatError = {
                positiveIntegerWithLimitFormat: {
                    inputValue: control.value,
                    maxVal: limit,
                },
            };

            const number = Number(value);
            if (
                !Number.isInteger(number) ||
                number <= 0 ||
                number > limit ||
                value.includes('.')
            ) {
                return formatError;
            }

            return null;
        };
    }

    static validatePositiveIntegerGreaterOrEqualThanCustom(custom: number) {
        return (control: AbstractControl): ValidationErrors | null => {
            const value: string = AlgorithmValidator.controlValueParser(
                control.value
            );

            if (value === '') {
                return null;
            }

            const formatError = {
                positiveIntegerGreaterOrEqualFormat: {
                    inputValue: control.value,
                    minVal: custom,
                },
            };

            const number = Number(value);
            if (
                !Number.isInteger(number) ||
                number < custom ||
                number > AlgorithmValidator.maxInteger ||
                value.includes('.')
            ) {
                return formatError;
            }

            return null;
        };
    }

    static validatePositiveIntegerOrMinusOne(
        control: AbstractControl
    ): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            positiveIntegerOrMinusOneFormat: {
                inputValue: control.value,
            },
        };

        const number = Number(value);
        if (
            !Number.isInteger(number) ||
            (number !== -1 && number <= 0) ||
            number > AlgorithmValidator.maxInteger ||
            value.includes('.')
        ) {
            return formatError;
        }

        return null;
    }

    static validateNonNegativeInteger(
        control: AbstractControl
    ): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            nonNegativeIntegerFormat: {
                inputValue: control.value,
            },
        };

        const number = Number(value);
        if (
            !Number.isInteger(number) ||
            number < 0 ||
            number > AlgorithmValidator.maxInteger ||
            value.includes('.')
        ) {
            return formatError;
        }

        return null;
    }

    static validateInteger(control: AbstractControl): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            integerFormat: {
                inputValue: control.value,
            },
        };

        const number = Number(value);
        if (
            !Number.isInteger(number) ||
            number > AlgorithmValidator.maxInteger ||
            value.includes('.')
        ) {
            return formatError;
        }

        return null;
    }

    static validateEpsilonInRange(
        control: AbstractControl
    ): ValidationErrors | null {
        const value: string = AlgorithmValidator.controlValueParser(
            control.value
        );

        if (value === '') {
            return null;
        }

        const formatError = {
            epsilonNotInRange: {
                inputValue: control.value,
            },
        };

        const number: number = Number(value);
        if (number > 300 || number < -300) {
            return formatError;
        }
        return null;
    }

    private static controlValueParser(value: any): string {
        return !value ? '' : value.toString().replace(',', '.');
    }
}
