<div class="cms-block pos-{{ position }} cms-block-{{ type }}">
    <div class="cms-block-container" style="{{ style }}">
        <div class="cms-block-container-row row cms-row has--sidebar">
            <div class="col-12">
                <div class="cms-element-category-navigation">
                    <div class="category-navigation-box">
                        <ng-container
                            *ngFor="let category of categories"
                            [ngTemplateOutlet]="treeNode"
                            [ngTemplateOutletContext]="{ $implicit: category }">
                        </ng-container>

                        <ng-template #treeNode let-category>
                            <ul class="category-navigation level-{{ category.level }}">
                                <li class="category-navigation-entry">
                                    <a class="category-navigation-link is-active"
                                       [ngClass]="{ 'is-active': category.id === activeCategoryId }"
                                       href="/cms/{{ category.id }}"
                                    >
                                        {{ category.translated?.name }}
                                    </a>
                                    <ng-container *ngIf="category.children && category.inPath">
                                        <ng-container
                                            *ngFor="let child of category.children"
                                            [ngTemplateOutlet]="treeNode"
                                            [ngTemplateOutletContext]="{ $implicit: child }">
                                        </ng-container>
                                    </ng-container>
                                </li>
                            </ul>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>