import {
    composeLayer,
    WithFactor,
    WithFillMode,
    WithFlipMode,
    WithHeightWidth,
    WithHeightWidthFactor,
    WithInterpolation,
    WithSeed,
    WithStddev,
} from './tf-properties';

export class RandomContrast extends composeLayer([WithSeed, WithFactor]) {}

export class RandomCrop extends composeLayer([WithSeed, WithHeightWidth]) {}
export class RandomFlip extends composeLayer([WithSeed, WithFlipMode]) {}
export class RandomHeight extends composeLayer([WithSeed, WithFactor]) {}
export class RandomRotation extends composeLayer([
    WithFillMode,
    WithInterpolation,
    WithSeed,
]) {}

export class RandomTranslation extends composeLayer([
    WithFillMode,
    WithHeightWidthFactor,
    WithInterpolation,
    WithSeed,
]) {}

export class RandomWidth extends composeLayer([
    WithFactor,
    WithInterpolation,
    WithSeed,
]) {}

export class RandomZoom extends composeLayer([
    WithFillMode,
    WithHeightWidthFactor,
    WithInterpolation,
    WithSeed,
]) {}

export class GaussianNoise extends composeLayer([WithStddev]) {}
