import { Component, Input } from '@angular/core';
import { AddCard } from '../../model/add-card-implementations/add-card';

@Component({
    selector: 'app-add-card',
    templateUrl: './add-card.component.html',
    styleUrls: ['../shared/card.component.scss', './add-card.component.scss'],
})
export class AddCardComponent {
    @Input()
    addCard: AddCard;

    constructor() {}

    public onAddButtonClick() {
        this.addCard.createNewModel();
    }
}
