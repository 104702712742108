import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectWorkspaceNavigationItem } from '../../model/project-workspace-navigation-item';

@Component({
    selector: 'app-project-workspace-navigation',
    templateUrl: './project-workspace-navigation.component.html',
    styleUrls: ['./project-workspace-navigation.component.scss'],
})
export class ProjectWorkspaceNavigationComponent implements OnInit {
    @Input()
    projectPicture: string;

    @Input()
    projectName: string;

    @Input()
    projectId: string;

    @Output()
    selectNavigationItemEvent = new EventEmitter<ProjectWorkspaceNavigationItem>();

    projectUuid: string;
    trainingUuid: string;
    selectedNavItem: ProjectWorkspaceNavigationItem;

    @Output()
    sidenavStatusEvent = new EventEmitter();
    sideNavStatus: boolean = false;

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.projectUuid = params.projectUuid;
            this.trainingUuid = params.trainingUuid;
        });
        if (this.trainingUuid !== undefined) {
            this.selectedNavItem = ProjectWorkspaceNavigationItem.Trainings;
            this.selectNavigationItemEvent.emit(this.selectedNavItem);
        } else {
            this.selectedNavItem = ProjectWorkspaceNavigationItem.Overview;
        }

        // check localstorage if the side navbar is small or normal
        if (localStorage.getItem('sideNavStatus') === 'true') {
            this.sideNavStatus = true;
        }
    }

    clickNavigation(item: string): void {
        const oldItem = this.selectedNavItem;
        if (ProjectWorkspaceNavigationItem.Overview === item) {
            this.selectedNavItem = ProjectWorkspaceNavigationItem.Overview;
            this.removeTrainingUuidParam();
        } else if (ProjectWorkspaceNavigationItem.Trainings === item) {
            this.selectedNavItem = ProjectWorkspaceNavigationItem.Trainings;
        } else if (ProjectWorkspaceNavigationItem.Members === item) {
            this.selectedNavItem = ProjectWorkspaceNavigationItem.Members;
        }
        if (oldItem !== this.selectedNavItem) {
            this.selectNavigationItemEvent.emit(this.selectedNavItem);
        }
    }

    private removeTrainingUuidParam(): void {
        this.router.navigate([], {
            queryParams: {
                trainingUuid: null,
            },
            queryParamsHandling: 'merge',
        });
    }

    onClickProjectPicture(): void {
        this.router.navigate(['/project/overview', this.projectId]);
    }

    getStyleClassForNavItem(item: string): string {
        if (this.selectedNavItem !== undefined) {
            if (this.selectedNavItem === item) {
                return 'nav-link nav-item item-text active';
            }
        }

        return 'nav-link nav-item item-text';
    }
}
