import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgDbComponent } from './img-db.component';
import { EditableLabelComponent } from '../shared/components/editable-label/editable-label.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImgLabelingViewerComponent } from './img-labeling-viewer/img-labeling-viewer.component';
import { ImgDbInfoComponent } from './img-db-info/img-db-info.component';
import { ImgDbGalleryComponent } from './img-db-gallery/img-db-gallery.component';
import { ImgDbCategoryManagerComponent } from './img-db-category-manager/img-db-category-manager.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DeleteCategoryDialogComponent } from './img-db-category-manager/delete-category-dialog/delete-category-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ImgDbCategoriesRightComponent } from './img-db-categories-right/img-db-categories-right.component';
import { SharedModule } from '../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { ClassificatorTreeComponent } from './classificator-tree/classificator-tree.component';
import { ImgDbWizardComponent } from './component/img-db-wizard/img-db-wizard.component';
import { ImgDbWizardSlideComponent } from './component/img-db-wizard-slide/img-db-wizard-slide.component';
import { AnnotationStateService } from './services/annotation-state.service';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { ImgDbZoomService } from './services/img-db-zoom.service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ToastMessageService } from '../shared/services/toast-message.service';

@NgModule({
    declarations: [
        ImgDbComponent,
        EditableLabelComponent,
        ImgLabelingViewerComponent,
        ImgDbInfoComponent,
        ImgDbGalleryComponent,
        ImgDbCategoryManagerComponent,
        DeleteCategoryDialogComponent,
        ImgDbCategoriesRightComponent,
        ClassificatorTreeComponent,
        ImgDbWizardComponent,
        ImgDbWizardSlideComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        MatDialogModule,
        SharedModule,
        MatIconModule,
        NgxPanZoomModule,
        NgxFileDropModule,
    ],
    providers: [ImgDbZoomService, AnnotationStateService, ToastMessageService],
})
export class ImgDbModule {}

export { ImgDbComponent };
