import { Directive, Input, OnInit } from '@angular/core';
import { CostFunctionService } from '../../services/cost-function.service';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { EMPTY, from } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { nonNil } from 'src/app/shared/utility';
import { environment } from 'src/environments/environment';
import { CostFunctionRow } from '../../models/cost-function-row';

@Directive()
export abstract class AbstractTabComponent implements OnInit {
    @Input() sceneId: string;
    @Input() editingIsDisabled: boolean;

    abstract type: string;

    weightMinValue: number = environment.costFunction.weight.min;
    weightMaxValue: number = environment.costFunction.weight.max;
    weightDecimalPoint: number = environment.costFunction.weight.numDecimals;

    rows: UntypedFormGroup[];

    constructor(private costFunctionService: CostFunctionService) {}

    ngOnInit(): void {
        this.costFunctionService
            .getCostFunctionContent(this.sceneId, this.type)
            .pipe(
                filter(nonNil),
                tap(() => (this.rows = [])),
                switchMap(from),
                map(this.getElementFormGroup.bind(this)),
                tap((formGroup: UntypedFormGroup) => this.rows.push(formGroup)),
                tap((formGroup: UntypedFormGroup) => {
                    if (this.editingIsDisabled) formGroup.disable();
                }),
                catchError(() => EMPTY)
            )
            .subscribe();
    }

    removeElement(index: number) {
        this.rows.splice(index, 1);

        this.costFunctionService
            .deleteCostFunctionRow(this.sceneId, this.type, index)
            .subscribe();
    }

    // Handle dropping a scene composition element in a dropzone
    abstract drop($event: any, prefix?: string);

    saveRow(elementFormGroup: UntypedFormGroup, sceneId: string, type: string) {
        const formData: CostFunctionRow = elementFormGroup.getRawValue();
        this.replaceCommas(formData);

        this.costFunctionService
            .addCostFunctionRow(formData, sceneId, type)
            .subscribe();
    }

    updateRow(form: UntypedFormGroup, index: number) {
        const formData: CostFunctionRow = form.value;
        this.replaceCommas(formData);

        this.costFunctionService
            .updateCostFunctionRow(formData, this.sceneId, this.type, index)
            .subscribe();
    }

    // returns FormGroup<CostFunctionRow>
    protected abstract getElementFormGroup(
        element: CostFunctionRow
    ): UntypedFormGroup;

    // BE returns values as number or null, that's why this step is required to be able to
    // use existing methods for number translation (e.g. ',' -> '.')
    convertElementValueToString(value: any, decimals: number): string {
        if (nonNil(value)) {
            return typeof value === 'number' ? value.toFixed(decimals) : value;
        }
        return '';
    }

    replaceCommas(formData: CostFunctionRow) {
        Object.keys(formData).forEach((key: string) => {
            const value = formData[key];
            if (key !== 'sceneElementId' && typeof value === 'string') {
                if (value.includes(',')) {
                    formData[key] = formData[key].replace(',', '.');
                }
            }
        });
    }
}
