<div class="steppers">
    <div
        class="stepper up"
        (click)="changeValueWithStepper('+')"
    >
        <em class="material-icons">expand_less</em>
    </div>
    <div
        class="stepper down"
        (click)="changeValueWithStepper('-')"
    >
        <em class="material-icons">expand_more</em>
    </div>
</div>