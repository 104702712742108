import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CmsSection } from '../../../models/cms-section';
import { CmsBlock } from '../../../models/cms-block';
import { CmsBlockDirective } from '../../../directive/cms-block.directive';
import { CmsBlockService } from '../../../services/cms-block.service';

@Component({
    selector: 'app-cms-sidebar',
    templateUrl: './cms-sidebar.component.html',
})
export class CmsSidebarComponent implements OnInit {
    @ViewChild(CmsBlockDirective, { static: true })
    cmsMainBlock: CmsBlockDirective;

    categoryNavigationBlocks: CmsBlock[];
    mainBlocks: CmsBlock[];

    @Input()
    section: CmsSection;

    constructor(private cmsBlockService: CmsBlockService) {}

    ngOnInit(): void {
        this.categoryNavigationBlocks = this.section.blocks.filter(
            (block) => block.sectionPosition === 'sidebar'
        );
        this.mainBlocks = this.section.blocks.filter(
            (block) => block.sectionPosition === 'main'
        );
        this.loadComponent();
    }

    loadComponent(): void {
        this.cmsBlockService.loadComponent(
            this.cmsMainBlock.viewContainerRef,
            this.mainBlocks
        );
    }
}
