import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-project-overview-layout',
    templateUrl: './project-overview-layout.component.html',
    styleUrls: ['./project-overview-layout.component.scss'],
})
export class ProjectOverviewLayoutComponent {
    @Input() headerText: string = '';
}
