<div class="tryb-input-container">
    <label [for]="inputLabel" class="input-label">
        {{ inputLabel }}{{ required ? '*' : '' }}
    </label>
    <div
        class="input-wrapper d-flex align-items-center"
        [ngClass]="{
            disabled: control.disabled
        }"
    >
        <div
            class="
                fixed-prefix
                d-flex
                align-items-center
                justify-content-center
            "
        >
            {{ fixedPrefix }}
        </div>
        <div class="position-relative">
            <input
                [id]="inputLabel"
                #textInput
                [type]="type"
                name=""
                class="me-1 algo-input"
                [ngClass]="{
                    optional: !required,
                    'has-steppers': hasSteppers,
                    focused: isFocused || forceFocus,
                    'is-invalid': !control.pristine && control.invalid
                }"
                [step]="step"
                (focus)="inputFocused()"
                (focusout)="inputBlurred()"
                [formControl]="control"
                (keyup.enter)="blurInput()"
                (keyup.escape)="cancelInput()"
                (input)="formatValue()"
                (change)="updateValue()"
            />
            <div *ngIf="hasSteppers" class="steppers position-absolute">
                <div
                    class="stepper up"
                    (click)="changeValueWithStepper('+')"
                    (mouseleave)="forceInputBlur()"
                >
                    <img
                        class="info-icon d-block"
                        src="assets/svg/algorithm/stepper-arrow.png"
                        alt="upward arrow"
                    />
                </div>
                <div
                    class="stepper down"
                    (click)="changeValueWithStepper('-')"
                    (mouseleave)="forceInputBlur()"
                >
                    <img
                        class="info-icon d-block"
                        src="assets/svg/algorithm/stepper-arrow.png"
                        alt="downward arrow"
                    />
                </div>
            </div>
            <div class="error-div" *ngIf="!control.pristine && control.invalid">
                <app-display-errors
                    [control]="control"
                    [translationKey]="'algorithm.errors'"
                ></app-display-errors>
            </div>
        </div>
    </div>
</div>
