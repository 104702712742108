<div class="project-events">
    <div class="project-events-title">
        <p>{{'projectWorkspace.components.projectWorkspaceOverview.projectEvents' | translate}}</p>
    </div>
    <div class="infinite-list">
        <ul infiniteScroll
            [scrollWindow]="false"
            [infiniteScrollDistance]="2"
            [infiniteScrollUpDistance]="1.5"
            [infiniteScrollThrottle]="50"
            (scrolled)="onScrollDown()">
            <li *ngFor="let activity of activities">
                <div class="event">
                    <div class="profile-img-container">
                        <div *ngIf="activity.profilePicture; else placeholderImage">
                            <img class="profile-img" [src]="'data:image/png;base64,'+ activity.profilePicture"
                                 alt="-" [routerLink]="'/user/' + activity.username">
                        </div>
                        <ng-template #placeholderImage>
                            <img class="profile-img" src="/assets/img/workspace-overview/u13.svg" alt="-"
                                 [routerLink]="'/user/' + activity.username">
                        </ng-template>
                    </div>
                    <div class="event-description">
                        {{ activity.selectActivityText() | translate:{ eventParam: activity.getActivityParameter() } }}
                    </div>
                    <div class="time">
                        {{ activity.generateActivityTime(translate) }}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
