import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightClickMenuComponent } from './components/right-click-menu/right-click-menu.component';
import { TrainingControlComponent } from './components/training-control/training-control.component';
import { TreeComponent } from './components/tree/tree.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        RightClickMenuComponent,
        TrainingControlComponent,
        TreeComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
    ],
    exports: [RightClickMenuComponent, TrainingControlComponent, TreeComponent],
})
export class SplitScreenModule {}
