<div [id]="item.elementId" class="d-flex flex-row mn-item" (click)="onClick()">
    <div class="flex-column">
        <p class="menu-item-text title noto-font-bold">
            <ng-container *ngIf="item.iconSvg; else iconITemplate">
                <img
                    [src]="item.iconSvg.source"
                    *ngIf="item.iconSvg.source"
                    [ngStyle]="{
                        width: item.iconSvg.width + 'px',
                        height: item.iconSvg.height + 'px'
                    }"
                    alt="icon"
                />
            </ng-container>
            <span translate>{{ item.title }}</span>
        </p>
        <p class="menu-item-text description noto-font ms-1" translate>
            {{ item.description }}
        </p>
    </div>
</div>

<ng-template #iconITemplate>
    <mat-icon fontSet="material-icons-outlined">{{
        item.iconMaterial.iconName
    }}</mat-icon>
</ng-template>
