import { EvaluationComponent } from './evaluation.component';
import { LegendComponent } from './legend/legend.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { SideContainerComponent } from './side-container/side-container.component';
import { MatIconModule } from '@angular/material/icon';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { PanZoomComponent } from 'ngx-panzoom';

@NgModule({
    declarations: [
        EvaluationComponent,
        LegendComponent,
        VideoPlayerComponent,
        SideContainerComponent,
        ImageViewerComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MatSlideToggleModule,
        MatIconModule,
        PanZoomComponent,
    ],
    providers: [],
})
export class EvaluationModule {}

export { EvaluationComponent };
