import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import * as Skill from '../models/layer-namespace';
import { SkillArchitectureStateService } from '../skill-architecture-state.service';
import { InputFieldType } from '../../skill-utility';
import { SkillSelectboxValues } from '../../skill-selectbox-values';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { nonNil } from 'src/app/shared/utility';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-layer-context-menu',
    templateUrl: './layer-context-menu.component.html',
    styleUrls: [
        './layer-context-menu.component.scss',
        '../../../scenes/styles/scrollable-container.scss',
    ],
})
export class LayerContextMenuComponent implements OnInit, OnChanges, OnDestroy {
    @Input() editingIsDisabled: boolean;
    selectedLayer: Skill.LayerModel;

    layerContext: UntypedFormGroup;
    propertiesToInputTypesMap: [string, InputFieldType][];
    InputFieldTypeEnum = InputFieldType;
    stateServiceSubscription: Subscription;
    selectBoxValues = SkillSelectboxValues;
    separator: string = ',';

    constructor(
        public skillArchitectureStateService: SkillArchitectureStateService,
        private translate: TranslateService
    ) {
        // feel like this shouldnt be part of this component
        if (this.translate.currentLang === 'en') {
            this.separator = '.';
        }
        translate.onLangChange.subscribe((lang) => {
            if (lang.lang === 'en') {
                this.separator = '.';
            } else {
                this.separator = ',';
            }
        });
    }
    ngOnInit(): void {
        this.subscribeToLayerState();
    }

    ngOnDestroy(): void {
        this.stateServiceSubscription.unsubscribe();
    }

    ngOnChanges(): void {
        this.layerContext = new UntypedFormGroup(
            this.createFormControlsFromLayerProperties()
        );
        this.mapLayerPropertiesToInputFieldType();
    }

    subscribeToLayerState() {
        this.stateServiceSubscription =
            this.skillArchitectureStateService.selectedLayer$
                .pipe(
                    filter(nonNil),
                    tap((selectedLayer: Skill.LayerModel) => {
                        this.selectedLayer = selectedLayer;
                        this.generateForm();
                    }),
                    mergeMap(() => {
                        return this.layerContext.valueChanges;
                    }),
                    tap(() => {
                        this.patchLayer();
                    })
                )
                .subscribe();
    }

    generateForm() {
        this.layerContext = new UntypedFormGroup(
            this.createFormControlsFromLayerProperties()
        );
        this.propertiesToInputTypesMap =
            this.mapLayerPropertiesToInputFieldType();
        if (this.editingIsDisabled) {
            this.layerContext.disable();
        }
    }

    patchLayer() {
        const submittedLayer: Skill.Layer = {
            ...(this.layerContext.value as Skill.Layer),
        };
        const patchedLayer: Skill.LayerModel =
            this.createPatchedLayer(submittedLayer);
        this.skillArchitectureStateService.updateLayer(patchedLayer);
    }

    createFormControlsFromLayerProperties() {
        const layer = this.selectedLayer?.config;
        const formControlsOfLayerProperties = {};
        for (const layerProperty in layer) {
            formControlsOfLayerProperties[layerProperty] =
                new UntypedFormControl(layer[layerProperty], {
                    validators: [Validators.required],
                    updateOn: 'blur',
                });
        }
        return formControlsOfLayerProperties;
    }

    mapLayerPropertiesToInputFieldType() {
        let propertiesToInputTypesMap = [];
        for (let layerProperty in this.layerContext.controls) {
            const inputFieldType = this.determineInputFieldType(layerProperty);
            propertiesToInputTypesMap.push([layerProperty, inputFieldType]);
        }

        return propertiesToInputTypesMap.sort(this.groupCheckboxesAfterName);
    }

    determineInputFieldType(layerProperty: string): InputFieldType {
        const configProperty = this.selectedLayer?.config[layerProperty];

        if (/[a-z]*_initializer/.test(layerProperty)) {
            return InputFieldType.INITIALIZER;
        } else if (layerProperty in SkillSelectboxValues) {
            return InputFieldType.SELECTBOX;
        } else if (typeof configProperty === 'number') {
            return InputFieldType.NUMBER;
        } else if (typeof configProperty === 'boolean') {
            return InputFieldType.CHECKBOX;
        } else if (typeof configProperty === 'string') {
            return InputFieldType.TEXT;
        } else {
            return InputFieldType.UNKNOWN;
        }
    }

    createPatchedLayer(submittedLayer: Skill.Layer): Skill.LayerModel {
        // copy selectedLayer to eliminate unwanted side effects
        const selectedLayer = { ...this.selectedLayer } as Skill.LayerModel;
        selectedLayer.config = { ...this.selectedLayer.config } as Skill.Layer;

        for (const property in submittedLayer) {
            if (
                /[a-z]*_initializer/.test(property) &&
                submittedLayer[property] !== null &&
                typeof submittedLayer[property] === 'string'
            ) {
                selectedLayer.config[property] = {
                    config: new (<any>Skill)[
                        submittedLayer[property]
                    ]() as Skill.InitializerType,
                    class_name: submittedLayer[property],
                };
            } else {
                // TODO: add proper validation - currently empty strings are supported
                selectedLayer.config[property] = submittedLayer[property];
            }
        }
        return selectedLayer;
    }

    changeValueWithStepper(operation: string, fieldName: string) {
        // TODO: after implementing LEA-730 it will be possible to config each field seperatle.
        // So step value will be passed from configuration
        const step = 1;
        const control = this.layerContext.controls[fieldName];
        let value = this.layerContext.value[fieldName].toString();
        value = value.replace(',', '.');
        if (!value) {
            value = '0';
        }
        const numberValue = parseFloat(value);
        const newValue = operation === '+' ? numberValue + 1 : numberValue - 1;
        const roundedNewValue = parseFloat(newValue.toFixed(4));
        let newValueString = roundedNewValue
            .toString()
            .replace('.', this.separator);
        newValueString = roundedNewValue
            .toString()
            .replace('.', this.separator);
        control.setValue(parseFloat(newValueString));
        control.markAsDirty();
        control.updateValueAndValidity();
    }

    private groupCheckboxesAfterName(a, b): number {
        if (a[1] !== 1 && b[1] === 1) return 1;

        if (
            (a[1] === 1 && b[0] === 'trainable') ||
            (a[1] !== 1 && b[1] !== 1) ||
            b[0] === 'name'
        )
            return 0;

        return -1;
    }

    deleteSelectedLayer() {
        this.skillArchitectureStateService.deleteSelectedLayer();
    }
}
