// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    backendUrl: window.location.hostname === 'localhost' && window.location.port === '4200'
        ? '//localhost:8080/sec' : '//' + window.location.hostname + '/bff/tt/be/sec',
    frontendUrl: window.location.hostname === 'localhost' && window.location.port === '4200'
        ? '//localhost:4200' : '//' + window.location.hostname,
    namePattern: '^[A-Za-z0-9äöüÄÖÜß._\\- ]*$',
    costFunction: {
        weight: {
            min: 0.00,
            max: 999999.99,
            default: '1,00',
            numDecimals: 2
        },
        position: {
            min: -100000,
            max: 100000,
            default: '',
            numDecimals: 2,
        },
        orientation: {
            min: -360,
            max: 360,
            default: '0,0',
            numDecimals: 1,
        }
    },

    shopwareUrl: 'https://showroom.sw6.staging.isento-ecommerce.net/store-api',
    shopwareAccessKey: 'SWSCMLFLZWO2UK1VV1VOCTVBZG',
    rootCategoryId: '1470133142ed4ba1aeb6ca251ae6c005',
    rootCmsCategoryId: 'c769d68669634099a457a1da2af7b646',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
