import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private history: string[] = [];

    constructor(private router: Router, private location: Location) {}

    saveTrainingHistory() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const data = window.history.state;
                if (data.trainingUuid !== undefined)
                    this.history.push(data.trainingUuid);
            }
        });
    }

    getLastTraining(): any {
        const data = this.history.pop();
        this.clearHistory();
        return data;
    }

    clearHistory() {
        this.history = [];
    }
}
