export class BrowserAndPhysicsLoadable {
    uuid: string;
    name: string;
    picture: string;
    description: string;
    creator: string; // two creaters must be distinguishable
    directoryPath: string;
    externalAddress: string;
    entryFile: string;
    importDate: Date;
    isRobot: boolean;
}
