<mat-horizontal-stepper [linear]="isLinear" #stepper>
    <mat-step [completed]="step1completed">
        <ng-template matStepLabel>{{'robots.components.addZipObject.uploadFile'|translate}}</ng-template>
        <form>
            <input
                hidden
                type="file"
                accept="{{ this.acceptedFileTypes }}"
                #fileInput
                (change)="onFileInput(fileInput.files)"
            />
            <button mat-raised-button (click)="fileInput.click()">
                {{ file ? file.name : ('robots.components.addZipObject.select'|translate) }}
            </button>
            <br />
            <mat-label>
                {{
                'robots.components.addZipObject.maximumUploadSize'
                    | translate
                }}
                {{ maxFileSize }} MB.
            </mat-label>
            <br />
            <mat-label>
                {{ 'robots.components.addZipObject.allowedFiles' | translate }}
                {{ this.acceptedFileTypes }}
            </mat-label>
            <div>
                <button mat-button matStepperNext>
                    {{ 'robots.components.addZipObject.next1' | translate }}
                </button>
            </div>
        </form>
    </mat-step>
    <mat-step [completed]="step2completed">
        <mat-progress-bar value="{{ progress }}"></mat-progress-bar>
        <ng-template matStepLabel>{{
            'robots.components.addZipObject.objectName1' | translate
            }}</ng-template>
        <mat-form-field appearance="fill">
            <mat-label>{{
                'robots.components.addZipObject.objectName2' | translate
                }}</mat-label>
            <mat-error *ngIf="objectFormControl.hasError('required')">
                {{ 'robots.components.addZipObject.objectNameIs' | translate }}
                <strong>{{
                    'robots.components.addZipObject.required' | translate
                    }}</strong>
            </mat-error>
            <mat-error *ngIf="objectFormControl.hasError('pattern')">
                {{
                'robots.components.addZipObject.objectNameError1'
                    | translate
                }}
            </mat-error>
            <mat-error *ngIf="objectFormControl.hasError('maxlength')">
                {{
                'robots.components.addZipObject.objectNameError2'
                    | translate
                }}
            </mat-error>
            <input
                matInput
                [formControl]="objectFormControl"
                [errorStateMatcher]="matcher"
                placeholder="{{
                    'robots.components.addZipObject.exampleObject' | translate
                }}"
                required
            />
        </mat-form-field>
        <button
            [disabled]="!file || !objectFormControl.valid || !(progress === 0)"
            type="submit"
            mat-raised-button
            color="primary"
            (click)="onSubmit()"
        >
            {{ 'robots.components.addZipObject.import' | translate }}
        </button>

        <div class="alert alert-light object-message" role="alert">
            {{ fileSizeMessage | translate:{ size: maxFileSize } }}
        </div>
        <div>
            <button mat-button matStepperPrevious>
                {{ 'robots.components.addZipObject.back' | translate }}
            </button>
            <button mat-button [disabled]="!success">
                {{ 'robots.components.addZipObject.next2' | translate }}
            </button>
        </div>
    </mat-step>
    <mat-step label="{{ 'robots.components.addGitObject.result' | translate }}">
        <div class="alert alert-light object-message" role="alert">
            {{ message }}
        </div>
        <div>
            <button mat-button matStepperNext (click)="dismissModal()">
                {{ 'robots.components.addZipObject.exit' | translate }}
            </button>
        </div>
    </mat-step>
</mat-horizontal-stepper>
