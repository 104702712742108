export enum InputLayerType {
    INPUT_LAYER = 'Input',
}

export enum PreprocessingLayerType {
    CATEGORY_ENCODING = 'CategoryEncoding',
    DISCRETIZATION = 'Discretization',
    INTEGER_LOOKUP = 'IntegerLookup',
    NORMALIZATION = 'Normalization',
    STRING_LOOKUP = 'StringLookup',
}

export enum RandomizationLayerType {
    GAUSSIAN_NOISE = 'GaussianNoise',
    RANDOM_CONTRAST = 'RandomContrast',
    RANDOM_CROP = 'RandomCrop',
    RANDOM_FLIP = 'RandomFlip',
    RANDOM_HEIGHT = 'RandomHeight',
    RANDOM_ROTATION = 'RandomRotation',
    RANDOM_TRANSLATION = 'RandomTranslation',
    RANDOM_WIDTH = 'RandomWidth',
    RANDOM_ZOOM = 'RandomZoom',
}

export enum ConvolutionalLayerType {
    CONV_1D = 'Conv1D',
    CONV_1D_TRANSPOSE = 'Conv1DTranspose',
    CONV_2D = 'Conv2D',
    CONV_2D_TRANSPOSE = 'Conv2DTranspose',
    CONV_3D = 'Conv3D',
    CONV_3D_TRANSPOSE = 'Conv3DTranspose',
    // DEPTHWISE_CONV_1D = "", // TODO: with newer tf versions
    DEPTHWISE_CONV_2D = 'DepthwiseConv2D',
    LOCALLY_CONNECTED_1D = 'LocallyConnected1D',
    LOCALLY_CONNECTED_2D = 'LocallyConnected2D',
    SEPARABLE_CONV1D = 'SeparableConv1D',
    SEPARABLE_CONV2D = 'SeparableConv2D',
}

export enum FullyConnectedLayerType {
    ALPHA_DROPOUT = 'AlphaDropout',
    DENSE = 'Dense',
    // TODO: figure out: how to use and do we want this?
    // DENSE_FEATURES = "",
    DROPOUT = 'Dropout',
    GAUSSIAN_DROPOUT = 'GaussianDropout',
    // SPATIAL_DROPOUT_1D = 'SpatialDropout1D',
    // SPATIAL_DROPOUT_2D = 'SpatialDropout2D',
    // SPATIAL_DROPOUT_3D = 'SpatialDropout3D',
}

// export enum RNNLayerType {
// GRU = "",
// GRU_CELL = "",
// RNN = "RNN",
// SIMPLE_RNN = "SimpleRNN",
// SIMPLE_RNN_CELL = "SimpleRNN",
// STACKED_RNN_CELLS = "",
// }

// export enum LSTMLayerType {
//     LSTM = "",
//     LSTM_CELL = "",
//     CONV_LSTM_1D = "",
//     CONV_LSTM_2D = "",
//     CONV_LSTM_3D = "",
// }

export enum PoolingLayerType {
    AVERAGE_POOLING_1D = 'AveragePooling1D',
    AVERAGE_POOLING_2D = 'AveragePooling2D',
    AVERAGE_POOLING_3D = 'AveragePooling3D',
    GLOBAL_AVERAGE_POOLING_1D = 'GlobalAveragePooling1D',
    GLOBAL_AVERAGE_POOLING_2D = 'GlobalAveragePooling2D',
    GLOBAL_AVERAGE_POOLING_3D = 'GlobalAveragePooling3D',
    GLOBAL_MAX_POOLING_1D = 'GlobalMaxPooling1D',
    GLOBAL_MAX_POOLING_2D = 'GlobalMaxPooling2D',
    GLOBAL_MAX_POOLING_3D = 'GlobalMaxPooling3D',
    MAXIMUM = 'Maximum',
    MAX_POOL_1D = 'MaxPool1D',
    MAX_POOL_2D = 'MaxPool2D',
    MAX_POOL_3D = 'MaxPool3D',
    MINIMUM = 'Minimum',
}

export enum TransformationLayerType {
    CENTER_CROP = 'CenterCrop',
    CROPPING_1D = 'Cropping1D',
    CROPPING_2D = 'Cropping2D',
    CROPPING_3D = 'Cropping3D',
    FLATTEN = 'Flatten',
    PERMUTE = 'Permute',
    REPEAT_VECTOR = 'RepeatVector',
    RESCALING = 'Rescaling',
    RESHAPE = 'Reshape',
    RESIZING = 'Resizing',
    UP_SAMPLING_1D = 'UpSampling1D',
    UP_SAMPLING_2D = 'UpSampling2D',
    UP_SAMPLING_3D = 'UpSampling3D',
    ZERO_PADDING_1D = 'ZeroPadding1D',
    ZERO_PADDING_2D = 'ZeroPadding2D',
    ZERO_PADDING_3D = 'ZeroPadding3D',
}

export enum AttentionLayerType {
    ADDITIVE_ATTENTION = 'AdditiveAttention',
    ATTENTION = 'Attention',
    MULTI_HEAD_ATTENTION = 'MultiHeadAttention',
}

export enum NormalizationLayerType {
    BATCH_NORMALIZATION = 'BatchNormalization',
    LAYER_NORMALIZATION = 'Normalization',
}

export enum CombineLayerType {
    ADD = 'Add',
    CONCATENATE = 'Concatenate',
    DOT = '',
    MULTIPLY = '',
    SUBTRACT = '',
}

export enum WrapperLayerType {
    BIDIRECTIONAL = 'Bidirectional',
    TIME_DISTRIBUTED = 'TimeDistributed',
}

export type LayerType =
    | InputLayerType
    | PreprocessingLayerType
    | RandomizationLayerType
    | ConvolutionalLayerType
    | FullyConnectedLayerType
    | PoolingLayerType
    | TransformationLayerType
    | AttentionLayerType
    | NormalizationLayerType
    | CombineLayerType
    | WrapperLayerType;
// | RNNLayerType
// | LSTMLayerType
