import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ContentItem,
    ContentType,
    SideContainerData,
    SideContainerPosition,
} from '../models/side-container-data';
import { EvaluationStorageService } from '../service/evaluation-storage.service';
import { Subscription } from 'rxjs';
import { ColorService } from 'src/app/shared/services/color.service';

@Component({
    selector: 'app-side-container',
    templateUrl: './side-container.component.html',
    styleUrls: ['./side-container.component.scss'],
})
export class SideContainerComponent implements OnInit, OnDestroy {
    //HTML import
    protected readonly ContentType = ContentType;

    @Input() side: SideContainerPosition;

    containerData: SideContainerData;
    subscription: Subscription;

    constructor(
        private service: EvaluationStorageService,
        private colorService: ColorService
    ) {}

    ngOnInit(): void {
        if (this.side === SideContainerPosition.LEFT) {
            this.subscription = this.service.leftContainerData.subscribe(
                (obj: SideContainerData) => (this.containerData = obj)
            );
        } else {
            this.subscription = this.service.rightContainerData.subscribe(
                (obj: SideContainerData) => (this.containerData = obj)
            );
        }
    }

    onChange(selectedOption: string) {
        if (this.containerData.contentType === ContentType.RADIO) {
            this.service.sideToSideFlow(selectedOption);
        } else {
            this.service.updateSideContainer(selectedOption, this.side);
        }
    }

    getTagColor(item: ContentItem): string {
        let color: string;
        if (item.translationKey) {
            color = this.colorService.getColorForEvaluation(item.value);
        } else {
            color = this.colorService.getColor(item.value);
        }
        return color;
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
