<div *ngFor="let wizardSlide of wizardList">
    <div
        class="wizard-point"
        *ngIf="
            wizardSlide.type === 'card' && wizardSlide.id === activeWizardSlide
        "
        @fadeIn
    >
        <div class="row slide-container">
            <div class="col-12">
                <p class="font-weight-bold text-center slide-title">
                    {{ wizardSlide.slideTitle | translate }}
                </p>
            </div>
            <app-carousel
                [slides]="wizardSlide"
                (optionChosen)="chooseOption($event)"
                [creationParameter]="chosenDbId === undefined ? '' : chosenDbId"
            ></app-carousel>
        </div>
    </div>
    <div
        class="wizard-point"
        *ngIf="
            wizardSlide.type === 'form' && wizardSlide.id === activeWizardSlide
        "
        @fadeIn
    >
        <div class="create-project-form">
            <div class="text-container">
                <p class="text-center text-1">
                    {{ 'imgDb.wizard.wizardSlide.text1' | translate }}
                </p>
                <p class="text-center text-2">
                    {{ 'imgDb.wizard.wizardSlide.text2' | translate }}
                    <span class="text-tryb-pink">{{
                        'imgDb.wizard.wizardSlide.text3' | translate
                    }}</span>
                    {{ 'imgDb.wizard.wizardSlide.text4' | translate }}
                </p>
                <p class="text-3">
                    {{ 'imgDb.wizard.wizardSlide.dbName' | translate }}
                </p>
            </div>
            <app-wizard-form
                [hasSubmitFailed]="hasSubmitFailed"
                [formForProject]="false"
                (newData)="onSubmit($event.name)"
                (backClicked)="onGoBack()"
            ></app-wizard-form>
        </div>
    </div>
</div>
