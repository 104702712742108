import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    WizardSlide,
    WizardSlideContentElement,
} from '../../../project/models/wizard-slide';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
    @Input() creationParameter = '';
    @Input() numVisible: number = 3;
    @Input() numScroll: number = 3;
    @Input() slides: WizardSlide;
    @Input() handler: CarouselComponentHandler;
    @Output() optionChosen: EventEmitter<WizardSlideContentElement> =
        new EventEmitter<WizardSlideContentElement>();

    chooseOption(data: WizardSlideContentElement) {
        this.optionChosen.emit(data);
    }

    isActive(elem): boolean {
        if (!!this.handler) {
            return this.handler.isActive(elem);
        } else {
            return this.creationParameter === elem.cardValue;
        }
    }
}
export interface CarouselComponentHandler {
    isActive(elem: WizardSlideContentElement): boolean;
}
