<div class="context-menu d-flex flex-column">
    <p>{{ treeNode.name }}</p>
    <button *ngIf="isStatus(TrainingStatus.NEW)" (click)="start()">
        {{ 'splitScreen.components.contextMenu.start' | translate }}
    </button>
    <button
        *ngIf="
            isStatusOneOf([
                TrainingStatus.REQUESTING,
                TrainingStatus.RUNNING,
                TrainingStatus.QUEUED,
                TrainingStatus.PREPARING
            ])
        "
        (click)="stop()"
    >
        {{ 'splitScreen.components.contextMenu.stop' | translate }}
    </button>
    <button (click)="clone()">
        {{ 'splitScreen.components.contextMenu.clone' | translate }}
    </button>
    <button
        *ngIf="!treeNode.isRoot && treeNode.status === TrainingStatus.NEW"
        (click)="openDeletionConfirmDialog()"
    >
        {{ 'splitScreen.components.contextMenu.delete' | translate }}
    </button>
</div>
