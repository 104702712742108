export abstract class ThreejsOptions {
    /**
     *  These options are mainly for the Threejs Scene. For further information please check the threejs docs.
     *   Threejs docs - https://threejs.org/docs/
     *
     *
     *
     *
     * @author: UgurKoysuren
     * */

    /** Camera frustum far plane. Default is 2000.
   Must be greater than the current value of near plane. */
    static readonly far = 3000;
    /**  Camera frustum vertical field of view, from bottom to top of view, in degrees. Default is 50. */
    static readonly fov = 35;
    /**  Camera frustum near plane.*/
    static readonly near = 1;
    /** Whether to use physically correct lighting mode. Default is false. */
    static readonly physicallyCorrectLights = true;
    /**  Scene Background Color frustum near plane.*/
    static readonly sceneBackground = 0xaaaaaa;

    static readonly webGLRendererParams = { antialias: true };

    static readonly directionalLightOptions = {
        color: 0xffffff,
        intensity: 1.8,
    };
    static readonly ambientLightOptions = {
        color: 0xddeeff,
        intensity: 0.8,
    };

    /** Color for shininess effect */
    static readonly specularColor = {
        r: 0.0666667,
        g: 0.0666667,
        b: 0.0666667,
    };
}
