import { HierarchyPointLink, HierarchyPointNode, linkVertical } from 'd3';
import {
    DThreeLink,
    DThreeLinkElement,
    DThreeNodeBaseTypeElement,
    DThreeNodeElement,
} from 'src/app/shared/models/d-three-types';
import { TrainingTreeNode } from '../models/training-tree-node';
import {
    LINK_OFFSET_TO_REACH_NODE_EDGE,
    NODE_HEIGHT,
    NODE_WIDTH,
} from '../models/tree-drawing-properties';
import { Injectable } from '@angular/core';
import { TreeNodePositionService } from './tree-node-position.service';

@Injectable({ providedIn: 'root' })
export class TreeTransitionCreatorService {
    constructor(private treeNodePositionService: TreeNodePositionService) {}

    public addNodeExitTransition(node: DThreeNodeBaseTypeElement) {
        node.transition()
            .duration(1000)
            .attr(
                'transform',
                (nodeData: HierarchyPointNode<TrainingTreeNode>) => {
                    const parentNode = nodeData.parent;
                    if (parentNode) {
                        const targetPos =
                            this.treeNodePositionService.getPosition(
                                parentNode.data.uuid
                            );
                        return `translate(${targetPos.x},${targetPos.y})`;
                    }
                }
            )
            .remove();
    }

    public addLinkExitTransition(node: DThreeNodeBaseTypeElement) {
        node.transition()
            .duration(1000)
            .attr('d', this.getConnectionToParent())
            .remove();
    }

    public addLinkEnterTransition(node: DThreeNodeBaseTypeElement) {
        node.attr('d', this.getConnectionToParent())
            .transition()
            .duration(1000)
            .attr('d', this.getConnectionsBetweenNodes());
    }

    public addNodeUpdateTransition(node: DThreeNodeElement) {
        node.transition()
            .duration(1000)
            .attr('transform', (nodeData) => {
                this.treeNodePositionService.setPosition(nodeData.data.uuid, {
                    x: nodeData.x,
                    y: nodeData.y,
                });
                return `translate(${nodeData.x},${nodeData.y})`;
            });
    }

    public addLinkUpdateTransition(node: DThreeLinkElement) {
        node.transition()
            .duration(1000)
            .attr('d', this.getConnectionsBetweenNodes());
    }

    private getConnectionToParent(): DThreeLink {
        return linkVertical<HierarchyPointLink<TrainingTreeNode>, number[]>()
            .source((node) => [
                node.source.x + NODE_WIDTH / 2,
                node.source.y + NODE_HEIGHT,
            ])
            .target((node) => [
                node.source.x + NODE_WIDTH / 2,
                node.source.y + NODE_HEIGHT,
            ]);
    }

    private getConnectionsBetweenNodes(): DThreeLink {
        return linkVertical<HierarchyPointLink<TrainingTreeNode>, number[]>()
            .source((node) => [
                node.source.x + NODE_WIDTH / 2,
                node.source.y + NODE_HEIGHT,
            ])
            .target((node) => [
                node.target.x + NODE_WIDTH / 2,
                node.target.y + LINK_OFFSET_TO_REACH_NODE_EDGE,
            ]);
    }

    public addNodeEnterTransition(selection: DThreeNodeBaseTypeElement) {
        selection
            .attr('transform', (node: HierarchyPointNode<TrainingTreeNode>) => {
                if (node.parent) {
                    const targetPos = this.treeNodePositionService.getPosition(
                        node.parent.data.uuid
                    );
                    if (targetPos) {
                        return `translate(${targetPos.x},${targetPos.y})`;
                    } else {
                        return `translate(${node.parent.x},${node.parent.y})`;
                    }
                }
            })
            .transition()
            .duration(1000)
            .attr(
                'transform',
                (node: HierarchyPointNode<TrainingTreeNode>) =>
                    `translate(${node.x},${node.y})`
            );
    }
}
