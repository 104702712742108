import { AddCard } from './add-card';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImgDbWizardComponent } from '../../../img-db/component/img-db-wizard/img-db-wizard.component';

export class ImageDatabaseAddCard extends AddCard {
    public constructor(private modal: NgbModal) {
        super(
            'card.model.addCardImplementations.imageDatabaseAddCard.createNewScene'
        );
    }

    createNewModel(): void {
        this.modal.open(ImgDbWizardComponent, {
            size: 'lg',
            modalDialogClass: 'add-project-wizard-modal',
        });
    }
}
