<div class="rigth-info">
    <div class="row">
        <p class="label">{{ 'imgDb.dbInfo.imageCount' | translate }}</p>
        <p class="value">{{ dbInfo?.imageCount }}</p>
    </div>
    <div class="row">
        <p class="label">{{ 'imgDb.dbInfo.labelClassCount' | translate }}</p>
        <p class="value">{{ dbInfo?.categoryCount }}</p>
    </div>
    <div class="row">
        <div class="d-flex align-items-center">
            <div class="d-inline-block">
                <span class="label">{{
                    'imgDb.dbInfo.labelCount' | translate
                }}</span>
            </div>
            <div class="infotip">
                <mat-icon
                    fontSet="material-icons-outlined"
                    class="info-icon"
                    aria-hidden="true"
                    >info_outlined</mat-icon
                >
                <span class="infotiptext info-label"
                    >the total number of all set labels set(box count)
                </span>
            </div>
        </div>
        <div>
            <p class="value">{{ dbInfo?.annotationCount }}</p>
        </div>
    </div>
    <div class="row">
        <p class="label">{{ 'imgDb.dbInfo.created' | translate }}</p>
        <p class="value">{{ dbInfo?.creationDate | date: 'dd.MM.YYYY' }}</p>
    </div>
    <div class="row">
        <p class="label">{{ 'imgDb.dbInfo.modified' | translate }}</p>
        <p class="value">{{ dbInfo?.modifyDate | date: 'dd.MM.YYYY' }}</p>
    </div>
</div>
