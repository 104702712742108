import {
    composeLayer,
    WithActivationTanh,
    WithActivityRegularizer,
    WithBias,
    WithDropoutRate,
    WithKernel,
    WithRecurrentDropout,
    WithRecurrent,
    WithRNN,
    WithUnits,
    WithUseBias,
} from './tf-properties';

export class RNN extends composeLayer([WithRNN]) {}

export class SimpleRNN extends composeLayer([
    WithActivationTanh,
    WithActivityRegularizer,
    WithBias,
    WithDropoutRate,
    WithKernel,
    WithRNN,
    WithRecurrent,
    WithRecurrentDropout,
    WithUnits,
    WithUseBias,
]) {}

export class SimpleRNNCell extends composeLayer([
    WithActivationTanh,
    WithBias,
    WithDropoutRate,
    WithKernel,
    WithRecurrent,
    WithRecurrentDropout,
    WithUnits,
    WithUseBias,
]) {}
