export class RightClickMenuPosition {
    public position: string = 'absolute';
    public transform: string = `transform: translate(0px, 0px)`;
    public zIndex: number = 1;

    constructor(public top: string, public left: string) {
        this.top = `${top}px`;
        this.left = `${left}px`;
    }
}
