import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as Skill from './models/layer-namespace';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { handleError } from 'src/app/shared/http-utilities';

@Injectable({
    providedIn: 'root',
})
export class SkillArchitectureService {
    readonly baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = `${environment.backendUrl}/rest/skill-architecture`;
    }

    get(trainingId: string): Observable<Skill.SkillArchitectureEntity> {
        const url = `${this.baseUrl}/${trainingId}`;
        return this.http.get<Skill.SkillArchitectureEntity>(url);
    }

    patch(
        skillArchitecture: Skill.SkillArchitectureEntity
    ): Observable<Skill.SkillArchitectureEntity> {
        const url = `${this.baseUrl}/${skillArchitecture.id}`;
        return this.http
            .patch<Skill.SkillArchitectureEntity>(url, skillArchitecture)
            .pipe(catchError(handleError));
    }
}
