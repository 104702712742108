import { AbstractControl, ValidationErrors } from '@angular/forms';

export class ScaleValidator {
    static validateRange(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return {
                invalidScaleRange: {
                    inputValue: control.value,
                },
            };
        }

        const value: string = control.value.replace(',', '.');
        const parsedValue: number = parseFloat(value);

        return parsedValue >= 0
            ? null
            : {
                  invalidScaleRange: {
                      inputValue: control.value,
                  },
              };
    }
}
