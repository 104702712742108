<div class="tryb-input-container">
    <div class="main-container">
        <div class="limit-label left-label" *ngIf="showLimits">
            <p>
                {{ limits.lower }}
            </p>
        </div>
        <div class="limit-label left-label" *ngIf="!showLimits && leftLabel">
            <p>
                {{ leftLabel }}
            </p>
        </div>
        <div
            class="input-div"
            [ngClass]="{
                'show-limits': showLimits,
                'show-left-label': leftLabel && !showLimits
            }"
        >
            <input
                [ngClass]="{
                    'is-invalid': control.invalid
                }"
                [formControl]="control"
                #inputField
                type="text"
                placeholder="0"
                (keydown.escape)="onEscapeButtonPress()"
                (blur)="onBlur()"
                (keydown.enter)="onEnterKey()"
                (focus)="onFocus()"
            />

            <div class="error-item" *ngIf="control.errors">
                <app-display-errors
                    [control]="control"
                    [translationKey]="errorTranslationKey"
                ></app-display-errors>
            </div>

            <div class="rows-wrapper">
                <div class="row-up rows-item">
                    <em (click)="plusStep()" class="material-icons"
                        >expand_less</em
                    >
                </div>
                <div class="row-down rows-item">
                    <em (click)="minusStep()" class="material-icons"
                        >expand_more</em
                    >
                </div>
            </div>
        </div>
        <div class="limit-label right-label" *ngIf="showLimits">
            <p>
                {{ limits.upper }}
            </p>
        </div>
    </div>
</div>
