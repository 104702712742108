<table class="table">
    <thead>
    <tr>
        <th>
            {{ 'project.components.userProjects.projectImage' | translate }}
        </th>
        <th>
            {{ 'project.components.userProjects.projectName' | translate }}
        </th>
        <th>{{ 'project.components.userProjects.status' | translate }}</th>
        <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let userProject of projects">
        <td>
            <ngb-carousel
                [showNavigationArrows]="
                        userProject?.project?.pictures?.length > 1
                    "
                [interval]="0"
                class="project-picture"
            >
                <ng-template
                    ngbSlide
                    *ngFor="let picture of userProject.project.pictures"
                >
                    <img
                        src="{{ picture }}"
                        alt="Projektbild"
                        class="project-picture"
                    />
                </ng-template>
            </ngb-carousel>
        </td>
        <td>{{ userProject.project.name }}</td>
        <td>
            {{
            "project.components.userProjects.userProjectRelation." + userProject.relation_type.toLowerCase() | translate
            }}
        </td>
        <td>
            <button
                class="btn btn-danger"
                *ngIf="userProject.can_leave"
                (click)="leaveProject(userProject)"
            >
                {{
                'project.components.userProjects.leaveProject'
                    | translate
                }}
            </button>
            <button
                class="btn btn-danger"
                *ngIf="userProject.relation_type === 'REQUEST'"
                (click)="deleteJoinRequest(userProject)"
            >
                {{
                'project.components.userProjects.leaveDeleteJoinRequest'
                    | translate
                }}
            </button>
        </td>
    </tr>
    </tbody>
</table>
