import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
    AnnotationHttpResponse,
    VisualEvalAnnotation,
    VisualEvalDataSet,
    VisualEvalDataSetHttpResponse,
    VisualEvalSet,
    VisualEvalSetHttpResponse,
} from '../models/visual-eval-data-set';
import { ImgAnnotation } from '../../img-db/model/img-annotation';
import { ImgWithSelection } from '../../img-db/model/img-with-selection';

@Injectable({
    providedIn: 'root',
})
export class VideoAndIterationFileService {
    readonly restUrl: string;

    constructor(private http: HttpClient) {
        this.restUrl = environment.backendUrl;
    }

    getEvaluationVideo(uuid: string) {
        const url = `${this.restUrl}/rest/training/${uuid}/video`;
        return this.http.get(url, {
            observe: 'response',
            responseType: 'arraybuffer',
        });
    }

    getAllIterationFiles(
        uuid: string,
        prefix: string,
        after?: number
    ): Observable<string[]> {
        const url = `${this.restUrl}/rest/training/${uuid}/iterations`;
        let httpParams = new HttpParams();
        httpParams = httpParams.set('filePrefix', prefix);
        if (after !== undefined) {
            httpParams = httpParams.set('after', after + '');
        }
        return this.http.get<string[]>(url, { params: httpParams });
    }

    getIterationFile(uuid: string, file: string) {
        const url = `${this.restUrl}/rest/training/${uuid}/iterations/${file}`;
        return this.http.get(url, { responseType: 'text' });
    }

    getEvalVisualData(trainingId: string): Observable<VisualEvalDataSet> {
        const url: string = `${this.restUrl}/rest/training/${trainingId}/visualEvalData`;
        return this.http
            .get<VisualEvalDataSetHttpResponse>(url)
            .pipe(map(this.toVisualEvalDataSet));
    }

    toVisualEvalDataSet(
        dataSetResponse: VisualEvalDataSetHttpResponse
    ): VisualEvalDataSet {
        if (!dataSetResponse) {
            return null;
        }
        // copy evalSet
        const resEvalSet: VisualEvalSet[] = [];

        dataSetResponse.evalSet.forEach(
            (evalSetResponseItem: VisualEvalSetHttpResponse) => {
                // copy annotations
                const annotations: ImgAnnotation[] =
                    evalSetResponseItem.annotations.map(
                        (annotation: AnnotationHttpResponse): ImgAnnotation => {
                            return new ImgAnnotation(
                                null,
                                null,
                                annotation.categoryId.toString(),
                                annotation.x,
                                annotation.y,
                                annotation.width,
                                annotation.height
                            );
                        }
                    );

                // copy modelAnnotations
                const modelAnnotations: VisualEvalAnnotation[] =
                    evalSetResponseItem.modelAnnotations.map(
                        (annotation: AnnotationHttpResponse) => {
                            return new VisualEvalAnnotation(
                                null,
                                annotation.categoryId.toString(),
                                annotation.x,
                                annotation.y,
                                annotation.width,
                                annotation.height,
                                annotation.confidence
                            );
                        }
                    );

                const image: ImgWithSelection = {
                    id: evalSetResponseItem.location,
                    name: evalSetResponseItem.name,
                    url: evalSetResponseItem.location,
                    width: evalSetResponseItem.width,
                    height: evalSetResponseItem.height,
                } as ImgWithSelection;

                resEvalSet.push(
                    new VisualEvalSet(annotations, modelAnnotations, image)
                );
            }
        );

        return new VisualEvalDataSet(dataSetResponse.categories, resEvalSet);
    }
}
