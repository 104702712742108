import { Layer } from './layer';
import {
    composeLayer,
    WithDataFormat,
    WithHeightWidth,
    WithInterpolation,
    WithPadding1D,
    WithPadding2D,
    WithPadding3D,
} from './tf-properties';
import { TfInterpolation } from './tf-types';

export class Cropping1D extends Layer {
    cropping: [number, number] = [1, 1];
}

export class Cropping2D extends composeLayer([WithDataFormat]) {
    cropping: [[number, number], [number, number]] = [
        [0, 0],
        [0, 0],
    ];
}

export class Cropping3D extends composeLayer([WithDataFormat]) {
    cropping: [[number, number], [number, number], [number, number]] = [
        [1, 1],
        [1, 1],
        [1, 1],
    ];
}

export class CenterCrop extends composeLayer([WithHeightWidth]) {}

export class Reshape extends Layer {
    target_shape: number[];
}

export class Rescaling extends Layer {
    scale: number;
    offset = 0.0;
}

export class Resizing extends composeLayer([
    WithHeightWidth,
    WithInterpolation,
]) {
    crop_to_aspect_ratio = false;
}

export class Flatten extends composeLayer([WithDataFormat]) {}

export class Permute {
    dims: [number, number];
}

export class RepeatVector {
    n: number;
}

export class UpSampling1D {
    size = 2;
}

export class UpSampling2D extends composeLayer([
    WithDataFormat,
    WithInterpolation,
]) {
    size: [number, number] = [2, 2];
    interpolation: TfInterpolation = 'nearest';
}

export class UpSampling3D extends composeLayer([WithDataFormat]) {
    size: [number, number, number] = [2, 2, 2];
}

export class ZeroPadding1D extends composeLayer([WithPadding1D]) {}

export class ZeroPadding2D extends composeLayer([WithPadding2D]) {}

export class ZeroPadding3D extends composeLayer([
    WithDataFormat,
    WithPadding3D,
]) {}
