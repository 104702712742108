import { BehaviorSubject, Subject } from 'rxjs';
import { limit } from 'src/app/shared/services/number-translate.service';

export class ImgDbZoomService {
    // constant values for Manage Dialog
    static readonly MIN_IMAGES_PER_ROW: number = 3;
    static readonly MAX_IMAGES_PER_ROW: number = 10;
    static readonly DEFAULT_IMAGES_PER_ROW: number = 6;
    // constant values for Label Dialog
    static readonly MIN_IMAGE_ZOOM: number = 0;
    static readonly MAX_IMAGE_ZOOM: number = 13;
    static readonly DEFAULT_IMAGE_ZOOM: number =
        ImgDbZoomService.MIN_IMAGE_ZOOM;

    defaultWidthManageDialog: number =
        100 / ImgDbZoomService.DEFAULT_IMAGES_PER_ROW;
    currentImagesInRowManageDialog: number;
    currentWidthManageDialog: number;
    currentZoomLevelLabelDialog: number = ImgDbZoomService.DEFAULT_IMAGE_ZOOM;
    widthManageDialog = new BehaviorSubject<number>(
        this.defaultWidthManageDialog
    );
    imagesInRowManageDialog = new BehaviorSubject<number>(
        ImgDbZoomService.DEFAULT_IMAGES_PER_ROW
    );
    zoomLevelLabelDialog = new Subject<number>();

    setDefaultValue() {
        this.currentImagesInRowManageDialog =
            ImgDbZoomService.DEFAULT_IMAGES_PER_ROW;
        this.currentWidthManageDialog = this.defaultWidthManageDialog;
        this.provideWidthManageDialog();
        this.provideImagesInRowManageDialog();
    }

    /**
     * Set the zoom level for LabelingDialog without physically changing the zoom (viewIndex = 1)
     * @param zoomLevel number of zoom level
     */
    setCurrentZoomLevel(zoomLevel: number) {
        this.currentZoomLevelLabelDialog = limit(
            zoomLevel,
            ImgDbZoomService.MIN_IMAGE_ZOOM,
            ImgDbZoomService.MAX_IMAGE_ZOOM
        );
    }

    /**
     * This is a generic method that decide which method should be called depending on activeViewIndex
     * That means in which dialog w currently are
     * @param zoomAction "in", "out", "default"
     * @param activeViewIndex says for which dialog should width of image be calculated
     */
    handleZoom(zoomAction: string, activeViewIndex: number) {
        if (activeViewIndex === 0) {
            this.handleZoomInManageDialog(zoomAction);
        } else if (activeViewIndex === 1) {
            this.handleZoomInLabelDialog(zoomAction);
        }
    }

    /**
     * The method will calculate the current images in row and width for each image only for manage dialog
     * and then will emit both values via Subject
     * @param zoomAction can have 3 options: "in", "out", "default"
     */
    handleZoomInManageDialog(zoomAction: string) {
        if (!this.currentImagesInRowManageDialog) {
            this.currentImagesInRowManageDialog =
                ImgDbZoomService.DEFAULT_IMAGES_PER_ROW;
        }

        if (zoomAction === 'in') {
            if (
                this.currentImagesInRowManageDialog <=
                ImgDbZoomService.MIN_IMAGES_PER_ROW
            ) {
                return;
            }
            this.currentImagesInRowManageDialog--;
        }

        if (zoomAction === 'out') {
            if (
                this.currentImagesInRowManageDialog >=
                ImgDbZoomService.MAX_IMAGES_PER_ROW
            ) {
                return;
            }
            this.currentImagesInRowManageDialog++;
        }

        if (zoomAction === 'default') {
            if (
                this.currentImagesInRowManageDialog ===
                ImgDbZoomService.DEFAULT_IMAGES_PER_ROW
            ) {
                return;
            }
            this.currentImagesInRowManageDialog =
                ImgDbZoomService.DEFAULT_IMAGES_PER_ROW;
        }

        this.currentWidthManageDialog =
            100 / this.currentImagesInRowManageDialog;

        this.provideWidthManageDialog();
        this.provideImagesInRowManageDialog();
    }

    /**
     * @param zoomAction can have 3 options: "in", "out", "default"
     */
    handleZoomInLabelDialog(zoomAction: string) {
        if (zoomAction === 'in') {
            if (
                this.currentZoomLevelLabelDialog >=
                ImgDbZoomService.MAX_IMAGE_ZOOM
            ) {
                return;
            }
            this.currentZoomLevelLabelDialog++;
        }
        if (zoomAction === 'default') {
            if (
                this.currentZoomLevelLabelDialog ===
                ImgDbZoomService.DEFAULT_IMAGE_ZOOM
            ) {
                return;
            }
            this.currentZoomLevelLabelDialog =
                ImgDbZoomService.DEFAULT_IMAGE_ZOOM;
        }
        if (zoomAction === 'out') {
            if (
                this.currentZoomLevelLabelDialog <=
                ImgDbZoomService.DEFAULT_IMAGE_ZOOM
            ) {
                return;
            }
            this.currentZoomLevelLabelDialog--;
        }

        this.provideZoomLevelLabelDialog();
    }

    provideWidthManageDialog() {
        this.widthManageDialog.next(this.currentWidthManageDialog);
    }

    provideImagesInRowManageDialog() {
        this.imagesInRowManageDialog.next(this.currentImagesInRowManageDialog);
    }

    provideZoomLevelLabelDialog() {
        this.zoomLevelLabelDialog.next(this.currentZoomLevelLabelDialog);
    }

    getCurrentZoomLevel(activeViewIndex: number): number {
        if (activeViewIndex === 1) {
            return this.currentZoomLevelLabelDialog;
        } else {
            return this.currentImagesInRowManageDialog;
        }
    }

    getCurrentMax(activeViewIdx: number): number {
        if (activeViewIdx === 1) {
            return ImgDbZoomService.MAX_IMAGE_ZOOM;
        } else {
            // Zoom in, show larger but fewer images
            return ImgDbZoomService.MIN_IMAGES_PER_ROW;
        }
    }

    getCurrentMin(activeViewIdx: number): number {
        if (activeViewIdx === 1) {
            return ImgDbZoomService.MIN_IMAGE_ZOOM;
        } else {
            // Zoom out, show smaller but more images
            return ImgDbZoomService.MAX_IMAGES_PER_ROW;
        }
    }

    getCurrentDefault(activeViewIdx: number): number {
        if (activeViewIdx === 1) {
            return ImgDbZoomService.DEFAULT_IMAGE_ZOOM;
        } else {
            return ImgDbZoomService.DEFAULT_IMAGES_PER_ROW;
        }
    }
}
