import { Component, Input } from '@angular/core';
import { UserReview } from '../models/user-review';

@Component({
    selector: 'app-user-review',
    templateUrl: './user-review.component.html',
    styleUrls: ['./user-review.component.scss'],
})
export class UserReviewComponent {
    @Input()
    userReview: UserReview = { review: '', image: '', user: null };

    constructor() {}

    userInfo(): string {
        const user = this.userReview.user;
        let userInfo: string;
        if (user !== null) {
            userInfo = `${user.firstName} ${user.lastName} aus ${user.residence}, ${user.age} Jahre, ${user.job}`;
        } else {
            userInfo = '';
        }
        return userInfo;
    }
}
