<div class="cms-section-sidebar cms-section-sidebar-mobile-wrap boxed row">
    <div class="cms-section-sidebar-sidebar-content col-lg-4 col-xl-3">
        <div *ngFor="let categoryNavigationBlock of categoryNavigationBlocks">
            <app-cms-category-navigation [block]="categoryNavigationBlock">
            </app-cms-category-navigation>
        </div>
    </div>

    <div class="cms-section-sidebar-main-content col-lg-8 col-xl-9">
        <ng-template appCmsBlock></ng-template>
    </div>
</div>
