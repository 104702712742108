<div class="skill-architecture-selection">
    <div
        class="d-flex skill-architecture-selection-header"
    >
        <div
            *ngFor="let selectableCategory of selectableCategories"
            [id]="selectableCategory.name"
            [class.selected]="selectableCategory.name === selectedCategory.name"
            (click)="onCategoryClick(selectableCategory)"
            class="dragBox"
            [ngStyle]="{'color': selectableCategory.color }"
        >
            <span class="background" [ngStyle]="{'background-color': selectableCategory.color}"></span>
            {{ selectableCategory.name }}      
        </div>
    </div>
    <div
        class="row col-12 skill-architecture-selection-row"
        id="skill-architecture-selection-row"
        cdkDropList
        cdkDropListSortingDisabled
        cdkDropListOrientation="horizontal"
        [cdkDropListConnectedTo]="['skill-architecture']"
    >
        <div class="draggable"
            cdkDrag
            [cdkDragDisabled]="editingIsDisabled"
            [cdkDragData]="{name: layerType, color: selectedCategory.color}"
            [ngStyle]="{'background-color': selectedCategory.color}"
            *ngFor="let layerType of layers">
            {{ layerType }}
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path [attr.fill]="selectedCategory.dotsColor" d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>
            </svg>      
        </div>
    </div>
</div>