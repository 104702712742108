import { LoginRedirectionService } from '../../../../authentication/services/login-redirection.service';
import { DOCUMENT } from '@angular/common';
import {
    OnDestroy,
    ViewChildren,
    QueryList,
    Inject,
    Renderer2,
    HostListener,
    Component,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, take, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { UserService } from 'src/app/authentication/services/user.service';
import { MenuButton } from '../../models/menu-button';
import { MenuButtonComponent } from '../menu-button/menu-button.component';
import { DomSanitizer } from '@angular/platform-browser';
import { UserAuth } from 'src/app/authentication/profile/models/user-profile';
import { IdmOption } from 'src/app/authentication/profile/models/idm-option';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
    menuButtons: MenuButton[] = [];

    isOnMainSite = false;
    isLoggedIn = false;
    isUserAdmin = false;
    inHomePage = true;
    mobileMenuOpened = false;
    menuItemOpened = false;
    profilePicture: string = null;
    currentPage: string = '';
    isLoginModalDisplayed = false;
    keyCloakAuthUrl;
    idmOption: IdmOption;

    @ViewChildren('menuButton')
    menuButtonComponents: QueryList<MenuButtonComponent>;
    @ViewChild('iframe')
    iframe: ElementRef;

    subscriptions: Subscription[] = [];
    redirectedLogin: boolean = false;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private sanitizer: DomSanitizer,
        private redirectService: LoginRedirectionService
    ) {
        this.subscriptions.push(
            this.authenticationService.idmOption$.subscribe(
                (idmOption: IdmOption) => {
                    this.idmOption = idmOption;
                }
            )
        );
        this.subscriptions.push(
            this.userService.profilePicture$.subscribe(
                (pic) => (this.profilePicture = pic)
            )
        );

        // set activation highlight in menu
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentPage = '';
                if (this.checkPageParent(['/communityprojects'], event.url)) {
                    this.currentPage = 'community';
                }
                if (
                    this.checkPageParent(
                        ['/workspace', '/project', '/scenes', '/policy-search'],
                        event.url
                    )
                ) {
                    this.currentPage = 'workspace';
                }
                if (this.checkPageParent(['/home?id='], event.url)) {
                    this.currentPage = 'discover';
                }
            }
        });

        this.subscriptions.push(
            this.redirectService.loginTriggered$.subscribe(() => {
                this.redirectedLogin = true;
                this.onLogin();
            })
        );
    }

    ngOnInit(): void {
        this.isOnMainSite = true;
        this.inHomePage = true;
        this.authenticationService.isLoggedIn$.subscribe({
            next: (loggedIn) => {
                this.isLoggedIn = loggedIn;
                if (this.isLoggedIn) {
                    this.isOnMainSite = false;
                    this.isUserAdmin = this.authenticationService.isAdmin();
                }
            },
            error: (err) => {
                console.error('Observer got an error: ' + err);
            },
        });

        this.initData();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription?.unsubscribe();
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (event.target.innerWidth < 768) {
            this.onCloseOpenedMenu();
        }
    }

    onLogin(): void {
        this.keyCloakAuthUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.idmOption.loginUri
        );
        this.isLoginModalDisplayed = true;
    }

    onLogout(): void {
        this.authenticationService.signOut();
        this.isLoggedIn = false;
        this.inHomePage = true;
        this.isUserAdmin = false;
        this.userService.deleteProfilePicture();
    }

    onSignup(): void {
        this.keyCloakAuthUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.idmOption.registrationUri
        );
        this.isLoginModalDisplayed = true;
        this.inHomePage = false;
    }

    onIframeLoad() {
        if (
            this.iframe &&
            !this.iframe.nativeElement.contentDocument.location.href.includes(
                this.idmOption.label
            )
        ) {
            this.userService.getMe().subscribe((userAuth: UserAuth) => {
                this.authenticationService.saveUser(userAuth);
                this.isLoggedIn = !!userAuth.id;
                this.isLoginModalDisplayed = !this.isLoggedIn;

                if (this.isLoggedIn && this.redirectedLogin) {
                    this.redirectedLogin = false;
                    this.redirectService.loginCompleted$.next(true);
                }
            });
        }
    }

    onToggleMenuItems(clickedComponent: MenuButtonComponent): void {
        this.menuButtonComponents.forEach((component) => {
            if (clickedComponent !== component) {
                component.closeMenu();
            }
        });
    }

    onCloseOpenedMenuInNav(event: Event): void {
        let target = event.target as HTMLDivElement;

        if (target.classList.contains('mat-menu-trigger')) {
            return;
        }

        if (target.classList.contains('menu-arrow-icon')) {
            return;
        }

        if (target.classList.contains('mat-menu-text')) {
            return;
        }

        if (window.innerWidth >= 768) {
            this.onCloseOpenedMenu();
        }
    }

    onCloseOpenedMenu(): void {
        this.menuButtonComponents.forEach((component) => {
            if (component.opened) {
                component.closeMenu();
            }
        });
    }

    onChangeMenuItemStatus(status: boolean): void {
        this.menuItemOpened = status;
    }

    onToggleMobileMenu(open?: boolean): void {
        open !== undefined
            ? (this.mobileMenuOpened = open)
            : (this.mobileMenuOpened = !this.mobileMenuOpened);
        this.toggleNoScrollClassToBody();
        this.onCloseOpenedMenu();
    }

    private toggleNoScrollClassToBody(): void {
        this.mobileMenuOpened
            ? this.renderer.addClass(this.document.body, 'no_scroll')
            : this.renderer.removeClass(this.document.body, 'no_scroll');
    }

    private checkPageParent(paths: string[], eventURL: string): boolean {
        let result = false;
        paths.forEach((path) => {
            if (eventURL.indexOf(path) >= 0) {
                result = true;
            }
        });
        return result;
    }

    private initData(): void {
        this.authenticationService.initializationFinished$
            .pipe(
                take(1),
                tap(() => {
                    this.subscriptions.push(
                        this.authenticationService
                            .observableUserAuth()
                            .subscribe(() => {
                                this.updateMenuButtons();
                            })
                    );
                })
            )
            .subscribe();
    }

    private updateMenuButtons() {
        let buttons = [];
        if (
            this.authenticationService.currentUserValue.roles?.includes(
                'ROLE_USER_DISCOVER'
            )
        ) {
            buttons.push({
                title: 'navigation.menu.components.menu.discover.discoverTitle',
                elementId: 'discover',
                items: [
                    {
                        elementId: 'describe',
                        title: 'navigation.menu.components.menu.discover.describe.describeTitle',
                        description:
                            'navigation.menu.components.menu.discover.describe.describeDescr',
                        iconMaterial: {
                            iconName: 'info',
                        },
                        onClick: () => {
                            this.router.navigate(['/home'], {
                                queryParams: {
                                    id: 'what-is-tryb',
                                },
                            });
                            this.onToggleMobileMenu(false);
                        },
                    },
                    {
                        elementId: 'news',
                        title: 'navigation.menu.components.menu.discover.news.newsTitle',
                        description:
                            'navigation.menu.components.menu.discover.news.newsDescr',
                        iconMaterial: {
                            iconName: 'feed',
                        },
                        onClick: () => {
                            this.router.navigate(['/home'], {
                                queryParams: {
                                    id: 'news',
                                },
                            });
                            this.onToggleMobileMenu(false);
                        },
                    },
                ],
            });
        }

        buttons.push({
            title: 'navigation.menu.components.menu.community.communityTitle',
            elementId: 'community',
            items: [
                {
                    elementId: 'projects',
                    title: 'navigation.menu.components.menu.community.projects.projectsTitle',
                    description:
                        'navigation.menu.components.menu.community.projects.projectsDescr',
                    iconMaterial: {
                        iconName: 'collections',
                    },
                    onClick: () => {
                        this.router.navigate(['/communityprojects']);
                        this.onToggleMobileMenu(false);
                    },
                },
            ],
        });
        buttons.push({
            title: 'navigation.menu.components.menu.workspace.workspaceTitle',
            elementId: 'workspace',
            routerLink: '/workspace',
            items: [],
            onClick: () => {
                this.onToggleMobileMenu(false);
            },
        });
        this.menuButtons = buttons;
    }
}
