import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-input-paging-bar',
    templateUrl: './input-paging-bar.component.html',
    styleUrls: ['./input-paging-bar.component.scss'],
})
export class InputPagingBarComponent implements OnChanges {
    previousValue: number = 1;
    inputControl: FormControl = new FormControl(this.previousValue);
    timer: any;
    delayCallBack: number = 300;

    @ViewChild('numberInput') inputElement: ElementRef;

    @Input() maxValue: number = this.previousValue;
    @Input() currentValue: number = this.previousValue;
    @Input() tag: string;

    @Output() nextValue: EventEmitter<number> = new EventEmitter<number>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentValue) {
            this.inputControl.setValue(this.currentValue);
            this.previousValue = this.currentValue;
        }
    }

    formatValue() {
        let value: string = this.inputControl.value.replace(/\D/g, '');
        if (value.startsWith('0')) {
            value = '';
        }
        this.inputControl.setValue(value);
    }

    onNextClick() {
        if (this.inputValue < this.maxValue) {
            this.inputControl.setValue(this.inputValue + 1);
            this.onSubmit();
        }
    }

    onPreviousClick() {
        if (this.inputValue > 1) {
            this.inputControl.setValue(this.inputValue - 1);
            this.onSubmit();
        }
    }

    onEnterPress() {
        this.inputElement.nativeElement.blur();
    }

    onSubmit() {
        if (this.inputControl.value === '') {
            this.inputControl.setValue(this.previousValue);
            return;
        }
        if (this.inputValue >= this.maxValue) {
            this.previousValue = this.maxValue;
            this.inputControl.setValue(this.previousValue);
        }

        this.previousValue = this.inputValue;
        this.provideCurrentValue();
    }

    provideCurrentValue() {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.nextValue.emit(this.inputValue);
        }, this.delayCallBack);
    }

    get inputValue(): number {
        return +this.inputControl.value;
    }
}
