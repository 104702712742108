<mat-tab-group
    mat-stretch-tabs
    class="tryb-mat-group"
    (selectedIndexChange)="onTabIndexChange($event)"
>
    <mat-tab
        [disabled]="isCostFunctionInvalid"
        label="{{
            'costFunction.costFunctionEditor.targetPosition' | translate
        }}"
    >
        <app-position-tab-content
            [sceneId]="sceneId"
            xAxisLabel="{{
                'costFunction.costFunctionEditor.targetPositionXAxis'
                    | translate
            }}"
            yAxisLabel="{{
                'costFunction.costFunctionEditor.targetPositionYAxis'
                    | translate
            }}"
            zAxisLabel="{{
                'costFunction.costFunctionEditor.targetPositionZAxis'
                    | translate
            }}"
            [editingIsDisabled]="editingIsDisabled"
        ></app-position-tab-content>
    </mat-tab>
    <mat-tab
        [disabled]="isCostFunctionInvalid"
        label="{{ 'costFunction.costFunctionEditor.distances' | translate }}"
    >
        <app-distance-tab-content
            [sceneId]="sceneId"
            [editingIsDisabled]="editingIsDisabled"
            (disableFixedElementDrag)="onDisableFixedElementDrag($event)"
        ></app-distance-tab-content>
    </mat-tab>
    <mat-tab
        [disabled]="isCostFunctionInvalid"
        label="{{ 'costFunction.costFunctionEditor.orientations' | translate }}"
    >
        <app-orientation-tab-content
            [sceneId]="sceneId"
            [editingIsDisabled]="editingIsDisabled"
            xAxisLabel="{{
                'costFunction.costFunctionEditor.targetOrientationRoll'
                    | translate
            }}"
            yAxisLabel="{{
                'costFunction.costFunctionEditor.targetOrientationPitch'
                    | translate
            }}"
            zAxisLabel="{{
                'costFunction.costFunctionEditor.targetOrientationYaw'
                    | translate
            }}"
        ></app-orientation-tab-content>
    </mat-tab>
</mat-tab-group>
