import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormValidatorService } from '../../services/form-validator.service';

@Component({
    selector: 'app-editable-label',
    templateUrl: './editable-label.component.html',
    styleUrls: ['./editable-label.component.scss'],
})
export class EditableLabelComponent implements OnInit, OnChanges {
    @Output()
    update: EventEmitter<string> = new EventEmitter();

    @Input()
    editable: boolean;

    @Input()
    label: string;

    @Input()
    value: string;
    data: string;
    inputGroup: FormGroup;
    formControlName: string = 'inputValue';

    @ViewChild('dbName') inputElementRef: ElementRef;

    constructor(private formValidatorService: FormValidatorService) {}

    ngOnInit(): void {
        //do not edit input variable
        const control = this.formValidatorService.getControlByType('name');
        control.setValue(this.value);
        this.inputGroup = new FormGroup({ [this.formControlName]: control });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.value = changes.value.currentValue;
            if (this.inputGroup) {
                this.dbNameControl.setValue(this.value);
            }
        }
    }

    onValueSubmit() {
        this.trimInputValue();
        this.handleFocus();

        if (this.dbNameInvalid) {
            return;
        }

        let newInputValue = this.dbNameControl.value;
        if (this.value !== newInputValue) {
            this.update.emit(newInputValue);
        }
    }

    public getValue(): string {
        return this.value;
    }

    get dbNameControl(): FormControl {
        return this.inputGroup.get(this.formControlName) as FormControl;
    }

    handleFocus() {
        if (this.dbNameInvalid) {
            this.inputElementRef.nativeElement.focus();
        } else {
            this.inputElementRef.nativeElement.blur();
        }
    }

    get dbNameInvalid(): boolean {
        return this.dbNameControl.status === 'INVALID';
    }

    trimInputValue() {
        if (this.dbNameControl.value !== null) {
            this.dbNameControl.setValue(this.dbNameControl.value.trim());
        }
    }

    onEscape() {
        this.dbNameControl.setValue(this.value);
        this.handleFocus();
    }
}
