<div class="container-fluid p-0">
    <div class="col-12 p-0">
        <app-header-box [name]="trainingName"></app-header-box>
    </div>
</div>
<div class="skill-architecture-selection">
    <div class="d-flex skill-architecture-selection-header">
        <div
            *ngIf="trainingType === 'RL'"
            id="observationTab"
            [class.selected]="selectedIndex === 0"
            class="dragBox"
            (click)="changeSelection(0)"
        >
            <span class="background"></span>
            {{ 'neuralNetwork.observations.tabName' | translate }}
        </div>
        <div
            id="NetworkArchitectureTab"
            [class.selected]="selectedIndex === 1"
            class="dragBox"
            (click)="changeSelection(1)"
        >
            <span class="background"></span>
            {{ 'neuralNetwork.networkArchitecture.tabName' | translate }}
        </div>
        <div
            id="codeEditor"
            [class.selected]="selectedIndex === 2"
            class="dragBox"
            (click)="changeSelection(2)"
        >
            <span class="background"></span>
            {{ 'neuralNetwork.codeEditor.tabName' | translate }}
        </div>
    </div>
    <app-skill-architecture
        *ngIf="selectedIndex === 1"
        [editingIsDisabled]="editingIsDisabled"
    ></app-skill-architecture>
    <app-observation-element-details
        *ngIf="selectedIndex === 0"
        [trainingId]="trainingId"
        [editingIsDisabled]="editingIsDisabled"
    ></app-observation-element-details>
    <app-custom-randomization
        *ngIf="selectedIndex === 2"
        [trainingId]="trainingId"
    ></app-custom-randomization>
</div>
