import { CdkDragDrop, Point } from '@angular/cdk/drag-drop';

export interface SkillArchitectureDropEventData {
    color: string;
    name: string;
}

export interface SkillArchitectureDropEvent {
    dropPoint: Point;
    containerPosition: { top: number; left: number };
    data: SkillArchitectureDropEventData;
}

export function mapToSkillArchitectureDropEvent(
    event: CdkDragDrop<string>
): SkillArchitectureDropEvent {
    return {
        dropPoint: event.dropPoint,
        containerPosition:
            event.container.element.nativeElement.getClientRects()[0],
        data: event.item.data,
    };
}
