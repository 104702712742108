import { environment } from 'src/environments/environment';
import { CostFunctionRow } from './cost-function-row';

export class PositionCostFunctionRow extends CostFunctionRow {
    sceneElementId: string;
    name: string;
    positionX: string = environment.costFunction.position.default;
    positionY: string = environment.costFunction.position.default;
    positionZ: string = environment.costFunction.position.default;
    positionXActive: boolean;
    positionYActive: boolean;
    positionZActive: boolean;
}
