<div class="container">
    <div class="nav-header">
        <div class="row header">
            <h4 class="nav-name">
                {{
                    'admin.components.adminNavigation.navigationName'
                        | translate
                }}
            </h4>
        </div>
    </div>
    <div class="nav flex-column nav-pills">
        <a
            [ngClass]="getStyleClassForNavItem('Benutzer')"
            (click)="clickNavigation('Benutzer')"
        >
            <em class="bi bi-people icon"></em
            >{{ 'admin.components.adminNavigation.users' | translate }}</a
        >
        <a
            [ngClass]="getStyleClassForNavItem('Trainings')"
            (click)="clickNavigation('Trainings')"
        >
            <em class="bi bi-journal-richtext icon"></em>
            {{ 'admin.components.adminNavigation.trainings' | translate }}</a
        >
        <a
            [ngClass]="getStyleClassForNavItem('Performance')"
            (click)="clickNavigation('Performance')"
        >
            <em class="bi bi-sliders icon"></em
            >{{ 'admin.components.adminNavigation.performance' | translate }}</a
        >
        <a
            [ngClass]="getStyleClassForNavItem('Token')"
            (click)="clickNavigation('Token')"
        >
            <em class="bi bi-egg icon"></em
            >{{ 'admin.components.adminNavigation.token' | translate }}</a
        >
    </div>
</div>
