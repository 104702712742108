import { BehaviorSubject } from 'rxjs';
import { nonNil } from 'src/app/shared/utility';
import { Object3D } from 'three';
import { SceneElement } from './scene-element';
import { StateOfSceneElement } from './state-of-scene-element';

export class LoadedSceneElement {
    public sceneElement: SceneElement;
    private _promiseObject?: Promise<Object3D>;
    public object3D$ = new BehaviorSubject<Object3D>(null);
    public state: StateOfSceneElement;

    public set promiseObject(promise: Promise<Object3D>) {
        this._promiseObject = promise;
        this._promiseObject.then(this.object3D$.next.bind(this.object3D$));
    }

    public get hasBeenSetBefore() {
        return nonNil(this._promiseObject);
    }
}
