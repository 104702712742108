/**
 * Root Object, representing ImageDB
 * !!!Name is shorten becaus of colision with default dom.Image!!!
 */
export class ImgDb {
    private _id: string;
    public name: string;
    private _userId: string;
    creationDate: Date;
    modifyDate: Date;

    constructor(id: string, name: string, userId: string) {
        this._id = id;
        this.name = name;
        this._userId = userId;
    }

    public get id(): string {
        return this._id;
    }

    public get userId(): string {
        return this._userId;
    }
}
