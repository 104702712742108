<div class="tree">
    <ng-container *ngFor="let keyValuePair of classificatorTree | keyvalue">
        <div
            class="node"
            [ngClass]="{
                'folded-out': classificatorTree.get(keyValuePair.key).open
            }"
        >
            <div
                class="node-data"
                (click)="toggleClassificatorDropdown(keyValuePair.key)"
            >
                <div class="icon">
                    <span
                        class="material-icons"
                        *ngIf="!classificatorTree.get(keyValuePair.key).open"
                        >arrow_right</span
                    >
                    <span
                        class="material-icons"
                        *ngIf="classificatorTree.get(keyValuePair.key).open"
                        >arrow_drop_down</span
                    >
                </div>
                <div
                    class="color-dot"
                    [ngStyle]="{ backgroundColor: getColor(keyValuePair.key) }"
                ></div>
                <p class="name">{{ keyValuePair.key }}</p>
                <div class="children-count">
                    {{ classificatorTree.get(keyValuePair.key).items.length }}
                </div>
            </div>
            <div class="children">
                <ng-container
                    *ngFor="
                        let classificatorTreeItem of classificatorTree.get(
                            keyValuePair.key
                        ).items
                    "
                >
                    <div
                        class="node-data"
                        [ngClass]="{ active: classificatorTreeItem.active }"
                        (click)="selectClassificator(classificatorTreeItem)"
                    >
                        <p class="child-name">{{ keyValuePair.key }}</p>
                        <div
                            class="delete"
                            (click)="deleteAnnotation(classificatorTreeItem)"
                        >
                            <span class="material-icons">close</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
