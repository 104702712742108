import { Injectable } from '@angular/core';

type Position = { x: number; y: number };

@Injectable({
    providedIn: 'root',
})
export class TreeNodePositionService {
    private mapping: Map<string, Position> = new Map<string, Position>();

    constructor() {}

    getPosition(uuid: string): Position {
        return this.mapping.get(uuid);
    }

    setPosition(uuid: string, position: Position): void {
        this.mapping.set(uuid, position);
    }
}
